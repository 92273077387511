import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material';
import { Router} from '@angular/router';

@Component({
  selector: 'app-counter-sale-end-bye',
  templateUrl: './counter-sale-end-bye.component.html',
  styleUrls: ['./counter-sale-end-bye.component.css']
})
export class CounterSaleEndByeComponent implements OnInit {
  partialCutMin = {
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      }
    }
  };
  partialCutList = [];
  blindSaleTurn = {
    main:{
      uuid:'',
      totalClosing:0,
      taxClosing:0,
      subTotalClosing:0,
      dateClosing:''
    },
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:'',
          fullName:''
        }
      }
    }
  };
  saleShift = {
    main:{
      uuid:'',
      shiftStartDate:'',
      shiftEndDate:'',
      shiftOpeningAmount:0,
      shiftClosingAmount:0,
      open:true
    },
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:'',
          fullName:''
        }
      },
      currency:{
        main:{
          uuid:''
        }
      }
    }
  };
  metadata = {
    total:0,
    totalVales:0,
    totalCredito:0,
    totalPaymenMethods:{
      paymentMethod:'Total',
      total:0
    }
  }
  paymentMethods = [
    {
      paymentMethod:'Efectivo',
      label:'Efectivo',
      total:0
    },{
      paymentMethod:'Tarjeta de débito',
      label:'Tarjeta de débito',
      total:0
    },{
      paymentMethod:'Tarjeta de crédito',
      label:'Tarjeta de crédito',
      total:0
    },{
      paymentMethod:'Cheque',
      label:'Cheque',
      total:0
    },{
      paymentMethod:'Transferencia electrónica de fondos',
      label:'Transferencia',
      total:0
    },{
      paymentMethod:'Credito',
      label:'Crédito',
      total:0
    }
  ];

  constructor(private session: SessionService,
    public loading: LoadingService,
    public snackBar: MatSnackBar,
    public router:Router
  ) { }

  ngOnInit() {
    let resp :any =  this.session.getSaleShift();
    this.saleShift = resp;
    this.loading.show(true,"Cargando");
    this.session.getRequest("saleShift:get",this.saleShift).subscribe((data:any)=>{
      this.saleShift = data.object;
      this.getPartuialCut();
      for(let i = 0; i < this.paymentMethods.length; i++){
        this.getTotalByPaymentMethod(this.paymentMethods[i]);
      }
      this.loading.hide();
    });
  }

  /**función para obtener el total de ventas por forma de pago
  */
  getTotalByPaymentMethod(object){
    this.session.getRequest("sale:getTotalBySaleShiftAndPaymentMethod",{complement: { paymentMethod: object.paymentMethod },system:{saleShift:{main:{uuid:this.saleShift.main.uuid}}}}).subscribe((data:any)=>{
      object.total = data.object.total;
      this.metadata.totalPaymenMethods.total = this.metadata.totalPaymenMethods.total + object.total;
    },error=>{
      console.log(error);
    })
  }

  /**funcionalidad para para obtener los cortes parciales de un turno.
  */
  getPartuialCut(){
    this.partialCutMin.system.saleShift.main.uuid = this.saleShift.main.uuid;
    this.session.getRequest("partialCut:findAllBySaleShift",this.partialCutMin).subscribe((data:any)=>{
      this.partialCutList = data.object.instanceList;
      this.metadata.total = 0;
      for(let item of this.partialCutList){
        this.metadata.total = this.metadata.total + item.main.amount;
      }
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    });
  }

  /**función para obtener el detalle de ventas por tipos de pago segun el turno
  */
  getSalesByShiftAndPaymentMethod(paymentMethod){
    let object = {
      complement:{
        paymentMethod:paymentMethod
      },
      system:{
        saleShift:{
          main:{
            uuid:this.saleShift.main.uuid
          }
        }
      }
    }
    this.session.getRequest("saleShift:findByPaymentMethod",object).subscribe((data:any)=>{
      console.log(data);
      if(paymentMethod == 'Vale'){
        this.metadata.totalVales =  data.object;
      }else{
        this.metadata.totalCredito = data.object;
      }
    },error=>{
      console.log("error:saleShift:findByPaymentMethod",error);
    })
  }

  loggout(){
    this.session.logout().then(()=>{
      this.snackBar.open("Nos vemos pronto...", 'Aceptar', {duration: 5000});
      this.router.navigate(['/']);
    })
  }

}
