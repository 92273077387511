import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import {  MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-purchase-generate-details',
  templateUrl: './purchase-generate-details.component.html',
  styleUrls: ['./purchase-generate-details.component.css']
})
export class PurchaseGenerateDetailsComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  nationalPurchaseModel = {
    main: {
      uuid: '',
      description: '',
      subtotal: 0.0,
      total: 0.0,
      tax: 0.0,
      status: 'PaidReceived'
    },
    system: {
      products: [],
      warehouse: {
        main: {
          uuid: '',
          name: ''
        }
      },
      user: {
        main: {
          username: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      provider: {
        main: {
          uuid: ''
        }
      }
    },
    complement: {
      billProvider: '',
      dateOrderProvider: ''
    }
  };

  metadata = {
    router:'/admin/purchase/generate',
    total:0,
    tax:0,
    subtotal:0
  }

  object = JSON.parse(JSON.stringify(this.nationalPurchaseModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog, public location:Location) {
    switch (this.session.getUser().system.objectType) {
      case 'Branch': {
        let branch: any = this.session.getSessionObject();
        this.metadata.router = '/counter/purchase/generate';
        break;
      }
      case 'Company': {
        this.metadata.router = '/admin/purchase/generate';
        break;
      }
    };
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        this.loadingService.hide()
      } else {
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('nationalPurchase:get', this.object).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          this.calculate();
          this.loadingService.hide();
        },
          (error) => {
            this.loadingService.hide();
            console.log('Error:nationalPurchase:get', error)
          })
      }
    });
  }
  //función para calcular total de Compra
  calculate(){
    this.metadata.total = 0;
    this.metadata.subtotal = 0;
    this.metadata.tax = 0;
    for(let item of this.object.system.products){
      this.metadata.subtotal = this.metadata.subtotal;
      this.metadata.tax = this.metadata.tax + item.main.tax;
    }
    this.metadata.total = this.metadata.tax + this.metadata.subtotal;

  }
  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  back(){
    this.location.back();
  }
}
