import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.module';
import { LoadingService } from '../../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-adjustment-type-update',
  templateUrl: './adjustment-type-update.component.html',
  styleUrls: ['./adjustment-type-update.component.css']
})
export class AdjustmentTypeUpdateComponent implements OnInit {
  complexForm: FormGroup;
  adjustmentTypeList = [];
  adjustmentTypeModel = {
    main: {
      uuid: '',
      description: '',
      typeMovement: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };
  object = JSON.parse(JSON.stringify(this.adjustmentTypeModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdjustmentTypeUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'description': [null, Validators.required]
    })
   }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('adjustmentType:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:adjustmentType:get', error)
        })
    }
  }

  send(object) {
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("adjustmentType:update", object).subscribe(
      (data: any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("maker:update", error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'adjustmentType:001';
    }
    this.dialogRef.close(object);
  }

}
