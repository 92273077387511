import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { ProductCategoriesUpdateComponent } from './product-categories-update/product-categories-update.component';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { ITreeOptions } from 'angular-tree-component';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  productFamilyMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      },
      status:true
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  productFamilyList = [];
  customTemplateStringOptions: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  }
  metadata = {
    object:{
      main:{
        uuid:'',
        name:'',
        code:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) {
    this.productFamilyMin.system.company.main = this.session.getSessionObject().main;
  }

  ngOnInit() {
    this.treeViewCategories();
  }

  treeViewCategories() {
    this.loadingService.wait();
    this.metadata.object.main.uuid ="";
    this.metadata.object.main.name ="";
    this.session.getRequest("productFamily:treeviewByCompanyAndStatus", this.productFamilyMin).subscribe(
      (data: any) => {
        this.loadingService.hide();
        this.productFamilyList = data.object;
      },
      error => {
        console.log("productFamily:treeviewByCompanyAndStatus", error);
        this.loadingService.hide();
      });
  }

  /** funcionalidad para crear una categoria
  */
  updateCategory(uuid){
    const dialogRef = this.dialog.open(ProductCategoriesUpdateComponent, {
      width: '50',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.treeViewCategories();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /** función que se ejecuta cuando se selecciona un elemento del arbol
  */
  onEvent(event) {
    console.log(event.node.data);
    this.metadata.object.main.uuid =event.node.data.uuid;
    this.metadata.object.main.name =event.node.data.name;
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  disable(object){
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest('productFamily:get',{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      let objectAux = JSON.parse(JSON.stringify(data.object));
      objectAux.system.status = false;
      this.session.getRequest("productFamily:update",objectAux).subscribe((data:any)=>{
        this.snackBar.open('El registro se desactivó correctamente', '', {
          duration: 8000
        });
        for(let  i = 0; i < this.productFamilyList.length; i++){
          if(this.productFamilyList[i].uuid == objectAux.main.uuid){
            this.productFamilyList.splice(i,1);
          }
        }
        this.treeViewCategories();
        this.loadingService.hide();
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error:productFamily:get',error)
    })

  }

}
