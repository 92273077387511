import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-sale-dashboard',
  templateUrl: './sale-dashboard.component.html',
  styleUrls: ['./sale-dashboard.component.css']
})
export class SaleDashboardComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    dates: {
      startDate: moment().clone().startOf('month').toISOString(),
      endDate: moment().clone().endOf('month').toISOString(),
    },
    sale: {
      main: {
        expeditionDateStart: moment().clone().startOf('month').format('YYYY-MM-DD hh:mm:ss'),
        expeditionDateEnd: moment().clone().endOf('month').format('YYYY-MM-DD hh:mm:ss'),
      }
    },
    startDate: '',
    endDate: '',
    openAmount: 0.0,
    totalSpending: 0.0,
    totalSpendingGeneral: 0.0,
    honoraryGuards: 0.0,
    totalPatient: 0
  }
  productFamilyList = [];
  saleProductFamilyList = [];
  paymentMethods = ['Efectivo','Tarjeta de débito','Transferencia','Tarjeta de crédito','Mexpago','Vale','Credito'];

  // Grafica de pastel
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left',
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartLabels2: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartData2: SingleDataSet = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartColors = [{
    backgroundColor: [
      'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
       'rgba(201,190,10,1)', 'rgba(275,220,10,1)'
    ]
  }];
  //Grafica de barras
  public barChartLabels: Label[] = [];
  public barChartLabels2: Label[] = [];
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: 'rgba(3,206,205,1)' },
    { backgroundColor: 'rgba(91,15,205,1)' },
    { backgroundColor: 'rgba(51,215,245,1)' },
    { backgroundColor: 'rgba(244,100,171,1)' },
    { backgroundColor: 'rgba(10,110,200,1)' },
    { backgroundColor: 'rgba(245,135,51,1)' },
    { backgroundColor: 'rgba(175,2,110,1)' },
    { backgroundColor: 'rgba(275,220,10,1)' },
    { backgroundColor: 'rgba(255,226,205,1)' },
    { backgroundColor: 'rgba(133,100,2,1)' },
  ]
  public barChartData: ChartDataSets[] = [
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Efectivo' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Tarjeta de débito' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Transferencia' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Tarjeta de crédito' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Mexpago' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Transferencia' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Vale' },
    // { data: [0.1, 0.1, 0.1, 0.1, 0.1], label: 'Credito' }
  ];
  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit() {
    // Obtenemos la familia de produtos
    this.findAllByCompany().then((productFamily: any) => {
      for (var x = 0; x < productFamily.object.length; x++) {
        let saleProductFamily = {
          name: productFamily.object[x].name,
          value: 0,
        }
        this.findAllSaleByProductFamilyAndDateBetween(productFamily.object[x]).then((object: any) => {
          if (object.object.value > 0) {
            saleProductFamily.value = object.object.value;
            this.saleProductFamilyList.push(saleProductFamily);
          }
        });
      }
    });

    // Obtenemos las Sucursales (cedis)
    this.findAllByStatus().then((branches: any)=> {
      this.pieChartLabels = [];
      for (var y=0; y<branches.object.instanceList.length; y++) {
        this.pieChartLabels.push(branches.object.instanceList[y].main.name);
        // this.barChartLabels.push(branches.object.instanceList[y].main.name);
        let label = branches.object.instanceList[y].main.name;
        this.findAllByBranchAndPeriod(branches.object.instanceList[y]).then((object: any) =>  {
          this.pieChartData.push(object.object.value);
        });
      }
    });

    // Obtenemos por metodo de pago
    this.pieChartLabels2=[];
    for (let z=0; z<this.paymentMethods.length; z++) {
      this.findAllByPaymentMethod(this.paymentMethods[z]).then((object: any) => {
        this.pieChartLabels2.push(this.paymentMethods[z]);
        this.pieChartData2.push(object.object.value);
      });
    }

    // Obtenemos las sucursales (cedis)
    this.barChartLabels = [];
    this.barChartData = [];
    this.findAllByStatus().then((branches: any)=> {
      // Recorremos los centros de costo
      for (var y=0; y<branches.object.instanceList.length; y++) {
        // Recorremos los metodos de pago
        this.barChartLabels.push(branches.object.instanceList[y].main.name);
        for (let z=0; z<this.paymentMethods.length; z++) {
          this.findAllByPaymentMethodAndBranchAndPeriod(this.paymentMethods[z], branches.object.instanceList[y]).then((object: any) =>  {
            // console.log('barra ', object.object.value);
            let ubication = -1;
            for (let x=0; x<this.barChartData.length; x++) {
              if(this.barChartData[x].label == this.paymentMethods[z]) {
                ubication = x;
              }
            }
            if (ubication == -1) {
              this.barChartData.push({ data: [object.object.value], label: this.paymentMethods[z] });
            } else {
              this.barChartData[ubication].data.push(object.object.value)
            }
          });
        }
      }
    });
  }

  findAllByPaymentMethodAndBranchAndPeriod(paymentMethod, branch) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByPaymentMethodAndBranchAndPeriod", {
        complement: {
          paymentMethod: paymentMethod
        },
        system: {
          branch: branch,
          sale: this.metadata.sale,
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByPaymentMethodAndBranchAndPeriod", error);
        this.loadingService.hide();
      })
    });
  }

  findAllByPaymentMethodAndWebAndPeriod(paymentMethod) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByPaymentMethodAndWebAndPeriod", {
        complement: {
          paymentMethod: paymentMethod
        },
        system: {
          sale: this.metadata.sale,
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByPaymentMethodAndWebAndPeriod", error);
        this.loadingService.hide();
      })
    });
  }

  findAllByPaymentMethod(paymentMethod) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByPaymentMethodAndPeriod", {
        complement: {
          paymentMethod: paymentMethod
        },
        system: {
          sale: this.metadata.sale,
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByPaymentMethodAndPeriod", error);
        this.loadingService.hide();
      })
    });
  }

  findAllByBranchAndPeriod(branch) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByBranchAndPeriod", {
        system: {
          sale: this.metadata.sale,
          branch: branch
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByBranchAndPeriod", error);
        this.loadingService.hide();
      })
    });
  }

  findAllByWebAndPeriod() {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByWebAndPeriod", {
        system: {
          sale: this.metadata.sale
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByWebAndPeriod", error);
        this.loadingService.hide();
      })
    });
  }

  selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.sale.main.expeditionDateStart = moment(event).format('YYYY-MM-DD hh:mm:ss');
    } else {
      this.metadata.sale.main.expeditionDateEnd = moment(event).format('YYYY-MM-DD hh:mm:ss');
    }
    if (this.metadata.sale.main.expeditionDateStart <= this.metadata.sale.main.expeditionDateEnd) {
      // Obtenemos el total de pacientes por tipo de ocupación
      this.findAllByCompany().then((productFamily: any) => {
        this.saleProductFamilyList = [];
        for (var x = 0; x < productFamily.object.length; x++) {
          let saleProductFamily = {
            name: productFamily.object[x].name,
            value: 0,
          }
          this.findAllSaleByProductFamilyAndDateBetween(productFamily.object[x]).then((object: any) => {
            if (object.object.value > 0) {
              saleProductFamily.value = object.object.value;
              this.saleProductFamilyList.push(saleProductFamily);
            }
          });
        }
      });

      // Obtenemos las Sucursales (cedis)
      this.findAllByStatus().then((branches: any)=> {
        this.pieChartLabels = [];
        this.pieChartData = [];
        for (var y=0; y<branches.object.instanceList.length; y++) {
          this.pieChartLabels.push(branches.object.instanceList[y].main.name);
          // this.barChartLabels.push(branches.object.instanceList[y].main.name);
          let label = branches.object.instanceList[y].main.name;
          this.findAllByBranchAndPeriod(branches.object.instanceList[y]).then((object: any) =>  {
            this.pieChartData.push(object.object.value);
          });
        }
      });

      // Obtenemos por metodo de pago
      this.pieChartLabels2=[];
      this.pieChartData2=[];
      for (let z=0; z<this.paymentMethods.length; z++) {
        this.findAllByPaymentMethod(this.paymentMethods[z]).then((object: any) => {
          this.pieChartLabels2.push(this.paymentMethods[z]);
          this.pieChartData2.push(object.object.value);
        });
      }

      // Obtenemos las sucursales (cedis)
      this.barChartLabels = [];
      this.barChartData = [];
      this.findAllByStatus().then((branches: any)=> {
        // Recorremos los centros de costo
        for (var y=0; y<branches.object.instanceList.length; y++) {
          // Recorremos los metodos de pago
          this.barChartLabels.push(branches.object.instanceList[y].main.name);
          for (let z=0; z<this.paymentMethods.length; z++) {
            this.findAllByPaymentMethodAndBranchAndPeriod(this.paymentMethods[z], branches.object.instanceList[y]).then((object: any) =>  {
              let ubication = -1;
              for (let x=0; x<this.barChartData.length; x++) {
                if(this.barChartData[x].label == this.paymentMethods[z]) {
                  ubication = x;
                }
              }
              if (ubication == -1) {
                this.barChartData.push({ data: [object.object.value], label: this.paymentMethods[z] });
              } else {
                this.barChartData[ubication].data.push(object.object.value)
              }
            });
          }
        }
      });
    }
  }

  changeTab(event) {
    console.log('Cambio de Tab ', event);
  }

  findAllSaleByProductFamilyAndDateBetween(productFamily) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByProductFamilyAndDateBetween", {
        system: {
          productFamily: productFamily,
          sale: this.metadata.sale
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByProductFamilyAndDateBetween", error);
        this.loadingService.hide();
      })
    });
  }

  findAllByStatus() {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("branch:findAllByStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("branch:findAllByStatus", error);
          this.loadingService.hide();
        });
    });
  }

  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("productFamily:treeviewByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("productFamily:treeviewByCompanyAndStatus", error);
          this.loadingService.hide();
        });
    });
  }

  // funcion para controlar si se abre o cierra el menu
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
