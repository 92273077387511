import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-inventory-kardex',
  templateUrl: './inventory-kardex.component.html',
  styleUrls: ['./inventory-kardex.component.css']
})
export class InventoryKardexComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  kardexList = [];
  warehouseList = [];
  productList = [];
  productMin={
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  kardexMin = {
    complement:{
      startDate:'',
      endDate:'',
      stockTotal:0,
      stockWarehouse:0
    },
    system:{
      company:{
        main:{
          uuid: ''
        }
      },
      product:{
        main:{
          uuid:'',
          code:'',
          name:''
        },
        system:{
          unitMeasure:{
            main:{
              name:''
            }
          }
        }
      },
      warehouse:{
        main:{
          uuid:'',
          name:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    startDate:moment(new Date()).set('date', 1).toISOString(),
    endDate:moment(new Date()).toISOString(),
    searching:false,
  }

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.kardexList = [];
        this.kardexMin.filter.name = 'searchElement';
      }else{
        this.kardexList = [];
        this.kardexMin.filter.name = '';
        this.kardexMin.filter.value = '';
        val = 'false'
      }
      this.getkardexList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
}
  ngOnInit() {
    this.getWarehouseList();
  }

  getkardexList(){
    if(this.kardexMin.system.warehouse.main.uuid != ''){
      this.loadingService.wait();
      this.kardexList = [];
      // verificamos en que pagina verificamos
      this.kardexMin.complement.startDate = moment(this.metadata.startDate).format('YYYY-MM-DD');
      this.kardexMin.complement.endDate = moment(this.metadata.endDate).format('YYYY-MM-DD');
      this.kardexMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      for(let item of this.warehouseList){
        if(item.main.uuid == this.kardexMin.system.warehouse.main.uuid){
          this.kardexMin.system.warehouse = JSON.parse(JSON.stringify(item));
          break;
        }
      }
      this.session.getRequest("dependentMethods:kardexByProduct", this.kardexMin).subscribe(
        (data: any) => {
          this.kardexList = data.object.instanceList;
          // console.log("list", this.kardexList)
          this.kardexMin.complement.stockTotal =data.object.stockTotal;
          this.kardexMin.complement.stockWarehouse =data.object.stockWarehouse
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("dependentMethods:kardexByProduct", error);
          this.loadingService.hide();
        }
      );
    }
  }
  // Funcion para obtener la lista de almacenes
  getWarehouseList(){
    this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
      (data: any) => {
        this.warehouseList = data.object.instanceList;
      },
      error => {
        console.log("warehouse:findAllByCompanyAndStatus", error);
      });
  }
  // Funcion para buscar productos.
  productFindAllByNameLike(name) {
      if(name != ''){
        this.productList = [];
        this.metadata.searching = true;
        this.productMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        this.productMin.filter.value = name;
        this.session.getRequest('product:findAllByCompany',this.productMin).subscribe(
          (data:any)=>{
            this.productList = data.object.instanceList;
            this.metadata.searching = false;
          },
          error => {
            console.log("product:findAllByCompany",error);
            this.snackBar.open(error.message, 'Error', {duration: 5000});
          })
      }else{
        this.productList = [];
      }
  }
  // Accion a ejecutar cuando se seleccione un producto.
  productToSelectedList(object){
    this.kardexMin.system.product = object;
    this.productList = [];
    this.productMin.filter.value = object.main.name;
    // console.log(this.kardexMin);
    this.getkardexList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
  getFormatDate(object){
    return moment(object).format('DD-MM-YYYY HH:mm')
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object){
    for(let i=0; i < this.kardexList.length; i++){
      this.kardexList[i].main.selected = false;
    }
    if(object.main.uuid == this.metadata.object.main.uuid){
      object.main.selected = false;
      this.metadata.object.main.uuid = '';
    }else{
      object.main.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.kardexMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.kardexMin.offset = (event.pageIndex * event.pageSize);
    this.kardexMin.max = event.pageSize;
    this.getkardexList();
  }

  handleUpdated(event) {
    this.kardexMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.kardexMin.offset);
    this.getkardexList();
  }

}
