import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { BranchUpdateComponent } from './branch-update/branch-update.component';
import { SessionService } from '../../../service/session/session.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.getBranchList();
  }

  // funcion para obtener la lista de sucursales
  getBranchList (){
    this.branchList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("branch:list",this.branchMin).subscribe(
      (data:any) => {
        this.branchList = data.object.instanceList;
     },
     error => {
       console.log("branch:list",error);
     }
    );
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Modal para visualizar el update de sucursal
   */
  branchUpdateDialog(uuid) {
    this.dialog.open(BranchUpdateComponent, {
      width: '60%',
      height:'40rem',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getBranchList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }
}
