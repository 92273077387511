import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { SaleSaleshiftDasboardDetailsComponent } from './sale-saleshift-dasboard-details/sale-saleshift-dasboard-details.component';

@Component({
  selector: 'app-sale-saleshift-dasboard',
  templateUrl: './sale-saleshift-dasboard.component.html',
  styleUrls: ['./sale-saleshift-dasboard.component.css']
})
export class SaleSaleshiftDasboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  shiftList = [];
  shiftMin = {
    main:{
      startDate:'',
      endDate:''
    },
    system:{
      branch:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100,500,1000],
  };
  pageEvent: PageEvent;
  metadata = {
    startDate:'',
    endDate:'',
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    viewSalesByBranch:true
  };
  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loadingService:LoadingService) { }

  ngOnInit() {
    let actualMonth = moment().get('month');
    let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');

    this.shiftMin.main.startDate =  moment().set('date', 1).format('YYYY-MM-DD');
    this.shiftMin.main.endDate = endOfTheMonth.format('YYYY-MM-DD');
    this.metadata.startDate =  moment().set('date', 1).toISOString();
    this.metadata.endDate =  endOfTheMonth.toISOString();
    this.getBranchList();
  }

  getBranchList (){
    this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.branchList = [];
    this.session.getRequest("branch:list",this.branchMin).subscribe(
      (data:any) => {
        this.branchList = data.object.instanceList;
     },
     error => {
       console.log("branch:list",error);
     }
    );
  }

  /** función para obtener los turno
  */
  getShiftList (){
  this.loadingService.wait();
    this.shiftList = [];
    this.session.getRequest("saleShift:findAllByBranchAndShiftStartDateBetween", this.shiftMin).subscribe(
      (data:any) => {
        this.shiftList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("saleShift:findAllByBranchAndShiftStartDateBetween",error);
       this.loadingService.hide();
     }
    );
  }

  /**función que se ejecuta cuando se cambia de pestaña en el tab
  */
  tabChanged(ev){
    for (let item of this.branchList){
      if(item.main.name == ev.tab.textLabel){
        this.shiftMin.system.branch.main = item.main;
      }
    }
    this.getShiftList();
  }

  /** funcion a ejecutar cuando cambia una fecha
  */
  changeDate(){
    this.shiftMin.main.startDate =  moment(this.metadata.startDate).format('YYYY-MM-DD');
    this.shiftMin.main.endDate =  moment(this.metadata.endDate).format('YYYY-MM-DD');
    // console.log("Cambio de periodo");

    if(moment(this.metadata.startDate).diff(moment(this.metadata.endDate)) < 0){
      this.shiftList = [];
      this.getShiftList();
    }else{
      this.snackBar.open('Revisar el periodo de tiempo seleccionado.', 'Aceptar', {duration: 5000});
    }
  }

  /*
  *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.shiftMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.shiftMin.offset = (event.pageIndex * event.pageSize);
    this.shiftMin.max = event.pageSize;
    this.getShiftList();
  }
  /**
   * Modal para visualizar el update de solicitudes de crédito
   */
   details(object) {
     this.dialog.open(SaleSaleshiftDasboardDetailsComponent, {
       width: '95%',
       height: '90%',
       data: object
     }).afterClosed().subscribe(result => {
       if(result != undefined ){
         if(result.transaction == 'ok'){
           // El modal se cerro con objeto
         }else{
           // El modal se cerro sin objeto
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       }else{
         // El modal se cerro sin seleccionar algo, dandole click fuera
         this.snackBar.open('No se guardo ningun registro', '', {
           duration: 1000
         });
       }
     });
   }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
