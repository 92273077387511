import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AdminAccountDeleteComponent } from './admin-account-delete/admin-account-delete.component';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-account',
  templateUrl: './admin-account.component.html',
  styleUrls: ['./admin-account.component.css']
})
export class AdminAccountComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  accountingAccountModel = {
    main: {
      uuid: '',
      accountNumber: '0',
      parentAccountNumber: '',
      description: '',
      natureAccount: '' //Acredora o Deudora
    },
    complement: {
      edit: false,
      accountLevel: '',
      numberAccountLevel: 0
    },
    system: {
      accountingAccounts:[],
      accountingAccount: {
        main: {
          uuid: '',
          description: ''
        }
      },
      typeAccount: {
        main: {
          uuid: ''
        }
      }
    }
  }
  metadata = {
    company: {
      main: {
        uuid: ''
      }
    },
    editParentAccount: false
  }
  typeAccountList = [];

  object = JSON.parse(JSON.stringify(this.accountingAccountModel));
  isLinear = true;

  constructor(public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.object.complement.edit = true;
    this.metadata.company.main = this.session.getSessionObject().main
    this.getTypeAccounts(this.metadata.company.main.uuid);
  }

  /**
   * Función para agregar una cuenta nueva
   */
  addAccountingAccount(object) {
    this.object = JSON.parse(JSON.stringify(this.accountingAccountModel));
    if (object.main.classNumber === undefined) {
      this.object.system.accountingAccount.main = JSON.parse(JSON.stringify(object.main));
      this.object.system.typeAccount.main = JSON.parse(JSON.stringify(object.system.typeAccount.main));
    } else {
      this.object.system.typeAccount.main = JSON.parse(JSON.stringify(object.main));
      this.object.system.accountingAccount.main.description = object.main.name;
    }
    this.object.main.natureAccount = this.object.system.typeAccount.main.natureAccount
    // Obtenemos el código de la cuenta clave
    this.session.getRequest("accountingAccount:generateAccountNumber", { system: { typeAccount: this.object.system.typeAccount, accountingAccount: { main: this.object.system.accountingAccount.main } } }).subscribe((data: any) => {
      this.object.main.parentAccountNumber = data.object.parentAccountNumber;
      this.object.main.accountNumber = data.object.accountNumber;
      this.object.complement.numberAccountLevel = data.object.level;
    }, error => {
      this.snackBar.open(error.message, 'Error', {
        duration: 8000
      });
      console.log("error:accountingAccount:generateAccountNumber", error);
    });
  }

  /**
   * Limpia el arreglo de cuentas contables
   */
  cleanAccountingAccount(object) {
    object.system.open = false;
    object.system.accountingAccounts = [];
  }

  /**
   * Elimina una cuenta contable del Listado
   */
  deleteAccountingAccount(accountingAccount, accountingAccounts) {
    for (let i=0; i<accountingAccounts.length; i++) {
      if ( accountingAccounts[i].main.uuid === accountingAccount.system.accountingAccount.main.uuid ) {
        for (let j=0; j<accountingAccounts[i].system.accountingAccounts.length; j++) {
          if (accountingAccounts[i].system.accountingAccounts[j].main.uuid === accountingAccount.main.uuid) {
            accountingAccounts[i].system.accountingAccounts.splice(j, 1);
          }
        }
      } else {
        if (accountingAccounts[i].system.accountingAccounts.length > 0) {
          this.deleteAccountingAccount(accountingAccount, accountingAccounts[i].system.accountingAccounts);
        }
      }
    }
  }

  /**
   * funcion para obtener las cuentas contables padres segun el tipo de cuentas
   */
  findFatherAccountingAccountByTypeAccount(object) {
    object.system.loading = true;
    object.system.open = true;
    this.session.getRequest("accountingAccount:findAllByAccountingAccountIsNullAndTypeAccount", { system: { typeAccount: { main: { uuid: object.main.uuid } } } }).subscribe((data: any) => {
      for (let item of data.object.instanceList) {
        item.system.open = false;
        item.system.loading = false;
        item.system.selected = 'false';
        item.system.accountingAccounts = [];
        object.system.accountingAccounts.push(item);
      }
      object.system.loading = false;
      for (let i = 0; i < this.typeAccountList.length; i++) {
        this.typeAccountList[i].system.selected = 'false'
      }
      if (data.object.instanceList.length == 0) {
        object.system.selected = 'true';
      } else {
        object.system.selected = 'indeterminate';
      }
      // this.generateParentAccountNumber();
    }, error => {
      console.log("error:accountingAccount:findAllByAccountingAccountIsNullAndTypeAccount", error);
    })
  }

  /**
   * Funcion para obtener los tipos de cuenta
   */
  getTypeAccounts(uuid) {
    this.loadingService.wait();
    this.typeAccountList = [];
    this.object = JSON.parse(JSON.stringify(this.accountingAccountModel));
    this.session.getRequest("typeAccount:findAllByObjectTypeAndObjectTypeUuid", { system: { objectType: 'Company', objectTypeUuid: uuid} }).subscribe((data: any) => {
      for (let item of data.object.instanceList) {
        item.system.open = false;
        item.system.loading = false;
        item.system.selected = 'false';
        item.system.accountingAccounts = [];
        this.typeAccountList.push(item);
      }
      // Se obtiene la configuración por objeto para conocer si se edita la cuenta padre
      this.session.getRequest('accountingConfiguration:findByObjectTypeAndObjectTypeUuid', {system: { objectType: 'Company', objectTypeUuid: uuid}}).subscribe((data: any) => {
        if (data.object.main.uuid == null || data.object.main.uuid == '') {
          this.metadata.editParentAccount = false;
        } else {
          this.metadata.editParentAccount = data.object.system.editParentAccount;
        }
      }, (error) => {
        this.loadingService.hide();
        console.log('Error:accountingConfiguration :findByObjectTypeAndObjectTypeUuid', error)
      });
      this.loadingService.hide();
    }, error => {
      this.loadingService.hide();
      console.log("error:typeAccount:findAllByObjectTypeAndObjectTypeUuid", error);
    })
  }

  /**
   * Abre el cuadro de dialogo para advertir que al eliminar la cuenta se eliminan sus hijos
   */
  openAccountingAccountDialog(object) {
    const dialogRef = this.dialog.open(AdminAccountDeleteComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.show(true, 'Eliminando la cuenta contable');
        this.session.getRequest("accountingAccount:delete", object).subscribe((data: any) => {
          this.snackBar.open('La cuenta contable se elimino correctamente', '', {
            duration: 8000
          });
          this.deleteAccountingAccount(object, this.typeAccountList);
          this.object = JSON.parse(JSON.stringify(this.accountingAccountModel));
          this.loadingService.hide();
        }, error => {
          console.log("Error:accountingAccount:update", error);
        });
      }
    });
  }

  /**
   * Agrega la cuenta cuenta al arbol del Listado
   */
  pushAccountingAccount(accountingAccount, accountingAccounts) {
    for (let i=0; i<accountingAccounts.length; i++) {
      if ( accountingAccounts[i].main.uuid === accountingAccount.system.accountingAccount.main.uuid ) {
        if(accountingAccounts[i].system.accountingAccounts !=  undefined){
          accountingAccounts[i].system.accountingAccounts.push(accountingAccount);
        }else{
          accountingAccounts[i].system.accountingAccounts = [];
          accountingAccounts[i].system.accountingAccounts.push(accountingAccount);
        }
      } else {
        if (accountingAccounts[i].system.accountingAccounts.length > 0) {
          this.pushAccountingAccount(accountingAccount, accountingAccounts[i].system.accountingAccounts);
        }
      }
    }
  }

  /**
   * Funcion para guardar una cuenta
   */
  send(object, event) {
    this.loadingService.show(true, 'Agregado cuenta contable');
    this.session.getRequest("accountingAccount:update", object).subscribe((data: any) => {
      this.snackBar.open('La cuenta contable se guardo correctamente', '', {
        duration: 8000
      });
      // Agregamos el elemento al arbol
      switch (event) {
        case 'new':
          this.pushAccountingAccount(data.object, this.typeAccountList);
        break;
        case 'edit':
          this.updateAccountingAccount(object, this.typeAccountList);
        break;
      }
      this.loadingService.hide();
      this.object = JSON.parse(JSON.stringify(this.accountingAccountModel));
    }, error => {
      console.log("Error:accountingAccount:update", error);
    })
  }

  /**
   * Actualiza los datos de la cuenta actualizada
   */
  updateAccountingAccount(accountingAccount, accountingAccounts) {
    for (let i=0; i<accountingAccounts.length; i++) {
      if ( accountingAccounts[i].main.uuid === accountingAccount.system.accountingAccount.main.uuid ) {
        for (let j=0; j<accountingAccounts[i].system.accountingAccounts.length; j++) {
          if (accountingAccounts[i].system.accountingAccounts[j].main.uuid === accountingAccount.main.uuid) {
            accountingAccounts[i].system.accountingAccounts[j] = accountingAccount;
          }
        }
      } else {
        console.log('accountingAccounts[i].system.accountingAccounts', accountingAccounts[i].system.accountingAccounts);
        if (accountingAccounts[i].system.accountingAccounts.length > 0) {
          this.updateAccountingAccount(accountingAccount, accountingAccounts[i].system.accountingAccounts);
        }
      }
    }
  }

  /** Funcion para descargar reporte de catálogo de cuentas **/
  getAccountingAccount(uuid) {
    this.loadingService.show(true, 'Espere un momento... Construyendo tu reporte.');
      this.session.getRequest('accountingAccount:downloadReport',{ main: {object:'All'}, system: {objectType:'Company', objectTypeUuid:uuid}}).subscribe((data:any)=>{
           let elem = document.createElement('a');
           elem.href = data.object;
           elem.setAttribute('download', 'Catálogo de cuentas.pdf');
           document.body.appendChild(elem);
           elem.click();
           this.loadingService.hide();
      },error=>{
        console.log("Error:accountingAccount:downloadReport",error);
        this.loadingService.hide();
      })
  }

  /** Función para controlar si se abre o cierra el menu **/
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
