import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.module';

@Component({
  selector: 'app-type-policy-update',
  templateUrl: './type-policy-update.component.html',
  styleUrls: ['./type-policy-update.component.css']
})
export class TypePolicyUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  companyBossesList = [];
  typePolicy = {
    main: {
      uuid: "",
      name: "",
      description: ""
    },
    complement: {
      classificationSat: '',
    },
    system: {
      objectType: 'Company',
      objectTypeUuid: '',
      status: true
    }
  };

  object = JSON.parse(JSON.stringify(this.typePolicy));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != 'new') {
        this.object.main.uuid = params['uuid'];
        this.loadingService.show(true,"Espere un momento...");
        this.session.getRequest('typePolicy:get', this.object).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        }, (error) => {
          this.loadingService.hide();
          console.log('Error: typePolicy:get', error)
        });
      }
    });
  }

  send(object) {
    this.loadingService.wait();
    object.main.uuid = this.object.main.uuid;
    object.system.objectTypeUuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest('typePolicy:update', object).subscribe((data: any) => {
      this.object = JSON.parse(JSON.stringify(data.object));
      this.snackBar.open('El tipo de poliza se guardo correctamente', 'correcto!', {
        duration: 8000
      });
      this.loadingService.hide();
      this.router.navigate(['/admin/accounting/typePolicy']);
    },
      (error) => {
        this.loadingService.hide();
        console.log('Error: typePolicy:update', error)
      });
  }

  /*
   * funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
