import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as moment from 'moment';
import * as printJS from 'print-js';

@Component({
  selector: 'app-sale-client-credit-abono',
  templateUrl: './sale-client-credit-abono.component.html',
  styleUrls: ['./sale-client-credit-abono.component.css']
})
export class SaleClientCreditAbonoComponent implements OnInit {
  creditPaymentMin = {
    system:{
      sale:{
        main:{
          uuid:''
        }
      }
    }
  };

  creditPaymentModel = {
	main: {
		uuid: '',
		amount: 0.0, // Monto abonado
		movementDate: '', // Fecha del abono
		paymentMethod: '' // Tajeta de debito, Credito o Efectivo, Transferencia, deposito
	},
	complement: {
		referenceNumber: '' // numero de referencia bancaria
	},
	system: {
		// Amortización a la venta
		sale: {
			main: {
				uuid: ''
			}
		},
		// Sucursal donde se recibió el pago
		branch: {
			main: {
				uuid: ''
			}
		},
		// Usuario que recibió el pago
		user: {
			main: {
				uuid: ''
			}
		},
    client:{
      main:{
        uuid:''
      }
    },
		status: true,
	}
};

object = JSON.parse(JSON.stringify(this.creditPaymentModel));

metadata = {
  restoSale:0,
  totalPayments:0
}

paymentMethods = [
  'Efectivo',
  'Tarjeta de débito',
  'Cheque',
  'Tarjeta de crédito',
  'Transferencia electrónica de fondos'
];

  constructor(public dialogRef: MatDialogRef<SaleClientCreditAbonoComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private session:SessionService, public loading: LoadingService
  ) {
    this.creditPaymentMin.system.sale.main.uuid = this.data.main.uuid;

   }

  ngOnInit() {
    this.getCreditPaymentBySale();
  }

  /**funcionalidad oara obtener el listado de ventas
  */
  getCreditPaymentBySale(){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("creditPayment:findAllBySale",this.creditPaymentMin).subscribe((data:any)=>{
      this.metadata.restoSale = 0;
      this.metadata.totalPayments = 0;
      for(let item of data.object.instanceList){
        this.metadata.totalPayments = this.metadata.totalPayments + item.main.amount;
      }
      this.metadata.restoSale = this.data.main.total - this.metadata.totalPayments;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  /**funcionalidad para enviar un abono
  */
  send(){
    this.object.system.sale.main.uuid = this.data.main.uuid;
    this.object.system.branch.main.uuid = this.data.system.branch.main.uuid;
    this.object.system.client.main.uuid = this.data.system.client.main.uuid;
    this.object.system.user.main.uuid = this.session.getUser().main.uuid;
    this.object.main.movementDate = moment().format('DD/MM/YYYY HH:mm:ss');
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("creditPayment:update",this.object).subscribe(async(data:any)=>{
      this.printTicket(data.object);
      //verificamos si ya se pago todo el Credito
      let creditComplete = await this.checkIfCreditisComplete();
      // console.log(creditComplete);
      if(creditComplete){
        //actualizamos la venta apagada
        await this.changeSaleToComplete();
        this.close(true);
      }else{
        this.close(true);
      }
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    })
  }

  async checkIfCreditisComplete(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("creditPayment:findAllBySale",this.creditPaymentMin).subscribe((data:any)=>{
        this.metadata.restoSale = 0;
        this.metadata.totalPayments = 0;
        for(let item of data.object.instanceList){
          this.metadata.totalPayments = this.metadata.totalPayments + item.main.amount;
        }
        this.metadata.restoSale = this.data.main.total - this.metadata.totalPayments;
        // console.log(this.metadata.restoSale);
        if( this.metadata.restoSale > 0){
          resolve(false);
        }else{
          resolve(true);
        }
      },error=>{
        reject(error);
        console.log(error);
      });
    });
  }

  changeSaleToComplete(){
    return new Promise((resolve,reject)=>{
      this.data.main.status = "Pagada";
      this.session.getRequest("sale:updateStatus",this.data).subscribe((data:any)=>{
        resolve(true);
      },error=>{
        reject(error);
        console.log(error);
      });
    });
  }

  printTicket(object){
    this.loading.show(true,"Obteniendo ticket, espere un momento...");
    this.session.getRequest("creditPaymentReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      this.loading.hide();
      printJS({printable: data.object, type: 'pdf', base64: true})
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'creditPayment:001';
    }
    this.dialogRef.close(object);
  }

}
