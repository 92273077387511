import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-saleshift-close',
  templateUrl: './counter-saleshift-close.component.html',
  styleUrls: ['./counter-saleshift-close.component.css']
})
export class CounterSaleshiftCloseComponent implements OnInit {
  saleShiftModel = {
    main:{
      uuid:'',
      shiftStartDate:'',
      shiftEndDate:'',
      shiftOpeningAmount:0,
      shiftClosingAmount:'',
      open:true
    },
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:''
        }
      },
      currency:{
        main:{
          uuid:''
        }
      }
    }
  };
  object = JSON.parse(JSON.stringify(this.saleShiftModel));
  constructor(public session: SessionService, public loadingService: LoadingService, public dialogRef: MatDialogRef<CounterSaleshiftCloseComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.object = this.session.getSaleShift();
  }
  send(object){
    this.loadingService.show(true,"Espere un momento...");
    object.main.shiftStartDate = moment(object.main.shiftStartDate).format('DD/MM/YYYY HH:mm:ss');
    object.main.shiftEndDate = moment().format('DD/MM/YYYY HH:mm:ss');
    object.main.open = false;

    this.session.getRequest("saleShift:update", object).subscribe((data:any)=>{
      this.object.main.uuid = data.object.main.uuid;
      this.loadingService.hide();
      this.close(true);
    },error=>{
      console.log("Error:saleShift:update",error);
      this.loadingService.hide();
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'saleshift:001';
    }
    this.dialogRef.close(object);
  }

}
