import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { CompanyComponent } from './admin/catalogs/company/company.component';
import { BranchComponent } from './admin/catalogs/branch/branch.component';
import { WarehouseComponent } from './admin/catalogs/warehouse/warehouse.component';
import { EmployeeComponent } from './admin/catalogs/employee/employee.component';
import { ProductComponent } from './admin/catalogs/product/product.component';
import { ProductCategoriesComponent } from './admin/catalogs/product/product-categories/product-categories.component';
import { ProductMakerComponent } from './admin/catalogs/product/product-maker/product-maker.component';
import { ProductMeasurementComponent } from './admin/catalogs/product/product-measurement/product-measurement.component';
import { ProductProviderComponent } from './admin/catalogs/product/product-provider/product-provider.component';
import { ProductProviderRelationComponent } from './admin/catalogs/product/product-provider/product-provider-relation/product-provider-relation.component';
import { AdjustmentTypeComponent } from './admin/catalogs/inventory/adjustment-type/adjustment-type.component';
import { TerminalComponent } from './admin/catalogs/sale/terminal/terminal.component';
import { CatalogSaleCredittypeComponent } from './admin/catalogs/sale/catalog-sale-credittype/catalog-sale-credittype.component';
import { CatalogSaleCreditDiscounttableComponent } from './admin/catalogs/sale/catalog-sale-credit-discounttable/catalog-sale-credit-discounttable.component';
import { InventoryAdjustmentComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment.component';
import { InventoryAdjustmentUpdateComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-update/inventory-adjustment-update.component';
import { InventoryAdjustmentValidateComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-validate/inventory-adjustment-validate.component';
import { InventoryTransferComponent } from './admin/inventory/inventory-transfer/inventory-transfer.component';
import { InventoryTransferUpdateComponent } from './admin/inventory/inventory-transfer/inventory-transfer-update/inventory-transfer-update.component';
import { InventoryTransferFinishComponent } from './admin/inventory/inventory-transfer/inventory-transfer-finish/inventory-transfer-finish.component';
import { InventoryTransferDetailsComponent } from './admin/inventory/inventory-transfer/inventory-transfer-details/inventory-transfer-details.component';
import { InventoryTransferRequestComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request.component';
import { InventoryTransferRequestUpdateComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request-update/inventory-transfer-request-update.component';
import { InventoryTransferRequestDetailsComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request-details/inventory-transfer-request-details.component';
import { InventoryDashboardComponent } from './admin/inventory/inventory-dashboard/inventory-dashboard.component';
import { SaleClientComponent } from './admin/sale/sale-client/sale-client.component';
import { AdminRoleComponent } from './admin/systemSettings/admin-role/admin-role.component';
import { AdminUsersComponent } from './admin/systemSettings/admin-users/admin-users.component';
import { CounterDashboardComponent } from './counter/counter-dashboard/counter-dashboard.component';
import { CounterTransferUpdateComponent } from './counter/counter-transfer/counter-transfer-update/counter-transfer-update.component';
import { CounterTransferComponent } from './counter/counter-transfer/counter-transfer.component';
import { StartShiftComponent } from './counter/start-shift/start-shift.component';
import { StartShiftUpdateComponent } from './counter/start-shift/start-shift-update/start-shift-update.component';
import { CounterTransferReceiveComponent } from './counter/counter-transfer/counter-transfer-receive/counter-transfer-receive.component';
import { CounterTransferDetailsComponent } from './counter/counter-transfer/counter-transfer-details/counter-transfer-details.component';
import { PurchaseGenerateComponent } from './admin/purchase/purchase-generate/purchase-generate.component';
import { PurchaseGeneratePeriodComponent } from './admin/purchase/purchase-generate/purchase-generate-period/purchase-generate-period.component';

import { PurchaseGenerateUpdateComponent } from './admin/purchase/purchase-generate/purchase-generate-update/purchase-generate-update.component';
import { InventoryKardexComponent } from './admin/inventory/inventory-kardex/inventory-kardex.component';
import { SaleClientCreditComponent } from './admin/sale/sale-client-credit/sale-client-credit.component';
import { SaleClientCreditDetailsComponent } from './admin/sale/sale-client-credit/sale-client-credit-details/sale-client-credit-details.component';
// import { SaleClientCreditUpdateComponent } from './admin/sale/sale-client-credit/sale-client-credit-update/sale-client-credit-update.component';
import { SaleRequestClientCreditComponent } from './admin/sale/sale-request-client-credit/sale-request-client-credit.component';
import { CounterSaleEndByeComponent } from './counter/start-shift/counter-sale-end-bye/counter-sale-end-bye.component';
import { CounterCreditRequestComponent } from './counter/counter-credit-request/counter-credit-request.component';
import { CounterCreditRequestUpdateComponent } from './counter/counter-credit-request/counter-credit-request-update/counter-credit-request-update.component';
import { SaleSaleshiftDasboardComponent } from './admin/sale/sale-saleshift-dasboard/sale-saleshift-dasboard.component';
// import { SaleSaleshiftDasboardDetailsComponent } from './admin/sale/sale-saleshift-dasboard/sale-saleshift-dasboard-details/sale-saleshift-dasboard-details.component';
import { CounterCreditClientComponent } from './counter/counter-credit-client/counter-credit-client.component';
import { InventoryAdjustmentImportComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-import/inventory-adjustment-import.component';
import { PurchaseGenerateDetailsComponent } from './admin/purchase/purchase-generate/purchase-generate-details/purchase-generate-details.component';
import { CounterProductComponent } from './counter/counter-product/counter-product.component';
import { CounterAdjustmentComponent } from './counter/counter-adjustment/counter-adjustment.component';
import { CounterAdjustmentValidateComponent } from './counter/counter-adjustment/counter-adjustment-validate/counter-adjustment-validate.component';
import { CounterAdjustmentUpdateComponent } from './counter/counter-adjustment/counter-adjustment-update/counter-adjustment-update.component';
import { CounterPurchaseGenerateComponent } from  './counter/counter-purchase-generate/counter-purchase-generate.component';
import { CounterTransferRequestComponent } from './counter/counter-transfer-request/counter-transfer-request.component';
import { AdminAccountComponent } from './admin/systemSettings/admin-account/admin-account.component';
import { AdminSettingAccountingComponent } from './admin/systemSettings/admin-setting-accounting/admin-setting-accounting.component';
import { AdminSettingRegistrationAccountingComponent } from './admin/systemSettings/admin-setting-registration-accounting/admin-setting-registration-accounting.component';
import { TypePolicyComponent } from './admin/accounting/type-policy/type-policy.component';
import { TypePolicyUpdateComponent } from './admin/accounting/type-policy/type-policy-update/type-policy-update.component';
import { PolicyCustomerComponent } from './admin/accounting/policy/policy.component';
import { PolicyCustomerUpdateComponent } from './admin/accounting/policy/policy-customer-update/policy-customer-update.component';
import { SaleDashboardComponent } from './admin/sale/sale-dashboard/sale-dashboard.component';
import { InventoryKardexWarehouseComponent } from './admin/inventory/inventory-kardex-warehouse/inventory-kardex-warehouse.component';
import { InventoryAdjustmentDetailsComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-details/inventory-adjustment-details.component';
import { CounterTraspasoComponent } from './counter/counter-traspaso/counter-traspaso.component';
import { CounterTraspasoUpdateComponent } from './counter/counter-traspaso/counter-traspaso-update/counter-traspaso-update.component';
import { CounterTraspasoDetailsComponent } from './counter/counter-traspaso/counter-traspaso-details/counter-traspaso-details.component';
import { CounterTraspasoRecibidoComponent } from './counter/counter-traspaso-recibido/counter-traspaso-recibido.component';
import { CounterTraspasoRecibidoFinishComponent } from './counter/counter-traspaso-recibido/counter-traspaso-recibido-finish/counter-traspaso-recibido-finish.component';

const routes: Routes = [
  { path :'' ,redirectTo:'login' , pathMatch:'full' },
  { path:'login',component:LoginComponent },
  { path:'admin/dashboard',component:AdminComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/sale/dashboard',component:SaleDashboardComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/company',component:CompanyComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/branch',component:BranchComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/product',component:ProductComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/warehouse',component:WarehouseComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/employee',component:EmployeeComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/category',component:ProductCategoriesComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/maker',component:ProductMakerComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/unitMeasure',component:ProductMeasurementComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/provider',component:ProductProviderComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/adjustmenttype',component:AdjustmentTypeComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/terminal',component:TerminalComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/catalog/sale/creditType',component:CatalogSaleCredittypeComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/catalog/sale/creditDiscountTable',component:CatalogSaleCreditDiscounttableComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/role/list',component:AdminRoleComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/user/list',component:AdminUsersComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/setting/account',component:AdminAccountComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/setting/settingAccounting',component:AdminSettingAccountingComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/setting/settingRegistrationAccount',component:AdminSettingRegistrationAccountingComponent , canActivate:[ AuthenticationGuard ] },

  { path:'admin/accounting/typePolicy',component:TypePolicyComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/accounting/typePolicy/update/:uuid',component:TypePolicyUpdateComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/accounting/policy/:companyUuid', canActivate:[AuthenticationGuard], component: PolicyCustomerComponent },
  { path:'admin/accounting/policy/update/:uuid/:companyUuid', canActivate:[AuthenticationGuard], component: PolicyCustomerUpdateComponent },

  { path:'admin/inventory/adjustment',component:InventoryAdjustmentComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/adjustment/import',component:InventoryAdjustmentImportComponent , canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/adjustment/update/:uuid',component:InventoryAdjustmentUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/inventory/adjustment/validate/:uuid',component:InventoryAdjustmentValidateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/inventory/adjustment/details/:uuid',component:InventoryAdjustmentDetailsComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/inventory/dashboard',component:InventoryDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/inventory/transfer',component:InventoryTransferComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/kardex',component:InventoryKardexComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/kardex/warehouse',component:InventoryKardexWarehouseComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/transfer/update/:uuid',component:InventoryTransferUpdateComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/transfer/details/:uuid',component:InventoryTransferDetailsComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/transfer/finish/:uuid', component: InventoryTransferFinishComponent, canActivate:[ AuthenticationGuard ]},
  { path:'admin/product/provider/relation/:uuid',component:ProductProviderRelationComponent , canActivate:[AuthenticationGuard] },
  { path:'admin/inventory/transferRequest',component:InventoryTransferRequestComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/transferRequest/update/:uuid',component:InventoryTransferRequestUpdateComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/inventory/transferRequest/details/:uuid',component:InventoryTransferRequestDetailsComponent, canActivate:[ AuthenticationGuard ] },
  { path:'admin/purchase/generate',component:PurchaseGenerateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/purchase/generate/update/:uuid',component:PurchaseGenerateUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/purchase/generate/details/:uuid',component:PurchaseGenerateDetailsComponent , canActivate:[AuthenticationGuard] },
  { path:'admin/sale/client',component:SaleClientComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/purchase/generate/period',component:PurchaseGeneratePeriodComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/sale/client/credit',component:SaleClientCreditComponent , canActivate:[AuthenticationGuard] },
  { path:'admin/sale/client/credit/details/:uuid',component:SaleClientCreditDetailsComponent, canActivate:[AuthenticationGuard] },
  // { path:'admin/sale/client/credit/update', component:SaleClientCreditUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/sale/client/credit/request', component:SaleRequestClientCreditComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/sale/saleshift/dashboard', component:SaleSaleshiftDasboardComponent, canActivate:[AuthenticationGuard] },
  // { path:'admin/sale/saleshift/details/:uuid', component:SaleSaleshiftDasboardDetailsComponent, canActivate:[AuthenticationGuard] },

  { path:'counter/dashboard',component:CounterDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/start-shift',component:StartShiftComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/start-shift/update/:uuid',component:StartShiftUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/transfer/update/:uuid',component:CounterTransferUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/transfer/list',component:CounterTransferComponent , canActivate:[AuthenticationGuard] },
  { path:'counter/transfer/receive/:uuid',component:CounterTransferReceiveComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/transfer/details/:uuid',component:CounterTransferDetailsComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/transfer/request/list',component:CounterTransferRequestComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/traspaso/list',component:CounterTraspasoComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/traspaso/update/:uuid',component:CounterTraspasoUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/traspaso/details/:uuid',component:CounterTraspasoDetailsComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/traspasorecibido/list',component:CounterTraspasoRecibidoComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/traspasorecibido/finish/:uuid',component:CounterTraspasoRecibidoFinishComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/sale/end',component:CounterSaleEndByeComponent , canActivate:[AuthenticationGuard] },
  { path:'counter/credit-request',component:CounterCreditRequestComponent , canActivate:[AuthenticationGuard] },
  { path:'counter/credit-request/update/:uuid',component:CounterCreditRequestUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/credit',component:CounterCreditClientComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/product',component:CounterProductComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/adjustment',component:CounterAdjustmentComponent , canActivate:[ AuthenticationGuard ] },
  { path:'counter/adjustment/update/:uuid',component:CounterAdjustmentUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/adjustment/validate/:uuid',component:CounterAdjustmentValidateComponent, canActivate:[AuthenticationGuard] },
  { path:'counter/purchase/generate',component:CounterPurchaseGenerateComponent , canActivate:[ AuthenticationGuard ] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
