import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.css']
})
export class ProductUpdateComponent implements OnInit {
  complexForm: FormGroup;
  productTransientDataModel = {
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      transientData: {
        main: {
          uuid: ''
        }
      }
    }
  }
  productTransientDataValueModel = {
    main: {
      value: ''
    },
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      transientData: {
        main: {
          uuid: ''
        }
      }
    }
  }
  productModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
      keySat: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      kindProduct: {
        main: {
          uuid: '',
          code: '',
          name: ''
        }
      },
      productFamily: {
        main: {
          uuid: '',
          code: '',
          name: ''
        }
      },
      maker: {
        main: {
          uuid: '',
          name: ''
        }
      },
      unitMeasure: {
        main: {
          uuid: '',
          name: ''
        }
      },
      status: true
    }
  }
  kindProductList = [];
  kindProductListFilter = [];
  productFamilyList = [];
  makerList = [];
  makerListFilter = [];
  unitMeasureList = [];
  transientDataList = [];
  productTransientDataList = [];
  productOrServiceList = [];
  selectedIndex = 0;
  metadata = {
    // productOrService: {
    //   filter: '',
    //   searchBoxInput: new Rx.Subject<string>(),
    //   searching: false
    // },
  }
  customTemplateStringOptions: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  }
  object = JSON.parse(JSON.stringify(this.productModel));
  constructor(public fb: FormBuilder, protected sessionService: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialogRef: MatDialogRef<ProductUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'keySat': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    // obtenemos la lista de categorias.
    this.loadingService.show(true, 'Cargando Recursos para crear productos...')
    this.sessionService.getRequest("productFamily:treeviewByCompanyAndStatus", { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.productFamilyList = data.object;
        // obtenemos la lista de kit de productos.
        this.sessionService.getRequest("kindproduct:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } }, status: true } }).subscribe(
          (data: any) => {
            this.kindProductList = data.object.instanceList;
            this.object.system.kindProduct =  data.object.instanceList[0];
            // obtenemos la lista de marcas.
            this.sessionService.getRequest("maker:getAll", {}).subscribe(
              (data: any) => {
                this.makerList = data.object.instanceList;
                // obtenemos la lista de unidades de medida.
                this.sessionService.getRequest("unitMeasure:findAllByStatus", { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } } } }).subscribe(
                  (data: any) => {
                    this.unitMeasureList = data.object.instanceList;
                    // obtenemos la lista de productos que se puede aceptar como composicion
                    this.sessionService.getRequest("kindProduct:findAllByCompanyAndCompositionAndStatus", { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } } }, composition: true, status: true }).subscribe(
                      (data: any) => {
                        this.kindProductListFilter = data.object.instanceList;
                        for (let item of this.kindProductListFilter) {
                          item.system.selected = false;
                        }
                        this.loadingService.hide();
                      }, error => {
                        console.log("kindProduct:findAllByCompanyAndCompositionAndStatus", error);
                        this.loadingService.hide();
                      });
                  }, error => {
                    console.log("unitMeasure:findAllByStatus", error);
                    this.loadingService.hide();
                  });
              }, error => {
                console.log("maker:list", error);
                this.loadingService.hide();
              });
          }, error => {
            console.log("kindproduct:findAllByCompany", error);
            this.loadingService.hide();
          });
      }, error => {
        console.log("productFamily:findAllByCompany", error);
        this.loadingService.hide();
      });

    if (this.data == 'new') {
      this.object.main.code = moment().get('date').toString() + moment().get('month').toString() + moment().get('millisecond').toString();
      this.getProductTransientData();
    } else {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.sessionService.getRequest('product:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.getProductTransientData();
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:product:get', error)
        })

    }
  }

  /*
   * Función para guardar un producto
   */
  send(object) {
    this.loadingService.wait();
    if (object.main.name != '' && object.main.code != '' && object.system.kindProduct.main.uuid != '' && object.system.productFamily.main.uuid != '' && object.system.maker.main.uuid != '' && object.system.unitMeasure.main.uuid != '') {
      object.system.company.main.uuid = this.sessionService.getSessionObject().main.uuid;
      this.sessionService.getRequest("product:update", object).subscribe(
        (data: any) => {
          this.loadingService.hide();
          this.object.main.uuid = data.object.main.uuid;
          this.snackBar.open('El producto se guardo correctamente', '', {
            duration: 8000
          });
          this.selectedIndex = 0;
          this.close(true);
        },
        error => {
          console.log("product:update", error);
          this.loadingService.hide();
        }
      );
    }
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

  /**
   * Funcion para obtener el fabricante.
   */
  makerFindAllByNameLike(name) {
    this.object.system.maker.main.name = name.toUpperCase();
    this.makerListFilter = this.makerList.filter((item: any): any => {
      var newStrProperty = 'main.name';
      // var newItem1 = item;
      while (newStrProperty.indexOf(".") != -1) {
        var positionPoint = newStrProperty.indexOf(".");
        var stringProperty = newStrProperty.slice(0, positionPoint);
        newStrProperty = newStrProperty.slice(positionPoint + 1, newStrProperty.length);
        if (typeof (item[stringProperty]) == "object") {
          item = item[stringProperty];
        }
      }
      if (item.name.toUpperCase().indexOf(this.object.system.maker.main.name) != -1)
        return item;
    });
  }

  /**
   * Función para colocar el fabricante seleccionado
   */
  makerSeleted(object) {
    this.object.system.maker = object;
    this.makerListFilter = [];
  }

  /**
   * Función para eliminar la categoria de productos
   */
  deleteProductFamily(object) {
    this.object.system.productFamily.main.uuid = '';
    this.object.system.productFamily.main.code = '';
    this.object.system.productFamily.main.name = '';
  }

  /**
   * Función que obtiene el objecto seleccionado de la categoria de producto
   */
  onEvent(event) {
    this.object.system.productFamily.main.uuid = event.node.data.uuid;
    this.object.system.productFamily.main.name = event.node.data.name;
  }

  /*
  ##########################
  DATOS TRANSITORIOS
  ##########################
  */
  // funcion para asignar datos transitorios a  productos
  addTransientDataToProduct(itemTransientData) {
    this.loadingService.wait();
    let item = Object.assign({}, this.productTransientDataModel);
    item.system.product = this.object;
    item.system.transientData = itemTransientData;
    if (!itemTransientData.system.selected) {
      this.sessionService.getRequest("productTransientData:update", item).subscribe(
        (data: any) => {
          item.system.transientData = data.object;
          this.loadingService.hide();
        }, error => {
          console.log("productTransientData:update", error);
          this.loadingService.hide();
        });
    } else {
      this.sessionService.getRequest("productTransientData:delete", item).subscribe(
        (data: any) => {
          this.loadingService.hide();
        }, error => {
          console.log("productTransientData:update", error);
          this.loadingService.hide();
        });

    }
    itemTransientData.system.selected = !itemTransientData.system.selected;
    for (let x=0; x<this.transientDataList.length; x++) {
      if (itemTransientData.main.uuid == this.transientDataList[x].main.uuid) {
        if (itemTransientData.system.selected) {
          this.transientDataList[x].main = item.system.transientData.main;
        }
        this.transientDataList[x].system.selected = !this.transientDataList[x].system.selected;
      }
    }
  }

  // funcion para obtener los datos transitorios de productos
  getProductTransientData() {
    // obtenemos la lista de datos transitorios.
    this.sessionService.getRequest("transientdata:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.transientDataList = data.object.instanceList;
        for (let item of this.transientDataList) {
          item.system.selected = false;
        }
        // obtenemos la lista de datos transitorios asociados al producto.
        if (this.object.main.uuid != '') {
          this.sessionService.getRequest("productTransientData:findAllByProduct", { system: { product: { main: { uuid: this.object.main.uuid } } } }).subscribe(
            (data: any) => {
              this.productTransientDataList = data.object.instanceList;
              //  colocamos el true los datos transitorios que ya tiene asignado el producto
              for (let item of this.productTransientDataList) {
                for (let item2 of this.transientDataList) {
                  if (item.system.transientData.main.uuid == item2.main.uuid) {
                    item2.system.selected = true;
                  }
                }
              }
            },
            error => {
              console.log("productTransientData:findAllByProduct", error);
            }
          );
        }
      },
      error => {
        console.log("transientdata:findAllByCompany", error);
      });
  }

}
