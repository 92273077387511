import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import {  MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProductUpdateComponent } from '../../../catalogs/product/product-update/product-update.component';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-purchase-generate-update',
  templateUrl: './purchase-generate-update.component.html',
  styleUrls: ['./purchase-generate-update.component.css']
})
export class PurchaseGenerateUpdateComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  productList = [];
  providerList = [];
  warehouseList = [];
  currencyList = [];
  taxList = [];
  productsListFilter = [];
  nationalPurchaseModel = {
    main: {
      uuid: '',
      description: '',
      subtotal: 0.0,
      total: 0.0,
      tax: 0.0,
      status: 'PaidReceived',
      invoiced:false
    },
    system: {
      products: [],
      warehouse: {
        main: {
          uuid: '',
          name: ''
        }
      },
      user: {
        main:this.session.getUser().main
      },
      company: {
        main: {
          uuid: ''
        }
      },
      provider: {
        main: {
          uuid: ''
        }
      },
      nationalPurchase:{
        main:{
          uuid:''
        }
      },
    },
    complement: {
      billProvider: '',
      dateOrderProvider: ''
    }
  };
  listProducts = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter: {
      name: "",
      value: ""
    },
    max: 10,
    offset: 0
  };
  metadata = {
    billProvider:moment().toISOString(),
    router:'/admin/purchase/generate',
    filter: '',
    searching: false,
    searchBoxInput: new Rx.Subject<string>(),
    company: {
      main: {
        uuid: ''
      }
    }
  }
  nationalPurchaseProductModel = {
    main: {
      uuid: '',
      code: '',
      quantity: 0,
      unitPrice: 0.0,
      subtotal: 0.0,
      total: 0.0,
      tax: 0.0,
    },
    system: {
      status: true,
      nationalPurchase: {
        main: {
          uuid: ''
        }
      },
      product: {
        main: {
          uuid: ''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.nationalPurchaseModel));
  statusList = [
    {
      name:'PAGADO',
      value:'Paid'
    },{
      name:'CRÉDITO',
      value:'PendingPayment'
    }
  ]
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog, public location:Location) {
    this.complexForm = fb.group({
      'descriptionPurchess': [null, Validators.required],
      'mbillProvider': [null,Validators.required]
    })
    switch (this.session.getUser().system.objectType) {
      case 'Branch': {
        let branch: any = this.session.getSessionObject();
        this.metadata.company.main.uuid = branch.system.company.main.uuid;
        this.metadata.router = '/counter/purchase/generate';
        break;
      }
      case 'Company': {
        this.metadata.router = '/admin/purchase/generate';
        this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
        break;
      }
    };
    this.object.system.company.main.uuid = this.metadata.company.main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.listProducts.filter.value = val;
          this.listProducts.filter.name = 'likeNameOrCode';
          this.productFindAllByNameLike(val);
          // this.trigger.openMenu();
        } else {
          // this.trigger.closeMenu();
          this.productsListFilter = [];
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.listTax();
    this.listCurrency();
    this.listProvider();
    this.loadingService.show(true, "Cargando recursos, espere un momento...")
    this.listWarehouseList().then(data => {
      this.object.system.status = true;
      this.activatedRoute.params.subscribe((params: Params) => {
        if (params['uuid'] == 'new') {
          this.loadingService.hide()
        } else {
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('nationalPurchase:get', this.object).subscribe((data: any) => {
            this.object = JSON.parse(JSON.stringify(data.object));
            this.loadingService.hide();
          },
            (error) => {
              this.loadingService.hide();
              console.log('Error:nationalPurchase:get', error)
            })
        }
      });
    });
  }

  /** Lista de almacenes por company y activos **/
  listWarehouseList() {
    return new Promise((resolve) => {
      switch (this.session.getUser().system.objectType) {
        case 'Branch': {
          this.session.getRequest("warehouse:findAllByBranch", { system: { branch: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
            (data: any) => {
              this.warehouseList = data.object.instanceList;
            },
            error => {
              console.log("warehouse:findAllByBranch", error);
            });
            break;
          }
          case 'Company': {
            this.session.getRequest("warehouse:findAllByCompany", { system: { company: { main: { uuid: this.metadata.company.main.uuid } } }, status: true, max: 100, offset: 0 }).subscribe(
              (data: any) => {
                this.warehouseList = data.object.instanceList;
                resolve(true);
              },
              error => {
                console.log("warehouse:findAllByCompany", error);
              });
              break;
            }
          };

        })
      }

  /** Funcion para obtener el producto **/
  productFindAllByNameLike(name) {
    this.productsListFilter = [];
    this.metadata.searching = true;
    this.listProducts.system.company.main.uuid = this.metadata.company.main.uuid;
    this.session.getRequest('product:findAllByCompany', this.listProducts).subscribe(
      (data: any) => {
        this.productsListFilter = data.object.instanceList;
        this.metadata.searching = false;
      },
      error => {
        console.log("product:findAllByCompany", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      })
  }

  /** Función para colocar el producto seleccionado **/
  productSeleted(object) {
    this.productsListFilter = [];
    this.metadata.filter = '';
    // buscamos si el producto ya fue agregado.
    let found = false;
    console.log('asdsdas', this.object.system.products.length);
    for (let x = 0; x < this.object.system.products.length; x++) {
      console.log('this.object.system.products[x].system.product.main.uuid', this.object.system.products[x].system.product.main.uuid);
      console.log('object.main.uuid', object.main.uuid);
      if (this.object.system.products[x].system.product.main.uuid == object.main.uuid) {
        found = true;
        break;
      }
    }
    if (!found) {
      let nationalPurchaseProduct = JSON.parse(JSON.stringify(this.nationalPurchaseProductModel))
      nationalPurchaseProduct.system.product = JSON.parse(JSON.stringify(object));
      this.object.system.products.push(nationalPurchaseProduct);
    }
  }

  /*
  Funcion para eliminar un producto de la lista de cotización
  */
  deleteAproductFronQuotation(object) {
    this.loadingService.wait();
    for (let i = 0; i < this.object.system.products.length; i++) {
      if (this.object.system.products[i].main.uuid == object.main.uuid) {
        this.snackBar.open('El producto ' + this.object.system.products[i].system.product.main.name + ' removido de la compra', '', {
          duration: 8000
        });
        if (this.object.system.products[i].main.uuid != '') {
          this.session.getRequest("nationalPurchase:deleteByProduct", object).subscribe(
            (data: any) => {
              this.object.system.products.splice(i, 1);
              this.loadingService.hide()
            }, error => {
              console.log("nationalPurchase:deleteByProduct", error);
              this.loadingService.hide()
            });
        } else {
          this.object.system.products.splice(i, 1);
          this.loadingService.hide();
        }
        break;
      }
    }
  }

  /*
   * Funcion para guardar la cotización
   */
  send(object) {
    this.loadingService.show(true,"Espere un momento...");
    object.complement.dateOrderProvider = moment(this.metadata.billProvider).format('YYYY-MM-DD');
    //calculamos el total de la compra
    object.main.subtotal = 0;
    object.main.tax = 0;
    object.main.total = 0;
    for(let i = 0; i < object.system.products.length; i++){
      object.system.products[i].main.subtotal = object.system.products[i].main.unitPrice * object.system.products[i].main.quantity;
      object.main.subtotal = object.main.subtotal + object.system.products[i].main.subtotal;
      object.main.tax =  object.main.tax + object.system.products[i].main.tax;
      object.main.total =  object.main.total + object.system.products[i].main.total;
    }
    this.session.getRequest("nationalPurchase:update", object).subscribe(
      (data: any) => {
        this.object = data.object;
        this.snackBar.open('Compra guardada correctamente', '', {duration: 8000});
        this.loadingService.hide();
        this.router.navigate(['/admin/purchase/generate']);
      },
      error => {
        console.log("nationalPurchase:update", error);
        this.loadingService.hide();

      });
  }

  // funcion para obtener el listado de impuestos
  listTax() {
    this.session.getRequest('tax:findAllByCompanyAndType', { main: { type: 'Traslado' }, system: { company: { main: { uuid: this.metadata.company.main.uuid } }, status: true } }).subscribe((data: any) => {
      this.taxList = data.object.instanceList;
    }, (error) => {
      console.log('Error:tax:findAllByCompanyAndStatus', error)
    });
  }
  // funcion para obtener el listado de tipo de Cambiando
  listCurrency() {
    this.session.getRequest("currency:list", { system: { main: { uuid: this.metadata.company.main.uuid } }, max: 100, offset: 0 }).subscribe(
      (data: any) => {
        this.currencyList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("currency:list", error);
        this.loadingService.hide();
      }
    );
  }
  // funcion para obtener el listado de tipo de Cambiando
  listProvider() {
    this.session.getRequest("provider:list", { system: { company: { main: { uuid: this.metadata.company.main.uuid } } }, max: 100, offset: 0 }).subscribe(
      (data: any) => {
        this.providerList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("currency:list", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * Modal para abrir product Update
   */
  productUpdateDialog(uuid) {
    this.dialog.open(ProductUpdateComponent, {
      width: '80%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.productSeleted(result.object);
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**funcionalidad para calcular el total
  */
  calculateTotalProduct(object){
    object.main.total = (object.main.quantity * object.main.unitPrice);
    object.main.subtotal =  object.main.total - object.main.tax;
  }

  /*
   * Funcion para buscar productos
   */
  search() {
    if (this.metadata.filter.length > 3)
      this.metadata.searchBoxInput.next(this.metadata.filter);
    else
      this.productsListFilter = [];
  }

  /*
   * funcionalidad para cambiar el menú
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  back(){
    this.location.back();
  }

}
