import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { WarehouseUpdateComponent } from './warehouse-update/warehouse-update.component';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  warehouseList = [];
  warehouseMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) { }


  ngOnInit() {
    this.getWarehouseList();
  }


  getWarehouseList(){
  this.loadingService.wait();
  this.warehouseList = [];
  // verificamos en que pagina verificamos
  this.warehouseMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  this.session.getRequest("warehouse:findAllByCompany", this.warehouseMin).subscribe(
    (data: any) => {
      this.warehouseList = data.object.instanceList;
      // this.paginatorModel.total = data.object.total;
      this.loadingService.hide();
    },
    error => {
      console.log("warehouse:findAllByCompany", error);
      this.loadingService.hide();
    }
  );
}

/* funcionalidad para cambiar el menú
*/
toogleMenu(ev){
  this.drawerCtrl.opened = ev;
}

/**
 * Modal para dar de alta un almacen
 */
UpdateDialog(uuid) {
  this.dialog.open(WarehouseUpdateComponent, {
    width: '60%',
    height:'40rem',
    data: uuid
  }).afterClosed().subscribe(result => {
    if(result != undefined ){
      if(result.transaction == 'ok'){
        // El modal se cerro con objeto
        this.getWarehouseList();
      }else{
        // El modal se cerro sin objeto
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    }else{
      // El modal se cerro sin seleccionar algo, dandole click fuera
      this.snackBar.open('No se guardo ningun registro', '', {
        duration: 1000
      });
    }
  });
}

}
