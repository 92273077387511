import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';

@Component({
  selector: 'app-counter-shift-payment',
  templateUrl: './counter-shift-payment.component.html',
  styleUrls: ['./counter-shift-payment.component.css']
})
export class CounterShiftPaymentComponent implements OnInit {
  paymentMethods = [
    'Efectivo',
    'Crédito',
    'Tarjeta de débito',
    'Cheque',
    'Tarjeta de crédito',
    'Transferencia electrónica de fondos'
  ];
  metadata = {
    enabledToBuy:true,
    alert:'',
    paymentMethod:'Efectivo',
    totalPayment:0,
    totalChange:0,
    saldo:0,
    object:{
      main:{
        uuid:'',
        total:'',
        payment:'',
        changePayment:0
      }
    },
    firtsPickCalculator:false
  };
  fastPayment = [
    {
      name:'20 MXN',
      value:20
    },{
      name:'50 MXN',
      value:50
    },{
      name:'100 MXN',
      value:100
    },{
      name:'200 MXN',
      value:200
    },{
      name:'500 MXN',
      value:500
    }
  ];
  calculatorDigits = [
    {
      name:'7',
      value:7,
      type:'number'
    },{
      name:'8',
      value:8,
      type:'number'
    },{
      name:'9',
      value:9,
      type:'number'
    },{
      name:'4',
      value:4,
      type:'number'
    },{
      name:'5',
      value:5,
      type:'number'
    },{
      name:'6',
      value:6,
      type:'number'
    },{
      name:'1',
      value:1,
      type:'number'
    },{
      name:'2',
      value:2,
      type:'number'
    },{
      name:'3',
      value:3,
      type:'number'
    },{
      name:'0',
      value:0,
      type:'number'
    },{
      name:'.',
      value:'.',
      type:'number'
    },{
      name:'CE',
      value:'CE',
      type:'clean'
    },{
      name:'COMPLETO',
      value:'COMPLETO',
      type:'completed'
    }
  ];
  clientCredit = {
    main:{
      uuid:'',
      dischargeDate:'',//dd/MM/yyyy hh:mm:ss’, fecha de alta
      acceptanceDate:'',// fecha de aceptación
      status:'Trámite',//Trámite, Valoración, Aprobado, Supendido, Cancelado
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      client:{
        main:{
          uuid:'',
          nickname:''
        }
      },
      creditType:{
        main:{
          uuid:'',
          maxAmount:0
        }
      }
    }
  }
  constructor(public dialogRef: MatDialogRef<CounterShiftPaymentComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private session:SessionService, public loading: LoadingService
  ) {
    this.metadata.object.main = data.object.main;
   }

  ngOnInit() {
    this.metadata.totalPayment = parseFloat(this.metadata.object.main.total);
    this.calculateChangePayment();
  }


  /**fucnión para cargar un pago rapido
  */
  loadFastPayment(object){
    this.metadata.totalPayment = object.value;
    this.metadata.firtsPickCalculator = false;
    this.calculateChangePayment();
  }

  /**función para calcular el cambio
  */
  calculateChangePayment(){
    let aux:any = this.metadata.totalPayment;
    this.metadata.totalChange = parseFloat(aux) - parseFloat(this.metadata.object.main.total);
    if(this.metadata.totalChange < 0)
      this.metadata.totalChange = 0;
  }

  /**función para concatenar el valor de totalPayment
  */
  useCalculator(object,status){
    console.log("object::::",object);
    console.log("status::::",status);

    if(!this.metadata.firtsPickCalculator){
      this.metadata.totalPayment = 0;
      this.metadata.firtsPickCalculator = true;
    }
    if(status == 'number'){
      let actualValue:any = this.metadata.totalPayment;
      actualValue = `${actualValue}${object.value}`;
      this.metadata.totalPayment = actualValue;
    }else{
      if(status == 'completed'){
        this.metadata.totalPayment = parseFloat(this.metadata.object.main.total);
      }else{
        //clean totalChange
        this.metadata.totalPayment = 0;
      }
    }
    this.calculateChangePayment();
  }


  changeUpdate(){
    this.metadata.object.main.changePayment = parseFloat(this.metadata.object.main.payment) - parseFloat(this.metadata.object.main.total);
  }

  /**funcion para seleccionar el metodo de pago
  */
  selectedPaymentMethod(object){
    this.metadata.firtsPickCalculator = false;
    this.metadata.paymentMethod = object;
    this.metadata.alert = '';
    this.metadata.enabledToBuy = true;
    if(this.metadata.paymentMethod != 'Efectivo'){
      this.metadata.totalPayment = parseFloat(this.metadata.object.main.total);
      this.calculateChangePayment();
    }
    if(this.metadata.paymentMethod =='Credito'){
      //buscamos los datos de credito de un cliente.
      this.clientCredit.system.client.main = this.data.object.system.client.main;
      this.loading.show(true,"Buscando crédito disponible.");
      this.session.getRequest("clientCredit:findByClient",this.clientCredit).subscribe(async(data:any)=>{
        this.clientCredit = data.object;
        if(this.clientCredit.main.uuid != null){
          await this.clientHaveCredit();
        }else{
          this.metadata.enabledToBuy = false;
          this.metadata.alert = 'Lo siento, el cliente NO TIENE CRÉDITO.'
        }
        this.loading.hide();
      },error=>{
        this.metadata.enabledToBuy = false;
        this.metadata.alert = 'Lo siento, el cliente '+this.clientCredit.system.client.main.nickname+' NO TIENE CRÉDITO.'
        console.log(error);
        this.loading.hide();
      });
    }
  }

  /**función para determinar si se le puede dar credito al cliente
  */
  clientHaveCredit(){
    return new Promise(async(resolve,reject)=>{
      // verificamos si el cliente no esta Suspendido
      if(this.clientCredit.main.status != 'Aprobado' ){
        this.metadata.enabledToBuy = false;
        this.metadata.alert = 'Lo siento, el cliente '+this.clientCredit.system.client.main.nickname+' NO TIENE CRÉDITO DISPONIBLE; El crédito del cliente esta '+this.clientCredit.main.status;
      }else{
        try{
          //verificamos si el cliente tiene credito disponible
          let creditUsed:any  =await this.getCreditUsedFromClient();
          let totalUsedCreditAux = parseFloat(this.metadata.object.main.total) + parseFloat(creditUsed.total);
          let totalCreditAvailable = this.clientCredit.system.creditType.main.maxAmount - parseFloat(this.metadata.object.main.total)

          if(totalUsedCreditAux > this.clientCredit.system.creditType.main.maxAmount){
            if(this.clientCredit.system.creditType.main.maxAmount == 0){
              this.metadata.enabledToBuy = true;
            }else{
              this.metadata.enabledToBuy = false;
              this.metadata.alert = 'Lo siento, el cliente '+this.clientCredit.system.client.main.nickname+' NO TIENE SALDO SUFICIENTE EN SU CRÉDITO; Saldo: $'+totalCreditAvailable;
            }

          }else{
            this.metadata.enabledToBuy = true;
          }
        }catch(e){
          this.metadata.enabledToBuy = false;
          this.metadata.alert = 'Lo siento hubo un error en el sistema. Intente con otro cliente.';
          reject(e);
        }
      }
      resolve(this.metadata.enabledToBuy);
    });
  }

  getCreditUsedFromClient(){
    return new Promise((resolve,reject)=>{
      let saleMinAux ={
        main:{
          status:'Credito'
        },
        system:{
          client:{
            main:{
              uuid:this.clientCredit.system.client.main.uuid
            }
          }
        }
      }
      this.session.getRequest("sale:getTotalByClientAndStatus",saleMinAux).subscribe((data:any)=>{
        // console.log(data.object);
        resolve(data.object);
      },error=>{
        console.log("error:sale:getTotalByClientAndStatus",error);
        reject(error);
      });
    })
  }

  onKeydown(ev){
    console.log("calculatorDigits",this.calculatorDigits);
    console.log("ev::::::",ev);
    if(ev.keyCode >= 48 && ev.keyCode <=57){
      let cal:any = {};
      for(let item of this.calculatorDigits){
        if(item.value == ev.key){
          cal = JSON.parse(JSON.stringify(item));
        }
      }
      this.useCalculator(cal,cal.type);
    }else{
      if(ev.key == 'Backspace'){
        let cal:any = {};
        for(let item of this.calculatorDigits){
          if(item.value == 'CE'){
            cal = JSON.parse(JSON.stringify(item));
          }
        }
        this.useCalculator(cal,cal.type);
      }
      if(ev.key == '.'){
        let cal:any = {};
        for(let item of this.calculatorDigits){
          if(item.value == '.'){
            cal = JSON.parse(JSON.stringify(item));
          }
        }
        this.useCalculator(cal,cal.type);
      }
      console.log("No es un numero");
    }

  }


  close(status){
    this.metadata.firtsPickCalculator = false;
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.metadata;
    }else{
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

}
