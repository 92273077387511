import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
// const server_url:string = environment.production ? 'http://25.17.175.137:8082/SORIEL-INV-0320-ERP-Backend/':'http://25.17.175.137:8082/SORIEL-INV-0320-ERP-Backend/';
const server_url:string = environment.production ? 'https://refacciones.keysoft.mx/INV-ERP/':'https://refacciones.keysoft.mx/INV-ERP/';
// const server_url:string = environment.production ? 'http://localhost:8080/SORIEL-INV/':'http://localhost:8080/SORIEL-INV/';


export default server_url;
