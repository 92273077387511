import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-policy-document',
  templateUrl: './policy-document.component.html',
  styleUrls: ['./policy-document.component.css']
})
export class PolicyDocumentComponent implements OnInit {
  documentPolicyModel = {
    main:{
      uuid:''
    },
    image:{
      nameFile:'',
      file:''
    },
    system:{
      policy:{
        main:{
          uuid:''
        }
      }
    }
  }

  metadata = {
    file:{
      file:'',
      name:'',
      type:'',
      nameFile:''
    }
  }

  object = {
    main:{
      policyNumber:'',
      uuid:''
    }
  };
  documentList = [];
  constructor(public dialogRef: MatDialogRef<PolicyDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, protected session: SessionService, public snackBar: MatSnackBar) {
    this.object = JSON.parse(JSON.stringify(this.data.object));
    this.documentPolicyModel.system.policy.main.uuid = data.object.main.uuid;
  }

  ngOnInit() {
    this.findAllDocuments();
  }

  /* Funcion para obtener los documentos de una poliza */
  findAllDocuments(){
    this.session.getRequest("documentPolicy:findAllByPolicy", this.documentPolicyModel).subscribe((data:any)=>{
      for(let item of data.object.instanceList){
        item.image.file =item.image.file.replace(/-/g, "+");
        this.documentList.push(item);
      }
    },error=>{
      console.log("Error:")
    })
  }

  send(){
    let documentPolicyAux = JSON.parse(JSON.stringify(this.documentPolicyModel));
    documentPolicyAux.image.file = this.metadata.file.file.replace(/\+/g, "-");
    documentPolicyAux.image.nameFile = this.metadata.file.name;
    this.session.getRequest("documentPolicy:update",documentPolicyAux).subscribe((data:any)=>{
      // console.log(data);
      data.object.image.file = data.object.image.file.replace(/-/g, "+");
      this.documentList.push(data.object);
      this.metadata.file.name = '';
      this.metadata.file.file = '';
      this.metadata.file.type = '';
      this.snackBar.open('Registro guardado exitosamente', 'Información', { duration: 5000 });
    },error=>{
      console.log("Error:documentPolicy:update",error);
    })
  }

  /* funcion para descargar un documento */
  downloadADocument(object){
    let elem = document.createElement('a');
    elem.href = object.image.file;
    elem.setAttribute('download', object.image.nameFile);
    document.body.appendChild(elem);
    elem.click();
  }

  /* funcion para eliminar un documento */
  deleteADocument(object){
    this.session.getRequest("documentPolicy:delete",object).subscribe(()=>{
      this.snackBar.open('Registro eliminado exitosamente', 'Información', { duration: 5000 });
      for(let i=0; i <this.documentList.length; i++){
        if(this.documentList[i].main.uuid == object.main.uuid){
          this.documentList.splice(i,1);
          break;
        }
      }
    },error=>{
      console.log("Error:documentPolicy:delete",error);
    })
  }

  /* Funcion para cerrar el modal de incremento salarial. */
  closeDialog(object) {
    this.dialogRef.close(object);
  }
}
