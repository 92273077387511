import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar} from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
@Component({
  selector: 'setting-products-min-price',
  templateUrl: './setting-products-min-price.component.html',
  styleUrls: ['./setting-products-min-price.component.css']
})
export class SettingProductsMinPriceComponent implements OnInit {
  productPriceModel = {
    main:{
      uuid:'',
      price:0,
      direct:true, // false el precio se toma directo del input || true el precio se calcula segun formula
      formula:'',
      name:'',
      tax:true
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      }
    }
  };
  productPriceList = [];
  productPrice:any = JSON.parse(JSON.stringify(this.productPriceModel));
  @Input('product') object:{main:{uuid:''}} ;

  constructor(public loadingService:LoadingService, private session: SessionService, public snackBar: MatSnackBar) {}

  /*
  Funcion para obtener el los precios de un producto
  */
  getPrices(){
    this.productPrice.system.product.main.uuid = this.object.main.uuid;
    this.loadingService.show(true,'Cargando recursos, espere un momento...')
    this.session.getRequest('productPrice:findAllByProduct',this.productPrice).subscribe((data:any)=>{
      this.productPriceList = data.object.instanceList;
      let x = this.productPriceList.length;
      while (x < 3) {
        let aux = JSON.parse(JSON.stringify(this.productPriceModel))
        switch(x){
          case 0:
            aux.main.name=1;
          break;
          case 1:
            aux.main.name=2;
          break;
          case 2:
            aux.main.name=3;
          break;
        }
        x++;
        this.productPriceList.push(aux);
      }
      this.loadingService.hide();
      this.newProductPrice();
    },error=>{
      console.log("error:productPrice:findAllByProduct",error);
      this.loadingService.hide();
    })
  }

  ngOnInit() {
    this.getPrices();
  }

  /* función para crear un nuevo precio
  */
  newProductPrice(){
    this.productPriceList.push(JSON.parse(JSON.stringify(this.productPriceModel)));
  }

  addPriceProduct(object,i,status){
    object.system.product.main.uuid = this.object.main.uuid;
    if(object.main.uuid == ''){
      object.main.name = this.productPriceList.length + 1;
    }
    object.main.formula = object.main.formula.replace(/\+/g,"#");
    this.session.getRequest('productPrice:update',object).subscribe((data:any)=>{
      if(object.main.uuid == ''){
        this.newProductPrice();
      }
      object.main.uuid = data.object.main.uuid;
      this.snackBar.open('El registro se guardo correctamente', '', {
        duration: 8000
      });
    },error=>{
      console.log("Error:productPrice",error);
      this.snackBar.open('Verifique la infromación que se agrego', '', {
        duration: 8000
      });
    })
  }
  deleteAProductPrice(object){
    this.session.getRequest('productPrice:delete',object).subscribe((data:any)=>{
      for(let i=0; i < this.productPriceList.length; i++){
        if(this.productPriceList[i].main.uuid == object.main.uuid){
          this.productPriceList.splice(i,1);
          this.snackBar.open('El registro se eliminado correctamente', '', {
            duration: 8000
          });
          break;
        }
      }
    },error=>{
      console.log("Error;productPrice:delete",error);
    })
  }

}
