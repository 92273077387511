import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatSnackBar, MatDrawer,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-users-access',
  templateUrl: './admin-users-access.component.html',
  styleUrls: ['./admin-users-access.component.css']
})
export class AdminUsersAccessComponent implements OnInit {

  userObject = {
    main: {
      uuid: ''
    },
    user: {
      main: {
        uuid: '',
        username: ''
      }
    },
    role: {
      main: {
        uuid: ''
      }
    },
    system: {
      objectIdTemp: '',
      objectId: '',
      objectType: 'Company',
      listObjects:[]
    }
  };
  rolesList= [];
  objectsList= [];
  objectsAssingList= [];
  branchList = [];
  object = JSON.parse(JSON.stringify(this.userObject));
  user:any = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      roles:[],
      status:true,
      type: "Company",
      objectUuid: "",
      physicalPerson: {
        main:{
          uuid:''
        },
        complement:{
          rfc:''
        }
      }
    }
  };
  metadata = {
    userType:'',
    userObjectUuid:''
  }
  constructor(protected sessionService: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,public dialogRef: MatDialogRef<AdminUsersAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
  }
  ngOnInit() {
    this.user = JSON.parse(JSON.stringify(this.data.object));
    this.metadata.userType = this.user.system.type;
    this.metadata.userObjectUuid = this.user.system.objectUuid;
    // cargamos los recursos requeridos
    this.getRoles().then(data=>{
    this.object.user.main.username = this.user.main.username;
    this.object.user.main.uuid = this.user.main.uuid;
    this.object.system.objectIdTemp = this.metadata.userObjectUuid;
    this.object.system.objectId = '';
    this.object.role.main.uuid = '';
    this.object.system.objectType = this.user.system.type;
    this.getRolByUser().then(data=>{
      if(this.user.system.roles.length > 0){
         this.object.role = this.user.system.roles[0];
      }
      this.getObjectsAssing(this.user.main.uuid);
    }).catch(error=>{
      console.log('Error:getRolByUser',error);
    })
    this.objectsList = [];
    this.object.system.listObjects = [];
    });
    // cargamos las sucursales es el usuario es de tipo branch
    if(this.user.system.type=='Branch'){
      this.getBranchByCompany()
    }
  }
  /*
  funcion para obtener el rol de un usuario
  */
  getRolByUser(){
    return new Promise((resolve,reject)=>{
      this.sessionService.getRequest('user:findAllRoleByUser',{user:{main:{uuid:this.user.main.uuid}}}).subscribe((data:any)=>{
        this.user.system.roles = data.object.instanceList;
        resolve(true);
      },error=>{
        reject(error);
        console.log('Error:user:findAllRoleByUser',error);
      })
    })
  }

  /*
  funcion para obtener la lista de roles
  */
  //obtenemos los roles registrados en el sistema
  getRoles(){
   return new Promise(resolve=>{
    this.sessionService.getRequest('role:findAllByObjectTypeAndObjectId',{system:{objectType: 'Company', objectId:this.metadata.userObjectUuid}}).subscribe(
      (data:any)=>{
        if(data.object.instanceList.length > 0){
          this.rolesList = data.object.instanceList;
          resolve(this.rolesList);
        }
        else{
          console.log("No hay roles registrados");
        }
      },
      error => {
        console.log("role:findAllByObjectTypeAndObjectId",error);
      })
    })
  }
  //obtenemos los objetos asignados al usuario
  getObjectsAssing(uuid){
   return new Promise(resolve=>{
      this.objectsAssingList = [];
      this.sessionService.getRequest('validate:userObjects', {main: {uuid: uuid}}).subscribe(
        (data:any)=>{
          this.objectsAssingList = data.objectList;
          resolve(this.objectsAssingList);
        },
        error => {
          console.log("validate:userObjects",error);
        })
    })
  }
  /*
  Obtenemos las sucursales de una compañia cliente
  */
  getBranchByCompany(){
    this.loadingService.wait();
    this.sessionService.getRequest('branch:list', {system:{company:{main:{uuid: this.metadata.userObjectUuid}}, status:true}}).subscribe(
     (data:any)=>{
       this.branchList = data.object.instanceList;
       this.loadingService.hide();
     },
     error => {
       console.log("branch:list",error);
       this.loadingService.hide();
     })
  }


     /** Permite asignar el rol al usuario **/
      addRol(){
         this.objectsList = [];
         this.object.system.listObjects = [];
         this.object.system.objectId = '';
         // for (var x = 0; x < this.rolesList.length; x++) {
         //   if(this.rolesList[x].main.uuid == this.object.role.main.uuid){
         //     if(this.rolesList[x].main.authority == 'ROLE_SALE' || this.rolesList[x].main.authority == 'ROLE_COUNTER'){
         //       this.object.system.objectType='Branch';
         //     }else{
         //       this.object.system.objectType = this.rolesList[x].system.objectType;
         //     }
         //     break;
         //   }
         // }
         if(this.object.system.objectType == 'Branch'){
           this.getBranchByCompany();
         }
      }

      /** Permite modifcar el rol asignado al usuario **/
      changeRole(uuid){
        this.loadingService.wait();
        this.object.system.objectId = '';
        this.object.role.main.uuid = '';
        this.object.system.objectType = '';
        this.objectsList = [];
        this.objectsAssingList = [];
        this.object.system.listObjects = [];

        this.sessionService.getRequest('user:deleteByRolAndObjects', {user:{main:{uuid:uuid}}}).subscribe(
         (data:any)=>{
           // console.log("¡Listo para cambio de rol!");
           this.snackBar.open('Listo para cambio de rol', 'Información', {duration: 5000});
           this.loadingService.hide();
         },
         error => {
           console.log("user:deleteByRolAndObjects",error);
           this.snackBar.open('Error al modificar rol', 'Error', {duration: 5000});
           this.loadingService.hide();
         })
      }

      //Funcion para guardar accesos
      send() {
      // console.log(this.object);
        this.loadingService.show(true,'Guardando accesos de usuario');
        this.object.system.listObjects = [];
        if(this.object.system.objectType == 'Company'){
          this.object.system.objectId = this.metadata.userObjectUuid;
          this.object.system.listObjects.push({
            main:{
              uuid:this.metadata.userObjectUuid
            }
          })
        }
        else{
          this.object.system.listObjects = this.objectsList;
        }

        this.sessionService.getRequest('user:updateUserObject', this.object).subscribe(
          (data:any)=>{
            // console.log("¡Guardado Exitoso!");
            this.snackBar.open('Registro guardado exitosamente', 'Success', {duration: 5000});
            this.loadingService.hide();
            this.user.system.type = this.object.system.objectType;
            this.loadingService.show(true,'Guardando usuario');
            if(this.user.system.physicalPerson.complement.dateBirth){
              this.user.system.physicalPerson.complement.dateBirth = moment(this.user.system.physicalPerson.complement.dateBirth).format('DD-MM-YYYY');
            }
            this.sessionService.getRequest('user:update', this.user).subscribe((data:any)=>{
              this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
              this.loadingService.hide();
              this.close();
            },
            (error)=>{
              console.log('Error:user:update',error)
              this.snackBar.open(error.message, 'Error', {duration: 5000});
              this.loadingService.hide();
            })
          },
          error => {
            console.log("user:updateUserObject",error);
            this.snackBar.open(error.message, 'Error', {duration: 5000});
            this.loadingService.hide();
          })
      }

      //Funcion para agregar una sucursal
      addBranch(){
        var exist = 'false'
        for (var x = 0; x < this.branchList.length; x++) {
          if (this.branchList[x].main.uuid == this.object.system.objectId) {
            if (this.objectsList.length > 0) {
              for (var y = 0; y < this.objectsList.length; y++) {
                if (this.objectsList[y].main.uuid == this.object.system.objectId) {
                  exist = 'true';
                  break;
                }
              }
              if (exist == 'false') {
                this.objectsList.push(this.branchList[x]);
              }
            }
            else {
              this.objectsList.push(this.branchList[x]);
            }
          }
        }
        this.object.system.objectId = '';
        this.object.system.listObjects = this.objectsList;
      }
      // Remover objecto agregado
      removeObject(index){
        this.objectsList.splice(index,1);
        this.object.system.listObjects = this.objectsList;
      }

      deleteObject(id, index){
        this.sessionService.getRequest('user:deleteObject', {id: id}).subscribe(
         (data:any)=>{
           this.objectsAssingList.splice(index,1);
           // console.log("¡Eliminado exitoso!");
           this.snackBar.open('Eliminado exitoso', 'Información', {duration: 5000});
         },
         error => {
           console.log("user:deleteObject",error);
           this.snackBar.open(error.message, 'Error', {duration: 5000});
         })
      }

      close(){
        this.dialogRef.close(true);
      }
}
