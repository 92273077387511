import { Component, OnInit, Inject } from '@angular/core';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { SessionService } from '../../../service/session/session.service';
import { MatDialogRef, MAT_DIALOG_DATA,PageEvent } from '@angular/material';

@Component({
  selector: 'app-sale-terminal-update',
  templateUrl: './sale-terminal-update.component.html',
  styleUrls: ['./sale-terminal-update.component.css']
})
export class SaleTerminalUpdateComponent implements OnInit {
  saleTerminal = {
    main:{
      uuid:'',
      authorizationNumber:''
    },
    system:{
      sale:{
        main:{
          uuid:''
        }
      },
      terminal:{
        main:{
          uuid:''
        }
      }
    }
  }
  terminalMin ={
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }
  metadata = {
    branch:{
      main:{
        uuid:''
      }
    }
  }
terminalList = [];
branchList = [];
  constructor(public dialogRef: MatDialogRef<SaleTerminalUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService) {
    if(data.main.uuid != ''){
      this.terminalMin.system.branch.main.uuid = data.system.terminal.system.branch.main.uuid;
      this.saleTerminal.system = data.system;
      this.saleTerminal.main = data.main;
      this.getTerminalsByBranch();
    }

  }

  ngOnInit() {
    this.getCompanyByBranch();
  }
  /**get branch by company
  */
  getCompanyByBranch(){
    let user = this.session.getUser();
    let company = {main:{uuid:''}};
    if(user.system.objectType == 'Company'){
      company = this.session.getSessionObject();
    }else{
      let branch:any = this.session.getSessionObject();
      company = branch.system.company;
    }
    this.session.getRequest("branch:findAllByCompanyAndStatus",{system:{company:{main:{uuid:company.main.uuid}}},status:true}).subscribe((data:any)=>{
      this.branchList = data.object.instanceList;
    },error=>{
      console.log("error",error);
    })
  }
  /** function to find al terminals from company
  */
  getTerminalsByBranch(){
    this.terminalList = [];
    this.session.getRequest("terminal:findAllByBranchAndStatus",this.terminalMin).subscribe((data:any)=>{
      this.terminalList =  data.object.instanceList;
    },error=>{
      console.log("error:findAllByBranch",error);
    })
  }

  /*
    funcion para cerrar el modal de incremento salarial.
  */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

}
