import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-branch-update',
  templateUrl: './branch-update.component.html',
  styleUrls: ['./branch-update.component.css']
})
export class BranchUpdateComponent implements OnInit {
complexForm: FormGroup;
contacts = [];
branchModel = {
  main: {
    uuid: '',
    code: '',
    name: ''
  },
  complement: {
    address: {
      main: {
        uuid: '',
        settingName: '',
        numberOutdoor: '',
        numberInterior: '',
        zipCode: '',
        fullAddress:''
      },
      complement: {
        betweenRoads: '',
        locationDescription: ''
      },
      system: {
        addressType: 'Fiscal',
        setting: {
          main: {
            uuid: '',
            mName: ''
          },
          system: {
            locality: ''
          }
        },
        locality: {
          main: {
            uuid: '',
            code: '',
            name: '',
            typeLocality: ''
          },
          system: {
            town: {
              main: {
                uuid: '',
                name: ''
              },
              system: {
                state: {
                  main:{
                    uuid:'',
                    name:''
                  },
                }
              }
            }
          }
        },
        state:{
          main:{
            uuid:''
          }
        },
        status: true
      }
    }
  },
  system: {
    company: {
      main:{
        uuid: ''
      }
    },
    status: true,
    contacts: this.contacts
  }
};
object = JSON.parse(JSON.stringify(this.branchModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<BranchUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
   }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
       this.object.main.uuid = this.data;
       // cargamos los datos de la BD
       this.session.getRequest('branch:get',this.object).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error:branch:get',error)
       })
    }
  }

  /**
   * funcion para guardar una sucursale
   */
  send(object){
    this.loadingService.show(true,'Guardando registro');
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("branch:update",object).subscribe(
      (data:any) => {
        this.snackBar.open('La sucursal se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("branch:update",error);
        this.loadingService.hide();
      }
    );
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

}
