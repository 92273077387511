import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from './../../../directive/loading/loading.module';
import { AccountingAccountSelectComponent } from './accounting-account-select/accounting-account-select.component';

@Component({
  selector: 'app-admin-setting-accounting',
  templateUrl: './admin-setting-accounting.component.html',
  styleUrls: ['./admin-setting-accounting.component.css']
})
export class AdminSettingAccountingComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  currencyList = [];
  accountingConfigurationModel = {
    main: {
      uuid: '',
      patternDigit: '',
      typePattern: '',
      separatorSymbol: '',
      year: false,
      month: false
    },
    system: {
      editParentAccount: false,
      objectType: '',
      objectTypeUuid: '',
      accountingAccount: {
        main: {
          uuid: '',
          accountNumber: '',
          description: '',
          natureAccount: ''
        }
      },
      previousExerciseAccount: {
        main: {
          uuid: '',
          accountNumber: '',
          description: '',
          natureAccount: ''
        }
      },
      currency: {
        main: {
          uuid: ''
        }
      }
    }
  }
  metadata = {
    company: {
      main: {
        uuid: ''
      }
    }
  }
  typePatternList = [{ name: 'Dígitos', value: 'Digitos' }, { name: 'Pátron', value: 'Patron' }, { name: 'Número de Nivel', value: 'Numero de Nivel' }];
  companyBossesList = [];
  object = JSON.parse(JSON.stringify(this.accountingConfigurationModel));

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.loadingService.wait();
    // Obtenemos las Empresas Patronales
    // this.getCompanyBosses();
    this.metadata.company.main = this.session.getSessionObject().main;
    this.getSettingAccounting(this.metadata.company.main.uuid);
    this.listCurrency();
  }

  getSettingAccounting(uuid) {
    // cargamos los datos de la BD
    this.object.system.objectType = 'Company';
    this.object.system.objectTypeUuid = uuid;
    this.session.getRequest('accountingConfiguration:findByObjectTypeAndObjectTypeUuid', this.object).subscribe((data: any) => {
      if (data.object.main.uuid == null || data.object.main.uuid == '') {
        this.object = JSON.parse(JSON.stringify(this.accountingConfigurationModel));
      } else {
        this.object = JSON.parse(JSON.stringify(data.object));
      }
      this.loadingService.hide();
    }, (error) => {
      this.loadingService.hide();
      console.log('Error:accountingConfiguration :get', error)
    });
  }


  /*
  funcion para guardar un kit de producto
  */
  send(object) {
    this.loadingService.show(true, 'Guardando registro');
    object.system.objectType = 'Company';
    object.system.objectTypeUuid = this.metadata.company.main.uuid;
    this.session.getRequest("accountingConfiguration:update", object).subscribe(
      (data: any) => {
        this.snackBar.open('Configuración actualizada', 'Success', {
          duration: 8000
        });
        this.loadingService.hide();
      },
      error => {
        console.log("accountingConfiguration:update", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * Abre el cuadro de dialogo para advertir que al eliminar la cuenta se eliminan sus hijos
   */
  openAccountingAccountDialog(object, account) {
    const dialogRef = this.dialog.open(AccountingAccountSelectComponent, {
      width: '600px',
      height: '40rem',
      data: { accountingAccount: object, companyUuid: this.metadata.company.main.uuid }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Actualizamos la cuenta seleccionada
      if (result) {
        if (account) {
          this.object.system.accountingAccount.main = result.main;
        } else {
          this.object.system.previousExerciseAccount.main = result.main;
        }
      }
    });
  }

  /*
   *funcion para obtener las monedad
   */
  listCurrency() {
    this.session.getRequest("currency:list", {}).subscribe(
      (data: any) => {
        this.currencyList = data.object.instanceList;
      },
      error => {
        console.log("currency:list", error);
      });
  }

  /*
   * funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
