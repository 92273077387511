import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-account-delete',
  templateUrl: './admin-account-delete.component.html',
  styleUrls: ['./admin-account-delete.component.css']
})
export class AdminAccountDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
