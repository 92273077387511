import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.module';
import { LoadingService } from '../../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-product-provider-update',
  templateUrl: './product-provider-update.component.html',
  styleUrls: ['./product-provider-update.component.css']
})
export class ProductProviderUpdateComponent implements OnInit {
  complexForm: FormGroup;

  contacts = [];
  accounts = [];
  updateAccounts = [];
  address = {
    id:'address',
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: ''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          name: ''
        },
        system: {
          locality: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main:{
                  uuid:'',
                  name:''
                },
              }
            }
          }
        }
      },
      state: {
        main:{
          uuid:'',
          name:''
        },
      },
      status: true
    }
  };
  moralPerson = {
    main: {
      uuid: '',
      socialReason: ''
    },
    complement: {
      rfc: ''
    },
    system: {
      contacts: this.contacts,
      address: this.address,
      status: true
    }
  };
  physicalPerson = {
    main:{
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement:{
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system :{
      status:true,
      address:this.address,
      contacts:this.contacts
    }
  };

  providerModel = {
    main: {
      uuid: '',
      name: '',
      contactName: '',
      code: ''
    },
    system: {
      national:true,
      typePerson: 'MoralPerson',
      person:JSON.parse(JSON.stringify(this.moralPerson)),
      company:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  };
  metadata = {
    disable:false,
    dateBirth : null
  }
  object = JSON.parse(JSON.stringify(this.providerModel));
  isLinear = true;
  constructor(public fb: FormBuilder, private sessionService: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ProductProviderUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'code': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'contactName': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      // 'curp': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[0-9]{2}')])],
      'rfcP': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
      'rfcM': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{3}[0-9]{6}[A-Za-z0-9]{3}')])],
      'socialReason': [null, Validators.compose([Validators.required])],
      'surname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    if(this.data == 'new'){
      // no hacemos nada por el momento
      this.metadata.disable = false;
    }else{
      this.isLinear = false;
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.loadingService.wait();
      this.sessionService.getRequest('provider:get',this.object).subscribe((data:any)=>{
        this.loadingService.hide();
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).add(6, 'hours').utc().toISOString();
        if(this.object.system.typePerson != ''){
          if(this.object.system.typePerson == "PhysicalPerson"){
            this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).format("YYYY-MM-DD");
          }
          // console.log(this.address);
          let aux:any = this.object.system.person;
          if(aux.system.address){
            this.address = aux.system.address;
          }
        }
        if(this.object.system.typePerson != ''){
          if(this.object.system.typePerson == "PhysicalPerson"){
            this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).format("YYYY-MM-DD");
          }
          // console.log(this.address);
          let aux:any = this.object.system.person;
          this.contacts = aux.system.contacts;
        }
        this.metadata.disable = true;
      },
      (error)=>{
        console.log('Error:provider:get',error)
        this.loadingService.hide();
      })

    }
  }

  loadTypePerson() {
    if (this.object.system.typePerson == "MoralPerson") {
      this.object.system.person = this.moralPerson;
    }
    if (this.object.system.typePerson == "PhysicalPerson") {
      this.object.system.person = this.physicalPerson;
    }
  }

  send(object) {
    this.loadingService.show(true,'Guardando...');
    if (object.system.person.main != undefined) {
      // pasamos la fecha de compleaño al formato que recibe al BD
      if (object.system.typePerson == "PhysicalPerson") {
        object.system.person.complement.dateBirth = moment().format("DD-MM-YYYY");
      }
      object.system.company.main.uuid = this.sessionService.getSessionObject().main.uuid;
      //guardamos la direccónew
      if(this.address.system.state.main.uuid != ''){
        object.system.person.system.address = this.address;
      }
      this.sessionService.getRequest("provider:update", object).subscribe(
        (data:any) => {
          let first = false;
          if(this.object.main.uuid == ''){
           first = true;
          }
          this.object.main.uuid = data.object.main.uuid;
          // if(first){
          //  this.getRegistriSettings();
          // }
          this.object.system.person.main.uuid = data.object.system.person.main.uuid;
          this.snackBar.open('El proveedor se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);
       },
       error => {
         console.log("Error: provider:update",error);
       }
      );

    }else{
      this.snackBar.open('Seleccion un tipo de persona', '', {
        duration: 8000
      });
    }
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

}
