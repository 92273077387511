import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-purchase-generate-period',
  templateUrl: './purchase-generate-period.component.html',
  styleUrls: ['./purchase-generate-period.component.css']
})
export class PurchaseGeneratePeriodComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  purchaseGenerateList = [];
  purchaseGenerateMin = {
    system:{
      startDate:'',
      endDate:'',
      company:{
        main:{
          uuid: ''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    startDate:'',
    endDate:'',
    company:{
      main:{
        uuid:''
      }
    },
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) {
    switch(this.session.getUser().system.objectType){
      case 'Branch':{
        console.log("Branch");
        let branch:any = this.session.getSessionObject();
        this.metadata.company.main.uuid = branch.system.company.main.uuid;
        break;
      }
      case 'Company':{
        console.log("Company");
        this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
        break;
      }
    };
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
        // console.log('called once',val)
        if(val != ''){
          this.purchaseGenerateList = [];
          this.purchaseGenerateMin.filter.name = 'findAllLikeName';
        }else{
          this.purchaseGenerateList = [];
          this.purchaseGenerateMin.filter.name = '';
          this.purchaseGenerateMin.filter.value = '';
          val = 'false'
        }
        this.getpurchaseGenerateListByStatus();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    let actualMonth = moment().get('month');
    let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');

    this.purchaseGenerateMin.system.startDate =  moment().set('date', 1).format('DD/MM/YYYY');
    this.purchaseGenerateMin.system.endDate = endOfTheMonth.format('DD/MM/YYYY');
    this.metadata.startDate =  moment().set('date', 1).toISOString();
    this.metadata.endDate =  endOfTheMonth.toISOString();
    this.getpurchaseGenerateListByStatus();
    // this.getpurchaseGenerateListByStatus();
  }

  getBranchList (){
    this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.branchList = [];
    this.session.getRequest("branch:list",this.branchMin).subscribe(
      (data:any) => {
        this.branchList = data.object.instanceList;
     },
     error => {
       console.log("branch:list",error);
     }
    );
  }

  /**función que se ejecuta cuando se cambia de pestaña en el tab
  */
  tabChanged(ev){
    for (let item of this.branchList){
      if(item.main.name == ev.tab.textLabel){
        this.purchaseGenerateMin.system.branch.main = item.main;
      }
    }
    this.getPurchaseByBranch();
  }

  getPurchaseByBranch(){
    this.purchaseGenerateList = [];
    // verificamos en que pagina verificamos
    this.purchaseGenerateMin.system.company.main.uuid = this.metadata.company.main.uuid;
    this.session.getRequest("nationalPurchase:listByBranch",this.purchaseGenerateMin).subscribe(
      (data:any) => {
        this.purchaseGenerateList = data.object.instanceList;
        for(let item of this.purchaseGenerateList){
          item.system.createDate = moment(item.system.createDate).format('DD/MMM/YYYY');
        }
        for(let i = 0; i < this.purchaseGenerateList.length; i++){
          this.getTotals(this.purchaseGenerateList[i]);
        }
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("nationalPurchase:listByBranch", error);
       this.loadingService.hide();
     }
    );
  }

  getpurchaseGenerateListByStatus (){
    this.purchaseGenerateList = [];
    // verificamos en que pagina verificamos
    this.purchaseGenerateMin.system.company.main.uuid = this.metadata.company.main.uuid;
    this.session.getRequest("nationalPurchase:findAllByCompanyAndPeriodBetween",this.purchaseGenerateMin).subscribe(
      (data:any) => {
        this.purchaseGenerateList = data.object.instanceList;
        for(let item of this.purchaseGenerateList){
          item.system.createDate = moment(item.system.createDate).format('DD/MMM/YYYY');
        }
        for(let i = 0; i < this.purchaseGenerateList.length; i++){
          this.getTotals(this.purchaseGenerateList[i]);
        }
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
        this.paginatorModel.total = data.object.total;
     },
     error => {
       console.log("nationalPurchase:listByCompany", error);
       this.loadingService.hide();
     }
    );
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.purchaseGenerateMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.purchaseGenerateMin.offset = (event.pageIndex * event.pageSize);
    this.purchaseGenerateMin.max = event.pageSize;
    this.getpurchaseGenerateListByStatus();
  }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /** funcion a ejecutar cuando cambia una fecha
  */
  changeDate(){
    this.purchaseGenerateMin.system.startDate =  moment(this.metadata.startDate).format('DD/MM/YYYY');
    this.purchaseGenerateMin.system.endDate =  moment(this.metadata.endDate).format('DD/MM/YYYY');
    // console.log("Cambio de periodo");

    if(moment(this.metadata.startDate).diff(moment(this.metadata.endDate)) < 0){
      this.purchaseGenerateList = [];
      this.getpurchaseGenerateListByStatus();
    }else{
      this.snackBar.open('Revisar el periodo de tiempo seleccionado.', 'Aceptar', {duration: 5000});
    }
  }

  /**función para calcular el total de una compra
  */
  getTotals(object){
    this.session.getRequest('nationalPurchase:get', object).subscribe((data: any) => {
      object.main.total = 0;
      object.main.subtotal = 0;
      object.main.tax = 0;
      for(let item of data.object.system.products){
        object.main.subtotal = object.main.subtotal +  (item.main.quantity * item.main.unitPrice);
        object.main.tax = object.main.tax + item.main.tax;
      }
      object.main.total = object.main.tax + object.main.subtotal;
    },
      (error) => {
        this.loadingService.hide();
        console.log('Error:nationalPurchase:get', error)
      })
  }

}
