import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-type-policy',
  templateUrl: './type-policy.component.html',
  styleUrls: ['./type-policy.component.css']
})
export class TypePolicyComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  typePolicyList = [];
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    },
    company: {
      main: {
        uuid: ''
      }
    }
  };

  companyBossesList = [];

  typePolicyMin = {
    system: {
      objectType: 'Company',
      objectTypeUuid: ''
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };

  pageEvent: PageEvent;

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.metadata.company.main  = this.session.getSessionObject().main;
    this.getTypePolicy(this.metadata.company.main.uuid);
  }


  /**
   * funcion para obtener la lista de kit de productos
   */
  getTypePolicy(uuid) {
    this.loadingService.wait();
    this.typePolicyList = [];
    // verificamos en que pagina verificamos
    this.typePolicyMin.system.objectType = 'Company';
    this.typePolicyMin.system.objectTypeUuid = uuid;
    this.session.getRequest("typePolicy:findAllByObjectTypeAndObjectTypeUuid", this.typePolicyMin).subscribe(
      (data: any) => {
        this.typePolicyList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("typePolicy:findAllByObjectTypeAndObjectTypeUuid", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * Funcionalidad para colocar el objeto en el menu de opciones
   */
  objectSelected(object) {
    for (let i = 0; i < this.typePolicyList.length; i++) {
      this.typePolicyList[i].main.selected = false;
    }
    if (object.main.uuid == this.metadata.object.main.uuid) {
      object.main.selected = false;
      this.metadata.object.main.uuid = '';
    } else {
      object.main.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.typePolicyMin.filter.value);
  }

  /**
   * funcion para obtener los datos del paginado
   */
  onPaginateChange(event) {
    this.typePolicyMin.offset = (event.pageIndex * event.pageSize);
    this.typePolicyMin.max = event.pageSize;
    this.getTypePolicy(this.metadata.company.main.uuid);
  }

  /**
   * Función para inicializar el paginado
   */
  handleUpdated(event) {
    this.typePolicyMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.typePolicyMin.offset);
    this.getTypePolicy(this.metadata.company.main.uuid);
  }

  /**
   * Funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
