import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as printJS from 'print-js';

@Component({
  selector: 'app-sale-client-credit-abonos',
  templateUrl: './sale-client-credit-abonos.component.html',
  styleUrls: ['./sale-client-credit-abonos.component.css']
})
export class SaleClientCreditAbonosComponent implements OnInit {
  creditPaymentMin = {
    system:{
      sale:{
        main:{
          uuid:''
        }
      }
    }
  };
  metadata = {
    restoSale:0,
    totalPayments:0
  }
  creditPaymentList = [];
  constructor(public dialogRef: MatDialogRef<SaleClientCreditAbonosComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private session:SessionService, public loading: LoadingService
  ) {
    this.creditPaymentMin.system.sale.main.uuid = this.data.main.uuid;
   }

  ngOnInit() {
    this.getCreditPaymentBySale();
  }
  /**funcionalidad oara obtener el listado de ventas
  */
  getCreditPaymentBySale(){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("creditPayment:findAllBySale",this.creditPaymentMin).subscribe((data:any)=>{
      this.metadata.restoSale = 0;
      this.metadata.totalPayments = 0;
      for(let item of data.object.instanceList){
        this.metadata.totalPayments = this.metadata.totalPayments + item.main.amount;
      }
      this.creditPaymentList = data.object.instanceList;
      this.metadata.restoSale = this.data.main.total - this.metadata.totalPayments;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  printTicket(object){
    this.loading.show(true,"Obteniendo ticket, espere un momento...");
    this.session.getRequest("creditPaymentReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      this.loading.hide();
      printJS({printable: data.object, type: 'pdf', base64: true})
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
    }else{
      object.transaction = 'bad';
      object.code = 'abonos:001';
    }
    this.dialogRef.close(object);
  }

}
