import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-warehouse-update',
  templateUrl: './warehouse-update.component.html',
  styleUrls: ['./warehouse-update.component.css']
})
export class WarehouseUpdateComponent implements OnInit {
  complexForm: FormGroup;
  branchList = [];
  managerListFilter = [];
  managerList = [];
  warehouseModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
    },
    system: {
      branch: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      manager: {
        main: {
          uuid: '',
          code: '',
          fullName: '',
        }
      },
      department: {
        main: {
          uuid: '',
          name: ''
        }
      },
      status: true
    }
  };
  warehouseManagerModel = {
    main: {
      uuid: '',
    },
    system: {
      warehouse: {
        main: {
          uuid: ''
        }
      },
      manager: {
        main: {
          uuid: ''
        }
      }
    }
  }

  warehouseManagerList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }

  object = JSON.parse(JSON.stringify(this.warehouseModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<WarehouseUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    // Se obtienen las sucursales
    this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("branch:findAllByStatus", this.branchMin).subscribe(
      (data: any) => {
        this.branchList = data.object.instanceList;
      },
      error => {
        console.log("branch:findAllByStatus", error);
      });
    if (this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('warehouse:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        // this.object.system.branch = this.object.system.department.system.branch;
        this.session.getRequest("warehouseManager:findAllByWarehouse", { system: { warehouse: { main: { uuid: this.object.main.uuid } } } }).subscribe((data: any) => {
          this.warehouseManagerList = data.object.instanceList;
          for(let i =0; i < this.warehouseManagerList.length; i++){
            if(this.object.system.manager.main.uuid == this.warehouseManagerList[i].system.manager.main.uuid){
              this.warehouseManagerList[i].system.selected = true;
            }
          }
        }, error => {
          console.log("error:warehouseManager:findAllByWarehouse", error);
        })
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:warehouse:get', error)
        })
    }
    this.findAllEmployee();
    this.object.system.manager.main.fullName = '';
  }

  findAllEmployee() {
    // Se obtienen los empleados para managers
    this.session.getRequest("employee:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } },status: true } }).subscribe(
      (data: any) => {
        this.managerList = data.object.instanceList;
      },
      error => {
        console.log("employee:findAllByCompanyAndStatus", error);
      });
  }

  /** ================= EMPLEADOS ======================= **/
  /** Funcion para obtener los empleados **/
  managerFindAllByFullNameLike(name) {
    this.findAllEmployee();
    this.object.system.manager.main.fullName = name.toUpperCase();
    this.managerListFilter = this.managerList.filter((item: any): any => {
      var newStrProperty = 'main.fullName';
      while (newStrProperty.indexOf(".") != -1) {
        var positionPoint = newStrProperty.indexOf(".");
        var stringProperty = newStrProperty.slice(0, positionPoint);
        newStrProperty = newStrProperty.slice(positionPoint + 1, newStrProperty.length);
        if (typeof (item[stringProperty]) == "object") {
          item = item[stringProperty];
        }
      }
      if (item.fullName.toUpperCase().indexOf(this.object.system.manager.main.fullName) != -1)
        return item;
    });
  }

  /** Función para colocar el trabajador seleccionado **/
  managerSeleted(object) {
    this.managerListFilter = [];
    this.object.system.manager.main.fullName = '';
    let manageraux = JSON.parse(JSON.stringify(this.warehouseManagerModel));
    manageraux.system.manager = object;
    this.warehouseManagerList.push(manageraux);
  }

  send(object) {
    //buscamos sucursal para asignarle la company
    for (let item of this.branchList) {
      if (item.main.uuid == object.system.branch.main.uuid) {
        object.system.company.main = item.system.company.main;
      }
    }
    this.loadingService.show(true, 'Guardando registro');
    this.session.getRequest("warehouse:update", object).subscribe(
      (data: any) => {
        object.main.uuid = data.object.main.uuid;
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        for (let item of this.warehouseManagerList) {
          item.system.warehouse.main.uuid = object.main.uuid;
          this.session.getRequest("warehouseManager:update", item).subscribe((data: any) => {
          }, error => {
            console.log("error", error);
          });
        }
        this.close(true);
      },
      error => {
        this.loadingService.hide();
        console.log("warehouse:update", error);
      }
    );
  }

  /**funcionalidad para eliminar manager
  */
  deleteAManager(object) {
    this.loadingService.show(true, 'Eliminando el trabajador');
    if (object.main.uuid != '') {
      this.session.getRequest("warehouseManager:delete", object).subscribe((data: any) => {

      }, error => {
        console.log("Error: warehouseManager:delete", error);
      })
    }
    for (let i = 0; i < this.warehouseManagerList.length; i++) {
      if (this.warehouseManagerList[i].system.manager.main.uuid == object.system.manager.main.uuid) {
        this.warehouseManagerList.splice(i, 1);
        this.warehouseManagerList.slice(i, 1);
        this.snackBar.open('El registro se elimino correctamente', '', {
          duration: 8000
        });
      }
    }
    this.loadingService.hide();
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }
  /**funcionalidad para seleccionar el encargado principal
  */
  selectedMainManeger(object){
    this.loadingService.show(true, 'Guardando, espere un momento.');
    for(let i = 0; i < this.warehouseManagerList.length; i++){
      this.warehouseManagerList[i].system.selected = false;
    }
    this.object.system.manager.main.uuid = object.system.manager.main.uuid;
    object.system.selected = true;
    if(this.object.main.uuid != ''){
      this.session.getRequest("warehouse:update", this.object).subscribe(
        (data: any) => {
          object.main.uuid = data.object.main.uuid;
          // evento a emitir cuando se crea o actaliza una categoria
          this.snackBar.open('El registro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
        },
        error => {
          this.loadingService.hide();
          console.log("warehouse:update", error);
        }
      );
    }
  }

}
