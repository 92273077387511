import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatDrawer } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';

@Component({
  selector: 'app-inventory-adjustment-details',
  templateUrl: './inventory-adjustment-details.component.html',
  styleUrls: ['./inventory-adjustment-details.component.css']
})
export class InventoryAdjustmentDetailsComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  employeeMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
    warehouseList= [];
    adjustmentTypeList= [];
    statusList= [];
    adjustmentProductsListFilter= [];
    productList= [];
    productAdjustmentList = [];
    transientDatas= [];
    transientDatasAux= [];
    transientDatasValue= [];
    productSelectedList = [];
    adjustmentProductModel= {
      main: {
        uuid: '',
        quantity: 0
      },
      system: {
        product: {
          main: {
            uuid:'',
            code: '',
            name: ''
          },
          system: {
            transientDatas: this.transientDatasValue,
            maker: {},
            unitMeasure: {},
            productFamily: {}
          }
        },
        company:{
          main:{
            uuid:''
          }
        }
      },
      filter: {
        name: 'likeNameOrCode',
        value: ''
      }
    }
    adjustmentModel = {
      main: {
        uuid: '',
        dateAdjustment: '',
        status: 'Por validar',
        subtotal:0,
        total:0,
        discount:0,
      },
      complement: {
        comment: ''
      },
      system: {
        warehouse: {
          main: {
            uuid: ''
          }
        },
        adjustmentType: {
          main: {
            uuid: ''
          }
        },
        company: {
          main: {
            uuid: ''
          }
        },
        status: true,
        products: this.productAdjustmentList,
        employee:{
          main:{
            uuid:''
          }
        }
      },
      inventoryMovement: {
        main: {
          uuid: '',
          object: 'Adjustment',
          objectUuid: '',
          date: ''
        },
        system: {
          status: true
        }
      }
    }
    metadata={
      dateAdjustment:'',
      searching:false,
      searchProduct: new Rx.Subject<string>(),
      searchEmployee: new Rx.Subject<string>(),
    }
    list=[];

    object = JSON.parse(JSON.stringify(this.adjustmentModel));
    constructor(public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){
        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('adjustment:get',{uuid:this.object.main.uuid}).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateAdjustment = this.object.main.dateAdjustment;
          this.productSelectedList = data.object.system.products;
          this.employeeMin.filter.value =  this.object.system.employee.main.fullName;
          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:adjustment:get',error)
        })
      }
    });
    }

      /* funcionalidad para cambiar el menú
      */
      toogleMenu(ev){
        this.drawerCtrl.opened = ev;
      }

}
