import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-historical-credit',
  templateUrl: './counter-historical-credit.component.html',
  styleUrls: ['./counter-historical-credit.component.css']
})
export class CounterHistoricalCreditComponent implements OnInit {

  constructor(private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CounterHistoricalCreditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog)
    { }

  ngOnInit() {
  }

}
