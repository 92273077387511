import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatDrawer } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inventory-adjustment-update',
  templateUrl: './inventory-adjustment-update.component.html',
  styleUrls: ['./inventory-adjustment-update.component.css']
})
export class InventoryAdjustmentUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  employeeMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
    warehouseList= [];
    adjustmentTypeList= [];
    statusList= [];
    adjustmentProductsListFilter= [];
    productList= [];
    productAdjustmentList = [];
    transientDatas= [];
    transientDatasAux= [];
    transientDatasValue= [];
    productSelectedList = [];
    adjustmentProductModel= {
      main: {
        uuid: '',
        quantity: 0
      },
      system: {
        product: {
          main: {
            uuid:'',
            code: '',
            name: ''
          },
          system: {
            transientDatas: this.transientDatasValue,
            maker: {},
            unitMeasure: {},
            productFamily: {}
          }
        },
        company:{
          main:{
            uuid:''
          }
        }
      },
      filter: {
        name: 'likeNameOrCode',
        value: ''
      }
    }
    adjustmentModel = {
      main: {
        uuid: '',
        dateAdjustment: '',
        status: 'Por validar',
        subtotal:0,
        total:0,
        discount:0,
      },
      complement: {
        comment: ''
      },
      system: {
        warehouse: {
          main: {
            uuid: ''
          }
        },
        adjustmentType: {
          main: {
            uuid: ''
          }
        },
        company: {
          main: {
            uuid: ''
          }
        },
        status: true,
        products: this.productAdjustmentList,
        employee:{
          main:{
            uuid:''
          }
        }
      },
      inventoryMovement: {
        main: {
          uuid: '',
          object: 'Adjustment',
          objectUuid: '',
          date: ''
        },
        system: {
          status: true
        }
      }
    }
    metadata={
      dateAdjustment:'',
      searching:false,
      searchProduct: new Rx.Subject<string>(),
      searchEmployee: new Rx.Subject<string>(),
    }
    list=[];

    object = JSON.parse(JSON.stringify(this.adjustmentModel));
    constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
      this.metadata.searchProduct.debounceTime(700).switchMap(val => {
        // console.log('called once',val)
        if(val != ''){
          this.adjustmentProductModel.filter.value=val;
          this.adjustmentProductsListFilter = [];
          this.adjustmentProductModel.filter.name = 'likeNameOrCode';
          this.productFindAllByNameLike();
        }else{
          this.adjustmentProductsListFilter = [];
          this.adjustmentProductModel.filter.name = '';
          this.adjustmentProductModel.filter.value = '';
          this.adjustmentProductModel.system.product.main.name = '';
          val = 'false'
        }
        return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
          console.log('error logged:');
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          console.log(error);
        });

        this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        this.metadata.searchEmployee.debounceTime(700)
        .switchMap(val => {
          // console.log('called once',val)
          if(val != ''){
            this.list = [];
            this.employeeMin.filter.name = 'findAllByCompanyAndLikeName';
          }else{
            this.list = [];
            this.employeeMin.filter.name = '';
            this.employeeMin.filter.value = '';
            val = 'false'
          }
          this.getEmployeeList();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
          console.log('error logged:');
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          console.log(error);
        });
    }

    ngOnInit() {
      this.listAllWarehouse();
      this.listAllAdjustmentType();
      // this.listAllProducts();
      this.adjustmentProductModel.system.product.main.name = '';
      this.metadata.dateAdjustment = moment().toISOString();
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){
        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('adjustment:get',{uuid:this.object.main.uuid}).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateAdjustment = this.object.main.dateAdjustment;
          this.productSelectedList = data.object.system.products;
          this.employeeMin.filter.value =  this.object.system.employee.main.fullName;
          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:adjustment:get',error)
        })
      }
    });
    }
    /** Lista de almacenes por company y activos **/
    listAllWarehouse(){
      this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
        (data: any) => {
          this.warehouseList = data.object.instanceList;
        },
        error => {
          console.log("warehouse:findAllByCompanyAndStatus", error);
        });
    }
    /** Lista de tipos de ajuste de inventario por company y activos **/
    listAllAdjustmentType(){
      this.session.getRequest("adjustmentType:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
        (data: any) => {
          this.adjustmentTypeList = data.object.instanceList;
        },
        error => {
          console.log("adjustmentType:findAllByCompanyAndStatus", error);
        });
    }
    productFindAllByNameLike() {
      this.adjustmentProductModel.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest('product:findAllByCompany',this.adjustmentProductModel).subscribe(
        (data:any)=>{
          this.adjustmentProductsListFilter = data.object.instanceList;
          this.metadata.searching = false;
        },
        error => {
          console.log("product:findAllByCompany",error);
          this.snackBar.open(error.message, 'Error', {duration: 5000});
        });
    }
    /*
    Funcion para agregar un producto al ajuste de inventario
    */
    addProductToSelectedList(object){
      let adjustmentType = ''
      // determinamos que tipo de ajuste de salida es:
      for(let item of this.adjustmentTypeList){
        if(this.object.system.adjustmentType.main.uuid == item.main.uuid){
            adjustmentType = item.main.typeMovement;
            break;
        }
      }
      if(adjustmentType != 'Salida'){
        let adjustmentProduct = JSON.parse(JSON.stringify(this.adjustmentProductModel));
        adjustmentProduct.system.product.main.uuid = object.main.uuid;
        adjustmentProduct.system.product.main.name = object.main.name;
        adjustmentProduct.system.product.main.code = object.main.code;
        adjustmentProduct.system.product.system.productFamily.main = object.system.productFamily.main;
        adjustmentProduct.system.product.system.unitMeasure.main = object.system.unitMeasure.main;
        adjustmentProduct.system.product.system.maker.main = object.system.maker.main;
        adjustmentProduct.system.company.main = this.session.getSessionObject().main;
        adjustmentProduct.system.product.system.transientDatas = [];
        console.log(adjustmentProduct);
        this.productSelectedList.push(adjustmentProduct);
        this.adjustmentProductsListFilter = [];
      }else{
        this.getProductsAvailableByWharehouse(object,this.object.system.warehouse).then((data:any)=>{
          if(data.main.uuid != null && data.main.uuid != 'null'){
            let adjustmentProduct = JSON.parse(JSON.stringify(this.adjustmentProductModel));
            adjustmentProduct.system.product.main.uuid = object.main.uuid;
            adjustmentProduct.system.product.main.name = object.main.name;
            adjustmentProduct.system.product.main.code = object.main.code;
            adjustmentProduct.system.product.system.unitMeasure.main = object.system.unitMeasure.main;
            adjustmentProduct.system.product.system.productFamily.main = object.system.productFamily.main;
            adjustmentProduct.system.product.system.maker.main = object.system.maker.main;
            adjustmentProduct.system.company.main = this.session.getSessionObject().main;
            adjustmentProduct.system.product.system.transientDatas = [];
            console.log(adjustmentProduct);
            this.productSelectedList.push(adjustmentProduct);
            this.adjustmentProductsListFilter = [];
          }else{
            this.snackBar.open('No hay existencias de este producto en el inventario.', 'Aceptar', {
              duration: 8000
            });
          }
        })
      }
    }

    /** Función para colocar el producto seleccionado **/
    adjustmentProductSeleted(object) {
      // Limpiamos el modelo para otro producsto
      this.transientDatasAux= [];
      this.adjustmentProductModel.system.product.main.name = '';

      // Buscamos los datos transitorios de dicho producto
      this.session.getRequest("productTransientData:listByProduct", { system: { product: { main: { uuid: object.main.uuid } }, max: 20 } }).subscribe(
        (data: any) => {
          this.transientDatas = data.object.instanceList;
          for(let transientData of this.transientDatas ){
            this.transientDatasAux.push({
              main: {
                uuid:'',
                value:''
              },
              system: {
                product: transientData.system.product,
                transientData: transientData.system.transientData
              }
            });
          }
          this.transientDatasValue = this.transientDatasAux;
          // Asignamos el producto
          this.object.system.products.push({
            main: {
              uuid: '',
              quantity: 0
            },
            system: {
              product: {
                main: {
                  uuid: object.main.uuid,
                  code: object.main.code,
                  name: object.main.name
                },
                system: {
                  transientDatas: this.transientDatasValue
                }
              }
            }
          });
          this.adjustmentProductsListFilter = [];
        },
        error => {
          console.log("productTransientData:listByProduct", error);
      });
    }

      /** Elimina productos del ajuste de inventarios **/
      deleteAdjustmentProduct(index, object){
        if(object.main.uuid == ''){
          this.object.system.products.splice(index,1);
        }
        else{
        }
      }

      /** Envia a guardar el ajuste de inventario **/
      send(object){
        this.object.system.products = this.productSelectedList;
        // this.object.system.user.main.uuid = this.session.getUser().main.uuid;
        object.main.dateAdjustment = moment(this.metadata.dateAdjustment).format("DD-MM-YYYY HH:mm:ss");
        object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        object.main.status = 'Por validar'
        this.session.getRequest("adjustment:update", object).subscribe(
          (data:any) => {
            // evento a emitir cuando se crea o actaliza una categoria
            this.snackBar.open('El ajuste se guardo correctamente', '', {
              duration: 8000
            });
            this.loadingService.hide();
            this.router.navigate(['/admin/inventory/adjustment']);
         },
         error => {
           console.log("adjustment:update",error);
         }
        );
      }
      deleteFromProductSelectedList(object){
        if(object.main.uuid != ''){
          this.session.getRequest("adjustment:deleteAdjustmentProduct", object).subscribe(
            (data:any) => {
              // evento a emitir cuando se crea o actaliza una categoria
              this.snackBar.open('El registro se elimino correctamente', 'Aceptar', {
                duration: 8000
              });
              this.loadingService.hide();
              for(let i=0; i <this.productSelectedList.length; i++){
                if(this.productSelectedList[i].main.uuid == object.main.uuid){
                  this.productSelectedList.splice(i,1);
                  break;
                }
              }
           },
           error => {
             console.log("adjustment:deleteAdjustmentProduct",error);
           }
          );
        }else{
          for(let i=0; i <this.productSelectedList.length; i++){
            if(this.productSelectedList[i].system.product.main.uuid == object.system.product.main.uuid){
              this.productSelectedList.splice(i,1);
              break;
            }
          }
        }
      }
      /*
      Funcion para determinar cuantos elmentos hay en el inventario.
      */
      changeQuantity(object){
        let adjustmentType = ''
        // determinamos que tipo de ajuste de salida es:
        for(let item of this.adjustmentTypeList){
          if(this.object.system.adjustmentType.main.uuid == item.main.uuid){
              adjustmentType = item.main.typeMovement;
              break;
          }
        }
        if(adjustmentType == 'Salida'){
          this.getProductsAvailableByWharehouse(object.system.product,this.object.system.warehouse).then((data:any)=>{
            if(object.main.quantity > data.main.quantity){
              this.snackBar.open('La cantidad ingresada supera el maximo de existencias en el inventario...', 'Aceptar', {
                duration: 8000
              });
              object.main.quantity = data.main.quantity;
            }
          })
        }
      }
      /*
      Funcion para encontrar productos en inventario segun consulta de productos
      */
      getProductsAvailableByWharehouse(product,wharehouse){
        return new Promise((resolve=>{
          this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
            (data:any) => {
              resolve(data.object);
           },
           error => {
             console.log("warehouseProduct:findByProductByWarehouse",error);
             this.loadingService.hide();
           }
          );
        }))
      }
      /*
      funcion para obtener el uuis de findAllEmployee
      */
      selectedEmployee(object){
        console.log(object);
        this.object.system.employee.main = object.main;
      }

      /*
        *Funcion para buscar
      */
      search(){
        this.metadata.searchProduct.next(this.adjustmentProductModel.system.product.main.name);
      }


      // funcion para obtener la lista de empleados
      getEmployeeList (){
        this.list = [];
        this.loadingService.wait();
        this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        this.session.getRequest("employee:list",this.employeeMin).subscribe(
          (data:any) => {
            this.list = data.object.instanceList;
            // this.paginatorModel.total = data.object.total;
            this.loadingService.hide();
         },
         error => {
           console.log("employee:list",error);
           this.loadingService.hide();
         }
        );
      }
      /*
        *Funcion para buscar empleado
      */
      searchEmployee(){
        this.metadata.searchEmployee.next(this.employeeMin.filter.value);
      }

      /* funcionalidad para cambiar el menú
      */
      toogleMenu(ev){
        this.drawerCtrl.opened = ev;
      }

}
