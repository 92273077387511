import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.service';
import { LoadingService } from '../../../../../directive/loading/loading.module';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {

  object:any = {};
  documentAccountingAccountPolicyList = [];

  documentAccountingAccountPolicy = {
    main: {
      uuid: '',
      uuidSAT: '',
      type: 'AM',
      amount: 0
    },
    system: {
      accountingAccountPolicy: {
        main: {
          uuid: ''
        }
      }
    }
  }

  amountTotal = 0;
  documentAccountingAccountPolicyObject = JSON.parse(JSON.stringify(this.documentAccountingAccountPolicy));

  constructor(public dialogRef: MatDialogRef<AddDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    if(this.data){
        this.object = JSON.parse(JSON.stringify(this.data));
    }
  }

  ngOnInit() {
    this.getDocumentList();
  }

  /** Obtiene la lista de documentos asociados al movimiento de póliza **/
  getDocumentList(){
    this.loadingService.wait();
    this.session.getRequest('documentAccountingAccountPolicy:findAllByAccountingAccountPolicy', {system: {accountingAccountPolicy: {main: {uuid: this.object.accountingAccountsPolicy.main.uuid }}}}).subscribe(
     (data:any)=>{
       this.documentAccountingAccountPolicyList = data.object.instanceList;
       this.amountTotal = data.object.amountTotal;
       this.loadingService.hide();
     },
     error => {
       console.log("documentAccountingAccountPolicy:findAllByAccountingAccountPolicy",error);
       this.snackBar.open('Error al obtener documentos', 'Error', {duration: 5000});
       this.loadingService.hide();
     })
  }

  deleteObject(uuid, index){
    this.loadingService.show(true, "")
    this.session.getRequest('documentAccountingAccountPolicy:delete', {main: {uuid: uuid}}).subscribe(
     (data:any)=>{
       this.getDocumentList();
       this.loadingService.hide();
       this.snackBar.open('Eliminado exitoso', 'Información', {duration: 5000});

     },
     error => {
       this.loadingService.hide();
       console.log("documentAccountingAccountPolicy:delete",error);
       this.snackBar.open(error.message, 'Error', {duration: 5000});
     })
  }

  /* Guadamos la poliza contable */
  send() {
    this.loadingService.show(true, "Espere un momento...")
    this.documentAccountingAccountPolicyObject.system.accountingAccountPolicy.main.uuid = this.object.accountingAccountsPolicy.main.uuid;

    this.session.getRequest("dependentMethods:updateDocumentAccountingAccountPolicy", this.documentAccountingAccountPolicyObject).subscribe((data: any) => {
      this.documentAccountingAccountPolicyObject = JSON.parse(JSON.stringify(this.documentAccountingAccountPolicy));
      this.getDocumentList();
      this.snackBar.open('Guardado exitoso', 'Información', {duration: 5000});
      this.loadingService.hide();

    }, error => {
      this.loadingService.hide();
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log("Error:dependentMethods:updateDocumentAccountingAccountPolicy", error);
    })
  }

}
