import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-saleshift-update',
  templateUrl: './counter-saleshift-update.component.html',
  styleUrls: ['./counter-saleshift-update.component.css']
})
export class CounterSaleshiftUpdateComponent implements OnInit {
  saleShiftModel = {
    main:{
      uuid:'',
      shiftStartDate:'',
      shiftEndDate:'',
      shiftOpeningAmount:0,
      shiftClosingAmount:'',
      open:true
    },
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:''
        }
      },
      currency:{
        main:{
          uuid:''
        }
      }
    }
  };
  object = JSON.parse(JSON.stringify(this.saleShiftModel));
  currencyList = [];
  employeeList = [];
  employeeMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  metadata = {
    searchBoxInput:new Rx.Subject<string>(),
  }
  constructor(public dialogRef: MatDialogRef<CounterSaleshiftUpdateComponent>,
    public session:SessionService,
    public loadding:LoadingService,
    public snackBar:MatSnackBar
  ) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.employeeList = [];
        this.employeeMin.filter.name = 'findAllByCompanyAndLikeName';
        this.getEmployeeList();
      }else{
        this.employeeList = [];
        this.employeeMin.filter.name = '';
        this.employeeMin.filter.value = '';
        val = 'false'
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      console.log(error);
    });
   }

  ngOnInit() {
    this.getCurrencyList().then((data)=>{
      this.object.system.branch.main = this.session.getSessionObject().main;

    }).catch(e=>{
      console.log(e);
    })
  }
  /**función para obtener el listado de tipo de cambio
  */
  getCurrencyList(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("currency:list",{}).subscribe((data:any)=>{
        this.currencyList = data.object.instanceList;
        for(let item of this.currencyList){
          if(item.main.abbreviation == 'MXN'){
            this.object.system.currency.main = item.main;
          }
        }
        resolve(true);
      },error=>{
        reject(error);
      })
    });
  }

  // funcion para obtener la lista de empleados
  getEmployeeList (){
    this.employeeList = [];
    let branch:any = this.session.getSessionObject();
    this.employeeMin.system.company.main.uuid = branch.system.company.main.uuid;
    this.session.getRequest("employee:list",this.employeeMin).subscribe(
      (data:any) => {
        this.employeeList = data.object.instanceList;
        // this.paginatorModel.total = data.object.total;
     },
     error => {
       console.log("employee:list",error);
     }
    );
  }
  /*
    *Funcion para buscar empleados
  */
  search(){
    this.metadata.searchBoxInput.next(this.employeeMin.filter.value);
  }

  /**función para seleeccioanr un empleado
  */
  employeeSelected(object){
    this.object.system.employee.main = object.main;
    this.employeeMin.filter.value = object.main.fullName;
    this.employeeList = [];
  }

  /**función para crear una turno
  */
  send(){
    if(this.object.main.shiftOpeningAmount >= 0 && this.object.system.employee.main.uuid != ''){
      this.object.main.shiftStartDate = moment().format('DD/MM/YYYY hh:mm:ss');
      this.session.getRequest("saleShift:update",this.object).subscribe((data:any)=>{
        this.object.main.uuid = data.object.main.uuid;
        this.close(true);
      },error=>{
        console.log("error:saleShift:update",error);
        this.snackBar.open("Hubo un error intentelo nuevamente...","Aceptar",{
          duration:3000
        })
      });
    }else{
      this.snackBar.open("Ingresa los datos requeridos de formulario para continuar","Aceptar",{
        duration:3000
      })
    }
  };

  /**función para cerrar el modal
  */
  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
      this.session.setSaleShift(this.object);
    }else{
      object.transaction = 'bad';
      object.code = '001';
    }
    this.dialogRef.close(object);
  }

}
