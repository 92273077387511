import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-counter-traspaso-update',
  templateUrl: './counter-traspaso-update.component.html',
  styleUrls: ['./counter-traspaso-update.component.css']
})
export class CounterTraspasoUpdateComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  transferListFilter= [];
  warehouseList = [];
  productsFilter = [];
  productSelectedList = [];
  transferModel = {
    main: {
      uuid: '',
      dateTransfer:'',
      status:'Por validar' //'Por validar', 'Validado', 'Cancelado'
    },
    complement:{
      comment:''
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      warehouseDestination:{
        main:{
          uuid:''
        }
      },
      warehouseOrigin:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:''
        }
      },
      products:[]
    },
  }
  transferProductModel = {
    main:{
      uuid:'',
      quantity:1,
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      },
      transfer:{
        main:{
          uuid:''
        }
      }
    }

  }
  metadata={
    searchBoxInput: new Rx.Subject<string>(),
    dateAdjustment:'',
    searching:false
  }
  warehouseMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  productModel= {
    main: {
      uuid: '',
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  object = JSON.parse(JSON.stringify(this.transferModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'comment': [null, Validators.required],
    })
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if (val != '') {
        this.productsFilter = [];
        this.productFindAllByNameLike();
      } else {
        this.productsFilter = [];
        this.productModel.filter.value = '';
        val = 'false'
      }

      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', { duration: 5000 });
      console.log(error);
    });
  }

  ngOnInit() {
    this.loadingService.show(true,'Cargando Rescursos...');
    this.object.system.warehouseOrigin.main = this.session.getWarehouse().main;
    this.getWarehouseList().then(data=>{
      this.object.system.status = true;
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){
          this.loadingService.hide();
        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('transfer:get',this.object).subscribe((data:any)=>{
            this.object = JSON.parse(JSON.stringify(data.object));
            this.loadingService.hide();
          },
          (error)=>{
            this.loadingService.hide();
            this.snackBar.open('Hubo un error, salga y vuelva a entrar de nuevo.', 'Error', {
              duration: 8000
            });
            console.log('Error:transfer:get',error)
          })
        }
      });
    }).catch(e=>{
      this.loadingService.hide();
      this.snackBar.open('Hubo un error, salga y vuelva a entrar de nuevo.', 'Error', {
        duration: 8000
      });
    })
  }

  /**funcionalidad para buscar un producto
  */
  productFindAllByNameLike() {
    this.productsFilter = [];
    this.metadata.searching = true;
    let branch:any = this.session.getSessionObject();
    this.productModel.system.company.main.uuid = branch.system.company.main.uuid;
    // this.productModel.filter.value = name;
    this.session.getRequest('product:findAllByCompany',this.productModel).subscribe(
      (data:any)=>{
        this.productsFilter = data.object.instanceList;
        this.metadata.searching = false;
      },
      error => {
        console.log("product:findAllByCompany",error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
      });
    }

    /*
    Obtenemos el listado de almacenes
    */
    getWarehouseList(){
      return new Promise((resolve,reject)=>{
        // this.loadingService.wait();
        this.warehouseList = [];
        // verificamos en que pagina verificamos
        let branch:any = this.session.getSessionObject();
        this.warehouseMin.system.company.main.uuid = branch.system.company.main.uuid;
        this.session.getRequest("warehouse:findAllByCompany", this.warehouseMin).subscribe(
          (data: any) => {
            for(let i=0; i < data.object.instanceList.length; i++){
              if(data.object.instanceList[i].main.uuid == this.object.system.warehouseDestination.main.uuid){
                data.object.instanceList.splice(i,1);
              }
            }
            this.warehouseList = data.object.instanceList;

            // this.loadingService.hide();
            resolve(true);
          },
          error => {
            console.log("warehouse:findAllByCompany", error);
            // this.loadingService.hide();
            reject(error);
          }
        );
      })
    }

    /*
    Funcion para quitar un almacen en al seleccionarlo.
    */
    selectedWarehouse(object,type){
      console.log(object);
      for(let i=0; i < this.warehouseList.length; i++){
        if(object ==  this.warehouseList[i].main.uuid){
          if(type == 'destiny'){
            this.object.system.warehouseDestination = JSON.parse(JSON.stringify(this.warehouseList[i]));
            this.object.system.warehouseDestination.system.room = {};
            this.object.system.warehouseDestination.system.company = {};
            this.object.system.employee.main = this.object.system.warehouseDestination.system.manager.main;
          }
          if(type == 'origin'){
            this.object.system.warehouseOrigin = JSON.parse(JSON.stringify(this.warehouseList[i]));
            this.object.system.employee.main = this.object.system.warehouseOrigin.system.manager.main;
            this.object.system.warehouseOrigin.system.room = {};
            this.object.system.warehouseOrigin.system.company = {};
          }
          break;
        }
      }
    }

    /*
    Funcion para agregar un producto al traspaso
    */
    addProductToSelectedList(object){
      let transferProductAux = JSON.parse(JSON.stringify(this.transferProductModel));
      transferProductAux.main.quantity = 1;
      transferProductAux.system.product.main = object.main;
      transferProductAux.system.product.complement = object.complement;
      transferProductAux.system.product.system = {
        maker:object.system.maker
      };
      this.object.system.products.push(transferProductAux);
      this.productModel.filter.value = '';
      this.productsFilter = [];
    }

    send(object) {
      this.loadingService.show(true,'Guardando registro...');
      let branch :any= this.session.getSessionObject();
      object.system.company.main.uuid = branch.system.company.main.uuid;
      // object.system.user.main.uuid = this.session.getUser().main.uuid;
      object.main.status = 'Por validar';
      object.main.dateTransfer = moment().format('DD-MM-YYYY HH:mm:ss');
      this.session.getRequest("transfer:update", object).subscribe(
        (data:any) => {
          this.snackBar.open('Su traspaso fue enviado exitosamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.router.navigate(['/counter/traspaso/list']);
        },
        error => {
          console.log("transfer:update",error);
          this.loadingService.hide();
        }
      );
    }

    deleteAProduct(object){
      // console.log(object);
      for(let i=0; i < this.object.system.products.length; i++){
        if(this.object.system.products[i].system.product.main.uuid == object.system.product.main.uuid){
          if(object.main.uuid != ''){
            this.deteletProduct(object,i);
          }else{
            this.object.system.products.splice(i,1);
          }
        }
      }
    }

    /**función para eliminar un prodcuto del traspaso
    */
    deteletProduct(object,index){
      this.loadingService.wait();
      this.session.getRequest("transfer:deleteTransferProduct",object).subscribe((data:any)=>{
        this.loadingService.hide();
        this.object.system.products.splice(index,1);
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    }


    /**funcionalidad para cargar un pedido sugerido
    */
    loadSuggestRequest(){
      this.loadingService.show(true, "Espere un momento....");
      let branch:any = this.session.getSessionObject();
      let suggestModel = {
        main:{
          date:moment().format('DD/MM/YYYY HH:mm:ss')
        },
        system:{
          branch:{
            main:branch.main
          }
        }
      };
      this.session.getRequest("sale:productAllByBranchAndBetweenDate",suggestModel).subscribe((data:any)=>{
        this.loadingService.hide();
        for(let item of data.object.instanceList){
          let transferProductAux = JSON.parse(JSON.stringify(this.transferProductModel));
          transferProductAux.main.quantity = item.main.quantity;
          transferProductAux.system.product.main = item.system.product.main;
          transferProductAux.system.product.complement = item.system.product.complement;
          transferProductAux.system.product.system = {
            maker:item.system.product.system.maker
          };
          this.object.system.products.push(transferProductAux);
          this.productModel.filter.value = '';
          this.productsFilter = [];
        }
        console.log(data);
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    }

    /**
     * Funcion para buscar
     */
    search() {
      this.metadata.searchBoxInput.next(this.productModel.filter.value);
    }
    /*
    funcion para controlar si se abre o cierra el menu
    */
    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }

}
