import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-company-update',
  templateUrl: './company-update.component.html',
  styleUrls: ['./company-update.component.css']
})
export class CompanyUpdateComponent implements OnInit {
  complexForm: FormGroup;
  contacts = [];
  contact = {
    main: {
      uuid: '',
      data: '',
      type: ''
    },
    system: {
      status: true
    }
  };
  address = {
    id: 'address',
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: ''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          name: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main: {
                  uuid: '',
                  name: ''
                }
              }
            }
          }
        }
      },
      state: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };

  moralPerson = {
    main: {
      uuid: "",
      socialReason: ""
    },
    complement: {
      rfc: ""
    },
    system: {
      status: true,
      address: this.address,
      contacts: this.contacts
    }
  };

  physicalPerson = {
    main: {
      uuid: "",
      name: "",
      lastname: "",
      surname: ""
    },
    complement: {
      dateBirth: "",
      curp: "",
      rfc: "",
      gender: ""
    },
    system: {
      status: true,
      address: this.address,
      contacts: this.contact
    }
  }

  company = {
    main: {
      uuid: '',
      tradename: '',
      legalRepresentativeName: ''
    },
    complement: {
      primaryKey: '',
      socialObjetive: 'Giro'
    },
    system: {
      person: {
        main: {
          uuid: ''
        }
      },
      typePerson: '',
      taxRegime: {
        main: {
          uuid: '',
          code: '',
          description: ''
        },
        system: {
          status: true
        }
      },
      stationNumber: '',
      status: true
    },
    image: {
      fileCer: '',
      nameCer: '',
      fileKey: '',
      nameKey: '',
      logo: '',
      logoName: ''
    }
  };

  file = {
    image: {
      file: '',
      name: '',
      type: ''
    }
  };

  metadata = {
    disable: false,
    constitutionDate: null
  };

  getObject = {
    main: {
      uuid: ""
    }
  };
  object = JSON.parse(JSON.stringify(this.company));
  cerFile = JSON.parse(JSON.stringify(this.file));
  keyFile = JSON.parse(JSON.stringify(this.file));
  taxRegimeList = [];

  companyLogoModel = {
    main: {
      uuid: '',
      type: ''
    },
    image: {
      logo: '',
      name: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  logosList = [];
  constructor(public fb: FormBuilder, protected sessionService: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialogRef: MatDialogRef<CompanyUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.object.system.person = JSON.parse(JSON.stringify(this.moralPerson));
    this.object.system.typePerson = "MoralPerson";
    this.complexForm = fb.group({
      'tradename': [null, Validators.compose([Validators.required])],
      'socialReason': [null, Validators.compose([Validators.required])],
      // 'curp': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[0-9]{2}')])],
      'rfcP': [null, Validators.compose([Validators.required, Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
      'rfcM': [null, Validators.compose([Validators.required, Validators.pattern('[A-Za-z]{3}[0-9]{6}[A-Za-z0-9]{3}')])],
      'legalRepresentativeName': [null, Validators.compose([Validators.required])],
      'socialObjective': [null, Validators.compose([Validators.required])],
      // 'primaryKey': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      'surname': [null, Validators.compose([Validators.required])],
      'lastname': [null, Validators.compose([Validators.required])],
    });
    let logo = JSON.parse(JSON.stringify(this.companyLogoModel));
    logo.main.type = "Primary";
    this.logosList.push(logo);
    logo = JSON.parse(JSON.stringify(this.companyLogoModel));
    logo.main.type = "Secondary";
    this.logosList.push(logo);
    logo = JSON.parse(JSON.stringify(this.companyLogoModel));
    logo.main.type = "Other";
    this.logosList.push(logo);
  }

  ngOnInit() {
    this.getTaxRegime('MoralPerson');

    if (this.data == 'new') {

    } else {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.loadingService.wait();
      this.sessionService.getRequest('company:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.disable = true;

        if (this.object.system.typePerson === 'MoralPerson') {
          this.metadata.constitutionDate = moment(this.object.complement.constitutionDate).add(6, 'hours').utc().toISOString();
        }

        if (this.object.system.person.system.address)
          this.address = this.object.system.person.system.address;
        else
          this.address = JSON.parse(JSON.stringify(this.address));

        this.contacts = this.object.system.person.system.contacts;

        if (this.object.image.nameCer) {
          this.cerFile.image.file = this.object.image.fileCer.replace(/-/g, "+");;
          this.cerFile.image.name = this.object.image.nameCer;
        }
        if (this.object.image.nameKey) {
          this.keyFile.image.file = this.object.image.fileKey.replace(/-/g, "+");;
          this.keyFile.image.name = this.object.image.nameKey;
        }
        //obetenemos el listado del regimen fiscal
        this.getTaxRegime(this.object.system.typePerson);
        this.loadingService.hide();
        this.getLogos().then((data: any) => {
          if (data.length > 0) {
            this.logosList = data;
          }
        })
      },
        (error) => {
          console.log('Error:company:get', error)
          this.loadingService.hide();
        })
    }
  }
  getLogos() {
    return new Promise((resolve, reject): any => {
      this.sessionService.getRequest('companyLogo:findAllByCompany', { system: { company: { main: { uuid: this.sessionService.getSessionObject().main.uuid } } } }).subscribe((data: any) => {
        for (let i = 0; i < data.object.instanceList.length; i++) {
          if (data.object.instanceList[i].image.logo) {
            data.object.instanceList[i].image.file = data.object.instanceList[i].image.logo.replace(/-/g, "+");
            data.object.instanceList[i].image.logo = '';
          }
        }
        resolve(data.object.instanceList);
      }, error => {
        reject(error);
        console.log('Error:companyLogo:findAllByCompany', error);
      })
    })
  }

    /*funcion para obtener tipos de regimenes fiscales.*/
    getTaxRegime(use) {
      this.sessionService.getRequest('taxRegime:findAllByUse', { use: use }).subscribe((data: any) => {
        this.taxRegimeList = data.object.instanceList;
      },
        (error) => {
          console.log('Error:taxRegime:findAllByUse', error)
        })
    }

  send(object) {
    this.loadingService.show(true, 'Guardando compañia patronal');
    if (this.cerFile.image.name != '') {
      object.image.fileCer = this.cerFile.image.file.replace(/\+/g, "-");
      object.image.nameCer = this.cerFile.image.name;
    }
    if (this.keyFile.image.name != '') {
      object.image.fileKey = this.keyFile.image.file.replace(/\+/g, "-");
      object.image.nameKey = this.keyFile.image.name
    }
    if (object.system.typePerson == 'MoralPerson') {
      this.object.complement.constitutionDate = moment(this.metadata.constitutionDate).format('DD-MM-YYYY');
    }
    if (object.system.typePerson == 'PhysicalPerson') {
      this.object.system.person.complement.dateBirth = moment().format('DD-MM-YYYY');
    }
    console.log('dirección', this.address);
    if (this.address.main.settingName != '' || this.address.system.setting.main.name != '' || this.address.system.state.main.uuid != '') {
      this.object.system.person.system.address = this.address;
    }
    this.object.system.person.system.contacts = [];
    if (object.system.person.complement.rfc !== '' && this.object.complement.constitutionDate != 'invalid date') {
      this.sessionService.getRequest('company:update', object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.disable = true;
        this.snackBar.open('Registro guardado exitosamente', 'Información', { duration: 5000 });
        this.loadingService.hide();
        this.close(true);
        for (let item of this.logosList) {
          item.system.company.main.uuid = this.object.main.uuid;
          if (item.image.name != '') {
            this.sendLogos(item);
          }
        }
      },
        (error) => {
          console.log('Error:company:update', error)
          this.loadingService.hide();
        })
    } else {
      this.snackBar.open('RFC Invalido', 'Error', { duration: 5000 });
    }
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

  loadTypePerson() {
    if (this.object.system.typePerson == "MoralPerson") {
      this.object.system.person = this.moralPerson;
    }
    if (this.object.system.typePerson == "PhysicalPerson") {
      this.object.system.person = this.physicalPerson;
    }
    this.getTaxRegime(this.object.system.typePerson);
  }

  sendLogos(object) {
    object.image.file = object.image.file.replace(/\+/g, "-");
    object.image.logo = object.image.file;
    object.image.file = '';
    this.sessionService.getRequest('companyLogo:update', object).subscribe((data: any) => {
      this.snackBar.open('Registro guardado exitosamente', 'Información', { duration: 5000 });
      object.image.file = object.image.logo.replace(/-/g, "+");
      object.image.logo = '';
    },
      (error) => {
        console.log('Error:companyLogo:update', error)
      })
  }

  changeCerFile(value) {
    if (value !== undefined && value !== null) {
      this.object.image.fileCer = value;
      this.object.image.nameCer = this.cerFile.image.name;
    }
  }

  changeKeyFile(value) {
    if (value !== undefined && value !== null) {
      this.object.image.fileKey = value;
      this.object.image.nameKey = this.keyFile.image.name;
    }
  }

}
