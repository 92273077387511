import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { CounterBudgetUpdateComponent } from '../counter-budget-update/counter-budget-update.component';

@Component({
  selector: 'app-counter-budget-list',
  templateUrl: './counter-budget-list.component.html',
  styleUrls: ['./counter-budget-list.component.css']
})
export class CounterBudgetListComponent implements OnInit {
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  metadata = {
    company:{
      main:{
        uuid:''
      }
    },
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    },
    client:{
      main:{
        uuid:''
      }
    }
  };
  pageEvent: PageEvent;
  budgetList = [];
  budgetMin = {
    system: {
      client:{
        main:{
          uuid:''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      branch: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };

  //modelo del cliente
  clientMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  clientListFilter = [];

  budgetSelected = {
    main:{
      uuid:''
    }
  };

  constructor(public dialogRef: MatDialogRef<CounterBudgetListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private session:SessionService,
    public loading: LoadingService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar
  ) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      if(val != ''){
        this.clientListFilter = [];
        this.clientMin.filter.name = 'findAllByLikeName';
      }else{
        this.clientListFilter = [];
        this.clientMin.filter.name = '';
        this.clientMin.filter.value = '';
        val = 'false'
      }
      this.getClientList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.getBudgetList();
  }

  // funcion para obtener la lista de budgetos
  getBudgetList() {
    if(this.clientMin.filter.value == ''){
      this.loadingService.show(true, "Espere un momento...");
      this.budgetList = [];
      let branch:any = this.session.getSessionObject();
      this.metadata.company = branch.system.company;
      this.budgetMin.system.company.main.uuid = this.metadata.company.main.uuid;
      this.budgetMin.system.branch.main.uuid = branch.main.uuid;
      this.session.getRequest("budget:findAllByBranch", this.budgetMin).subscribe(
        (data: any) => {
          this.budgetList = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("budget:findAllByBranch", error);
          this.loadingService.hide();
        }
      );
    }else{
      this.loadingService.show(true, "Espere un momento...");
      this.budgetList = [];
      let branch:any = this.session.getSessionObject();
      this.metadata.company = branch.system.company;
      this.budgetMin.system.company.main.uuid = this.metadata.company.main.uuid;
      this.budgetMin.system.branch.main.uuid = branch.main.uuid;
      this.budgetMin.system.client.main.uuid = this.metadata.client.main.uuid;
      this.session.getRequest("budget:listByClient", this.budgetMin).subscribe(
        (data: any) => {
          this.budgetList = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("budget:listByClient", error);
          this.loadingService.hide();
        }
      );
    }

  }
  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.budgetMin.filter.value);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.budgetMin.offset = (event.pageIndex * event.pageSize);
    this.budgetMin.max = event.pageSize;
    this.getBudgetList();
  }
  /**
   * Modal para abrir budget Update
   */
  budgetUpdateDialog(uuid) {
    this.dialog.open(CounterBudgetUpdateComponent, {
      width: '80%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getBudgetList();
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /* ================ Busqueda cliente =============*/
  // funcion para obtener la lista de clientes
  getClientList (){
    this.clientListFilter = [];
    // Obtenemos los clientes
    let branch:any =  this.session.getSessionObject();
    this.clientMin.system.company.main.uuid = branch.system.company.main.uuid;
    this.session.getRequest("client:list", this.clientMin).subscribe(
      (data: any) => {
        for(let item of data.object.instanceList){
          this.clientListFilter.push(item);
        }
        // console.log("CLIENT",this.clientListFilter);
      },
      error => {
        console.log("client:list", error);
        this.loadingService.hide();
      }
    );
  }

  /*
  *Funcion para buscar un cliente
  */
  searchClient(){
    if(this.clientMin.filter.value.length > 3)
    this.metadata.searchBoxInput.next(this.clientMin.filter.value);
    else
    this.clientListFilter = [];
  }

  clienteSelected(object) {
    // console.log(object);
    for(let item of this.clientListFilter){
      if(item.main.nickname == object.option.value){
        this.metadata.client = JSON.parse(JSON.stringify(item));
        this.getBudgetList();
      }
    }
  }

  eraseClient(){
    this.metadata.client = JSON.parse(JSON.stringify({main:{uuid:''}}));
  }

  /* ==============================================*/
  returnBudget(object){
    this.loading.show(true,"Espere un momento...");
    this.session.getRequest("budget:get",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      this.budgetSelected = JSON.parse(JSON.stringify(data.object));
      this.close(true);
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    })

  };
  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.budgetSelected;
    }else{
      object.transaction = 'bad';
      object.code = 'budget:001';
    }
    this.dialogRef.close(object);
  }
}
