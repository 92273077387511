import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDrawer } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDialog } from '@angular/material/dialog';
import { CounterHistoricalCreditComponent } from './counter-historical-credit/counter-historical-credit.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-credit-client',
  templateUrl: './counter-credit-client.component.html',
  styleUrls: ['./counter-credit-client.component.css']
})
export class CounterCreditClientComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) {

  }

  ngOnInit() {
  }
  /**
   * Modal para visualizar el update de solicitudes de crédito
   */
  historicalCredit(){
    this.dialog.open(CounterHistoricalCreditComponent, {
      width: '60%',
    }).afterClosed().subscribe(result => {
      if(result != undefined ){

      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
