import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inventory-adjustment-import',
  templateUrl: './inventory-adjustment-import.component.html',
  styleUrls: ['./inventory-adjustment-import.component.css']
})
export class InventoryAdjustmentImportComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  metadata = {
    searchEmployee: new Rx.Subject<string>(),
    file:{
      name:'',
      file:'',
      type:''
    }
  }
  importAdjustment = {
    main:{
      uuid:''
    },
    complement:{
      comment:''
    },
    file:{
      file:'',
      name:'',
      type:''
    },
    system:{
      warehouse: {
        main: {
          uuid: ''
        }
      },
      adjustmentType: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      productFamily:{
        main:{
          uuid: ''
        }
      },
      status: true,
      employee:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.importAdjustment));
  warehouseList = [];
  adjustmentTypeList = [];
  productFamilyList = [];
  employeeMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  list = [];

  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchEmployee.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.list = [];
        this.employeeMin.filter.name = 'findAllByCompanyAndLikeName';
      }else{
        this.list = [];
        this.employeeMin.filter.name = '';
        this.employeeMin.filter.value = '';
        val = 'false'
      }
      this.getEmployeeList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.listAllWarehouse();
    this.listAllAdjustmentType();
    // this.getEmployeeByUser();
    this.getProductFamilyList();
  }

  /** Metodo para obtener el listado de inventarios a subir
  */
  getCSV(){
    this.session.getRequest("adjustment:layoutImportAdjustment",this.object).subscribe((data:any)=>{
      console.log(data);
      let elem = document.createElement('a');
      elem.href = data.object;
      elem.setAttribute('download', 'importar_inventario.csv');
      document.body.appendChild(elem);
      elem.click();
    },error=>{
      console.log("Error",error);
    })
  }

  /** Lista de almacenes por company y activos **/
  listAllWarehouse(){
    this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
      (data: any) => {
        this.warehouseList = data.object.instanceList;
      },
      error => {
        console.log("warehouse:findAllByCompanyAndStatus", error);
      });
  }
  /** Lista de tipos de ajuste de inventario por company y activos **/
  listAllAdjustmentType(){
    this.session.getRequest("adjustmentType:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
      (data: any) => {
        this.adjustmentTypeList = data.object.instanceList;
      },
      error => {
        console.log("adjustmentType:findAllByCompanyAndStatus", error);
      });
  }
  /*
  funcion para obtener el uuis de findAllEmployee
  */
  // getEmployeeByUser(){
  //   this.session.getRequest("dependentMethods:findEmployeeByUser", {user:{main:{uuid:this.session.getUser().main.uuid}}}).subscribe(
  //     (data:any) => {
  //       this.object.system.employee.main.uuid = data.object.main.uuid;
  //    },
  //    error => {
  //      console.log("dependentMethods:findEmployeeByUser",error);
  //      this.loadingService.hide();
  //    }
  //   );
  // }
  /** funcionalidad para obtener listado de categorias.
  */
  getProductFamilyList(){
    this.loadingService.wait();
      this.productFamilyList = [];
      // verificamos en que pagina verificamos
      this.session.getRequest("productFamily:listByCompany", {system:{company:{main:{uuid:this.session.getSessionObject().main.uuid}}},    max: 100,
          offset: 0,
          filter: {
            name: '',
            value: ''
          }}).subscribe(
        (data: any) => {
          this.productFamilyList = data.object.instanceList;
          this.loadingService.hide();
        },
        error => {
          console.log("productFamily:listByCompany", error);
          this.loadingService.hide();
        }
      );
  }

  send(object){
    // this.object.system.user.main.uuid = this.session.getUser().main.uuid;
    this.loadingService.show(true,"Esto tomará unos minutos, Se esta importando su inventario...")
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.object.file.file = this.metadata.file.file.replace(/\+/g,"-");
    this.object.file.name = this.metadata.file.name;
    this.object.file.type = this.metadata.file.type;
    this.session.getRequest("adjustment:importAdjustment", object).subscribe(
      (data:any) => {
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('Inventario importado exitosamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
     },
     error => {
       console.log("adjustment:importAdjustment",error);
       this.snackBar.open('Error', error.message, {
         duration: 8000
       });
       this.loadingService.hide();
     }
    );
  }

  // funcion para obtener la lista de empleados
  getEmployeeList (){
    this.list = [];
    this.loadingService.wait();
    this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("employee:list",this.employeeMin).subscribe(
      (data:any) => {
        this.list = data.object.instanceList;
        // this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("employee:list",error);
       this.loadingService.hide();
     }
    );
  }
  /*
  funcion para obtener el uuis de findAllEmployee
  */
  selectedEmployee(object){
    console.log(object);
    this.object.system.employee.main = object.main;
  }
  /*
    *Funcion para buscar empleado
  */
  searchEmployee(){
    this.metadata.searchEmployee.next(this.employeeMin.filter.value);
  }
  //funcion para controlar si se abre o cierra el menu

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
