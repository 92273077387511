import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-partialcut-update',
  templateUrl: './counter-partialcut-update.component.html',
  styleUrls: ['./counter-partialcut-update.component.css']
})
export class CounterPartialcutUpdateComponent implements OnInit {

  partialCutModel = {
    main:{
      uuid:'',
      datePartialCut:'',
      amount:0,
      tax:0,
      subTotal:0,
      reason:''
    },
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.partialCutModel));

  constructor( public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CounterPartialcutUpdateComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService) {
  }

  ngOnInit() {
  }

  /**funcianalidad para crear un corte parcila
  */
  send(){
    let saleShift:any = this.session.getSaleShift();
    this.object.system.saleShift.main.uuid = this.data.object.main.uuid;
    this.loadingService.show(true,"Espere un momento...");
    this.object.system.branch.main = this.session.getSessionObject().main;
    this.object.system.employee.main = saleShift.system.employee.main;
    this.object.main.subTotal = parseFloat(this.object.main.amount) / 1.16;
    this.object.main.tax = this.object.main.amount - parseFloat(this.object.main.subTotal);
    this.object.main.datePartialCut = moment().format('DD/MM/YYYY HH:mm:ss');
    this.session.getRequest("partialCut:update",this.object).subscribe((data:any)=>{
      this.object.main.uuid = data.object.main.uuid;
      this.loadingService.hide()
      this.close(true);
    },error=>{
      console.log("error:partialCut:udpate",error);
      this.loadingService.hide()
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'partialcut:001';
    }
    this.dialogRef.close(object);
  }

}
