import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-delete-policy',
  templateUrl: './confirm-delete-policy.component.html',
  styleUrls: ['./confirm-delete-policy.component.css']
})
export class ConfirmDeletePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
