import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from './../../../../directive/loading/loading.module';

@Component({
  selector: 'app-select-registration-accounting',
  templateUrl: './select-registration-accounting.component.html',
  styleUrls: ['./select-registration-accounting.component.css']
})
export class SelectRegistrationAccountingComponent implements OnInit {

  typeAccountList = [];
  selectRegistationAccountingModel = {
    main: {
      uuid: '',
      accountNumber: '0',
      parentAccountNumber: '',
      description: '',
      natureAccount: '' //Acredora o Deudora
    },
    complement: {
      edit: false,
      accountLevel: '',
      numberAccountLevel: 0
    },
    system: {
      accountingAccounts:[],
      accountingAccount: {
        main: {
          uuid: '',
          description: ''
        }
      },
      typeAccount: {
        main: {
          uuid: ''
        }
      }
    }
  }
  object = {
    main:{
      uuid:'',
      accountNumber:'',
      description:''
    }
  }
  metadata = {
    company: {
      main: {
        uuid: ''
      }
    }
  }

  constructor(public loadingService: LoadingService, public session: SessionService, public dialogRef: MatDialogRef<SelectRegistrationAccountingComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.metadata.company.main.uuid = data.companyUuid;
    this.getTypeAccounts();
    if (data.accountingAccount.main.uuid == '') {
      this.object = JSON.parse(JSON.stringify(this.selectRegistationAccountingModel));
    } else {
      this.object = JSON.parse(JSON.stringify(data.accountingAccount));
    }
  }

  ngOnInit() {
  }

  /**
   * Funcion para obtener las cuentas contables padres segun el tipo de cuentas
   */
  findFatherAccountingAccountByTypeAccount(object) {
    object.system.loading = true;
    object.system.open = true;
    this.session.getRequest("accountingAccount:findAllByAccountingAccountIsNullAndTypeAccount", { system: { typeAccount: { main: { uuid: object.main.uuid } } } }).subscribe((data: any) => {
      for (let item of data.object.instanceList) {
        item.system.open = false;
        item.system.loading = false;
        item.system.selected = false;
        item.system.accountingAccounts = [];
        object.system.accountingAccounts.push(item);
      }
      object.system.loading = false;
    }, error => {
      console.log("error:typeAccount:findAllByAccountingAccountIsNullAndTypeAccount", error);
    })
  }

  /**
   * Funcion para obtener los tipos de cuenta
   */
  getTypeAccounts() {
    // this.loadingService.wait();
    this.session.getRequest("typeAccount:findAllByObjectTypeAndObjectTypeUuid", { system: {objectType: 'Company', objectTypeUuid: this.metadata.company.main.uuid } }).subscribe((data: any) => {
      for (let item of data.object.instanceList) {
        item.system.open = false;
        item.system.loading = false;
        item.system.selected = 'false';
        item.system.accountingAccounts = [];
        this.typeAccountList.push(item);
      }
      // this.loadingService.hide();
    }, error => {
      console.log("error:typeAccount:findAllByObjectTypeAndObjectTypeUuid", error);
    })
  }

  /**
   * Limpia el arreglo de cuentas contables
   */
  cleanAccountingAccount(object) {
    object.system.open = false;
    object.system.accountingAccounts = [];
  }


}
