import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { SaleClientUpdateComponent } from '../../../admin/sale/sale-client/sale-client-update/sale-client-update.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-credit-request-update',
  templateUrl: './counter-credit-request-update.component.html',
  styleUrls: ['./counter-credit-request-update.component.css']
})
export class CounterCreditRequestUpdateComponent implements OnInit {
  clientCreditModel = {
    main:{
      uuid:'',
      dischargeDate:'',//dd/MM/yyyy hh:mm:ss’, fecha de alta
      acceptanceDate:'',// fecha de aceptación
      status:'Trámite',//Trámite, Valoración, Aprobado, Supendido, Cancelado
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      client:{
        main:{
          uuid:''
        }
      },
      creditType:{
        main:{
          uuid:''
        }
      }
    }
  };

  object = JSON.parse(JSON.stringify(this.clientCreditModel));

  creditTypeList = [];
  creditTypeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status:true
    },
    max: 100,
    offset: 0
  };

  clientList = [];
  clientMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  statusList = [
    'Trámite',
    'Valoración',
    'Aprobado',
    'Suspendido',
    'Cancelado'
  ]
  constructor(private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CounterCreditRequestUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    let branch:any = this.session.getSessionObject();
    this.clientMin.system.company.main.uuid = branch.system.company.main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.clientList = [];
        this.clientMin.filter.name = 'findAllByLikeName';
      }else{
        this.clientList = [];
        this.clientMin.filter.name = '';
        this.clientMin.filter.value = '';
        val = 'false'
      }
      this.getClientList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });
   }
   ngOnInit() {
     this.getCreditTypeList();
     if(this.data != 'new') {
       this.loadingService.wait();
       this.object.main.uuid = this.data;
       // cargamos los datos de la BD
       this.session.getRequest('clientCredit:get', this.object).subscribe((data: any) => {
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
         (error) => {
           this.loadingService.hide();
           console.log('Error:clientCredit:get', error)
         })
     }
   }


   // funcion para obtener la lista de clientes
   getClientList (){
   this.loadingService.wait();
     this.clientList = [];
     this.session.getRequest("client:list", this.clientMin).subscribe(
       (data:any) => {
         this.clientList = data.object.instanceList;
         this.loadingService.hide();
      },
      error => {
        console.log("client:list",error);
        this.loadingService.hide();
      }
     );
   }

   /*
     *Funcion para buscar
   */
   searchClient(){
     this.metadata.searchBoxInput.next(this.clientMin.filter.value);
   }

   clienteSelected(object) {
     for(let item of this.clientList){
       if(item.main.nickname == object.option.value){
         this.object.system.client = JSON.parse(JSON.stringify(item));
       }
     }
   }
   deleteClient(){
     this.object.system.client = {
       main:{
         uuid:''
       }
     };
     this.clientMin.filter.value = '';
   }

   updateDialog(uuid) {
     this.dialog.open(SaleClientUpdateComponent, {
       width: '70%',
       data: uuid
     }).afterClosed().subscribe(result => {
       if(result != undefined ){
         if(result.transaction == 'ok'){
           // El modal se cerro con objeto
           this.object.system.client = result.object;
         }else{
           // El modal se cerro sin objeto
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       }else{
         // El modal se cerro sin seleccionar algo, dandole click fuera
         this.snackBar.open('No se guardo ningun registro', '', {
           duration: 1000
         });
       }
     });
   }

   save(){
     this.loadingService.show(true, "Espere un momento...");
     let branch:any = this.session.getSessionObject();
     this.object.system.company.main.uuid = branch.system.company.main.uuid;
     if(this.object.main.dischargeDate != ''){
       this.object.main.dischargeDate = moment(this.object.main.dischargeDate).format('DD/MM/YYYY HH:mm:ss');
     }
     if(this.object.main.uuid == ''){
       this.object.main.dischargeDate = moment().format('DD/MM/YYYY HH:mm:ss');
     }
     if(this.object.main.status == 'Aprobado'){
       this.object.main.acceptanceDate = moment().format('DD/MM/YYYY HH:mm:ss');
     }
     this.session.getRequest("clientCredit:update",this.object).subscribe((data:any)=>{
       this.loadingService.hide();
       this.close(true);
     },error=>{
       this.loadingService.hide();
       console.log("error:",error);
     })
   }

   /**funciona para obetner el listado de tipos de creditos que hay habilitados en el sistema
   */
   getCreditTypeList(){
     let branch:any = this.session.getSessionObject();
     this.creditTypeMin.system.company.main.uuid = branch.system.company.main.uuid;
     this.creditTypeList = [];
     // verificamos en que pagina verificamos
     this.session.getRequest("creditType:findAllByCompanyAndStatus", this.creditTypeMin).subscribe(
       (data: any) => {
         this.creditTypeList = data.object.instanceList;
       },
       error => {
         console.log("Error: creditType:findAllByCompany", error);
       }
     );
   }

   close(status){
     let object = {
       transaction:'',
       code:'',
       object:{}
     };
     if(status){
       object.transaction = 'ok';
       object.object = this.object;
     }else{
       object.transaction = 'bad';
       object.code = 'clientCredit:001';
     }
     this.dialogRef.close(object);
   }
}
