import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-sale-client-update',
  templateUrl: './sale-client-update.component.html',
  styleUrls: ['./sale-client-update.component.css']
})
export class SaleClientUpdateComponent implements OnInit {
  complexForm: FormGroup;

  contacts = [];
  address = {
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: '',
      fullAddress:''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          mName: ''
        },
        system: {
          locality: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main:{
                  uuid:'',
                  name:''
                },
              }
            }
          }
        }
      },
      state:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  };
  moralPerson = {
    main: {
      uuid: '',
      socialReason: ''
    },
    complement: {
      rfc: ''
    },
    system: {
      contacts: this.contacts,
      address: this.address,
      status: true
    }
  };
  physicalPerson = {
    main:{
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement:{
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system :{
      status:true,
      address:this.address,
      contacts:this.contacts
    }
  };
  clientModel = {
    main: {
      uuid: '',
      nickname: '',
      code: '',
      priceNumber:0
    },
    image:{
      icon:'',
      name:''
    },
    complement:{
      bankReference:'',
      creditDays:0,
      rateMoratorium:0
    },
    system: {
      national:true,
      typePerson: '',
      person:{},
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  };
  metadata = {
    disable:false,
    dateBirth : null
  }
  object = JSON.parse(JSON.stringify(this.clientModel));
  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  priceList = [
    {
      name:'Lista',
      value:1
    },{
      name:'Mayoreo',
      value:2
    },{
      name:'Crédito',
      value:3
    }
  ]
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<SaleClientUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'code': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
      'nickname': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
      'curp': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[0-9]{2}')])],
      'rfcP': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
      'rfcM': [null, Validators.compose([Validators.required,Validators.pattern('[A-Za-z]{3}[0-9]{6}[A-Za-z0-9]{3}')])],
    })
  }

  ngOnInit() {
    this.getBranchList();
    let type = this.session.getUser().system.objectType;
    if(type == 'Company'){
      this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.object.system.branch.main.uuid = "2c9fa12f73de38590173df85e30c0004";
    }else{
      let branch:any = this.session.getSessionObject();
      this.object.system.company.main.uuid = branch.system.company.main.uuid;
      this.object.system.branch.main.uuid = branch.main.uuid;
    }
    if(this.data != 'new'){
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.loadingService.wait();
      this.session.getRequest('client:get',this.object).subscribe((data:any)=>{
        this.loadingService.hide();
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).utc().toISOString();
        if(this.object.system.typePerson != ''){
          if(this.object.system.typePerson == "PhysicalPerson"){
            this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).format("YYYY-MM-DD");
          }
          let aux:any = this.object.system.person;
          if(aux.system.address){
            this.address = aux.system.address;
          }else{
            this.object.system.person.system.address = this.address;
          }
        }
        if(this.object.system.typePerson != ''){
          if(this.object.system.typePerson == "PhysicalPerson"){
            this.metadata.dateBirth = moment(this.object.system.person.complement.dateBirth).format("YYYY-MM-DD");
          }
          let aux:any = this.object.system.person;
          this.contacts = aux.system.contacts;
        }
        this.metadata.disable = true;
      },
      (error)=>{
        console.log('Error:client:get',error)
        this.loadingService.hide();
      })
    }
  }

  send(object) {
    this.loadingService.show(true,'Guardando...');
    if (object.system.person.main != undefined) {
      // pasamos la fecha de compleaño al formato que recibe al BD
      if (object.system.typePerson == "PhysicalPerson") {
        object.system.person.complement.dateBirth = moment().format("DD-MM-YYYY");
      }
      let type = this.session.getUser().system.objectType;
      if(type == 'Company'){
        object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        // this.object.system.branch.main.uuid = "2c9fa12f73de38590173df85e30c0004";
      }else{
        let branch:any = this.session.getSessionObject();
        object.system.company.main.uuid = branch.system.company.main.uuid;
        // this.object.system.branch.main.uuid = branch.main.uuid;
      }

      if(this.address.system.state.main.uuid == ''){
        this.object.system.person.system.address = null;
      }else{
        this.address.main.settingName =  this.address.main.fullAddress;
        this.object.system.person.system.address = this.address;
      }

      this.session.getRequest("client:update", object).subscribe(
        (data:any) => {
          let first = false;
          if(object.main.uuid == ''){
            first = true;
          }
          object.main.uuid = data.object.main.uuid;
          this.snackBar.open('El cliente se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);

        },
        error => {
          console.log("Error: client:update",error);
        }
      );

    }else{
      this.snackBar.open('Seleccion un tipo de persona', '', {
        duration: 8000
      });
    }

  }

  // Función para selecionar un tipos de persona
  changeTypePerson(){
    if(this.object.system.typePerson == "MoralPerson"){
      this.object.system.person = Object.assign({},this.moralPerson);
    }else{
      if(this.object.system.typePerson == "PhysicalPerson"){
        this.object.system.person = Object.assign({},this.physicalPerson);
      }
    }
  }
  changeTab(ev){
    if(ev.selectedIndex == 2){
      // this.send(this.object);
    }
  }

  // funcion para obtener la lista de sucursales
  getBranchList (){
    return new Promise((resolve,reject)=>{
      this.branchList = [];
      // verificamos en que pagina verificamos
      let type = this.session.getUser().system.objectType;
      if(type == 'Company'){
        this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      }else{
        let branch:any = this.session.getSessionObject();
        this.branchMin.system.company.main.uuid = branch.system.company.main.uuid;
      }
      this.session.getRequest("branch:list",this.branchMin).subscribe(
        (data:any) => {
          this.branchList = data.object.instanceList;
          resolve(true);
       },
       error => {
         console.log("branch:list",error);
         reject(error);
       }
      );
    });

  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'client:001';
    }
    this.dialogRef.close(object);
  }

}
