import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { SessionService } from '../../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AdminAccountingPeriodComponent } from '../../../systemSettings/admin-accounting-period/admin-accounting-period.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-policy-customer-update',
  templateUrl: './policy-customer-update.component.html',
  styleUrls: ['./policy-customer-update.component.css']
})
export class PolicyCustomerUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  policy = {
    main: {
      uuid: "",
      month: "",
      year: "",
      policyDate: "",
      policyNumber: "",
      description: "",
      invoiceFolio: "",
      totalDebitAmount: 0,
      totalHaveAmount: 0
    },
    system: {
      company: {
        main: {
          uuid: ""
        }
      },
      typePolicy: {
        main: {
          uuid: ""
        }
      },
      accountingPeriod: {
        main: {
          uuid: ""
        }
      },
      invoiceUuid: '',
      userUuid: '',
      objectBelongs: '',// Venta | compra | devolucion
      objectUuidBelongs: '', /// uuid de tipo objectBelongs
      object: '', // Quien hace la poliza Empresa | companyBosses | Branch
      objectUuid: '', // uuid del tipo object;
      accountingAccountsPolicies: [],
      status: true,
    }
  };

  accountingAccountPolicy = {
    main: {
      uuid: '',
      concept: '',
      reference: '',
      documentNumber: '',
      debitAmount: 0.0,
      haveAmount: 0.0,
    },
    system: {
      policy: {
        main: {
          uuid: ''
        }
      },
      accountingAccount: {
        main: {
          uuid: '',
          accountNumber: '',
          parentAccountNumber: '',
          description: ''
        },
        system: {
          accountingAccounts: []
        }
      }
    }
  };

  typePolicyList = [];
  accountingPeriodList = [];
  object = JSON.parse(JSON.stringify(this.policy));
  changeInput = new Rx.Subject<string>();
  metadata = {
    movementDate: moment(),
    company: {
      main: {
        uuid: ''
      }
    },
    main: {},
    system: {}
  };
  companyBossesList = [];

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    // this.object.system.companyBosses.main.uuid = this.session.getSessionObject().main.uuid;
    this.object.system.object = 'Company';
    this.object.system.objectUuid = this.session.getSessionObject().main.uuid;
    this.changeInput.debounceTime(700)
      .switchMap(val => {
        this.policyMovement(this.metadata);
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
  }

  ngOnInit() {
    // this.listAccountingPeriod();
    // this.getCompanyBosses()
    this.metadata.company = this.session.getSessionObject();
    this.selectedCompanyBosses(this.metadata.company);
    this.getTypePolicy();
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        let accountingAccount = JSON.parse(JSON.stringify(this.accountingAccountPolicy));
        this.object.system.accountingAccountsPolicies.push(accountingAccount);
      } else {
        this.object.main.uuid = params['uuid'];
        this.session.getRequest("policy:get", this.object).subscribe((data: any) => {
          // data.object.main.totalDebitAmount = 0;
          // data.object.main.totalHaveAmount = 0;
          for (let i = 0; i < data.object.system.accountingAccountsPolicies.length; i++) {
            data.object.system.accountingAccountsPolicies[i].system.accountingAccount.system.accountingAccounts = [];
          }
          this.object = data.object;
          let accountingAccount = JSON.parse(JSON.stringify(this.accountingAccountPolicy));
          this.object.system.accountingAccountsPolicies.push(accountingAccount);
          this.metadata.company.main.uuid = this.object.system.objectUuid;
          this.metadata.movementDate = moment(this.object.main.policyDate);
          this.listAccountingPeriod();
          this.getTypePolicy();
        }, error => {
          console.log("Error:policy:get", error)
        })
      }
      //
      if(params['companyUuid'] == 'list'){

      }else{
        this.metadata.company.main.uuid = params['companyUuid'];
        this.selectedCompanyBosses(this.metadata.company);
      }
    });
  }

  /**
   * Funcion para obtener los tipos de polizas
   */
  getTypePolicy() {
    this.loadingService.wait();
    this.session.getRequest("typePolicy:findAllByObjectTypeAndObjectTypeUuid", { "system": { "objectType": "Company", "objectTypeUuid": this.metadata.company.main.uuid }, "max": 10, "offset": 0, "filter": { "name": "", "value": "" } }).subscribe((data: any) => {
      this.typePolicyList = data.object.instanceList;
      this.loadingService.hide();
    }, error => {
      console.log("error: typePolicy:findAllByObjectTypeAndObjectTypeUuid", error);
      this.loadingService.hide();
    })
  }

  /**
   * Obtiene el código de la poliza corriente
   */
  generateCodePolicy() {
    this.loadingService.wait();
    this.session.getRequest("policy:generatePolicyNumber", { system: { objectType: 'Company', objectTypeUuid: this.metadata.company.main.uuid, status: true } }).subscribe((data: any) => {
      this.object.main.policyNumber = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("error: typePolicy:findAllBycompanyBossesAndStatus", error);
      this.loadingService.hide();
    })
  }

  /**
   * validamos si hay datos en el renglon para ingresar otro renglon
   */
  policyMovement(object) {
    let addRow = true;
    this.object.main.totalDebitAmount = 0;
    this.object.main.totalHaveAmount = 0;
    // recalculamos los totales
    for (let x = 0; x < this.object.system.accountingAccountsPolicies.length; x++) {
      // Validamos si existe un renglon vacio para no seguir agregando
      if (this.object.system.accountingAccountsPolicies[x].system.accountingAccount.main.uuid === '' && this.object.system.accountingAccountsPolicies[x].main.debitAmount == 0.0 && this.object.system.accountingAccountsPolicies[x].main.haveAmount == 0.0) {
        addRow = false;
      }
      this.object.main.totalDebitAmount = (this.object.main.totalDebitAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.debitAmount * 1);
      this.object.main.totalHaveAmount = (this.object.main.totalHaveAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.haveAmount * 1);
    }
    if (object.system.accountingAccount.main.accountNumber !== '' && object.system.accountingAccount.main.description !== '' && (object.main.debitAmount > 0 || object.main.haveAmount > 0) && addRow) {
      let accountingAccount = JSON.parse(JSON.stringify(this.accountingAccountPolicy));
      this.object.system.accountingAccountsPolicies.push(accountingAccount);
    }
  }

  deleteAccountingAccountPolicy(uuid, index){
    if(uuid == ''){
        this.object.system.accountingAccountsPolicies.splice(index, 1);
        this.object.main.totalDebitAmount = 0;
        this.object.main.totalHaveAmount = 0;
        // recalculamos los totales
        for (let x = 0; x < this.object.system.accountingAccountsPolicies.length; x++) {
          this.object.main.totalDebitAmount = (this.object.main.totalDebitAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.debitAmount * 1);
          this.object.main.totalHaveAmount = (this.object.main.totalHaveAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.haveAmount * 1);
        }
    }else{
      this.session.getRequest('accountingAccountPolicy:delete', {main: {uuid: uuid}}).subscribe(
        (data: any) => {
          this.object.system.accountingAccountsPolicies.splice(index, 1);
          this.object.main.totalDebitAmount = 0;
          this.object.main.totalHaveAmount = 0;
          // recalculamos los totales
          for (let x = 0; x < this.object.system.accountingAccountsPolicies.length; x++) {
            this.object.main.totalDebitAmount = (this.object.main.totalDebitAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.debitAmount * 1);
            this.object.main.totalHaveAmount = (this.object.main.totalHaveAmount * 1) + (this.object.system.accountingAccountsPolicies[x].main.haveAmount * 1);
          }
          this.snackBar.open('Eliminado exitoso', 'Información', { duration: 5000 });
        },
        error => {
          console.log("accountingAccountPolicy:delete", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
        })
    }
  }

  movement(object) {
    this.metadata.main = JSON.parse(JSON.stringify(object.main));
    this.metadata.system = JSON.parse(JSON.stringify(object.system));
    this.changeInput.next('enabled');
  }

  /**
   * Busca la cuenta contable según el usuario
   */
  findAllByAccountingAccountDescription(object) {
    // realiza la busqueda a partir de 4 caracteres escritos por el usuario
    if (object.main.description.length > 3) {
      // obtenemos todas las cuentas contables que coincidan en la descripción
      this.loadingService.wait();
      object.system.accountingAccounts = [];
      this.session.getRequest("accountingAccount:findAllByDescriptionLike", { main: { description: object.main.description }, system: { objectType: 'Company', objectTypeUuid: this.metadata.company.main.uuid } }).subscribe((data: any) => {
        object.system.accountingAccounts = data.object.instanceList;
        this.loadingService.hide();
      }, error => {
        console.log("error: accountingAccount:findAllByDescriptionLike", error);
      })
    }
  }

  /**
   * Selección de la cuenta contable
   */
  select(object, index) {
    object.system.accountingAccounts = [];
    this.object.system.accountingAccountsPolicies[index].system.accountingAccount = JSON.parse(JSON.stringify(object));
  }

  /* Guadamos la poliza contable */
  send() {
    this.loadingService.show(true, "Actualizando datos de la poliza, espere un momento...")
    this.object.main.month = moment(this.metadata.movementDate).month() + 1;
    this.object.main.year = moment(this.metadata.movementDate).year();
    this.object.main.policyDate = moment(this.metadata.movementDate).format("DD-MM-YYYY");
    this.object.system.objectType = 'Company';
    this.object.system.objectTypeUuid = this.metadata.company.main.uuid;
    this.object.system.object = 'Company';
    this.object.system.objectUuid = this.metadata.company.main.uuid;
    this.session.getRequest("policy:update", {
      main: this.object.main,
      system: {
        object: this.object.system.object,
        objectUuid: this.object.system.objectUuid,
        objectType: this.object.system.objectType,
        objectTypeUuid: this.object.system.objectTypeUuid,
        typePolicy: this.object.system.typePolicy,
        accountingPeriod: this.object.system.accountingPeriod,
      }
    }).subscribe((data: any) => {
      this.object.main = data.object.main;
      let accountingProcess = this.object.system.accountingAccountsPolicies.length;
      for (let x = 0; x < this.object.system.accountingAccountsPolicies.length; x++) {
        this.object.system.accountingAccountsPolicies[x].system.policy.main.uuid = this.object.main.uuid;
        this.object.system.accountingAccountsPolicies[x].system.accountingAccount.system.accountingAccounts = [];
        if (this.object.system.accountingAccountsPolicies[x].system.accountingAccount.main.uuid != '') {
          this.session.getRequest("accountingAccountPolicy:update", this.object.system.accountingAccountsPolicies[x]).subscribe((data: any) => {
            this.object.system.accountingAccountsPolicies[x].main = data.object.main;
            accountingProcess--;
            if (accountingProcess == 0) {
              this.loadingService.hide();
              this.snackBar.open('Se guardaron los cambios en la poliza', 'Correcto', { duration: 5000 });
              this.router.navigate(['/admin/accounting/policy/'+this.metadata.company.main.uuid]);
            }
          }, error => {
            this.loadingService.hide();
            console.log("Error:policy:update", error);
          })
        } else {
          accountingProcess--;
          if (accountingProcess == 0) {
            this.router.navigate(['/accounting/customer/policy/'+this.metadata.company.main.uuid]);
            this.loadingService.hide();
          }
        }
      }
    }, error => {
      this.loadingService.hide();
      console.log("Error:policy:update", error);
    })
  }

  /* open modal to create accountingPeriod*/
  openAccountingPeriod() {
    this.dialog.open(AdminAccountingPeriodComponent, {
      width: '40%',
      data: {
        company: this.metadata.company
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.accountingPeriodList.push(result);
        this.object.system.accountingPeriod.main.uuid = result.main.uuid;
      } else {
        //No hacemos Nada
      }
    });
  }

  /* funcion para obtener el periodo contable  */
  listAccountingPeriod() {
    this.session.getRequest("accountingPeriod:findAllByObjectTypeAndObjectTypeUuid", { system: { objectType: 'Company', objectTypeUuid: this.metadata.company.main.uuid } }).subscribe((data: any) => {
      this.accountingPeriodList = data.object.instanceList;
    }, error => {
      console.log("Error:accountingPeriod:findAllByObjectTypeAndObjectTypeUuid", error);
    })
  }

  /* Funcion para seleccionar una patronal y buscar sus polizas */
  selectedCompanyBosses(object) {
    for (let item of this.companyBossesList) {
      if (object.main.uuid == item.main.uuid)
        this.metadata.company = object;
    }
    this.listAccountingPeriod();
    // Obtenemos los tipos de polizas
    this.getTypePolicy();
    if (this.object.main.uuid == '') {
      // Obtenemos el número de poliza
      this.generateCodePolicy();
    }
  }

  openDialogDocument(object) {
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: '70%',
      data: { accountingAccountsPolicy: object
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  /*
   * funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
