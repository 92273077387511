import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';
import * as printJS from 'print-js';

@Component({
  selector: 'app-counter-dashboard',
  templateUrl: './counter-dashboard.component.html',
  styleUrls: ['./counter-dashboard.component.css']
})
export class CounterDashboardComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  metadata = {
    date: moment().clone().startOf('day').toISOString()
  }
  saleList = [];

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit() {
    this.findAllByBranchAndBetweenDate()
  }

  findAllByBranchAndBetweenDate() {
    this.loadingService.wait();
    this.session.getRequest("sale:findAllByBranchAndBetweenDate", {
      system: {
        branch: this.session.getSessionObject()
      },
      main: {
        date: moment(this.metadata.date).format('DD/MM/YYYY hh:mm:ss'),
      }
    }).subscribe((data: any) => {
      this.saleList = data.object.instanceList;
      this.loadingService.hide();
    }, error => {
      console.log("Error: sale:findAllByBranchAndBetweenDate", error);
      this.loadingService.hide();
    });
  }

  /**función para imprimir una venta
  */
  printTicket(object){
    if(object.complement.paymentMethod == 'Credito'){
      this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
      this.session.getRequest("saleReport:creditTicket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
        this.loadingService.hide();
        printJS({printable: data.object, type: 'pdf', base64: true})
      },error=>{
        console.log(error);
        this.snackBar.open('No pudo imprimir el ticket', '', {
          duration: 1000
        });
        this.loadingService.hide();
      })
    }else{
      this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
      this.session.getRequest("saleReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
        this.loadingService.hide();
        printJS({printable: data.object, type: 'pdf', base64: true})
      },error=>{
        console.log(error);
        this.snackBar.open('No pudo imprimir el ticket', '', {
          duration: 1000
        });
        this.loadingService.hide();
      })
    }

  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
