import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import { TerminalUpdateComponent } from './terminal-update/terminal-update.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  terminalList = [];
  terminalMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) { }

  ngOnInit() {
    this.getterminalList();
  }

  /**
  * Obtiene la lista de tipos de ajuste de inventarios por empresa logueada
  */
  getterminalList() {
    this.terminalMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.terminalList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("terminal:listByCompany", this.terminalMin).subscribe(
      (data: any) => {
        this.terminalList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("Erro: terminal:findAllByCompany", error);
      }
    );
  }

  /*
  *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.terminalMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.terminalMin.offset = (event.pageIndex * event.pageSize);
    this.terminalMin.max = event.pageSize;
    this.getterminalList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Modal para dar de alta un almacen
   */
  UpdateDialog(uuid) {
    this.dialog.open(TerminalUpdateComponent, {
      width: '60%',
      height:'40rem',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getterminalList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }
}
