import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-update',
  templateUrl: './employee-update.component.html',
  styleUrls: ['./employee-update.component.css']
})
export class EmployeeUpdateComponent implements OnInit {
  complexForm: FormGroup;
  contacts = [];
  addressModel = {
    main: {
      uuid: '',
      settingName: '',
      numberOutdoor: '',
      numberInterior: '',
      zipCode: '',
      fullAddress:''
    },
    complement: {
      betweenRoads: '',
      locationDescription: ''
    },
    system: {
      addressType: 'Fiscal',
      setting: {
        main: {
          uuid: '',
          mName: ''
        },
        system: {
          locality: ''
        }
      },
      locality: {
        main: {
          uuid: '',
          code: '',
          name: '',
          typeLocality: ''
        },
        system: {
          town: {
            main: {
              uuid: '',
              name: ''
            },
            system: {
              state: {
                main:{
                  uuid:'',
                  name:''
                },
              }
            }
          }
        }
      },
      state:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  };

  physicalPersonModel = {
    main: {
      uuid: "",
      name: "",
      lastname: "",
      surname: ""
    },
    complement: {
      dateBirth: "",
      curp: "",
      rfc: "",
      gender: ""
    },
    system: {
      status: true,
      address: this.addressModel,
      contacts: this.contacts
    }

  };
  employeeModel = {
    main: {
      uuid: "",
      code: ""
    },
    complement: {
      maritalStatus: "",
      nationality: "",
      placeBirth: {
        main: {
          uuid: ""
        }
      },
      age: ""
    },
    system: {
      company: {
        main: {
          uuid: ""
        }
      },
      employment: {
        main: {
          uuid: "",
          name: ""
        }
      },
      status: true,
      physicalPerson: this.physicalPersonModel
    },
    image:{
      icon:"",
      name:""
    }
  };
  maritalStatusList = [{ name: 'Soltero' }, { name: 'Casado' }, { name: 'Divorciado' }, { name: 'Unión libre' }, { name: 'Viudo' }]
  stateList = [];
  metadata = {
    dateBirth: ''
  }
  object = JSON.parse(JSON.stringify(this.employeeModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EmployeeUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'fatherName': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'motherName': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'curp': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[A-Za-z0-9]{2}')])],
      'rfc': [null, Validators.compose([Validators.required, Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
    })
   }

  ngOnInit() {
    this.getStateList();
    if(this.data == 'new'){

    }else{
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('employee:get',this.object).subscribe((data:any)=>{
        if(!data.object.system.physicalPerson.system.address){
          data.object.system.physicalPerson.system.address = this.addressModel;
        }
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.dateBirth = this.object.system.physicalPerson.complement.dateBirth;
        this.loadingService.hide();
      },
      (error)=>{
        this.loadingService.hide();
        console.log('Error:employee:get',error)
      })
    }
  }

  // funcion para obtener todos los estados.
  getStateList() {
    this.session.getRequest("state:list", { system: { 'status': true }, 'order': 'asc', max: 100, offset: 0 }).subscribe(
      (data: any) => {
        this.stateList = data.object.instanceList;
      },
      error => {
        console.log("state:findAllByStatus", error);
      }
    );
  }

  loadContacts() {
    let aux: any = this.object.system.physicalPerson;
    this.contacts = aux.system.contacts;

  }
  send(object) {
    if (object.system.physicalPerson.system.address.system.setting.main.uuid != '') {
      // pasamos la fecha de compleaño al formato que recibe al BD
      object.system.physicalPerson.complement.dateBirth = moment().format("DD-MM-YYYY");
      object.complement.age = 0;
      object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.loadingService.show(true,'Guardando registro');
      object.system.physicalPerson.system.address.main.settingName = object.system.physicalPerson.system.address.main.fullAddress;
      this.session.getRequest("employee:update", object).subscribe(
        (data: any) => {
          object.main.uuid = data.object.main.uuid;
          this.snackBar.open('El trabajador se guardó correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);
        },
        error => {
          this.loadingService.hide();
          console.log("Error: employee:update", error);
        }
      );
    } else {
      this.snackBar.open('Complete su dirección', '', {
        duration: 8000
      });
    }
  }

  calculateAge(birthday) {
    var y = 0;
    if (birthday !== undefined && birthday !== '' && birthday !== null) {
      var ageDifMs = Date.now() - birthday; //obtemos la edad en milisegundos
      var totalMonts = ageDifMs * (1 / 2628000000);// convertimos los milisegundos a meses
      y = totalMonts / 12; //obtenemos la edad en años
    }
    this.object.complement.age = Math.floor(y);
    return Math.floor(y);
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'characteristic:001';
    }
    this.dialogRef.close(object);
  }

}
