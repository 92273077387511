import { Component, OnInit } from '@angular/core';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-counter-existence',
  templateUrl: './counter-existence.component.html',
  styleUrls: ['./counter-existence.component.css']
})
export class CounterExistenceComponent implements OnInit {

  warehouseList = [];
  productList = [];
  warehouseProductMin = {
    system:{
      warehouse:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 50, 100],
  };
  // MatPaginator Output
  pageEvent: PageEvent;

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CounterExistenceComponent>) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if(val != ''){
          this.productList = [];
          this.warehouseProductMin.filter.name = 'searchElement';
        }else{
          this.productList = [];
          this.warehouseProductMin.filter.name = '';
          this.warehouseProductMin.filter.value = '';
          val = 'false'
        }
        this.getStocks(this.warehouseProductMin.system.warehouse);
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.getWarehouseList();
  }


    // Funcion para obtener la lista de almacenes
    getWarehouseList(){
      let branch:any = this.session.getSessionObject();
      this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: branch.system.company.main.uuid } } }, status: true }).subscribe(
        (data: any) => {
          this.warehouseList = data.object.instanceList;
          //cargamos el primer alamacen
          this.warehouseProductMin.system.warehouse.main = JSON.parse( JSON.stringify(this.session.getWarehouse().main));
          this.selectedWareHouse();
        },
        error => {
          console.log("warehouse:findAllByCompanyAndStatus", error);
        });
    }
    /** funcion para seleccionar un alamacen
    */
    selectedWareHouse(){
      let aux = JSON.parse(JSON.stringify(this.warehouseProductMin.system.warehouse));

      this.getStocks(aux);
    }
    /** Funcion para obtener las existencias de un alamacen
    */
    getStocks(object){
      this.loadingService.wait();
      this.productList = [];
      // verificamos en que pagina verificamos
      this.warehouseProductMin.system.warehouse.main.uuid = object.main.uuid;
      this.session.getRequest("warehouseProduct:findAllProductByWarehouse", this.warehouseProductMin).subscribe(
        (data: any) => {
          this.productList = data.object.instanceList;
          for(let i = 0; i < this.productList.length; i++){
            this.getPrices(this.productList[i].system.product);
          }
          // console.log("list", this.productList)
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("warehouseProduct:findAllProductByWarehouse", error);
          this.loadingService.hide();
        }
      );
    }

    /** Funcion para obtener los precios de un producto*/
    getPrices(object){
      this.session.getRequest('productPrice:findAllByProduct',{system:{product:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
        object.prices = data.object.instanceList;
      },error=>{
        console.log("error:productPrice:findAllByProduct",error);
      })
    }


    // funcion para obtener los datos del paginado.
    onPaginateChange(event){
      this.warehouseProductMin.offset = (event.pageIndex * event.pageSize);
      this.warehouseProductMin.max = event.pageSize;
      this.getStocks(this.warehouseProductMin.system.warehouse);
    }

    /*
      *Funcion para buscar
    */
    search(){
      this.metadata.searchBoxInput.next(this.warehouseProductMin.filter.value);
    }


    /**función para cerra el modal
    */
    close(){
      this.dialogRef.close();
    }

}
