import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import {PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
// import { ProcessOutsideService } from "../../../directive/process-outside/process-outside.module";
import { PolicyDocumentComponent } from './policy-document/policy-document.component';
import { ConfirmDeletePolicyComponent } from './confirm-delete-policy/confirm-delete-policy.component';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyCustomerComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  policyList = [];
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    company:{
      main:{
        uuid:''
      }
    }
  }
  policyMin = {
    system: {
      objectType: 'Company',
      objectTypeUuid: ''
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  pageEvent: PageEvent;
  complexForm: FormGroup;
  companyBossesList = [];
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, public dialog:MatDialog) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.policyList = [];
          this.policyMin.filter.name = 'searchElement';
        } else {
          this.policyList = [];
          this.policyMin.filter.name = '';
          this.policyMin.filter.value = '';
          val = 'false'
        }
        if(this.metadata.company.main.uuid !=''){
          this.getPolicyList(this.metadata.company);
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    // this.getCompanyBosses();
    this.selectedCompanyBosses();
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   if (params['companyBossesUuid'] == 'list') {
    //     //no hacemos nada
    //   } else {
    //     this.metadata.company.main.uuid = params['companyBossesUuid'];
    //   }
    // });
  }

  /**
   * Obtiene los documentos de la poliza
   */
  openDocument(object){
    this.dialog.open(PolicyDocumentComponent, {
      width: '60%',
      data:{
        object:object
      }
    }).afterClosed().subscribe(result => {
      if (result) {

      } else {
        //No hacemos Nada
      }
    });
  }

  /**
   * Obtenemos las polizas por empresa patronal seleccionada
   */
  getPolicyList(object) {
    this.loadingService.wait();
    this.policyList = [];
    // verificamos en que pagina verificamos
    this.policyMin.system.objectTypeUuid = object.main.uuid;
    this.session.getRequest("policy:findAllByObjectTypeAndObjectTypeUuid", this.policyMin).subscribe(
      (data: any) => {
        this.policyList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("policy:findAllByObjectTypeAndObjectTypeUuid", error);
        this.loadingService.hide();
      }
    );
  }



  /**
   * Funcion para seleccionar una patronal y buscar sus polizas
   */
  selectedCompanyBosses(){
    this.metadata.company = this.session.getSessionObject();
    this.getPolicyList(this.metadata.company);
  }

 /** Función para descargar el reporte de poliza en xlsx o txt **/
  reportPolicy(uuid, type, versionTxt) {
    // var objectName = '';
    // for(let item of this.companyBossesList){
    //   if(this.metadata.company.main.uuid == item.main.uuid)
    //     objectName = item.main.tradename;
    // }
    this.loadingService.show(true,'Espere un momento... Construyendo reporte.');
    this.session.getRequest('policy:downloadPolicy', { main:{ uuid: uuid, type: type, objectName: this.session.getSessionObject().main.name, adjustAccountNumber: true, versionTxt: versionTxt }}).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object.base64;
      elem.setAttribute('download', data.object.name);
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
    },error=>{
      console.log("Error:policy:downloadPolicy",error);
      this.loadingService.hide();

    })
    // this.backgroud.getReportPolicy(uuid, type, objectName, versionTxt);
  }

  /**
   * Abre el cuadro de dialogo para advertir que se eliminará la nómina
   */
  openDialogConfirmDelete(uuid) {
    const dialogRef = this.dialog.open(ConfirmDeletePolicyComponent, {
      width: '500px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletePolicy(uuid);
      }
    });
  }

  /** Función para eliminar póliza **/
  deletePolicy(uuid) {
    if(uuid != ''){
      this.loadingService.wait();
      this.session.getRequest('policy:delete', {main: {uuid: uuid}}).subscribe((data:any)=>{
          this.getPolicyList(this.metadata.company);
          this.loadingService.hide();
          this.snackBar.open('Eliminado Exitoso', 'Información', {duration: 5000});
        },
        error => {
          console.log("policy:delete", error);
          this.snackBar.open('Error al eliminar', 'Error', {duration: 5000});
        });
    }
  }

  search(){
    this.metadata.searchBoxInput.next(this.policyMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.policyMin.offset = (event.pageIndex * event.pageSize);
    this.policyMin.max = event.pageSize;
    this.getPolicyList(this.metadata.company);
  }

  handleUpdated(event) {
    this.policyMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.policyMin.offset);
    this.getPolicyList(this.metadata.company);
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
