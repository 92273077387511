import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { SaleClientCreditUpdateComponent } from '../sale-client-credit/sale-client-credit-update/sale-client-credit-update.component';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-sale-request-client-credit',
  templateUrl: './sale-request-client-credit.component.html',
  styleUrls: ['./sale-request-client-credit.component.css']
})
export class SaleRequestClientCreditComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  clientCreditMin = {
    main:{
      status:''
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  clientCreditList=[];
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  };
  pageEvent: PageEvent;
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.clientCreditList = [];
        this.clientCreditMin.filter.name = 'findAllByCompanyAndLikeName';
      }else{
        this.clientCreditList = [];
        this.clientCreditMin.filter.name = '';
        this.clientCreditMin.filter.value = '';
        val = 'false'
      }
      this.getClientCredit();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
   }

  ngOnInit() {
    this.getClientCredit();
  }

  /***funcionalidad para listar las solicitudes de creditos
  */
  getClientCredit(){
    this.loadingService.wait();
    this.clientCreditMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.clientCreditMin.main.status = 'Trámite'
    this.session.getRequest("clientCredit:findAllByCompanyAndStatus", this.clientCreditMin).subscribe(
      (data: any) => {
        this.loadingService.hide();
        this.clientCreditList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("Error: clientCredit:findAllByCreditType", error);
        this.loadingService.hide();
      }
    );
  }


  /*
  *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.clientCreditMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.clientCreditMin.offset = (event.pageIndex * event.pageSize);
    this.clientCreditMin.max = event.pageSize;
    this.getClientCredit();
  }

  updateDialog(uuid) {
    this.dialog.open(SaleClientCreditUpdateComponent, {
      width: '60%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getClientCredit();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }


}
