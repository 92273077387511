import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from './../../../../directive/loading/loading.module';

@Component({
  selector: 'app-accounting-account-tree',
  templateUrl: './admin-account-tree.component.html',
  styleUrls: ['./admin-account-tree.component.css']
})
export class AdminAccountTreeComponent implements OnInit {

  @Input('accountingAccounts') accountingAccounts: any;
  @Input('object') object: any;
  @Input('selection') selection: boolean;
  @Output() returnCheck = new EventEmitter;

  accountingAccountModel = {
    main: {
      uuid: '',
      accountNumber: '0',
      parentAccountNumber: '',
      description: '',
      natureAccount: '' //Acredora o Deudora
    },
    complement: {
      edit: false,
      accountLevel: '',
      numberAccountLevel: 0
    },
    system: {
      accountingAccounts:[],
      accountingAccount: {
        main: {
          uuid: '',
          description: ''
        }
      },
      typeAccount: {
        main: {
          uuid: ''
        }
      }
    }
  }

  constructor(public session: SessionService) { }

  ngOnInit() {
  }

  /**
   * Limpia el formulario y establece lo númeraria de la cuenta padre a la que pertecera
   */
  addAccountingAccount(object) {
    if (object.main.classNumber === undefined) {
      this.object.system.accountingAccount.main = JSON.parse(JSON.stringify(object.main));
      this.object.system.typeAccount.main = JSON.parse(JSON.stringify(object.system.typeAccount.main));
    } else {
      this.object.system.typeAccount.main = JSON.parse(JSON.stringify(object.main));
      this.object.system.accountingAccount.main.description = object.main.name;
    }
    this.object.main.description= '';
    this.object.main.natureAccount = object.main.natureAccount;
    this.object.complement.accountLevel = '';
    this.object.complement.edit = false;

    // Obtenemos el código de la cuenta clave
    this.session.getRequest("accountingAccount:generateAccountNumber", { system: { typeAccount: this.object.system.typeAccount, accountingAccount: { main: this.object.system.accountingAccount.main }  }  }).subscribe((data: any) => {
      this.object.main.parentAccountNumber = data.object.parentAccountNumber;
      this.object.main.accountNumber = data.object.accountNumber;
      this.object.complement.numberAccountLevel = data.object.level;
    }, error => {
      console.log("error:accountingAccount:generateAccountNumber", error);
    });
  }

  /**
   * Limpia el arreglo de cuentas contables
   */
  cleanAccountingAccount(object) {
    object.system.open = false;
    object.system.accountingAccounts = [];
  }

  /**
   * Modifica los datos de la cuenta a Editar
   */
  editAccountingAccount(object) {
    this.object.main = JSON.parse(JSON.stringify(object.main));
    this.object.complement = JSON.parse(JSON.stringify(object.complement));
    this.object.system = JSON.parse(JSON.stringify(object.system));
    this.object.system.open = false;
    this.object.system.loading=false;
    this.object.system.selected = false;
    this.object.system.accountingAccounts = [];
  }

  /**
   * Funcion para obtener los hijos de un cuenta contable.
   */
  findAllAccountingAccountByAccountingAccount(object) {
    object.system.loading = true;
    object.system.open = true;
    object.system.accountingAccounts = [];
    this.session.getRequest("accountingAccount:findAllByAccountingAccount",{system:{accountingAccount:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
      for (let item of data.object.instanceList) {
        item.system.open = false;
        item.system.loading=false;
        item.system.selected = false;
        item.system.accountingAccounts = [];
        object.system.accountingAccounts.push(item);
      }
      object.system.loading = false;
    },error=>{
      console.log("error:accountingAccount:findAllByAccountingAccount",error);
    });
  }

  /**
   * Selecciona la cuenta
   */
  selectAccountingAccount(object) {
    // object.system.selected = true;
    this.object.main = JSON.parse(JSON.stringify(object.main));
    this.object.complement = JSON.parse(JSON.stringify(object.complement));
    this.object.system = JSON.parse(JSON.stringify(object.system));
    object.system.selected = true;
  }

}
