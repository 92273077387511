import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { ProductProviderUpdateComponent } from './product-provider-update/product-provider-update.component';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-product-provider',
  templateUrl: './product-provider.component.html',
  styleUrls: ['./product-provider.component.css']
})
export class ProductProviderComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;

  productProviderList = [];
  productProviderMin = {
    system:{
      status:true,
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;
  providerModel = {
    main: {
      uuid: '',
      name: '',
      contactName: '',
      code: ''
    },
    system: {
      typePerson: '',
      person:{},
      company:{
        main:{
          uuid:''
        }
      },
      status: true
    }
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, public loading:LoadingService) {
    this.productProviderMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.productProviderList = [];
        this.productProviderMin.filter.name = 'searchElement';
      }else{
        this.productProviderList = [];
        this.productProviderMin.filter.name = '';
        this.productProviderMin.filter.value = '';
        val = 'false'
      }
      this.getProductProviderList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });
  }

  ngOnInit() {

    this.getProductProviderList();
  }

  // funcion para obtener la lista de sucursales
  getProductProviderList (){
    this.productProviderList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("provider:findAllByCompanyAndStatus",this.productProviderMin).subscribe(
      (data:any) => {
        this.productProviderList = data.object.instanceList;
        this.paginatorModel.total =  data.object.total;
     },
     error => {
       console.log("provider:list",error);
     }
    );
  }

  /**
   * Modal para visualizar el update de sucursal
   */
  productProviderUpdateDialog(uuid) {
    this.dialog.open(ProductProviderUpdateComponent, {
      width: '80%',
      height: '90%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getProductProviderList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.productProviderMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.productProviderMin.offset = (event.pageIndex * event.pageSize);
    this.productProviderMin.max = event.pageSize;
    this.getProductProviderList();
  }

  handleUpdated(event) {
    this.productProviderMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.productProviderMin.offset);
    this.getProductProviderList();
  }
   /*
   funcion para controlar si se abre o cierra el menu
   */
   toogleMenu(ev){
     this.drawerCtrl.opened = ev;
   }

   disebled(object){
     this.loading.show(true,"Espere un momento..")
     this.session.getRequest('provider:get',object).subscribe((data:any)=>{
       object = JSON.parse(JSON.stringify(data.object));
       object.system.person.complement.dateBirth = moment(object.system.person.complement.dateBirth).add(6, 'hours').utc().toISOString();
       if(object.system.typePerson != ''){
         if(object.system.typePerson == "PhysicalPerson"){
           object.system.person.complement.dateBirth = moment(object.system.person.complement.dateBirth).format("YYYY-MM-DD");
         }
       }
       object.system.status = false;
       this.session.getRequest("provider:update", object).subscribe(
         data => {
           this.snackBar.open('El registro se desactivó correctamente', '', {
             duration: 8000
           });
           for(let  i = 0; i < this.productProviderList.length; i++){
             if(this.productProviderList[i].main.uuid == object.main.uuid){
               this.productProviderList.splice(i,1);
             }
           }
           this.loading.hide();
        },
        error => {
          console.log("Error: provider:update",error);
        }
       );

     },
     (error)=>{
       console.log('Error:provider:get',error)
       this.loading.hide();
     })
   }

}
