import { Input, HostListener, OnInit, Component } from '@angular/core';
import { SessionService } from '../../service/session/session.module';

@Component({
  selector: 'sti-address-component',
  templateUrl: './sti-address.component.html',
  styleUrls: ['./sti-address.component.css']
})
export class StiAddressComponent {
  state = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    system: {
      status: true
    }
  };
  metadata = {
    searchState: '',
  }
  stateList = [];
  stateListFilter = [];

  @Input('stiAddress') stiAddress: any;
  @Input('stiDisable') stiDisable: boolean;

  constructor(public session: SessionService) {
  }

  ngOnChanges() {
    // console.log(">>>>", this.stiAddress);
    this.stateList = [];
    this.stiAddress.system.locality.system.town.system.state.main.uuid = '2c9f91dd7084d077017084d0bc66016c';
    this.stiAddress.system.locality.system.town.main.uuid = '2c9f91dd708bba0201708d829e020000';
    this.stiAddress.system.locality.main.uuid = '2c9f91dd708bb05a01708d82b11a0000';
    this.stiAddress.system.setting.main.uuid = '2c9f91dd708bba0201708d8697cf0001';
    if(this.stiAddress.system.state.main.uuid != ''){
      this.metadata.searchState = this.stiAddress.system.state.main.name;
    }

    // this.stiAddress.main.settingName = '';
    /**
     * Obtiene la lista de Estados activos en el sistema
     */
    this.session.getRequest("state:list", { system: { 'status': true }, 'order': 'asc', max: 100, offset: 0 }).subscribe(
      (data: any) => {
        this.stateList = data.object.instanceList;
      },
      error => {
        console.log("state:list", error);
      }
    );

  }

  /**
   * Obtiene la lista de Municipios de acuerdo al estado seleccionado
   */
  stateSelected(state) {
    if (state != undefined) {
      this.stateListFilter = [];
      this.metadata.searchState = state.main.name;
      this.stiAddress.system.state = Object.assign({}, state);
    };
  }

/*  Funcion para obtener el nombre del estado.
*/
saveStateName(name) {

  this.state.main.name = name.toUpperCase();
  // console.log(this.state.main.name);
  this.stateListFilter = this.stateList.filter((item: any): any => {
    var newStrProperty = 'main.name';
    var newItem1 = item;
    while (newStrProperty.indexOf(".") != -1) {
      // statements if the condition is true
      var positionPoint = newStrProperty.indexOf(".");
      var stringProperty = newStrProperty.slice(0, positionPoint);
      newStrProperty = newStrProperty.slice(positionPoint + 1, newStrProperty.length);
      if (typeof (newItem1[stringProperty]) == "object") {
        newItem1 = newItem1[stringProperty];
      }
    }
    if (newItem1.name.toUpperCase().indexOf(this.state.main.name) != -1)
      return newItem1;
  });
  // console.log(this.stateListFilter);
}

}
