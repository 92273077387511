import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDrawer } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDialog } from '@angular/material/dialog';
import { CounterCreditRequestUpdateComponent } from './counter-credit-request-update/counter-credit-request-update.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-credit-request',
  templateUrl: './counter-credit-request.component.html',
  styleUrls: ['./counter-credit-request.component.css']
})
export class CounterCreditRequestComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  clientCreditMin = {
    main:{
      status:'Aprobado'
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  clientCreditList=[];
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  statusList = [
    'Trámite',
    'Aprobado',
    'Cancelado'
  ]
  pageEvent: PageEvent;
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.clientCreditList = [];
        this.clientCreditMin.filter.name = 'searchElement';
        this.getClientCredit();
      }else{
        this.clientCreditList = [];
        this.clientCreditMin.filter.name = '';
        this.clientCreditMin.filter.value = '';
        val = 'false'
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
   }

   ngOnInit() {
     this.clientCreditMin.main.status = 'Aprobado'
     // this.getClientCredit();
   }

   /***funcionalidad para listar las solicitudes de creditos
   */
   getClientCredit(){
     this.loadingService.wait();
     let branch:any = this.session.getSessionObject();
     this.clientCreditMin.system.company.main.uuid = branch.system.company.main.uuid;
     this.session.getRequest("clientCredit:findAllByCompanyAndStatus", this.clientCreditMin).subscribe(
       (data: any) => {
         this.loadingService.hide();
         this.clientCreditList = data.object.instanceList;
         this.paginatorModel.total = data.object.total;
       },
       error => {
         console.log("Error: clientCredit:findAllByCreditType", error);
         this.loadingService.hide();
       }
     );
   }


   /*
   *Funcion para buscar
   */
   search(){
     this.metadata.searchBoxInput.next(this.clientCreditMin.filter.value);
   }
   // funcion para obtener los datos del paginado.
   onPaginateChange(event){
     this.clientCreditMin.offset = (event.pageIndex * event.pageSize);
     this.clientCreditMin.max = event.pageSize;
     this.getClientCredit();
   }


  /**
   * Modal para visualizar el update de solicitudes de crédito
   */
   updateDialog(uuid) {
     this.dialog.open(CounterCreditRequestUpdateComponent, {
       width: '60%',
       data: uuid
     }).afterClosed().subscribe(result => {
       if(result != undefined ){
         if(result.transaction == 'ok'){
           // El modal se cerro con objeto
           this.getClientCredit();
         }else{
           // El modal se cerro sin objeto
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       }else{
         // El modal se cerro sin seleccionar algo, dandole click fuera
         this.snackBar.open('No se guardo ningun registro', '', {
           duration: 1000
         });
       }
     });
   }

   /**función que se ejecuta cuando se cambia de pestaña en el tab
   */
   tabChanged(ev){
     this.clientCreditMin.main.status = ev.tab.textLabel;
     this.getClientCredit();
   }

  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
