import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { SaleClientCreditAbonoComponent } from '../sale-client-credit-abono/sale-client-credit-abono.component';
import { SaleClientCreditAbonosComponent } from '../sale-client-credit-abonos/sale-client-credit-abonos.component';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';
import * as printJS from 'print-js';

@Component({
  selector: 'app-sale-client-credit-details',
  templateUrl: './sale-client-credit-details.component.html',
  styleUrls: ['./sale-client-credit-details.component.css']
})
export class SaleClientCreditDetailsComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  saleMin = {
    main:{
      status:'Credito'
    },
    system:{
      client:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0
  };
  clientCredit = {
    main:{
      uuid:'',
      dischargeDate:'',//dd/MM/yyyy hh:mm:ss’, fecha de alta
      acceptanceDate:'',// fecha de aceptación
      status:'Trámite',//Trámite, Valoración, Aprobado, Supendido, Cancelado
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      client:{
        main:{
          uuid:'',
          nickname:''
        }
      },
      creditType:{
        main:{
          uuid:'',
        }
      }
    }
  };
  saleList = [];
  object = JSON.parse(JSON.stringify(this.clientCredit));
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;
  metadata ={
    totalCreditAvailable:0,
    totalCreditPay:0,
    saldo:0,
    router:''
  }
  constructor(public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog:MatDialog, public location:Location) {
    switch(this.session.getUser().system.objectType){
      case 'Branch':{
        this.metadata.router = '/counter/credit-request';
        break;
      }
      case 'Company':{
        this.metadata.router = '/admin/sale/client/credit';
        break;
      }
    };
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
      }else{
        this.loadingService.wait();
         this.object.main.uuid = params['uuid'];
         // cargamos los datos de la BD
         this.session.getRequest('clientCredit:get',this.object).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           this.saleMin.system.client.main = this.object.system.client.main;
           this.getSaleFromClient();
           this.getTotalCreditUsed();
           // this.getcreditPayment();
           // this.metadata.productOrService.filter = this.object.complement.keySat;
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           console.log('Error:clientCredit:get',error)
         })

      }
    });
  }

  /**función para obtener la ventas a credito de un cliente
  */
  getSaleFromClient(){
    this.loadingService.show(true,"Cargando datos del cliente");
    this.session.getRequest("sale:findAllByClientAndStatus",this.saleMin).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
      for(let i=0; i < this.saleList.length; i++){
        this.getProductSaleList(this.saleList[i]);
      }
      this.paginatorModel.total = data.object.total;
      // console.log(this.saleList);
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    });
  }

  /** función para obtener los prodcutos de una venta
  */
  getProductSaleList(object){
    this.session.getRequest("saleProduct:findAllBySale",{system:{sale:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
      object.system.products = data.object.instanceList;
    },error=>{
      console.log(error);
    })
  }

  /**funcionalidad para obtener el monto total de venta de un cliente y por status
  */
  getTotalCreditUsed(){
    let saleMinAux ={
      main:{
        status:'Credito'
      },
      system:{
        client:{
          main:{
            uuid:this.saleMin.system.client.main.uuid
          }
        }
      }
    }
    this.session.getRequest("sale:getTotalByClientAndStatus",saleMinAux).subscribe((data:any)=>{
      // console.log(data.object);
      this.metadata.totalCreditAvailable = parseFloat(this.object.system.creditType.main.maxAmount) - parseFloat(data.object.total);
      // console.log(data.object.total);
      //
      // console.log(this.metadata.totalCreditAvailable);
      this.getcreditPayment();
    },error=>{
      console.log("error:sale:getTotalByClientAndStatus",error);
    });
  }

  /**función para obtener el credito pagado por un cliente de ventas en credito.
  */

  getcreditPayment(){
    let creditPaymentMin = {
      system:{
        client:{
          main:{
            uuid:this.saleMin.system.client.main.uuid
          }
        },
        sale:{
          main:{
            status:'Credito'
          },
        }
      }
    };

    this.session.getRequest("creditPayment:findAllByClientAndSaleStatus",creditPaymentMin).subscribe((data:any)=>{
      console.log(data.object);
      this.metadata.totalCreditPay = 0;
      for(let item of data.object.instanceList){
        this.metadata.totalCreditPay = this.metadata.totalCreditPay + item.main.amount;
      }
      // console.log(this.metadata.totalCreditPay);
      // console.log(this.metadata.totalCreditAvailable);
      this.metadata.totalCreditAvailable = this.metadata.totalCreditAvailable + this.metadata.totalCreditPay ;
      this.metadata.saldo = parseFloat(this.object.system.creditType.main.maxAmount) - this.metadata.totalCreditAvailable;
    },error=>{
      console.log("error:creditPayment:findAllByClientAndSaleStatus",error);
    });
  }

  tabChanged(ev){
    this.saleMin.main.status = ev.tab.textLabel;
    this.getSaleFromClient();
    // console.log(this.clientCreditMin);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.saleMin.offset = (event.pageIndex * event.pageSize);
    this.saleMin.max = event.pageSize;
    this.getSaleFromClient();
  }

  /**función para imprimir una venta
  */
  printTicket(object){
    if(object.complement.paymentMethod == 'Credito'){
      this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
      this.session.getRequest("saleReport:creditTicket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
        this.loadingService.hide();
        printJS({printable: data.object, type: 'pdf', base64: true})
      },error=>{
        console.log(error);
        this.snackBar.open('No pudo imprimir el ticket', '', {
          duration: 1000
        });
        this.loadingService.hide();
      })
    }else{
      this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
      this.session.getRequest("saleReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
        this.loadingService.hide();
        printJS({printable: data.object, type: 'pdf', base64: true})
      },error=>{
        console.log(error);
        this.snackBar.open('No pudo imprimir el ticket', '', {
          duration: 1000
        });
        this.loadingService.hide();
      })
    }
  }

  /***función para realizar un abono a la venta
  */
  openAbono(object){
    this.dialog.open(SaleClientCreditAbonoComponent, {
      width: '60%',
      height: '80%',
      data:object
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.snackBar.open('Gracias por tu abono', '', {
            duration: 1000
          });
          this.getSaleFromClient();
          this.getTotalCreditUsed();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /***función para ver el listado de abonos de una venta
  */
  openAbonos(object){
    this.dialog.open(SaleClientCreditAbonosComponent, {
      width: '60%',
      height: '80%',
      data:object
    }).afterClosed().subscribe(result => {

    });
  }

  back(){
    this.location.back();
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
