import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-inventory-transfer-finish',
  templateUrl: './inventory-transfer-finish.component.html',
  styleUrls: ['./inventory-transfer-finish.component.css']
})
export class InventoryTransferFinishComponent implements OnInit {


    transferModel = {
      main: {
        uuid: '',
        dateTransfer:'',
        status:'Por validar' //'Por validar', 'Validado', 'Cancelado'
      },
      complement:{
        comment:''
      },
      system: {
        company:{
          main:{
            uuid:''
          }
        },
        warehouseDestination:{
          main:{
            uuid:''
          }
        },
        warehouseOrigin:{
          main:{
            uuid:''
          }
        },
        employee:{
          main:{
            uuid:''
          },
          system:{
            physicalPerson:{
              main:{
                name:'',
                lastname:'',
                surname:''
              }
            }
          }
        },
        products:[]
      },
    }
    @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
    object = JSON.parse(JSON.stringify(this.transferModel));
    transferProductsListFilter = [];
    metadata = {
      dateTransfer:'',
      isLinear:true,
      searching:false
    }
    constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    }

    ngOnInit() {
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){

        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('transfer:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateTransfer = moment(this.object.main.dateTransfer).format('DD-MM-YYYY HH:mm:ss');
          this.loadingService.hide();
          // console.log(this.object);
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:transfer:get',error)
        })
      }
    });
    }
    nextLoadTrasientDataByProduct(){
      for(let i=0; i <this.object.system.products.length; i++){
        this.getProductsAvailableByWharehouse(this.object.system.products[i].system.product,this.object.system.warehouseOrigin,i).then((data)=>{
          this.getTrasientDataFromInventory(this.object.system.products[i]);
          this.object.system.products[i].main.value = '';
        });
      }
    }
    /*
    Funcion para encontrar productos en inventario segun consulta de productos
    */
    getProductsAvailableByWharehouse(product,wharehouse,index){
      return new Promise((resolve=>{
        this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
          (data:any) => {
            this.object.system.products[index].main.uuid = data.object.main.uuid;
            resolve(data.object);
         },
         error => {
           console.log("warehouseProduct:findByProductByWarehouse",error);
           this.loadingService.hide();
         }
        );
      }))
    }
    //Funcion para obtener lo datos transitorios de productos por almacen y Cantidad
    getTrasientDataFromInventory(object){
    // console.log(object);
      this.session.getRequest("warehouseProduct:stockForWarehouseAndProductDetail",{main:{uuid:object.main.uuid,quantity:object.main.quantity}}).subscribe(
        (data:any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          object.system.products = data.object.splice(0,object.main.quantity);
       },
       error => {
         console.log("warehouseProduct:stockForWarehouseAndProductDetail",error);
         this.loadingService.hide();
       }
      );
    }

    productFindAllByNameLike(object){
      if(object.main.value != ''){
        this.transferProductsListFilter = [];
        this.metadata.searching = true;
        this.session.getRequest("warehouseProduct:searchWarehouseProductByTransientData",{main:{uuid:object.main.uuid,value:object.main.value}}).subscribe(
          (data:any) => {
            this.metadata.searching = false;
            // console.log(data);
            this.transferProductsListFilter = data.object;
            this.loadingService.hide();
         },
         error => {
           console.log("warehouseProduct:searchWarehouseProductByTransientData",error);
           this.loadingService.hide();
         }
        );
      }else{
        this.transferProductsListFilter = [];
      }
    }
    addProductToSelectedList(object,item){
      // console.log('oko');
      if(object.system.products.length < object.main.quantity){
        let found = false;
        for(let item2 of object.system.products){
          if(item2.main.uuid == item.main.uuid){
            found = true;
            break;
          }
        }
        if(!found){
          object.system.products.push(item);
        }else{
          this.snackBar.open('Este artículo ya se encuentra agregado', 'Aceptar', {
            duration: 8000
          });
        }
      }else{
        this.snackBar.open('El artículo no se puede agregar debido a que supera la cantidad seleccionada en el traspaso', 'Aceptar', {
          duration: 8000
        });
      }
      this.transferProductsListFilter = [];
    }

    // Funcion para quitar un producto de las lista de productos a enviar al ajuste
    deleteATransferProduct(object,item){
      for(let i=0; i < object.system.products.length; i++){
        if(item.main.uuid == object.system.products[i].main.uuid){
          object.system.products.splice(i,1);
        }
      }
    }

    getLength(object){
      let length = 0;
      if(object.system.products){
        length = object.system.products.length;
      }
      return length;
    }

    /*
    funcion para enviar a validado el traspaso
    */
    send(){
      this.object.main.status = 'Validado';
       this.object.main.dateTransfer = moment().format('DD-MM-YYYY HH:mm:ss');
       // console.log(this.object);
       this.loadingService.show(true,'Guardando el registro, espero un momento...')
      this.session.getRequest("transfer:validateTransfer", this.object).subscribe(
        (data:any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          this.snackBar.open('El regsitro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.router.navigate(['/admin/inventory/transfer']);
       },
       error => {
         console.log("transfer:validateTransfer",error);
         this.loadingService.hide();
       }
      );
    }


    /** Envia a guardar el ajuste de inventario **/
    sendAndStatus(object,status){
      object.main.status = status;
       this.object.main.dateTransfer=this.metadata.dateTransfer;
       this.loadingService.show(true,'Guardando el registro, espero un momento...')
      this.session.getRequest("transfer:update", object).subscribe(
        (data:any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          this.snackBar.open('El regsitro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.router.navigate(['/admin/inventory/transfer']);
       },
       error => {
         console.log("transfer:update",error);
         this.loadingService.hide();
       }
      );
    }
    //funcion para controlar si se abre o cierra el menu

    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }

}
