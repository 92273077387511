import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar , MatDialog} from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';
import { CompanyUpdateComponent } from './company-update/company-update.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  companyMin = {
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };

  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    // searchBoxInput: new Rx.Subject<string>,
    object:{
      main:{
        uuid:''
      }
    },
    type:['Branch','Company','CompanyClient','Customer'],
    objectType:'',
    company:'',
    branch:''
  }
  companyList = [];
  constructor(public dialog: MatDialog, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar ) {
  }

  ngOnInit() {
    this.getListCompany();
  }

  /*funcion para obtener el listado de las compañias de tng*/
  getListCompany(){
    this.session.getRequest("company:list",{}).subscribe((data:any)=>{
      // console.log("OKO",data);
      this.companyList = data.object.instanceList;
    },error=>{
      console.log("Error:company:list",error);
    })
  }

  /**
   * Modal para ingresar a update de company
   */
  companyUpdateDialog(uuid) {
    this.dialog.open(CompanyUpdateComponent, {
      width: '80%',
      height:'40rem',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getListCompany();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * funcion para obtener los datos del paginado.
   */
  onPaginateChange(event) {
    this.companyMin.offset = (event.pageIndex * event.pageSize);
    this.companyMin.max = event.pageSize;
    this.getListCompany();
  }
}
