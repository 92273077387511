import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
export interface menu {
    route: string
  }
@Injectable()
export class MenuService {
  object = {
    route:''
  };

  metadata = {
    typeMenu:''
  }

  menuChange: Subject<menu> = new Subject<menu>();
  constructor() {
    this.menuChange.next(this.object);
   }

   selected(value){
     this.object.route = value;
     this.menuChange.next(this.object);
     sessionStorage.setItem('router', value);
   }
   getSubMenuSelected(){
     return Observable.create( observer => {
       observer.next(this.object.route)
     });
   }
   clean(){
     this.object.route = '';
     this.menuChange.next(this.object);
   }

   /** función para setear el tipo de menu a mostrar
   */
   setMenuType(object:string):void {
     sessionStorage.setItem('menuType', object);
     this.metadata.typeMenu = object;
   }

   /** funcion para obtener el tipo de menu a mostrar
   */
   getMenuType():string{
     this.metadata.typeMenu = sessionStorage.getItem('menuType');
     return this.metadata.typeMenu;
   }

   /** funcion para determinar el tipo de menu a rederizar
   */
   findMyMenu(){
     let typeMenu = this.getMenuType();
     let array = [];
     switch(typeMenu){
       case 'admin':{
         array = [
           { route: '/admin/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
           { route: '/admin/inventory', title:'Inventario', icon:'dns', open:false, options:[
             {route:'/admin/inventory/dashboard', title:'Existencias', icon:'', open:false, options:[], selected:false},
             {route:'/admin/inventory/kardex', title:'Kardex', icon:'', open:false, options:[], selected:false},
             {route:'/admin/inventory/kardex/warehouse', title:'Kardex por Almacen', icon:'', open:false, options:[], selected:false},
             {route:'/admin/inventory/transferRequest', title:'Pedidos', icon:'', open:false, options:[], selected:false},
             {route:'/admin/inventory/adjustment', title:'Ajustes', icon:'', open:false, options:[], selected:false},
             {route:'/admin/inventory/transfer', title:'Traspasos', icon:'', open:false, options:[], selected:false},
           ], selected:true },
           { route: '/admin/purchase', title:'Compras', icon:'storefront', open:false, options:[
             {route:'/admin/purchase/generate', title:'Generar compra', icon:'', open:false, options:[], selected:false},
             {route:'/admin/purchase/generate/period', title:'Compras por periodo', icon:'', open:false, options:[], selected:false},

             // {route:'/admin/purchase/policies', title:'Politicas', icon:'', open:false, options:[], selected:false},
           ], selected:true },
           { route: '/admin/sale', title:'Ventas', icon:'storefront', open:false, options:[
             {route:'/admin/sale/dashboard', title:'Dashboard de ventas', icon:'', open:false, options:[], selected:false},
             {route:'/admin/sale/saleshift/dashboard', title:'Reporte de ventas', icon:'', open:false, options:[], selected:false},
             {route:'/admin/sale/client', title:'Clientes', icon:'', open:false, options:[], selected:false},
             {route:'/admin/sale/client/credit', title:'Creditos', icon:'', open:false, options:[],selected:false}
           ], selected:true },
           { route: '/admin/company', title:'Configuración', icon:'settings', open:false, options:[
             // {route:'/admin/company', title:'Compañias', icon:'', open:false, options:[], selected:false},
             {route:'/admin/branch', title:'Sucursales', icon:'', open:false, options:[], selected:false},
             {route:'/admin/warehouse', title:'Almacenes', icon:'', open:false, options:[], selected:false},
             {route:'/admin/product', title:'Productos', icon:'', open:false, options:[], selected:false},
             {route:'/admin/employee', title:'Empleados', icon:'', open:false, options:[], selected:false},
             {route:'/admin/adjustmenttype', title:'Tipo de ajuste', icon:'', open:false, options:[], selected:false},
             {route:'/admin/terminal', title:'Terminal Bancaria', icon:'', open:false, options:[], selected:false},
             {route:'/admin/catalog/sale/creditType', title:'Tipo de Creditos', icon:'', open:false, options:[], selected:false},
             {route:'/admin/catalog/sale/creditDiscountTable', title:'Descuentos al pagar un crédito', icon:'', open:false, options:[], selected:false},
           ], selected:false },
           { route: '/admin/setting', title:'Contabilidad', icon:'account_balance', open:false, options:[
             {route:'/admin/accounting/policy/list', title:'Polizas', icon:'', open:false, options:[], selected:false},
             {route:'/admin/accounting/typePolicy', title:'Tipos de Polizas', icon:'', open:false, options:[], selected:false},
             {route:'/admin/setting/account', title:'Cuentas Contables', icon:'', open:false, options:[], selected:false},
             {route:'/admin/setting/settingAccounting', title:'Configuración Contable', icon:'', open:false, options:[], selected:false},
             {route:'/admin/setting/settingRegistrationAccount', title:'Configuración de Polizas', icon:'', open:false, options:[], selected:false},

           ], selected:false },
           { route: '/admin/systemSettings', title:'Sistema', icon:'build_circle', open:false, options:[
             {route:'/admin/role/list', title:'Roles', icon:'', open:false, options:[], selected:false},
             {route:'/admin/user/list', title:'Usuarios', icon:'', open:false, options:[], selected:false},
           ], selected:false },
         ];
         break;
       }
       case 'role_inventario':
       array = [
         { route: '/admin/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
         { route: '/admin/inventory', title:'Inventario', icon:'dns', open:false, options:[
           {route:'/admin/inventory/dashboard', title:'Existencias', icon:'', open:false, options:[], selected:false},
           {route:'/admin/inventory/kardex', title:'Kardex', icon:'', open:false, options:[], selected:false},
           {route:'/admin/inventory/kardex/warehouse', title:'Kardex por Almacen', icon:'', open:false, options:[], selected:false},
           {route:'/admin/inventory/transferRequest', title:'Pedidos', icon:'', open:false, options:[], selected:false},
           {route:'/admin/inventory/adjustment', title:'Ajustes', icon:'', open:false, options:[], selected:false},
           // {route:'/admin/inventory/transfer', title:'Traspasos', icon:'', open:false, options:[], selected:false},
         ], selected:true },

         { route: '/admin/company', title:'Configuración', icon:'settings', open:false, options:[
           {route:'/admin/product', title:'Productos', icon:'', open:false, options:[], selected:false},
           {route:'/admin/employee', title:'Empleados', icon:'', open:false, options:[], selected:false},
           {route:'/admin/adjustmenttype', title:'Tipo de ajuste', icon:'', open:false, options:[], selected:false},
         ], selected:false },
       ];
       break;
       case 'punto_venta':
       array = [
         { route: '/counter/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
         { route: '/counter/start-shift/update/new', title:'Punto de Venta', icon:'storefront', open:false, options:[
           // {route:'/counter/start-shift', title:'Turnos', icon:'', open:false, options:[], selected:false},
         ], selected:false },
         // { route: '/counter/credit', title:'Créditos', icon:'home', open:false, options:[], selected:true },
         { route: '/counter/purchase', title:'Solicitudes', icon:'dns', open:false, options:[
           {route:'/counter/transfer/list', title:'Pedidos', icon:'', open:false, options:[], selected:false},
           {route:'/counter/credit-request', title:'Créditos', icon:'', open:false, options:[], selected:false},
         ], selected:true },
         { route: 'counter/traspaso', title:'Inventario', icon:'dns', open:false, options:[
           { route: '/counter/adjustment', title:'Ajustes', icon:'all_inbox', open:false, options:[], selected:true },
           {route:'counter/traspaso/list', title:'Traspasos', icon:'', open:false, options:[], selected:false},
           {route:'counter/traspasorecibido/list', title:'Traspasos Recibido', icon:'', open:false, options:[], selected:false},
         ], selected:true },
       ];
       break;
       case 'role_compras':
       array = [
         // { route: '/admin/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
         { route: '/admin/purchase', title:'Compras', icon:'storefront', open:false, options:[
           {route:'/admin/purchase/generate', title:'Generar compra', icon:'', open:false, options:[], selected:false},
           // {route:'/admin/purchase/policies', title:'Politicas', icon:'', open:false, options:[], selected:false},
         ], selected:true },
       ];
       break;
       case 'encargado_sucursal':
       array = [
         { route: '/counter/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
         { route: '/counter/start-shift/update/new', title:'Punto de Venta', icon:'storefront', open:false, options:[
         ], selected:false },
         {route:'/counter/transfer/request/list', title:'Pedidos Solicitados', icon:'dns', open:false, options:[], selected:false},
         {route:'/counter/credit-request', title:'Creditos', icon:'assignment', open:false, options:[], selected:false},
         { route: '/counter/purchase/generate', title:'Compras', icon:'storefront', open:false, options:[], selected:true },
         { route: '/counter/adjustment', title:'Ajustes', icon:'all_inbox', open:false, options:[], selected:true },
         {route:'/counter/transfer/list', title:'Enviar Pedido', icon:'dns', open:false, options:[], selected:false},
         { route: '/counter/settings', title:'Configuración', icon:'settings', open:false, options:[
           {route:'/counter/product', title:'Productos', icon:'', open:false, options:[], selected:false},
         ], selected:false },
         {route:'/admin/inventory/transfer', title:'Traspasos', icon:'', open:false, options:[], selected:false},
       ];

       break;
       case 'encargado_pedidos_sucursal':
         array = [
           { route: '/counter/dashboard', title:'Inicio', icon:'home', open:false, options:[], selected:true },
           { route: '/counter/start-shift/update/new', title:'Punto de Venta', icon:'storefront', open:false, options:[
           ], selected:false },
           // { route: '/admin/purchase/generate', title:'Compras', icon:'assignment', open:false, options:[], selected:true },
           // {route:'/', title:'Surtir pedidos', icon:'receipt', open:false, options:[], selected:false},
           // {route:'/counter/credit-request', title:'Solicitudes de creditos', icon:'calendar_view_day', open:false, options:[], selected:false},
           // { route: '/counter/adjustment', title:'Ajustes', icon:'all_inbox', open:false, options:[], selected:true },
         ];
       break;
       default:{
         // no hacemos nada
         array = []
         break;
       }
     }
     return array;
   }

}
