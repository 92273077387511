import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthenticationGuard } from './authentication.guard';
import { SessionService } from './session.service';
import { SessionAlertComponent } from './session-alert/session-alert.component';
export * from './session.service';
export * from './authentication.guard';


@NgModule({
  declarations: [SessionAlertComponent],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    SessionService,
    AuthenticationGuard,
  ],
  exports: [
    SessionAlertComponent,
  ]
})
export class SessionModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SessionModule,
    };
  }
 }
