import { Component, OnInit , ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { ProductMakerUpdateComponent } from './product-maker-update/product-maker-update.component';
import { SessionService } from '../../../../service/session/session.service';

@Component({
  selector: 'app-product-maker',
  templateUrl: './product-maker.component.html',
  styleUrls: ['./product-maker.component.css']
})
export class ProductMakerComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  makerList = [];
  makerMin = {
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  pageEvent: PageEvent;

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getMakerList();
  }

  // funcion para obtener la lista de sucursales
  getMakerList(){
    this.makerList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("maker:list",this.makerMin).subscribe(
      (data:any) => {
        this.makerList = data.object.instanceList.sort(function(a, b) {
          return a.main.code.localeCompare(b.main.code, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
        this.paginatorModel.total = data.object.total;
     },
     error => {
       console.log("Error: maker:list",error);
     }
    );
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
  /**
   * Modal para visualizar el detalle de lo consumido por el paciente
   */
  productMakerUpdateDialog(uuid) {
    this.dialog.open(ProductMakerUpdateComponent, {
      width: '60%',
      data: uuid,
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getMakerList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.makerMin.offset = (event.pageIndex * event.pageSize);
    this.makerMin.max = event.pageSize;
    this.getMakerList();
  }
}
