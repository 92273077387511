import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { SaleClientUpdateComponent } from './sale-client-update/sale-client-update.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-sale-client',
  templateUrl: './sale-client.component.html',
  styleUrls: ['./sale-client.component.css']
})
export class SaleClientComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  clientList = [];
  clientMin = {
    system:{
      status:true,
      company:{
        main:{
          uuid: ''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100,500,1000],
  };
  pageEvent: PageEvent;
  metadata = {
    global:true,
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  };
  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) {
    this.clientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.clientList = [];
        this.clientMin.filter.name = 'findAllByLikeName';
      }else{
        this.clientList = [];
        this.clientMin.filter.name = '';
        this.clientMin.filter.value = '';
        val = 'false'
      }
      this.getClientListByBranch();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });
   }

  ngOnInit() {
    this.getBranchList().then(()=>{
      this.clientMin.system.branch.main.uuid = this.branchList[0].main.uuid;
      this.getClientListByBranch();
    });
  }
  // funcion para obtener la lista de clientes
  getClientListByBranch (){
  this.loadingService.wait();
  if(this.metadata.global){
    this.clientList = [];
    // this.clientMin.system.company.main.uuid = this.session.getObject().main.uuid;
    this.clientMin.system.status = true;
    this.session.getRequest("client:findAllByCompanyAndStatus", this.clientMin).subscribe(
      (data:any) => {
        this.clientList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("client:findAllByCompanyAndStatus",error);
       this.loadingService.hide();
     }
    );
  }else{
    this.clientList = [];
    // this.clientMin.system.company.main.uuid = this.session.getObject().main.uuid;
    this.clientMin.system.status = true;
    this.session.getRequest("client:findAllByBranchAndStatus", this.clientMin).subscribe(
      (data:any) => {
        this.clientList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("client:findAllByBranchAndStatus",error);
       this.loadingService.hide();
     }
    );
  }

  }
  // funcion para obtener la lista de por sucursal

  // getClientList (){
  // this.loadingService.wait();
  //   this.clientList = [];
  //   // this.clientMin.system.company.main.uuid = this.session.getObject().main.uuid;
  //   this.clientMin.system.status = true;
  //   this.session.getRequest("client:findAllByCompanyAndStatus", this.clientMin).subscribe(
  //     (data:any) => {
  //       this.clientList = data.object.instanceList;
  //       this.paginatorModel.total = data.object.total;
  //       this.loadingService.hide();
  //    },
  //    error => {
  //      console.log("client:findAllByCompanyAndStatus",error);
  //      this.loadingService.hide();
  //    }
  //   );
  // }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.clientMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.clientMin.offset = (event.pageIndex * event.pageSize);
    this.clientMin.max = event.pageSize;
    this.getClientListByBranch();
  }

  /**
   * Modal para dar de alta un cliente
   */
  updateDialog(uuid) {
    this.dialog.open(SaleClientUpdateComponent, {
      width: '70%',
      height: '90%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getClientListByBranch();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  // Funcion para inactivar trabajador
  disable(object){
    this.loadingService.show(true,"Espere un momento..");
    this.session.getRequest('client:get',object).subscribe((data:any)=>{
      object = JSON.parse(JSON.stringify(data.object));
      if(object.system.typePerson == "PhysicalPerson"){
        object.system.person.complement.dateBirth = moment(object.system.person.complement.dateBirth).format("DD-MM-YYYY");
      }
      object.system.status = false;
      this.session.getRequest("client:update", object).subscribe(
        data => {
          this.snackBar.open('El trabajador se desactivó correctamente', '', {
            duration: 8000
          });
          for(let  i = 0; i < this.clientList.length; i++){
            if(this.clientList[i].main.uuid == object.main.uuid){
              this.clientList.splice(i,1);
            }
          }
          this.loadingService.hide();
       },
       error => {
         console.log("Error: client:update",error);
       }
      );
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error:client:get',error)
    })
  }

  // funcion para obtener la lista de sucursales
  getBranchList (){
    return new Promise((resolve,reject)=>{
      this.branchList = [];
      // verificamos en que pagina verificamos
      this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("branch:list",this.branchMin).subscribe(
        (data:any) => {
          this.branchList = data.object.instanceList;
          resolve(true);
       },
       error => {
         console.log("branch:list",error);
         reject(error);
       }
      );
    });

  }

  /**función para seleccionar un role
  */
  selectedBranch(object){
    console.log("object",object.tab.textLabel);
    for(let item of this.branchList){
      if(item.main.name == object.tab.textLabel){
        this.clientMin.system.branch.main = JSON.parse(JSON.stringify(item.main));
      }
    }
    this.getClientListByBranch();
  }

  changeGlobal(){
    this.clientMin.system.branch.main.uuid = this.branchList[0].main.uuid;
    this.getClientListByBranch();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
