import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';
import * as printJS from 'print-js';

@Component({
  selector: 'app-sale-saleshift-dasboard-details',
  templateUrl: './sale-saleshift-dasboard-details.component.html',
  styleUrls: ['./sale-saleshift-dasboard-details.component.css']
})
export class SaleSaleshiftDasboardDetailsComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  saleMin = {
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0
  };
  saleShift ={
    main:{
      uuid:'',
      shiftStartDate:'',
      shiftEndDate:'',
      shiftOpeningAmount:0,
      shiftClosingAmount:0,
      open:true
    },
    system:{
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:'',
          fullName:''
        }
      },
      currency:{
        main:{
          uuid:''
        }
      }
    }
  };
  saleList = [];
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100,500,1000],
  };
  pageEvent: PageEvent;
  paymentMethods = [
    {
      paymentMethod:'Efectivo',
      label:'Efectivo',
      total:0
    },{
      paymentMethod:'Tarjeta de débito',
      label:'Tarjeta de débito',
      total:0
    },{
      paymentMethod:'Tarjeta de crédito',
      label:'Tarjeta de crédito',
      total:0
    },{
      paymentMethod:'Cheque',
      label:'Cheque',
      total:0
    },{
      paymentMethod:'Transferencia electrónica de fondos',
      label:'Transferencia',
      total:0
    },{
      paymentMethod:'Credito',
      label:'Crédito',
      total:0
    }
  ];
  metadata = {
    alert:false,
    totalSistemaCierre:0,
    totalPartial:0,
    totalPaymenMethods:{
      paymentMethod:'Total',
      total:0
    }
  }

  partialCutMin = {
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      }
    }
  };
  partialCutList = [];
  blindSaleTurn = {
    main:{
      uuid:'',
      totalClosing:0,
      taxClosing:0,
      subTotalClosing:0,
      dateClosing:''
    },
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:'',
          fullName:''
        }
      }
    }
  };
  constructor(public session: SessionService,
    public snackBar: MatSnackBar,
    public loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<SaleSaleshiftDasboardDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
    this.loadingService.wait();
     this.saleShift = this.data;
     // cargamos los datos de la BD
     this.session.getRequest('saleShift:get',this.saleShift).subscribe(async(data:any)=>{
       this.saleShift = JSON.parse(JSON.stringify(data.object));
       this.getSaleList();
       // this.getBlind();
       for(let i = 0; i < this.paymentMethods.length; i++){
        await  this.getTotalByPaymentMethod(this.paymentMethods[i]);
       }
       this.getPartuialCut();
       this.loadingService.hide();
     },
     (error)=>{
       this.loadingService.hide();
       console.log('Error:saleShift:get',error)
     })
  }

  /**funcionalidad para obtener la ventas por turno
  */
  getSaleList(){
    this.session.getRequest('sale:findAllBySaleShift',{system:{saleShift:{main:{uuid:this.saleShift.main.uuid}}}}).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
    },error=>{
      console.log(error);
    })
  }

  /**función para obtener los detalles de uan venta
  */
  openDetails(object){
    this.loadingService.wait();
    this.session.getRequest("saleProduct:findAllBySale",{system:{sale:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
      object.system.products = data.object.instanceList;
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  /**función para obtener el total de ventas por forma de pago
  */
  getTotalByPaymentMethod(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("sale:getTotalBySaleShiftAndPaymentMethod",{complement: { paymentMethod: object.paymentMethod },system:{saleShift:{main:{uuid:this.saleShift.main.uuid}}}}).subscribe((data:any)=>{
        object.total = data.object.total;
        this.metadata.totalPaymenMethods.total = this.metadata.totalPaymenMethods.total + object.total;
        resolve(true);
      },error=>{
        console.log(error);
        reject(error);
      })
    })

  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.saleMin.offset = (event.pageIndex * event.pageSize);
    this.saleMin.max = event.pageSize;
    this.getSaleList();
  }

  /**funcionalidad para para obtener los cortes parciales de un turno.
  */
  getPartuialCut(){
    this.partialCutMin.system.saleShift.main.uuid = this.saleShift.main.uuid;
    this.session.getRequest("partialCut:findAllBySaleShift",this.partialCutMin).subscribe((data:any)=>{
      this.partialCutList = data.object.instanceList;
      this.metadata.totalPartial = 0;
      for(let item of this.partialCutList){
        this.metadata.totalPartial = this.metadata.totalPartial + item.main.amount;
      }
       this.metadata.totalSistemaCierre = this.metadata.totalPaymenMethods.total - this.metadata.totalPartial;
       if(this.metadata.totalSistemaCierre > this.saleShift.main.shiftClosingAmount){
         //warning
         this.metadata.alert = true
       }else{
         //verde
         this.metadata.alert = false
       }
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    });
  }

  /**función para imprimir una venta
  */
  printTicket(object){
    this.session.getRequest("saleReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      printJS({printable: data.object, type: 'pdf', base64: true})
    },error=>{
      console.log(error);
    })
  }

  /**funcionalidad para obtener el corte ciego de un turno
  */
  getBlind(){
    this.blindSaleTurn.system.saleShift.main.uuid = this.saleShift.main.uuid;
    this.session.getRequest("blindSaleTurn:findBySaleShift",this.blindSaleTurn).subscribe((data:any)=>{
      this.blindSaleTurn = data.object;
    },error=>{
      console.log(error);
      // this.loading.hide();
    });
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }


    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
      }else{
        object.transaction = 'bad';
        object.code = 'saleshift:001';
      }
      this.dialogRef.close(object);
    }

}
