import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDrawer } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-inventory-adjustment',
  templateUrl: './inventory-adjustment.component.html',
  styleUrls: ['./inventory-adjustment.component.css']
})
export class InventoryAdjustmentComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  adjustmentList = []
  productAdjustmentList = [];
  adjustmentMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };

  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {

    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.adjustmentList = [];
        this.adjustmentMin.filter.name = 'searchElement';
      }else{
        this.adjustmentList = [];
        this.adjustmentMin.filter.name = '';
        this.adjustmentMin.filter.value = '';
        val = 'false'
      }
      this.getAdjustmentList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.getAdjustmentList();
  }

  getAdjustmentList() {
    this.loadingService.wait();
    this.adjustmentList = [];
    this.adjustmentMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("adjustment:list", this.adjustmentMin).subscribe(
      (data: any) => {
        this.adjustmentList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("adjustment:list", error);
        this.loadingService.hide();
      }
    );
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.adjustmentMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.adjustmentMin.offset = (event.pageIndex * event.pageSize);
    this.adjustmentMin.max = event.pageSize;
    this.getAdjustmentList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
