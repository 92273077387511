import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../directive/loading/loading.service';
import { CounterExistenceComponent } from '../../counter-existence/counter-existence.component';
import { CounterShiftPaymentComponent } from '../counter-shift-payment/counter-shift-payment.component';
import { SaleTerminalUpdateComponent } from '../counter-terminal-update/sale-terminal-update.component';
import { CounterSaleshiftUpdateComponent } from '../counter-saleshift-update/counter-saleshift-update.component';
import { CounterSaleshiftCloseComponent } from '../counter-saleshift-close/counter-saleshift-close.component';
import { SaleClientUpdateComponent } from '../../../admin/sale/sale-client/sale-client-update/sale-client-update.component';
import { CounterPartialcutUpdateComponent } from '../counter-partialcut-update/counter-partialcut-update.component';
import { CounterBudgetListComponent } from '../counter-budget-list/counter-budget-list.component';
import { CounterProductUpdateComponent } from '../../counter-product/counter-product-update/counter-product-update.component';

import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import * as printJS from 'print-js';

@Component({
  selector: 'app-start-shift-update',
  templateUrl: './start-shift-update.component.html',
  styleUrls: ['./start-shift-update.component.css']
})
export class StartShiftUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  //Modelo de cotizaciones
  budgetModel = {
    main:{
      uuid:'',
      budgetNumber:'',
      subtotal:0,
      total:0,
      tax:0,
      expeditionDate:'',
      effectiveDate:''
    },
    complement:{
      nameClient:''
    },
    system:{
      budgetProducts:[],
      currency:{
        main:{
          uuid:''
        }
      },
      seller:{
        main:{
          uuid:''
        }
      },
      client:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }
  budgetProductModel = {
    main:{
      uuid:'',
      quantity:0,
      unitPrice:0,
      subtotal:0,
      total:0,
      tax:0,
      taxValue:0
    },
    system:{
      budget:{
        main:{
          uuid:''
        }
      },
      product:{
        main:{
          uuid:''
        }
      },
      status:false //false indica productos que no han salido del inventario, TRUE significa que todos los producto de ese tipo ya salieron del inventario
    }
  }
  budgetsProduct = [];
  budget = JSON.parse(JSON.stringify(this.budgetModel));
  //fin modelo de cotizaciones
  saleModel = {
    main:{
      uuid:'',
      saleNumber:'',
      typeDiscount:'',//Tasa,Monto
      discountValue:0,
      discountAmount:0,
      subtotal:'',
      total:'',
      tax:'',
      status:'Abierta',//' Abierta', 'Pagada', 'Credito', 'Cancelada',
      expeditionDate:'' //dd/MM/YYYY HH:mm:ss
    },
    complement:{
      description:'',
      nameClient:'',
      paymentMethod:'', //Método de pago: Efectivo, No especificado (Crédito), tarjeta de debito, cheque, tarjeta de crédito,
    },
    system:{
      saleShift:{
        main:{
          uuid:''
        }
      },
      user:{
        main:{
          username:''
        }
      },
      currency:{
        main:{
          uuid:''
        }
      },
      quotationSale:{
        main:{
          uuid:''
        }
      },
      seller:{
        main:{
          uuid:'' // uuid de employee
        }
      },
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      client:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };
  salesProductModel = {
    main:{
      uuid:'',
      quantity:0,
      unitPrice:0,
      subtotal:0,
      discountAmount:0,
      tax:0,
      taxValue:0,
      total:0,
    },
    system:{
      sale:{
        main:{
          uuid:''
        }
      },
      product:{
        main:{
          uuid:''
        }
      },
      tax:{
        main:{
          uuid:''
        }
      },
      saleDiscount:{
        main:{
          uuid:''
        }
      },
      warehouse:{
        main:{
          uuid:''
        }
      },
      status:false //false indica productos que no han salido del inventario, TRUE significa que todos los producto de ese tipo ya salieron del inventario
    }
  }
  //Modelo de producto para busqueda
  productMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter:{
      name:"",
      value:""
    },
    max: 10,
    offset: 0
  };
  //modelo del cliente
  clientMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'findAllByLikeName',
      value: ''
    }
  }

  currencyList = [];
  clientListFilter = [];
  productListFilter = [];
  salesProductList = [];
  taxList = [];
  metadata = {
    date:moment().format('DD/MM/YYYY'),
    client:{
      searchBoxInput: new Rx.Subject<string>(),
      searching:false,
      object:{
        main:{
          uuid:'',
          code:'',
          nickname:'',
          priceNumber:0
        }
      }
    },
    product:{
      searchBoxInput: new Rx.Subject<string>(),
      searching:false,
    },
    warehouse:{
      main:{
        uuid:''
      }
    },
    partialCutMin:{
      main:{
        uuid:''
      }
    },
    porcentaje:true,
    companyUuid:''
  };
  saleShift:any = {
    main:{
      uuid:''
    }
  };
  saleTerminal:any = {
    main:{
      uuid:''
    },
    system:{
      terminal:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.saleModel));
  constructor(public dialog: MatDialog,
    protected session: SessionService,
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingService:LoadingService
  ) {
    /*============== ============== ================*/
    this.metadata.client.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      if(val != ''){
        this.clientListFilter = [];
        this.clientMin.filter.name = 'findAllByLikeName';
      }else{
        this.clientListFilter = [];
        this.clientMin.filter.name = 'findAllByLikeName';
        this.clientMin.filter.value = '';
        val = 'false'
      }
      this.getClientList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.metadata.product.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      if(val != ''){
        this.productMin.filter.value  = val;
        this.productMin.filter.name = 'likeNameOrCode';
        this.productFindAllByNameLike(val);
        // this.trigger.openMenu();
      }else{
        // this.trigger.closeMenu();
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
   }

  ngOnInit() {
    this.metadata.warehouse = this.session.getWarehouse();
    this.loadingService.show(true,'Cargando Recursos...');
    this.getTaxes();
    this.getOpenShift().then(async(data:any)=>{
      // verificamos si hay un turno abierto
      if(data.main.uuid != null){
        //existe un turno entonces lo cargamos
        this.session.setSaleShift(data);
        let branch:any = this.session.getSessionObject();
        console.log(branch);
        this.metadata.companyUuid = branch.system.company.main.uuid;
        await this.newSale();
        this.loadingService.hide();
      }else{
        // no existe turno
        this.openNewShift();
        this.loadingService.hide();
      }

    }).catch(e=>{
      console.log(e);
    })
  }

  /**función para saber si un turno esta abierto
  */
  getOpenShift(){
    return new Promise((resolve,reject)=>{
      let aux = {
        main:{
          open:true
        },
        system:{
          branch:{
            main:{
              uuid:this.session.getSessionObject().main.uuid
            }
          }
        }
      }
      this.session.getRequest("saleShift:findByBranchAndStatus",aux).subscribe((data:any)=>{
        resolve(data.object);
      },error=>{
        console.log(error);
        reject(error);
      });
    })
  }

  /**función para abrir un nuevo turno
  */
  openNewShift(){
    this.dialog.open(CounterSaleshiftUpdateComponent, {
      width: '60%',
    }).afterClosed().subscribe(async(result) => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          await this.newSale();
          this.loadingService.hide();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
          this.router.navigate(['/counter/dashboard']);
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
        this.router.navigate(['/counter/dashboard']);
      }
    });
  }

  /**función para cargar los valores iniciales de una venta
  */
  newSale(){
    return new Promise((resolve,reject)=>{
      this.object = JSON.parse(JSON.stringify(this.saleModel));
      let branch:any = this.session.getSessionObject();
      this.saleShift = this.session.getSaleShift();
      this.object.system.branch.main = branch.main;
      this.object.system.company.main = branch.system.company.main;
      this.object.system.currency.main = this.saleShift.system.currency.main;
      this.object.system.seller.main = this.saleShift.system.employee.main;
      //cargamos el cliente de publico en general
      this.clientMin.filter.value = 'Publico en general';
      this.clientMin.system.company.main.uuid = branch.system.company.main.uuid;
      this.object.system.user.main.username = this.session.getUsername();
      this.object.system.saleShift.main.uuid = this.saleShift.main.uuid;
      this.session.getRequest("client:list", this.clientMin).subscribe(
        (data: any) => {
          for(let item of data.object.instanceList){
            if(item.main.nickname == this.clientMin.filter.value){
              this.object.system.client.main = item.main;
              this.metadata.client.object.main = item.main;
            }
          }
          resolve(true);
        },
        error => {
          console.log("client:list", error);
          reject(error);
        }
      );
    });
  }
  /**función para obtener el listado de tipo de cambio
  */
  getCurrencyList(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("currency:list",{}).subscribe((data:any)=>{
        this.currencyList = data.object.instanceList;
        for(let item of this.currencyList){
          if(item.main.abbreviation == 'MXN'){
            this.object.system.currency.main = item.main;
          }
        }
        resolve(true);
      },error=>{
        reject(error);
      })
    });
  }

  /* ================ Busqueda cliente =============*/
  // funcion para obtener la lista de clientes
  getClientList (){
    this.clientListFilter = [];
    this.metadata.client.searching = true;
    // Obtenemos los clientes
    let branch:any =  this.session.getSessionObject();
    this.clientMin.system.company.main.uuid = branch.system.company.main.uuid;
    this.session.getRequest("client:list", this.clientMin).subscribe(
      (data: any) => {
        for(let item of data.object.instanceList){
          this.clientListFilter.push(item);
        }
        this.metadata.client.searching = false;
      },
      error => {
        console.log("client:list", error);
        this.loadingService.hide();
      }
    );
  }

  /*
  *Funcion para buscar un cliente
  */
  searchClient(){
    if(this.clientMin.filter.value.length > 3)
    this.metadata.client.searchBoxInput.next(this.clientMin.filter.value);
    else
    this.clientListFilter = [];
  }

  clienteSelected(object) {
    for(let item of this.clientListFilter){
      if(item.main.nickname == object.option.value){
        this.metadata.client.object = JSON.parse(JSON.stringify(item));
        this.object.system.client.main = JSON.parse(JSON.stringify(item.main));
      }
    }
  }

  eraseClient(){
    this.metadata.client.object = JSON.parse(JSON.stringify({main:{uuid:''}}));
  }

  /* ==============================================*/
  /* ==============================================*/
  /* ================ Busqueda Producto =============*/
  /** Funcion para obtener el producto **/
  productFindAllByNameLike(name) {
    this.productListFilter = [];
    this.metadata.product.searching = true;
    let branch:any = this.session.getSessionObject();
    this.productMin.system.company.main.uuid = branch.system.company.main.uuid;
    this.session.getRequest('product:findAllByCompany',this.productMin).subscribe(
      (data:any)=>{
        this.productListFilter = data.object.instanceList;
        this.metadata.product.searching = false;
      },
      error => {
        console.log("product:findAllByCompany",error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
      })
    }

    searchProduct(){
      // console.log(this.productMin);
      if(this.productMin.filter){
        if(this.productMin.filter.value.length > 3)
        this.metadata.product.searchBoxInput.next(this.productMin.filter.value);
        else
        this.productListFilter = [];
      }else{
        this.productListFilter = [];
      }

    }

    productSeleted(object){
      this.getProductsAvailableByWharehouse(object,this.metadata.warehouse).then((data:any)=>{
        // console.log("getProductsAvailableByWharehouse::::::::",data);

        let warehouseProduct = data;
        if(warehouseProduct.main.uuid != null){
          if(warehouseProduct.main.quantity > 0){
            // creamos el modelo para crear  la relacion de producto con cotización.
            let saleProductItem = JSON.parse(JSON.stringify(this.salesProductModel));
            saleProductItem.main.quantity = 1;
            saleProductItem.metadata = {
              quantityWarehouse:warehouseProduct.main.quantity
            };
            saleProductItem.system.product.main = object.main;
            saleProductItem.system.product.complement = object.complement;
            saleProductItem.system.product.makerName = object.system.maker.main.name;
            saleProductItem.system.product.unitName = object.system.unitMeasure.main.name;
            saleProductItem.system.warehouse.main.uuid = this.metadata.warehouse.main.uuid;

            if(this.object.main.uuid != ''){
              saleProductItem.system.sale = this.object.main.uuid;
            }
            //agregamos impuesto de IVA 16
            for(let item of this.taxList){
              if(item.main.name == 'IVA 16'){
                saleProductItem.system.tax = JSON.parse(JSON.stringify(item));
                break;
              }
            }
            saleProductItem.prices = [];
            this.getPrices(saleProductItem,true).then(async(data)=>{
              try{
                await this.calculateItem(saleProductItem);
                this.salesProductList.push(saleProductItem);
                this.productListFilter = [];
                this.globalCalculate();
              }catch(e){
                console.log("error:Calculate y global",e);
              }
            });
          }else{
            // creamos el modelo para crear  la relacion de producto con cotización.
            let saleProductItem = JSON.parse(JSON.stringify(this.salesProductModel));
            saleProductItem.main.quantity = 1;
            saleProductItem.metadata = {
              quantityWarehouse:1
            };
            saleProductItem.system.product.main = object.main;
            saleProductItem.system.product.complement = object.complement;
            saleProductItem.system.product.makerName = object.system.maker.main.name;
            saleProductItem.system.product.unitName = object.system.unitMeasure.main.name;
            saleProductItem.system.warehouse.main.uuid = this.metadata.warehouse.main.uuid;

            if(this.object.main.uuid != ''){
              saleProductItem.system.sale = this.object.main.uuid;
            }
            //agregamos impuesto de IVA 16
            for(let item of this.taxList){
              if(item.main.name == 'IVA 16'){
                saleProductItem.system.tax = JSON.parse(JSON.stringify(item));
                break;
              }
            }
            saleProductItem.prices = [];
            this.getPrices(saleProductItem,true).then(async(data)=>{
              try{
                await this.calculateItem(saleProductItem);
                this.salesProductList.push(saleProductItem);
                this.productListFilter = [];
                this.globalCalculate();
              }catch(e){
                console.log("error:Calculate y global",e);
              }
            });
            // this.snackBar.open('NO tiene este producto en el inventario.', 'Aceptar', {
            //   duration: 3000
            // });
          }
        }else{
          // creamos el modelo para crear  la relacion de producto con cotización.
          let saleProductItem = JSON.parse(JSON.stringify(this.salesProductModel));
          saleProductItem.main.quantity = 1;
          saleProductItem.metadata = {
            quantityWarehouse:1
          };
          saleProductItem.system.product.main = object.main;
          saleProductItem.system.product.complement = object.complement;
          saleProductItem.system.warehouse.main.uuid = this.metadata.warehouse.main.uuid;
          saleProductItem.system.product.makerName = object.system.maker.main.name;
          saleProductItem.system.product.unitName = object.system.unitMeasure.main.name;

          if(this.object.main.uuid != ''){
            saleProductItem.system.sale = this.object.main.uuid;
          }
          //agregamos impuesto de IVA 16
          for(let item of this.taxList){
            if(item.main.name == 'IVA 16'){
              saleProductItem.system.tax = JSON.parse(JSON.stringify(item));
              break;
            }
          }
          saleProductItem.prices = [];
          this.getPrices(saleProductItem,true).then(async(data)=>{
            try{
              await this.calculateItem(saleProductItem);
              this.salesProductList.push(saleProductItem);
              this.productListFilter = [];
              this.globalCalculate();
            }catch(e){
              console.log("error:Calculate y global",e);
            }
          });
          // this.snackBar.open('NO tiene este producto en el inventario.', 'Aceptar', {
          //   duration: 8000
          // });
        }
        // console.log("Listado de articulos :::::",this.salesProductList);
      }).catch(e=>{
        console.log(e);
      })
    }
    /* Funcion para obtener los precios de un producto*/
    getPrices(object,status){
      return new Promise((resolve,reject):any=>{
        this.loadingService.show(true,'Cargando recursos, espere un momento...')
        this.session.getRequest('productPrice:findAllByProduct',{system:{product:{main:{uuid:object.system.product.main.uuid}}}}).subscribe((data:any)=>{
          object.prices = data.object.instanceList;
          //buscamos el precio ma salto y lo colocamos como el precio
          if(status){
            let found = false;
            if(object.prices.length > 0){
              object.main.unitPrice = object.prices[0].main.price;
              object.system.productPrice = JSON.parse(JSON.stringify(object.prices[0]));
              object.system.productPrice.main.direct = true;
            }
            for (let item of object.prices){
              if(item.main.name == this.metadata.client.object.main.priceNumber){
                object.main.unitPrice = item.main.price;
                object.system.productPrice = item;
                object.system.productPrice.main.direct = true;
                found = true;
              }
            }
            if(object.system.productPrice == undefined){
              object.system.productPrice = {
                main:{
                  uuid:'',
                  price:0,
                  direct:true, // true el precio se toma directo del input || false el precio se calcula segun formula
                  formula:'',
                  name:'',
                  tax:true
                },
                system:{
                  product:{
                    main:{
                      uuid:''
                    }
                  }
                }
              };
            }
          }
          this.loadingService.hide();
          resolve(true);
        },error=>{
          console.log("error:productPrice:findAllByProduct",error);
          this.loadingService.hide();
          reject(error);
        })
      })
    }

    productSeletedToClean(ev){
      this.productMin.filter.value = '';
    }
    /* =============================

    /** se obtiene el listado de impuestos
    */
    getTaxes() {
      let branch:any = this.session.getSessionObject();
      this.session.getRequest('tax:findAllByCompanyAndType', { main:{type:'Traslado'},system: { company: { main: { uuid: branch.system.company.main.uuid } }, status: true } }).subscribe((data: any) => {
        this.taxList = data.object.instanceList;
      }, (error) => {
        console.log('Error:tax:findAllByCompanyAndStatus', error)
      });
    }

    /**funcionalidad para determinar cuanto producto hay en el amacen
    */
    getProductsAvailableByWharehouse(product,wharehouse){
      return new Promise(((resolve,reject)=>{
        this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
          (data:any) => {
            resolve(data.object);
         },
         error => {
           console.log("warehouseProduct:findByProductByWarehouse",error);
           this.loadingService.hide();
           reject(error);
         }
        );
      }))
    }


    // funcion para calcular el total de un articulos
    calculateItem(object){
      return new Promise((resolve, reject)=>{
        object.main.subtotal = object.main.quantity * object.main.unitPrice;
        // verificamos si hay descuentos para este item
        if(object.system.saleDiscount.main.uuid != ''){
          if(object.system.saleDiscount.complement.type == 'Por porcentaje'){
            object.main.discountAmount = object.main.subtotal * (object.system.saleDiscount.main.amount/100);
          }else{
            object.main.discountAmount = object.system.saleDiscount.main.amount;
          }
        }
        // calculamos nuevamente el subtotal incluyendo el si hay descuento
        object.main.subtotal = (object.main.quantity * object.main.unitPrice);
        // verificamos si hay impuestos para asignar el valor de impuesto
        if(object.system.tax.main.uuid != ''){
          if(object.system.tax.main.calculation == 'Tasa'){
            // object.main.tax = (object.main.subtotal - object.main.discountAmount)* (object.system.tax.main.value/100);
            object.main.tax = 0;
          }else{
            object.main.tax = object.system.tax.main.value;
          }
        }
        object.main.total = parseFloat(object.main.subtotal)+parseFloat(object.main.tax) - parseFloat(object.main.discountAmount);
        resolve(true);
      })
    }
    /*
    funcion para realizar los calculos globales de una venta.
    */
    globalCalculate(){
      return new Promise((resolve,reject)=>{
        this.object.main.total = 0;
        this.object.main.subtotal = 0;
        this.object.main.tax = 0;
        // this.object.main.discountAmount = 0;
        for(let item of this.salesProductList){
          this.object.main.tax = this.object.main.tax + item.main.tax;
          // this.object.main.discountAmount = this.object.main.discountAmount + item.main.discountAmount;
          this.object.main.subtotal = this.object.main.subtotal + item.main.subtotal;
        }
        //calculamos el descuento
        if(this.metadata.porcentaje){
          this.object.main.typeDiscount = 'Tasa';
          this.object.main.discountAmount = (this.object.main.subtotal * this.object.main.discountValue)/100;
        }else{
          this.object.main.typeDiscount = 'Monto';
          this.object.main.discountValue = 0;
        }
        this.object.main.total = parseFloat(this.object.main.subtotal) + parseFloat(this.object.main.tax) - parseFloat(this.object.main.discountAmount);
        this.productMin.filter.value = '';
        resolve(true);
      })
    }

    /**funcion para cambiar la cantidad de un articulo
    */
    async changeQuantity(object){
      try{
        await this.calculateItem(object);
        await this.globalCalculate();
      }catch(e){
        console.log(e);
        this.snackBar.open('Hubo un error al calcular el total, verifica tu información, Gracias.', 'Aceptar', {
          duration: 7000
        });
      }
    }
    /**función para eliminar una producto
    */
    deleteAProduct(object){
      for(let i = 0; i < this.salesProductList.length; i++){
        if(this.salesProductList[i].system.product.main.uuid == object.system.product.main.uuid){
          this.salesProductList.splice(i,1);
          this.snackBar.open('El articulo de quito exitosamente', 'Aceptar', {
            duration: 1000
          });
        }
      }
    }
    /**función para abrir el modal de existencias
    */
    openExistence(){
      const dialogRef = this.dialog.open(CounterExistenceComponent, {
        width: '80%',
      });
    }

    /**función para agregar las forma de pago y realizar el pago
    */
    sendToPay(object){
      this.dialog.open(CounterShiftPaymentComponent, {
        width: '75%',
        height: '90%',
        data:{
          object: object
        }
      }).afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            // El modal se cerro con objeto
            this.object.complement.paymentMethod = result.object.paymentMethod;
            this.object.main.expeditionDate = moment().format('DD/MM/YYYY HH:mm:ss');
            this.object.main.status = 'Pagada';
            if(this.object.complement.paymentMethod == 'Credito'){
              this.object.main.status = 'Credito';
            }
            this.object.main.paymentReceived = result.object.totalPayment;
            this.object.main.changeGiven = result.object.totalChange;
            switch(this.object.complement.paymentMethod){
              case 'Credito':
              case 'Efectivo':{
                this.saveSale(this.object);
                break;
              }
              case 'Tarjeta de crédito':
              case 'Transferencia electrónica de fondos':
              case 'Tarjeta de débito':{
                this.getSaleTerminal().then(()=>{
                  this.openTerminal();
                }).catch((error)=>{
                  console.log("error:getSaleTerminal",error);
                });
                break;
              }
            }
          }else{
            // El modal se cerro sin objeto
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      });
    }

    /**función para enviar a pagar una ventas
    */
    saveSale(object){
      this.loadingService.show(true,"Guardando venta");
      this.object.complement.nameClient = this.metadata.client.object.main.nickname;
      this.object.system.client.main = this.metadata.client.object.main;
      this.object.system.salesProduct = this.salesProductList;
      this.session.getRequest("sale:update",this.object).subscribe((data:any)=>{
        this.object.main.uuid = data.object.main.uuid;
        // verificamos si debemos guadar una venta por terminal
        if(this.saleTerminal.system.terminal.main.uuid != ''){
          this.saleTerminal.system.sale.main.uuid = this.object.main.uuid;
          this.session.getRequest('saleTerminal:update',this.saleTerminal).subscribe((data:any)=>{
          },error=>{
            console.log("error",error);
          })
        }
        // fin facturación sacamos del inventario los productos
        this.applyMovementToSale(this.object);
      },error=>{
        console.log("sale:update",error);
        this.loadingService.hide();
      })
    }

    /** funcion para determinar so exixte una venta por terminal
    */
    getSaleTerminal(){
      return new Promise((resolve,reject):any=>{
        if(this.object.main.uuid !=''){
          this.session.getRequest("saleTerminal:findAllBySale",{system:{sale:{main:{uuid:this.object.main.uuid}}}}).subscribe((data:any)=>{
            if(data.object.instanceList.length >0){
              this.saleTerminal = data.object.instanceList[0];
            }
            resolve(true);
          },error=>{
            console.log("error",error);
            reject(error);
          })
        }else{
          resolve(true);
        }
      })
    }

    /**function to open modal terminal to load data
    */
    openTerminal(){
      this.dialog.open( SaleTerminalUpdateComponent, {
        width: '60%',
        data:this.saleTerminal
      }).afterClosed().subscribe(result => {
        if (result) {
          if(this.saleTerminal.main.uuid==''){
            this.saleTerminal = result;
          }
          this.saveSale(this.object);
        }
      });
    }
    /**funcionalida dpara realizar el movimiento en inventarios
    */
    applyMovementToSale(object){
      this.session.getRequest("sale:warehouseMovementByProduct",object).subscribe((data:any)=>{
        this.loadingService.hide();
        this.object.main.status = data.object.main.status;
        // this.router.navigate(['/counter/start-shift']);
        this.printTicket(this.object);
        this.resetSale();
        this.snackBar.open('La venta ha sido concluida con EXITO', 'Aceptar', {
          duration: 8000
        });

      },error=>{
        console.log("sales:warehouseMovementByProduct",error);
        this.loadingService.hide();
        this.snackBar.open('Hubo un error'+error.message, 'Error', {
          duration: 8000
        });
      })
    }

    /**funcionalidad para resetar una venta
    **/
    resetSale(){
      this.loadingService.show(true,"Resetando Recursos");
      this.object = JSON.parse(JSON.stringify(this.saleModel));
      this.salesProductList = [];
      let branch:any = this.session.getSessionObject();
      this.saleShift = this.session.getSaleShift();
      this.object.system.branch.main = branch.main;
      this.object.system.company.main = branch.system.company.main;
      this.object.system.currency.main = this.saleShift.system.currency.main;
      this.object.system.seller.main = this.saleShift.system.employee.main;
      //cargamos el cliente de publico en general
      this.clientMin.filter.value = 'Publico en general';
      this.clientMin.system.company.main.uuid = branch.system.company.main.uuid;
      this.object.system.user.main.username = this.session.getUsername();
      this.object.system.saleShift.main.uuid = this.saleShift.main.uuid;
      this.session.getRequest("client:list", this.clientMin).subscribe(
        (data: any) => {
          for(let item of data.object.instanceList){
            if(item.main.nickname == this.clientMin.filter.value){
              this.object.system.client.main = item.main;
              this.metadata.client.object.main = item.main;
            }
          }
          this.loadingService.hide();
        },
        error => {
          console.log("client:list", error);
          this.loadingService.hide();
        }
      );
    }

    /**función para cerrar un turno
    */
      closeTurn(){
        this.dialog.open(CounterSaleshiftCloseComponent, {
          width: '60%',
          data: { object: this.saleShift },
        }).afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.snackBar.open('Turno cerrado exitosamente', '', {
                duration: 1000
              });
              this.router.navigate(['/counter/sale/end']);
            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }


      /**
       * Modal para dar de alta un cliente
       */
      updateClient(uuid) {
        this.dialog.open(SaleClientUpdateComponent, {
          width: '70%',
          data: uuid
        }).afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.metadata.client.object = JSON.parse(JSON.stringify(result.object));
              this.clientMin.filter.value = this.metadata.client.object.main.nickname;
            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      /**función para imprimir una venta
      */
      printTicket(object){
        if(this.object.complement.paymentMethod == 'Credito'){
          this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
          this.session.getRequest("saleReport:creditTicket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
            this.loadingService.hide();
            printJS({printable: data.object, type: 'pdf', base64: true})
          },error=>{
            console.log(error);
            this.snackBar.open('No pudo imprimir el ticket', '', {
              duration: 1000
            });
            this.loadingService.hide();
          })
        }else{
          this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
          this.session.getRequest("saleReport:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
            this.loadingService.hide();
            printJS({printable: data.object, type: 'pdf', base64: true})
          },error=>{
            console.log(error);
            this.snackBar.open('No pudo imprimir el ticket', '', {
              duration: 1000
            });
            this.loadingService.hide();
          })
        }

      }


      /*********** Lo de aqui se puede quitar sin problemas cuando ya no se permitar editar precio desde punto de venta */
      async updatePrice(object){
        object.system.productPrice.system.product.main.uuid = object.system.product.main.uuid;
        object.main.unitPrice = await this.saveProductPrice(object.system.productPrice);
        object.main.subtotal = object.main.quantity * object.main.unitPrice;
        object.main.total = parseFloat(object.main.subtotal)+parseFloat(object.main.tax) - parseFloat(object.main.discountAmount);

        this.globalCalculate();
      }

      saveProductPrice(object){
        return new Promise((resolve,reject)=>{
          if(object.main.uuid == ''){
            object.main.name = 1;
          }
          object.main.formula = object.main.formula.replace(/\+/g,"#");
          this.session.getRequest('productPrice:update',object).subscribe((data:any)=>{
            object.main.uuid = data.object.main.uuid;
            this.snackBar.open('El registro se guardo correctamente', '', {
              duration: 8000
            });
            resolve(object.main.price);
          },error=>{
            console.log("Error:productPrice",error);
            this.snackBar.open('Verifique la infromación que se agrego', '', {
              duration: 8000
            });
            reject(error);
          })
        });
      }
      /*****************/



      /**función para crear un corte parcial
      */
      openPartialCut(){
        this.dialog.open(CounterPartialcutUpdateComponent, {
          width: '60%',
          data: { object: this.saleShift },
        }).afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              this.snackBar.open('Egreso realizado exitosamente', '', {
                duration: 1000
              });
              this.metadata.partialCutMin = result.object;
              this.printParticalCutTicket(result.object);

              // imprimos ticket
               let sB = this.snackBar.open('Egreso realizado exitosamente', 'Reimprimir Ticket', {
                duration: 60000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }).onAction().subscribe((data)=>{
                this.printParticalCutTicket(this.metadata.partialCutMin);
              });

            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      /**función para imprimir un ticket de corteParcial
      */
      printParticalCutTicket(object){
        this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
        this.session.getRequest("partialCut:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
          this.loadingService.hide();
          printJS({printable: data.object, type: 'pdf', base64: true})
        },error=>{
          console.log(error);
          // this.snackBar.open('No pudo imprimir el ticket', '', {
          //   duration: 1000
          // });
          this.loadingService.hide();
        })
      }

      /**funcionalidad para abrir la busqueda de cotizaciones
      */
      openQuotationList(){
        this.dialog.open(CounterBudgetListComponent , {
          width: '70%',
          height: '70%',
          data: {},
        }).afterClosed().subscribe(async(result) => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
              console.log(result.object);
              console.log(this.object);
              this.object.complement.nameClient = result.object.complement.nameClient;
              this.object.system.branch.main = result.object.system.branch.main;
              this.object.system.client.main = result.object.system.client.main;
              this.clientMin.filter.value = result.object.system.client.main.nickname;
              this.object.system.company.main = result.object.system.company.main;
              this.object.system.currency.main = result.object.system.currency.main;
              this.metadata.client.object = await this.getClient(this.metadata.client.object);
              // buscamos los productos de una cotización
              for(let item of result.object.system.budgetProducts){
                let saleProductItem = JSON.parse(JSON.stringify(this.salesProductModel));
                saleProductItem.metadata = {
                  quantityWarehouse:1
                };
                saleProductItem.main.quantity = item.main.quantity;
                saleProductItem.main.unitPrice = item.main.unitPrice;
                saleProductItem.system.product.main = item.system.product.main;
                saleProductItem.system.product.complement = item.system.product.complement;
                saleProductItem.system.product.makerName = item.system.product.system.maker.main.name;
                saleProductItem.system.product.unitName = item.system.product.system.unitMeasure.main.name;
                saleProductItem.system.warehouse.main.uuid = this.metadata.warehouse.main.uuid;
                try{
                  this.getPrices(saleProductItem,true).then(async(data)=>{
                    try{
                      await this.calculateItem(saleProductItem);
                      this.salesProductList.push(saleProductItem);
                      this.globalCalculate();
                    }catch(e){
                      console.log("error:Calculate y global",e);
                    }
                  });
                }catch(e){
                  console.log("error:cotizacion seleccionada",e);
                }
              }


            }else{
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }


      createBudget(){
        this.loadingService.show(true,"Guardando cotización");
        this.budget.complement.nameClient = this.metadata.client.object.main.nickname;
        let branch:any = this.session.getSessionObject();
        this.saleShift = this.session.getSaleShift();
        this.budget.system.branch.main = branch.main;
        this.budget.system.company.main = branch.system.company.main;
        this.budget.system.currency.main = this.saleShift.system.currency.main;
        this.budget.system.client.main = this.metadata.client.object.main;
        this.budget.system.seller.main = this.saleShift.system.employee.main;

        this.budget.main.subtotal = this.object.main.subtotal;
        this.budget.main.total = this.object.main.total;
        this.budget.main.tax = this.object.main.tax;
        this.budget.main.expeditionDate = moment().format('DD/MM/YYYY HH:mm:ss');
        this.budget.main.effectiveDate = moment().add(2,'days').format('DD/MM/YYYY HH:mm:ss');

        this.salesProductModel.main
        // actualizamos
        for(let item of this.salesProductList){
          let aux = JSON.parse(JSON.stringify(this.budgetProductModel));
          aux.system.product.main = item.system.product.main;
          aux.main.quantity = item.main.quantity;
          aux.main.unitPrice = item.main.unitPrice;
          aux.main.subtotal = item.main.subtotal;
          aux.main.tax = item.main.tax;
          aux.main.total = item.main.total;
          this.budgetsProduct.push(aux);
        }
        this.budget.system.budgetProducts = [];
        this.budget.system.budgetProducts = this.budgetsProduct;
        this.session.getRequest("budget:update",this.budget).subscribe((data:any)=>{
          this.snackBar.open("cotización creada exitosamente...",'',{duration:3000});
          // ==== limpiamos las variables de cotización======
          this.budget = JSON.parse(JSON.stringify(this.budgetModel));
          this.budgetsProduct = [];
          // ==== FIN limpiamos las variables de cotización======

          //impresion de ticket de cotización
          this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
          this.session.getRequest("saleReport:budgetTicket",{main:{uuid:data.object.main.uuid}}).subscribe((data:any)=>{
            this.loadingService.hide();
            printJS({printable: data.object, type: 'pdf', base64: true})
          },error=>{
            console.log(error);
            this.snackBar.open('No pudo imprimir el ticket', '', {
              duration: 1000
            });
            this.loadingService.hide();
          })
          //impresion de ticket de cotización
          this.resetSale();
        },error=>{
          console.log(error);
          this.loadingService.hide();

        })
      }

      viewCredit(){
        let clientCredit = {
          main:{
            uuid:'',
            dischargeDate:'',//dd/MM/yyyy hh:mm:ss’, fecha de alta
            acceptanceDate:'',// fecha de aceptación
            status:'Trámite',//Trámite, Valoración, Aprobado, Supendido, Cancelado
          },
          system:{
            company:{
              main:{
                uuid:''
              }
            },
            client:{
              main:{
                uuid:'',
                nickname:''
              }
            },
            creditType:{
              main:{
                uuid:'',
                maxAmount:0
              }
            }
          }
        }
        //verificamos si el cliente tiene credito
        clientCredit.system.client.main = this.metadata.client.object.main;
        this.loadingService.show(true,"Buscando crédito disponible.");
        this.session.getRequest("clientCredit:findByClient",clientCredit).subscribe(async(data:any)=>{
          clientCredit = data.object;
          if(clientCredit.main.uuid != null){
            this.router.navigate(['/admin/sale/client/credit/details/'+data.object.main.uuid])
          }else{
            this.snackBar.open('Lo siento, el cliente '+this.metadata.client.object.main.nickname+' NO TIENE CRÉDITO.','',{duration:3000});

          }

          this.loadingService.hide();
        },error=>{
          this.snackBar.open('Lo siento, el cliente '+this.metadata.client.object.main.nickname+' NO TIENE CRÉDITO.','',{duration:3000});
          console.log(error);
          this.loadingService.hide();
        });
      }


      /**
       * Modal para abrir product Update
       */
      productUpdateDialog(uuid) {
        this.dialog.open(CounterProductUpdateComponent, {
          width: '80%',
          height:'90%',
          data: uuid
        }).afterClosed().subscribe(result => {
          if (result != undefined) {
            if (result.transaction == 'ok') {
              // El modal se cerro con objeto
              this.productSeleted(result.object);
            } else {
              // El modal se cerro sin objeto
              this.snackBar.open('No se guardo ningun registro', '', {
                duration: 1000
              });
            }
          } else {
            // El modal se cerro sin seleccionar algo, dandole click fuera
            this.snackBar.open('No se guardo ningun registro', '', {
              duration: 1000
            });
          }
        });
      }

      getClient(object):Promise<any>{
        return new Promise((resolve,reject)=>{
          this.session.getRequest('client:get',{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
            object = JSON.parse(JSON.stringify(data.object));
            resolve(data.object);
          },error=>{
            console.log("Error:client:get",error);
            reject(error);
          })
        });
      }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
