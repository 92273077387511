import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.module';
import { LoadingService } from '../../../../../directive/loading/loading.module';

@Component({
  selector: 'app-catalog-sale-credit-discounttable-update',
  templateUrl: './catalog-sale-credit-discounttable-update.component.html',
  styleUrls: ['./catalog-sale-credit-discounttable-update.component.css']
})
export class CatalogSaleCreditDiscounttableUpdateComponent implements OnInit {
  creditDiscountTableModel = {
    main:{
      uuid:'',
      startRange:0,
      endRange:0,
      percentage:0
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };
  object = JSON.parse(JSON.stringify(this.creditDiscountTableModel));

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CatalogSaleCreditDiscounttableUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('creditDiscountTable:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:creditDiscountTable:get', error)
        })
    }
  }
  save(){
    this.loadingService.wait();
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("creditDiscountTable:update",this.object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.close(true);
    },error=>{
      this.loadingService.hide();
      console.log("error:",error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'creditDiscountTable:001';
    }
    this.dialogRef.close(object);
  }

}
