import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-sti-adjustment-product',
  templateUrl: './sti-adjustment-product.component.html',
  styleUrls: ['./sti-adjustment-product.component.css']
})
export class StiAdjustmentProductComponent implements OnInit {
  warehouseProductMin = {
    system: {
      warehouse: {
        main: {
          uuid: ''
        }
      }
    },
    max: 1000,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  adjustmentProductModel = {
    main: {
      uuid: '',
      quantity: ''
    },
    system: {
      product: {
        main: {
          uuid: '',
          code: '',
          name: ''
        },
        system: {
          transientDatas: []
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  adjustmentModel = {
    main: {
      uuid: '',
      dateAdjustment: '',
      status: 'Por validar',
      subtotal: 0,
      total: 0,
      discount: 0,
    },
    complement: {
      comment: 'Ajuste por directiva de existencias'
    },
    system: {
      warehouse: {
        main: {
          uuid: ''
        }
      },
      adjustmentType: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
      products: [],
      employee: {
        main: {
          uuid: ''
        }
      }
    },
  }
  inventoryAdjusmentMixed = {
    main: {
      dateAdjustment: '',
      status: 'Por validar',
    },
    complement: {
      comment: '',
    },
    system: {
      employee: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      warehouse: {
        main: {
          uuid: ''
        }
      },
      status: true,
      products: []
    }
  };
  inventoryAdjusmentMixedProduct = {
    main: {
      uuid: '',
      quantityAlm: 0,
      quantity: 0,
      quantityDiff: 0
    },
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      inventoryAdjusmentMixed: {
        main: {
          uuid: ''
        }
      }
    }
  }
  inventoryAdjusmentMixedProductList = [];
  object = JSON.parse(JSON.stringify(this.inventoryAdjusmentMixed));
  metadata = {
    dateAdjustment: '',
  }
  adjustmentList = [];
  adjustmentTypeList = [];
  @Input('employeeUuid') employeeUuid: string;
  @Input('companyUuid') companyUuid: string;
  @Input('warehouseUuid') warehouseUuid: string;
  @Input('product') product: any;
  @Input('stockQuantity') stockQuantity: any;

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAdjustmentType();
    this.metadata.dateAdjustment = moment().toISOString();
    this.warehouseProductMin.system.warehouse.main.uuid = this.warehouseUuid;
    this.object.system.warehouse.main.uuid =  this.warehouseUuid;
    this.object.system.employee.main.uuid = this.employeeUuid;
    this.object.system.company.main.uuid = this.companyUuid;
    this.getProductsAvailableByWharehouse(this.product.main.uuid,this.warehouseProductMin.system.warehouse.main.uuid);
  }

  /**funcion a calcular la diferencia de ajuste de inventario
  */
  calculateDiff(object) {
    object.main.quantityDiff = object.main.quantity - object.main.quantityAlm;
    // console.log(object.main.quantityDiff);
    this.stockQuantity = object.main.quantity;
    this.send();
  }

  /**funcionalidad para determinar cuanto producto hay en el amacen
  */
  getProductsAvailableByWharehouse(productuuid,wharehouseuuid){
    return new Promise(((resolve,reject)=>{
      this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:productuuid}},warehouse:{main:{uuid:wharehouseuuid}}}}).subscribe(
        (data:any) => {
          this.inventoryAdjusmentMixedProductList = [];
          if(data.object.main.uuid != null){
            if(data.object.main.quantity > 0){
              let aux = JSON.parse(JSON.stringify(this.inventoryAdjusmentMixedProduct));
              aux.main.quantityAlm = data.object.main.quantity;
              aux.system.product.main = data.object.system.product.main;
              aux.main.quantity = JSON.parse(JSON.stringify(aux.main.quantityAlm));
              this.inventoryAdjusmentMixedProductList.push(aux);
            }else{
              let aux = JSON.parse(JSON.stringify(this.inventoryAdjusmentMixedProduct));
              aux.main.quantityAlm = 0;
              aux.system.product.main = this.product.main;
              this.inventoryAdjusmentMixedProductList.push(aux);
            }
          }else{
            let aux = JSON.parse(JSON.stringify(this.inventoryAdjusmentMixedProduct));
            aux.main.quantityAlm = 0;
            aux.system.product.main = this.product.main;
            this.inventoryAdjusmentMixedProductList.push(aux);
          }
          console.log("this.inventoryAdjusmentMixedProduct", this.inventoryAdjusmentMixedProductList);
          resolve(data.object);
       },
       error => {
         console.log("warehouseProduct:findByProductByWarehouse",error);
         this.loadingService.hide();
         reject(error);
       }
      );
    }))
  }

  /**funcionalidad para determinar cuanto producto hay en el amacen
  */
  getProductsAvailableByWharehouseMin(productuuid,wharehouseuuid){
    return new Promise(((resolve,reject)=>{
      this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:productuuid}},warehouse:{main:{uuid:wharehouseuuid}}}}).subscribe(
        (data:any) => {
          resolve(data.object);
       },
       error => {
         console.log("warehouseProduct:findByProductByWarehouse",error);
         this.loadingService.hide();
         reject(error);
       }
      );
    }))
  }

  /**funcionalidad enviar a guardar el ajuste de inventarios
  */
  async send() {
    try {
      this.loadingService.show(true, "Espere un momento...");
      this.object.main.dateAdjustment = moment(this.metadata.dateAdjustment).format('DD-MM-YYYY');
      // console.log(this.object);
      // console.log(this.inventoryAdjusmentMixedProductList);
      this.adjustmentList = [];
      await this.initAsjustments();
      //Creamos el ajuste en inventario de cada uno
      for (let item of this.adjustmentList) {
        let adjustment: any = await this.saveAdjustment(item);
        //ya que se guardo el ajuste, procedemos a realizar el movieminto en inventario
        if (adjustment.system.adjustmentType.main.typeMovement == 'Entrada') {
          for (let i = 0; i < adjustment.system.products.length; i++) {
            adjustment.system.products[i].system.products = [];
            for (let j = 0; j < adjustment.system.products[i].main.quantity; j++) {
              adjustment.system.products[i].system.products.push({ main: { uuid: '' }, system: { transientDatas: [] } })
            };
          }
        }
        if (adjustment.system.adjustmentType.main.typeMovement == 'Salida') {
          let adjustmentAux = await this.nextLoadTrasientDataByProduct(adjustment);
          adjustment = JSON.parse(JSON.stringify(adjustmentAux));
        }
        await this.sendToINvetory(adjustment);
      }
      this.loadingService.hide();
      this.snackBar.open('Se realizó el ajuste exitosamente', 'Aceptar', {
        duration: 8000
      });
    } catch (e) {
      console.log(e);
      this.snackBar.open(e.message, 'Error', {
        duration: 8000
      });
      this.loadingService.hide();
    }

  }

  saveAdjustment(object) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("adjustment:update", object).subscribe((data: any) => {
        resolve(data.object);
      }, error => {
        console.log("error:adjustment:update", error);
        reject(error);
      })
    });
  }

  /**fucnionalidad para crear los dos ajustes necesarios entrada o salidad.
  */
  initAsjustments() {
    return new Promise((resolve, reject) => {
      //verificamos que tipo de ajuste se debe inicializar
      let outAdjustment = [];
      let inAdjustment = [];
      for (let item of this.inventoryAdjusmentMixedProductList) {
        if (item.main.quantityDiff > 0) {
          inAdjustment.push(item);
        }
        if (item.main.quantityDiff < 0) {
          outAdjustment.push(item);
        }
      }
      //creamoslos ajustes a realziar
      let adjustmentAux = JSON.parse(JSON.stringify(this.adjustmentModel));
      adjustmentAux.main.dateAdjustment = moment(this.metadata.dateAdjustment).format("DD-MM-YYYY HH:mm:ss");
      adjustmentAux.main.status = 'Validado';
      adjustmentAux.complement.comment = this.object.complement.comment;
      adjustmentAux.system.warehouse.main = this.object.system.warehouse.main;
      adjustmentAux.system.company.main = this.object.system.company.main;
      adjustmentAux.system.employee.main = this.object.system.employee.main;
      if (outAdjustment.length > 0) {
        for (let item of this.adjustmentTypeList) {
          if (item.main.description == 'AJUSTE DE PUNTO DE VENTA SALIDA') {
            adjustmentAux.system.adjustmentType.main = item.main;
          }
        }
      }
      //creamoos los adjustmentProductList de este ajuste
      for (let item of outAdjustment) {
        let aux = JSON.parse(JSON.stringify(this.adjustmentProductModel));
        aux.main.quantity = Math.abs(item.main.quantityDiff);
        aux.system.company.main = adjustmentAux.system.company.main;
        aux.system.product.main = item.system.product.main;
        adjustmentAux.system.products.push(aux);
      }
      //creamos el otro ajuste
      let adjustmentAuxIn = JSON.parse(JSON.stringify(adjustmentAux));
      adjustmentAuxIn.system.products = [];
      if (inAdjustment.length > 0) {
        for (let item of this.adjustmentTypeList) {
          if (item.main.description == 'AJUSTE INICIAL - INVENTARIO') {
            adjustmentAuxIn.system.adjustmentType.main = item.main;
          }
        }
      }
      for (let item of inAdjustment) {
        let aux = JSON.parse(JSON.stringify(this.adjustmentProductModel));
        aux.main.quantity = Math.abs(item.main.quantityDiff);
        aux.system.company.main = adjustmentAuxIn.system.company.main;
        aux.system.product.main = item.system.product.main;
        adjustmentAuxIn.system.products.push(aux);
      }
      if (adjustmentAux.system.products.length > 0) {
        this.adjustmentList.push(adjustmentAux);
      }
      if (adjustmentAuxIn.system.products.length > 0) {
        this.adjustmentList.push(adjustmentAuxIn);
      }
      // console.log("adjustmentList",this.adjustmentList);
      resolve(true);
    });
  }

  async nextLoadTrasientDataByProduct(object) {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < object.system.products.length; i++) {
        let resp: any = await this.getProductsAvailableByWharehouseMin(object.system.products[i].system.product.main.uuid, object.system.warehouse.main.uuid);
        object.system.products[i].main.uuid = resp.main.uuid;
        let resp2: any = await this.getTrasientDataFromInventory(object.system.products[i]);
        object.system.products[i].main.value = '';
        object.system.products[i].system.products = resp2.splice(0, object.system.products[i].main.quantity);
      }
      resolve(object);
    });
  }

  //Funcion para obtener lo datos transitorios de productos por almacen y Cantidad
  getTrasientDataFromInventory(object) {
    // console.log(object);
    return new Promise((resolve, reject) => {
      this.session.getRequest("warehouseProduct:stockForWarehouseAndProductDetail", { main: { uuid: object.main.uuid, quantity: object.main.quantity } }).subscribe(
        (data: any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          // object.system.products = data.object.splice(0,object.main.quantity);
          resolve(data.object)
        },
        error => {
          console.log("warehouseProduct:stockForWarehouseAndProductDetail", error);
          reject(error);
        }
      );
    })
  }

  /*
  funcion para enviar a validado y proceder con el ajuste de inventario
  */
  sendToINvetory(object) {
    return new Promise((resolve, reject) => {
      object.main.status = 'Validado';
      object.main.dateAdjustment = moment().format('DD-MM-YYYY HH:mm:ss');
      this.session.getRequest("adjustment:validateAdjustment", object).subscribe(
        (data: any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          resolve(true);
        },
        error => {
          console.log("adjustment:validateAdjustment", error);
          reject(error);
        }
      );
    });
  }

  /**función para obtener el tipo de ajustes
  */
  getAdjustmentType() {
    this.adjustmentTypeList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("adjustmentType:findAllByCompany", { system: { company: { main: { uuid: this.companyUuid } } }, max: 100, offset: 0 }).subscribe(
      (data: any) => {
        this.adjustmentTypeList = data.object.instanceList;
      },
      error => {
        console.log("Erro: adjustmentType:findAllByCompany", error);
      }
    );
  }

  /************Lo de aqui abajo se puede borrar sin problemas *************/

  /** Funcion para obtener las existencias de un alamacen
  */
  getStocks(object) {
    this.loadingService.wait();
    // verificamos en que pagina verificamos
    this.warehouseProductMin.system.warehouse.main.uuid = object.main.uuid;
    this.session.getRequest("warehouseProduct:findAllProductByWarehouse", this.warehouseProductMin).subscribe(
      (data: any) => {
        this.inventoryAdjusmentMixedProductList = [];
        for (let item of data.object.instanceList) {
          if(item.main.quantity > 0){
            let aux = JSON.parse(JSON.stringify(this.inventoryAdjusmentMixedProduct));
            aux.main.quantityAlm = item.main.quantity;
            aux.system.product.main = item.system.product.main;
            this.inventoryAdjusmentMixedProductList.push(aux);
          }
        }
        console.log("this.inventoryAdjusmentMixedProduct", this.inventoryAdjusmentMixedProductList);
        this.loadingService.hide();
      },
      error => {
        console.log("warehouseProduct:findAllProductByWarehouse", error);
        this.loadingService.hide();
      }
    );
  }



}
