import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-admin-accounting-period',
  templateUrl: './admin-accounting-period.component.html',
  styleUrls: ['./admin-accounting-period.component.css']
})
export class AdminAccountingPeriodComponent implements OnInit {

  accountingPeriodModel = {
    main:{
      uuid:'',
      year:''
    },
    system:{
      objectType: 'Company',
      objectTypeUuid: '',
      currency:{
        main:{
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.accountingPeriodModel));
  currencyList = [];
  constructor(public dialogRef: MatDialogRef<AdminAccountingPeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected session: SessionService,
    public snackBar: MatSnackBar) {
      this.object.system.objectType = 'Company';
      this.object.system.objectTypeUuid = data.company.main.uuid;
    }

  ngOnInit() {
    this.getCurrencyList();
  }

  getCurrencyList(){
    this.session.getRequest("currency:list",{}).subscribe((data:any)=>{
      this.currencyList = data.object.instanceList;
    },error=>{
      console.log("error:currency:list",error);
    })
  }

  send(){
    this.session.getRequest("accountingPeriod:update",this.object).subscribe((data:any)=>{
      console.log(data);
      this.closeDialog(data.object);
    },error=>{
      console.log("Error:accountingPeriod:update",error);
    })
  }

  /*
    funcion para cerrar el modal de incremento salarial.
  */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

}
