import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.module';
import { LoadingService } from '../../../../../directive/loading/loading.module';

@Component({
  selector: 'app-terminal-update',
  templateUrl: './terminal-update.component.html',
  styleUrls: ['./terminal-update.component.css']
})
export class TerminalUpdateComponent implements OnInit {
  terminalModel = {
    main:{
      uuid:'',
      terminalNumber:''
    },
    complement:{
      description:''
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      bank:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };
  bankList = [];
  branchList = [];
  bankMin = {
    max: 100,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  object = JSON.parse(JSON.stringify(this.terminalModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<TerminalUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.getBanks();
    this.getCompanyByBranch();
    if(this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('terminal:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:terminal:get', error)
        })
    }
  }

  /** Function to get a bank list
  */
  getBanks(){
    this.session.getRequest("bank:list",this.bankMin).subscribe((data:any)=>{
      this.bankList = data.object.instanceList;
    },error=>{
      console.log("error",error);
    })
  }
  /**get branch by company
  */
  getCompanyByBranch(){
    this.session.getRequest("branch:list",{system:{company:{main:{uuid:this.session.getSessionObject().main.uuid}}},status:true}).subscribe((data:any)=>{
      this.branchList = data.object.instanceList;
    },error=>{
      console.log("error",error);
    })
  }

  saveTerminal(){
    this.loadingService.wait();
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    // console.log(this.metadata);
    this.session.getRequest("terminal:update",this.object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.close(true);
    },error=>{
      this.loadingService.hide();
      console.log("error:",error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'terminal:001';
    }
    this.dialogRef.close(object);
  }

}
