import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../../../directive/loading/loading.service';
import * as moment from 'moment';


@Component({
  selector: 'app-product-provider-relation',
  templateUrl: './product-provider-relation.component.html',
  styleUrls: ['./product-provider-relation.component.css']
})
export class ProductProviderRelationComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  makerList = [];
  makerMin = {
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  productMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  provider = {
    main:{
      uuid:'',
      name:''
    },
    system:{
      typePerson:'',
      person:{
        complement:{
          rfc:''
        }
      }
    }
  }
  productProviderModel = {
    main: {
      code:'',
      maximumAssortmentQuantity: 1,
      minimumQuantityAssortment: 1,
      daysMaximumAssortmentTime: 1,
    },
    system: {
      provider: {
        main:{
          uuid:''
        }
      },
      product: {
        main:{
          uuid:''
        }
      }
    }
  }
  productProviderList = [];
  productProviderMin = {
    provider: {},
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    maker:{
      main:{
        uuid:''
      }
    }
  }
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;
  productList = [];
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private router: Router, public loadingService:LoadingService) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.productList = [];
          this.productMin.filter.name = 'likeNameOrCode';
          this.getProductList();
        } else {
          this.productList = [];
          this.productMin.filter.name = '';
          this.productMin.filter.value = '';
          val = 'false'
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != 'new') {
        this.provider.main.uuid = params['uuid'];
        this.session.getRequest("provider:get",this.provider).subscribe((data:any)=>{
          this.provider = data.object;
          this.getMakerList();
          this.getProductProviderList();
        },error=>{
          console.log("error,provider:get",error);
        })
      }
    });
  }

  /*funcion para obtener el listado de marcas*/
  getMakerList(){
    this.makerList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("maker:list",this.makerMin).subscribe(
      (data:any) => {
        this.makerList = data.object.instanceList;
     },
     error => {
       console.log("Error: maker:list",error);
     }
    );
  }

  /* funcion para obtener la lista de productos */
  getProductList() {
    this.loadingService.wait();
    this.productList = [];
    this.productMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("product:findAllByCompany", this.productMin).subscribe(
      (data: any) => {
        this.productList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("product:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }

  /**Función para crear un productProvider
  **/
  createProductProvider(object){
    let providerProductAux = JSON.parse(JSON.stringify(this.productProviderModel));
    providerProductAux.system.provider.main.uuid = this.provider.main.uuid;
    providerProductAux.system.product.main = object.main;
    providerProductAux.main.code = moment().format('DMYHHmmss');
    this.session.getRequest("productProvider:create", providerProductAux).subscribe(
      (data:any) => {
        this.productProviderList.push(data.object);
        this.productMin.filter.value = '';
     },
     error => {
       console.log("productProvider:create", error);
     }
   );
  }

  // funcion para obtener la lista de productos proveedor.
  getProductProviderList (){
    this.productProviderList = [];
    this.productProviderMin.provider = this.provider
    this.session.getRequest("productProvider:findAllByProvider", this.productProviderMin).subscribe(
      (data:any) => {
        this.productProviderList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
     },
     error => {
       console.log("productProvider:findAllByProvider",error);
     }
    );
  }

  updateProductProviderCurrentPrice(object) {
    this.session.getRequest("productSupplierCurrentPrice:create", object).subscribe(
      (data:any) => {
        this.snackBar.open('Se actualizo el precio vigente del producto correctamente', '', {
          duration: 8000
        });
      },
      error => {
        console.log("productSupplierCurrentPrice:create", error);
      }
    );
  }

  // Función para eliminar productos proveedor
  deleteProductProvider(object) {
    this.loadingService.wait();
    this.session.getRequest("productProvider:delete", object).subscribe(
      (data:any) => {
        this.loadingService.hide();
        this.snackBar.open('Se elimina el producto del proveedor', '', {
          duration: 8000
        });
        for (var _i = 0; _i < this.productProviderList.length; _i++) {
          if(object.system.product.main.uuid == this.productProviderList[_i].system.product.main.uuid){
            this.productProviderList.splice(_i,1);
            break;
          }
        }
     },
     error => {
       console.log("productProvider:delete", error);
       this.loadingService.hide();
     }
   );
  }

  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.productMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.productProviderMin.offset = (event.pageIndex * event.pageSize);
    this.productProviderMin.max = event.pageSize;
    this.getProductProviderList();
  }

  /**
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
