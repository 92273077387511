import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../service/session/session.service';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {
  metadata = {
    name:'',
    enabledSettingProduct: false,
    enabledIventory: false,
    toggleMenu: false,
    objects: [],
    companyClients:[],
    object: {
      main: {
        uuid: ''
      }
    },
  }
  @Output() menuToogle: EventEmitter<boolean> = new EventEmitter<boolean>();
  productSettings = [
    { route: '/admin/product', title: 'Productos', icon: 'scatter_plot', options: [], selected: true },
    { route: '/admin/category', title: 'Categorias', icon: 'drag_indicator', options: [], selected: false },
    { route: '/admin/provider', title: 'Proveedores', icon: 'square_foot', options: [], selected: false },
    { route: '/admin/maker', title: 'Marca', icon: 'museum', options: [], selected: false },
    { route: '/admin/unitMeasure', title: 'Medidas', icon: 'square_foot', options: [], selected: false },
  ];
  constructor(public snackBar: MatSnackBar, private router: Router, protected session: SessionService) { }

  ngOnInit() {
    this.metadata.name = this.session.getUser().main.fullName;
    // verificamos que tipo routa estamos paar el logueo
    console.log(sessionStorage.getItem('router'), " resultado:");
    if (sessionStorage.getItem('router') == '/admin/product') {
      this.metadata.enabledSettingProduct = true;
    } else {
      this.metadata.enabledSettingProduct = false;
    }
  }

  selected(object, menu) {
    switch (menu) {
      case 'productSettings':
        for (var x = 0; x < this.productSettings.length; x++) {
          this.productSettings[x].selected = false;
          if (object.route == this.productSettings[x].route) {
            this.productSettings[x].selected = true;
          }
        }
        break;
      case 'inventoryRouters':

        break;
      case 'zoneRouters':

        break;
    }
  }

  /**función para cerrar sesión
  */
  logout(){
    this.session.logout().then(()=>{
      this.router.navigate(['/login']);
      this.snackBar.open('Sesión terminada. Nos vemos pronto... ', '', {
        duration: 8000
      });
    })
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenu(){
    this.metadata.toggleMenu = !this.metadata.toggleMenu;
    this.menuToogle.emit(this.metadata.toggleMenu);
  }
}
