import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { LoadingService } from '../../directive/loading/loading.service';
import { SessionService } from '../../service/session/session.service';
import { CounterSaleshiftUpdateComponent } from './counter-saleshift-update/counter-saleshift-update.component';
import { Router } from '@angular/router';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-start-shift',
  templateUrl: './start-shift.component.html',
  styleUrls: ['./start-shift.component.css']
})
export class StartShiftComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  shiftList = [];
  shiftMin = {
    system:{
      branch:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100,500,1000],
  };
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(public dialog: MatDialog, public loadingService: LoadingService, protected session: SessionService, public snackBar: MatSnackBar, public router:Router) {
    this.shiftMin.system.branch.main.uuid = this.session.getSessionObject().main.uuid;
  }

  ngOnInit() {
    this.getShiftList ();
  }

  // funcion para obtener la lista de clientes
  getShiftList (){
  this.loadingService.wait();
    this.shiftList = [];
    // this.clientMin.system.company.main.uuid = this.session.getObject().main.uuid;
    this.session.getRequest("saleShift:findAllByBranch", this.shiftMin).subscribe(
      (data:any) => {
        this.shiftList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
     },
     error => {
       console.log("saleShift:findAllByBranch",error);
       this.loadingService.hide();
     }
    );
  }

  /**función para abrir un nuevo turno
  */
  openNewShift(){
    this.dialog.open(CounterSaleshiftUpdateComponent, {
      width: '60%',
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.router.navigate(['/counter/start-shift/update/new']);
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**función para abrir un turno
  */
  openSaleShift(object){
    if(object.main.open){
      this.session.setSaleShift(object);
      this.router.navigate(['/counter/start-shift/update/new']);
    }else{
      this.snackBar.open('El turno esta cerrado, no puede realizar mas opeaciones en este turno.', '', {
        duration: 3000
      });
    }
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.shiftMin.offset = (event.pageIndex * event.pageSize);
    this.shiftMin.max = event.pageSize;
    this.getShiftList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
