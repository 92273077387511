import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from './../../../directive/loading/loading.module';
import { SelectRegistrationAccountingComponent } from './select-registration-accounting/select-registration-accounting.component';

@Component({
  selector: 'app-admin-setting-registration-accounting',
  templateUrl: './admin-setting-registration-accounting.component.html',
  styleUrls: ['./admin-setting-registration-accounting.component.css']
})
export class AdminSettingRegistrationAccountingComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  registryAccountSettingsModel = {
    main:{
      uuid:'',
      catalog:''
    },
    system:{
      objectType: '',
      objectTypeUuid: '',
      accountingAccount:{
        main:{
          uuid:'',
          accountNumber: '',
          description: '',
          natureAccount: ''
        }
      },
      accountingAccountBefore:{
        main:{
          uuid:'',
          accountNumber: '',
          description: '',
          natureAccount: ''
        }
      }
    }
  }
  registryAccountSettingsList=[];
  metadata = {
    company: {
      main: {
        uuid: ''
      }
    },
    object :JSON.parse(JSON.stringify(this.registryAccountSettingsModel))
  };
  companyBossesList=[];
  registryAccountSettingsTotal = 0;
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.metadata.company.main = this.session.getSessionObject().main;
    this.getRegistrySettings(this.metadata.company.main.uuid);
  }

  /**
   * Funcion para obtener la lista de configuracion de registros
   */
  getRegistrySettings(uuid) {
    this.loadingService.wait();
    this.registryAccountSettingsList = [];
    this.metadata.object.system.accountingAccount.main.description = '';
    this.metadata.object.main.catalog = '';
    this.session.getRequest("registryAccountSettings:findAllByObjectTypeAndObjectTypeUuid",{system:{objectType: 'Company', objectTypeUuid:uuid}}).subscribe((data:any)=>{
      for(let i =0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].system.accountingAccountBefore = JSON.parse(JSON.stringify(data.object.instanceList[i].system.accountingAccount))
        this.registryAccountSettingsList.push(data.object.instanceList[i]);
      }
      this.registryAccountSettingsTotal = this.registryAccountSettingsList.length-1;
      this.loadingService.hide();
    },error=>{
      this.loadingService.hide();
      console.log("Error:registryAccountSettings:findAllByObjectTypeAndObjectTypeUuid",error);
    })
  }

  move(object, move) {
    this.loadingService.wait();
    this.session.getRequest("registryAccountSettings:moveOrder", {main: object.main, system: object.system, move: move}).subscribe((data:any)=>{
      this.getRegistrySettings(this.metadata.company.main.uuid);
      this.loadingService.hide();
    },error=>{
      this.loadingService.hide();
      console.log("Error:registryAccountSettings:moveOrder", error);
    });
  }


  /**
   * Abre el cuadro de dialogo para advertir que al eliminar la cuenta se eliminan sus hijos
   */
  openSelectAccountDialog(object, account) {
    const dialogRef = this.dialog.open(SelectRegistrationAccountingComponent, {
      width: '600px',
      height:'40rem',
      data: {accountingAccount: object, companyUuid: this.metadata.company.main.uuid}
    });

    dialogRef.afterClosed().subscribe(result => {
      // Actualizamos la cuenta seleccionada
      if (result) {
        if (account) {
          this.metadata.object.system.accountingAccount.main = result.main;
        }
      }
    });
  }

  /**
   * Función para agregar registros al listado de cuentas
   */
  sendSettingRegistrationAccount(object){
    let found = false;
    for(let item of this.registryAccountSettingsList){
      if(item.main.catalog == object.main.catalog && item.system.accountingAccount.main.uuid == object.system.accountingAccount.main.uuid){
        found = true;
      }
    }
    if (!found) {
      this.loadingService.wait();
      object.system.objectType = 'Company';
      object.system.objectTypeUuid = this.metadata.company.main.uuid;
      this.session.getRequest("registryAccountSettings:update", object).subscribe((data: any) => {
        object.main.uuid = data.object.main.uuid;
        object.system.accountingAccountBefore = JSON.parse(JSON.stringify(object.system.accountingAccount))
        this.registryAccountSettingsList.push(object);
        this.metadata.object = JSON.parse(JSON.stringify(this.registryAccountSettingsModel));
        this.loadingService.hide();
      }, error => {
        this.loadingService.hide();
        console.log("error:registryAccountSettings:update", error);
      })
    } else {
      this.snackBar.open('El registro ya fue creado.. verifica nuevamente', '', {
        duration: 8000
      });
    }
  }

  /**
   * Funcion para editar un registro
   */
  editARegistry(object){
    this.metadata.object = JSON.parse(JSON.stringify(object));
    for(var x=0; x<this.registryAccountSettingsList.length;x++){
      if(this.registryAccountSettingsList[x].system.accountingAccount.main.uuid == object.system.accountingAccount.main.uuid){
        this.registryAccountSettingsList.splice(x,1);
        break;
      }
    }
  }

  /** Función para eliminar un registro de configuración de póliza
  **/
  delete(object, index) {
    this.loadingService.wait();
    this.session.getRequest('registryAccountSettings:delete', object).subscribe((data:any)=>{
        this.registryAccountSettingsList.splice(index, 1);
        this.loadingService.hide();
        this.snackBar.open('Eliminado Exitoso', 'Información', {duration: 5000});
    },
    error => {
        console.log("registryAccountSettings:delete", error);
        this.snackBar.open('Error al eliminar', 'Error', {duration: 5000});
    });
  }

  /**
   * funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
