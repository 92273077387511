import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import { LoadingService } from '../../../../directive/loading/loading.service';
import { CatalogSaleCredittypeUpdateComponent } from './catalog-sale-credittype-update/catalog-sale-credittype-update.component';
import * as Rx from 'rxjs/Rx';
@Component({
  selector: 'app-catalog-sale-credittype',
  templateUrl: './catalog-sale-credittype.component.html',
  styleUrls: ['./catalog-sale-credittype.component.css']
})
export class CatalogSaleCredittypeComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  creditTypeList = [];
  creditTypeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) { }

  ngOnInit() {
    this.getCreditTypeList();
  }

  /**funciona para obetner el listado de tipos de creditos que hay habilitados en el sistema
  */
  getCreditTypeList(){
    this.creditTypeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.creditTypeList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("creditType:findAllByCompany", this.creditTypeMin).subscribe(
      (data: any) => {
        this.creditTypeList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("Erro: creditType:findAllByCompany", error);
      }
    );
  }

  /*
  *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.creditTypeMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.creditTypeMin.offset = (event.pageIndex * event.pageSize);
    this.creditTypeMin.max = event.pageSize;
    this.getCreditTypeList();
  }

  /**
   * Modal para dar de alta un nuevo tipo de credito
   */
  updateDialog(uuid) {
    this.dialog.open(CatalogSaleCredittypeUpdateComponent , {
      width: '60%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getCreditTypeList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
