import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { BranchUpdateComponent } from '../admin/catalogs/branch/branch-update/branch-update.component';
import { SessionService } from '../service/session/session.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  branchList = [];
  branchMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  warehouseList = [];
  productList = [];
  warehouseProductMin = {
    system:{
      warehouse:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.branchMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.getBranchList();
    this.getWarehouseList();
  }

  // funcion para obtener la lista de sucursales
  getBranchList (){
    this.branchList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("branch:list",this.branchMin).subscribe(
      (data:any) => {
        this.branchList = data.object.instanceList;
     },
     error => {
       console.log("branch:list",error);
     }
    );
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Modal para visualizar el detalle de lo consumido por el paciente
   */
   branchUpdateDialog(uuid) {
     this.dialog.open(BranchUpdateComponent, {
       width: '60%',
       height:'60rem',
       data: uuid
     }).afterClosed().subscribe(result => {
       if(result != undefined ){
         if(result.transaction == 'ok'){
           // El modal se cerro con objeto
           this.getBranchList();
         }else{
           // El modal se cerro sin objeto
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       }else{
         // El modal se cerro sin seleccionar algo, dandole click fuera
         this.snackBar.open('No se guardo ningun registro', '', {
           duration: 1000
         });
       }
     });
   }

   // Funcion para obtener la lista de almacenes
   getWarehouseList(){
     this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
       (data: any) => {
         this.warehouseList = data.object.instanceList;
         //cargamos el primer alamacen
         this.warehouseProductMin.system.warehouse.main = JSON.parse(JSON.stringify(this.warehouseList[0].main));
         this.selectedWareHouse();
       },
       error => {
         console.log("warehouse:findAllByCompanyAndStatus", error);
       });
   }
   /** funcion para seleccionar un alamacen
   */
   selectedWareHouse(){
     let aux = JSON.parse(JSON.stringify(this.warehouseProductMin.system.warehouse));
     this.getStocks(aux);
   }

   /** Funcion para obtener las existencias de un alamacen
   */
   getStocks(object){
     this.productList = [];
     // verificamos en que pagina verificamos
     this.warehouseProductMin.system.warehouse.main.uuid = object.main.uuid;
     this.session.getRequest("warehouseProduct:findAllProductByWarehouse", this.warehouseProductMin).subscribe(
       (data: any) => {
         this.productList = data.object.instanceList;
         // console.log("list", this.productList)
       },
       error => {
         console.log("warehouseProduct:findAllProductByWarehouse", error);
       }
     );
   }
}
