
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code: "api:login",
    endpoint: "api/login"
  }, {
    code: "session:create",
    endpoint: "validate/validate"
  },  {
    code: "state:list",
    endpoint: "state/list"
  },  {
    code: "maker:list",
    endpoint: "maker/list"
  },  {
    code: "maker:delete",
    endpoint: "maker/delete"
  },  {
    code: "maker:update",
    endpoint: "maker/update"
  },  {
    code: "maker:get",
    endpoint: "maker/get"
  },  {
    code: "maker:getAll",
    endpoint: "maker/getAll"
  },  {
    code: "unitMeasure:list",
    endpoint: "unitMeasure/list"
  },  {
    code: "unitMeasure:update",
    endpoint: "unitMeasure/update"
  },  {
    code: "unitMeasure:get",
    endpoint: "unitMeasure/get"
  },  {
    code: "unitMeasure:findAllByStatus",
    endpoint:"unitMeasure/findAllByStatus"
  },  {
    code: "characteristic:update",
    endpoint: "characteristic/update"
  },  {
    code: "characteristic:get",
    endpoint: "characteristic/get"
  },  {
    code: "characteristic:findAllByCompanyAndStatus",
    endpoint: "characteristic/findAllByCompanyAndStatus"
  },  {
    code: "characteristic:findAllByCompany",
    endpoint: "characteristic/listByCompany"
  },  {
    code: "productFamily:get",
    endpoint: "productFamily/get"
  },  {
    code: "productFamily:treeviewByCompanyAndStatus",
    endpoint: "productFamily/treeviewByCompanyAndStatus"
  },  {
    code: "productFamily:update",
    endpoint: "productFamily/update"
  },  {
    code: "kindproduct:findAllByCompany",
    endpoint: "kindProduct/listByCompany"
  },  {
    code: "kindproduct:get",
    endpoint: "kindProduct/get"
  },  {
    code: "kindproduct:update",
    endpoint: "kindProduct/update"
  },{
    code: "kindproduct:findAllByCompanyAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndStatus"
  },{
    code: "kindProduct:findAllByCompanyAndCompositionAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndCompositionAndStatus"
  }, {
    code: "kindProduct:findAllByCompanyAndCompatibilityAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndCompatibilityAndStatus"
  }, {
    code: "transientdata:update",
    endpoint: "transientData/update"
  }, {
    code: "transientdata:get",
    endpoint: "transientData/get"
  }, {
    code: "transientdata:findAllByCompany",
    endpoint: "transientData/listByCompany"
  }, {
    code: "transientdata:findAllByCompanyAndStatus",
    endpoint: "transientData/findAllByCompanyAndStatus"
  }, {
    code: "product:update",
    endpoint: "product/update"
  }, {
    code: "product:get",
    endpoint: "product/get"
  }, {
    code: "product:findAllByCompany",
    endpoint: "product/listByCompany"
  },{
    code: "productCharacteristic:update",
    endpoint: "productCharacteristic/update"
  },{
    code: "productCharacteristicValue:findAllByProduct",
    endpoint: "productCharacteristicValue/findAllByProduct"
  },{
    code: "productCharacteristicValue:update",
    endpoint: "productCharacteristicValue/update"
  },{
    code: "productCharacteristicValue:delete",
    endpoint: "productCharacteristicValue/delete"
  },{
    code: "productCharacteristic:delete",
    endpoint: "productCharacteristic/delete"
  },{
    code: "transientdata:findAllByCompanyAndStatus",
    endpoint: "transientdata/findAllByCompanyAndStatus"
  },{
    code: "productTransientData:update",
    endpoint: "productTransientData/create"
  }, {
    code: "productTransientData:findAllByProduct",
    endpoint: "productTransientData/listByProduct"
  }, {
    code: "productTransientData:delete",
    endpoint: "productTransientData/delete"
  }, {
    code: "productTransientDataValue:create",
    endpoint: "productTransientDataValue/create"
  }, {
    code: "productTransientDataValue:findAllByProduct",
    endpoint: "productTransientDataValue/listByProduct"
  }, {
    code: "productTransientDataValue:delete",
    endpoint: "productTransientDataValue/delete"
  }, {
    code: "productTransientDataValue:update",
    endpoint: "productTransientDataValue/update"
  }, {
    code: "productPrice:update",
    endpoint: "productPrice/update"
  }, {
    code: "productPrice:findAllByProduct",
    endpoint: "productPrice/findAllByProduct"
  }, {
    code: "productPrice:delete",
    endpoint: "productPrice/delete"
  },{
    code: "productPrice:importXlsx",
    endpoint: "productPrice/importXlsx"
  },{
    code: "productPrice:createToLayout",
    endpoint: "productPrice/createToLayout"
  },{
    code: "productImage:findAllByProductAndStatus",
    endpoint: "productImage/findAllByProductAndStatus"
  },{
    code: "productImage:update",
    endpoint: "productImage/update"
  },{
    code: "productImage:delete",
    endpoint: "productImage/delete"
  },{
    code: "productImage:get",
    endpoint: "productImage/get"
  },{
    code: "productImage:delete",
    endpoint: "productImage/delete"
  },{
    code: "company:list",
    endpoint: "company/list"
  },{
    code: "company:get",
    endpoint: "company/get"
  },{
    code: "company:update",
    endpoint: "company/update"
  },{
    code: "companyLogo:findAllByCompany",
    endpoint: "companyLogo/findAllByCompany"
  },{
    code: "companyLogo:update",
    endpoint: "companyLogo/update"
  },{
    code: "taxRegime:findAllByUse",
    endpoint: "taxRegime/findAllByUse"
  },{
    code: "branch:update",
    endpoint: "branch/update"
  },{
    code: "branch:list",
    endpoint: "branch/list"
  }, {
    code: "branch:get",
    endpoint: "branch/get"
  },{
    code: "branch:findAllByCompanyAndStatus",
    endpoint: "branch/findAllByCompanyAndStatus"
  },  {
    code: "state:findAllByStatus",
    endpoint: "state/findAllByStatus"
  },  {
    code: "settingType:findAllByStatus",
    endpoint: "settingType/findAllByStatus"
  },  {
    code: "town:findAllByStateAndStatus",
    endpoint: "town/findAllByStateAndStatus"
  },  {
    code: "locality:findAllByTownAndStatus",
    endpoint: "locality/findAllByTownAndStatus"
  },  {
    code: "setting:findAllByLocalityAndStatus",
    endpoint: "setting/findAllByLocalityAndStatus"
  },  {
    code: "state:updatet",
    endpoint: "state/update"
  },  {
    code: "town:update",
    endpoint: "town/update"
  },  {
    code: "locality:update",
    endpoint:"locality/update"
  },  {
    code: "setting:update",
    endpoint: "setting/update"
  },  {
    code: "taxRegime:findAllByUse",
    endpoint: "taxRegime/findAllByUse"
  },  {
    code: "setting:get",
    endpoint: "setting/get "
  },{
    code: "employee:list",
    endpoint: "employee/list"
  },{
    code: "employee:update",
    endpoint: "employee/update"
  },{
    code: "employee:get",
    endpoint: "employee/get"
  },{
    code: "user:list",
    endpoint: "user/list"
  },  {
    code: "user:get",
    endpoint: "user/get"
  },  {
    code: "user:update",
    endpoint: "user/update"
  },  {
    code: "user:findAllRoleByUser",
    endpoint: "user/findAllRoleByUser"
  },  {
    code:"role:findAllByObjectTypeAndObjectId",
    endpoint: "role/findAllByObjectTypeAndObjectId"
  },  {
    code: "validate:userObjects",
    endpoint: "validate/userObjects"
  },  {
    code: "branch:list",
    endpoint: "branch/list"
  },  {
    code: "user:deleteByRolAndObjects",
    endpoint: "user/deleteByRolAndObjects"
  },  {
    code: "user:updateUserObject",
    endpoint: "user/updateUserObject"
  },  {
    code: "warehouse:findAllByCompanyAndStatus",
    endpoint: "warehouse/findAllByCompanyAndstatus"
  },  {
    code: "product:findAllByCompany",
    endpoint: "product/findAllByCompany"
  },  {
    code: "warehouseProduct:findByProductByWarehouse",
    endpoint: "warehouseProduct/findByProductByWarehouse"
  },  {
    code: "productPrice:findAllByProduct",
    endpoint: "productPrice/findAllByProduct"
  },  {
    code: "warehouse:findAllByCompany",
    endpoint: "warehouse/findAllByCompany"
  },  {
    code: "warehouse:update",
    endpoint: "warehouse/update"
  },  {
    code: "adjustmentType:findAllByCompany",
    endpoint: "adjustmentType/list"
  },  {
    code: "adjustmentType:get",
    endpoint: "adjustmentType/get"
  },  {
    code: "adjustmentType:update",
    endpoint: "adjustmentType/update"
  },  {
    code: "warehouse:get",
    endpoint: "warehouse/get"
  },  {
    code: "employee:findAllByCompanyAndStatus",
    endpoint: "employee/findAllByCompanyAndStatus"
  },  {
    code: "branch:findAllByStatus",
    endpoint: "branch/findAllByStatus"
  },  {
    code: "warehouseManager:delete",
    endpoint: "warehouseManager/delete"
  },  {
    code: "warehouseManager:get",
    endpoint: "warehouseManager/get"
  },  {
    code: "warehouseManager:update",
    endpoint: "warehouseManager/update"
  },  {
    code: "warehouseManager:findAllByWarehouse",
    endpoint: "warehouseManager/findAllByWarehouse"
  },  {
    code: "adjustment:list",
    endpoint: "adjustment/list"
  },  {
    code: "adjustment:get",
    endpoint: "adjustment/get"
  },  {
    code: "adjustmentType:findAllByCompanyAndStatus",
    endpoint: "adjustmentType/findAllByCompanyAndStatus"
  },  {
    code: "productTransientData:listByProduct",
    endpoint: "productTransientData/listByProduct"
  },  {
    code: "adjustment:update",
    endpoint: "adjustment/update"
  },  {
    code: "adjustment:deleteAdjustmentProduct",
    endpoint: "adjustment/deleteAdjustmentProduct"
  },  {
    code: "dependentMethods:findEmployeeByUser",
    endpoint: "dependentMethods/findEmployeeByUser"
  },  {
    code: "adjustment:validateAdjustment",
    endpoint: "adjustment/validateAdjustment"
  },  {
    code: "warehouseProduct:stockForWarehouseAndProductDetail",
    endpoint: "warehouseProduct/stockForWarehouseAndProductDetail"
  },  {
    code: "warehouseProduct:searchWarehouseProductByTransientData",
    endpoint: "warehouseProduct/searchWarehouseProductByTransientData"
  },  {
    code: "warehouseProduct:findAllProductByWarehouse",
    endpoint: "warehouseProduct/findAllProductByWarehouse"
  },  {
    code: "dependentMethods:getCompanyRoot",
    endpoint: "dependentMethods/getCompanyRoot"
  },  {
    code: "product:findAllByKindProduct",
    endpoint: "product/findAllByKindProduct"
  },{
    code: "product:listByCompanyAndKindProducts",
    endpoint: "product/listByCompanyAndKindProducts"
  },  {
    code: "productComposition:create",
    endpoint: "productComposition/create"
  },  {
    code: "productComposition:update",
    endpoint: "productComposition/update"
  },  {
    code: "productComposition:listByProduct",
    endpoint: "productComposition/listByProduct"
  },  {
    code: "productComposition:delete",
    endpoint: "productComposition/delete"
  },  {
    code: "saleShift:listByBranchAndEmployeeAndShiftStatus",
    endpoint: "saleShift/listByBranchAndEmployeeAndShiftStatus"
  },  {
    code: "saleShift:update",
    endpoint: "saleShift/update"
  },{
    code: "saleShift:get",
    endpoint: "saleShift/get"
  },  {
    code: "productFamily:listByCompany",
    endpoint: "productFamily/listByCompany"
  },{
    code: "product:listByCompanyAndProductFamily",
    endpoint: "product/listByCompanyAndProductFamily"
  },  {
    code: "product:listByCompanyAndProductFamilyAndKindProduct",
    endpoint: "product/listByCompanyAndProductFamilyAndKindProduct"
  },  {
    code: "provider:list",
    endpoint: "provider/list"
  },{
    code: "client:list",
    endpoint: "client/list"
  },{
    code: "currency:list",
    endpoint: "currency/list"
  },  {
    code: "tax:findAllByCompanyAndType",
    endpoint: "tax/findAllByCompanyAndType"
  },  {
    code: "sales:update",
    endpoint: "sales/update"
  },  {
    code: "sale:findAllByBranchAndBetweenDate",
    endpoint: "sale/findAllByBranchAndBetweenDate"
  },  {
    code: "sale:warehouseMovementByProduct",
    endpoint: "sale/warehouseMovementByProduct"
  },  {
    code: "sales:downloadInvoiceReport",
    endpoint: "sales/downloadInvoiceReport"
  },{
    code: "warehouse:findAllByBranch",
    endpoint: "warehouse/findAllByBranch"
  },  {
    code: "saleShiftDetail:update",
    endpoint: "saleShiftDetail/update"
  },  {
    code: "partialCut:update",
    endpoint: "partialCut/update"
  },  {
    code: "provider:update",
    endpoint: "provider/update"
  },  {
    code: "provider:get",
    endpoint: "provider/get"
  },  {
    code: "productProvider:update",
    endpoint: "productProvider/update"
  },  {
    code: "productProvider:create",
    endpoint: "productProvider/create"
  },  {
    code: "productProvider:findAllByProvider",
    endpoint: "productProvider/listByProvider"
  },  {
    code: "productSupplierCurrentPrice:create",
    endpoint: "productSupplierCurrentPrice/create"
  },  {
    code: "productProvider:delete",
    endpoint: "productProvider/delete"
  },  {
    code: "purchasePolicies:listByCompany",
    endpoint: "purchasePolicies/listByCompany"
  },  {
    code: "purchasePolicies:update",
    endpoint: "purchasePolicies/update"
  },  {
    code: "purchaseOrder:listByCompany",
    endpoint: "purchaseOrder/listByCompany"
  },  {
    code: "department:findaAllByCompany",
    endpoint: "department/findaAllByCompany"
  },{
    code: "client:get",
    endpoint: "client/get"
  },{
    code: "client:update",
    endpoint: "client/update"
  },{
    code: "transfer:list",
    endpoint: "transfer/list"
  },{
    code: "transfer:get",
    endpoint: "transfer/get"
  },{
    code: "transfer:update",
    endpoint: "transfer/update"
  },{
    code: "warehouse:findAllByCompany",
    endpoint: "warehouse/findAllByCompany"
  },{
    code: "transfer:validateTransfer",
    endpoint: "transfer/validateTransfer"
  },  {
    code: "action:list",
    endpoint: "action/list"
  },  {
    code: "role:update",
    endpoint: "role/update"
  },  {
    code: "role:findAllByAction",
    endpoint: "role/findAllByAction"
  },  {
    code: "role:deleteRoleAction",
    endpoint: "role/deleteRoleAction"
  },{
    code: "action:update",
    endpoint: "action/update"
  },{
    code: "role:createRoleAction",
    endpoint: "role/createRoleAction"
  },{
    code: "role:refreshRoles",
    endpoint: "role/refreshRoles"
  },{
    code: "user:list",
    endpoint: "user/list"
  },{
    code: "transfer:findAllBywarehouseDestination",
    endpoint: "transfer/findAllBywarehouseDestination"
  },{
    code: "saleShift:findAllByBranch",
    endpoint: "saleShift/findAllByBranch"
  },{
    code: "transfer:findAllByWarehouseDestination",
    endpoint: "transfer/findAllByWarehouseDestination"
  },{
    code: "sale:update",
    endpoint: "sale/update"
  },{
    code: "terminal:listByCompany",
    endpoint: "terminal/listByCompany"
  },{
    code: "terminal:update",
    endpoint: "terminal/update"
  },{
    code: "terminal:get",
    endpoint: "terminal/get"
  },{
    code: "bank:list",
    endpoint: "bank/list"
  },{
    code: "terminal:findAllByBranchAndStatus",
    endpoint: "terminal/findAllByBranchAndStatus"
  },{
    code: "saleTerminal:update",
    endpoint: "saleTerminal/update"
  },{
    code: "nationalPurchase:deleteByProduct",
    endpoint: "nationalPurchase/deleteByProduct"
  },{
    code: "nationalPurchase:get",
    endpoint: "nationalPurchase/get"
  },{
    code: "nationalPurchase:listByCompany",
    endpoint: "nationalPurchase/listByCompany"
  },{
    code: "nationalPurchase:update",
    endpoint: "nationalPurchase/update"
  },{
    code: "dependentMethods:kardexByProduct",
    endpoint: "dependentMethods/kardexByProduct"
  },{
    code: "saleShift:findByBranchAndStatus",
    endpoint: "saleShift/findByBranchAndStatus"
  },{
    code: "blindSaleTurn:update",
    endpoint: "blindSaleTurn/update"
  },{
    code: "creditType:findAllByCompany",
    endpoint: "creditType/findAllByCompany"
  },{
    code: "creditType:get",
    endpoint: "creditType/get"
  },{
    code: "creditType:update",
    endpoint: "creditType/update"
  },{
    code: "creditDiscountTable:findAllByCompany",
    endpoint: "creditDiscountTable/findAllByCompany"
  },{
    code: "creditDiscountTable:get",
    endpoint: "creditDiscountTable/get"
  },{
    code: "creditDiscountTable:update",
    endpoint: "creditDiscountTable/update"
  },{
    code: "clientCredit:findAllByCreditType",
    endpoint: "clientCredit/findAllByCreditType"
  },{
    code: "clientCredit:findAllByCompanyAndStatus",
    endpoint: "clientCredit/findAllByCompanyAndStatus"
  },{
    code: "clientCredit:get",
    endpoint: "clientCredit/get"
  },{
    code: "creditType:findAllByCompanyAndStatus",
    endpoint: "creditType/findAllByCompanyAndStatus"
  },{
    code: "clientCredit:update",
    endpoint: "clientCredit/update"
  },{
    code: "creditRequest:list",
    endpoint: "creditRequest/list"
  },{
    code: "creditRequest:update",
    endpoint: "creditRequest/update"
  },{
    code: "clientCredit:get",
    endpoint: "clientCredit/get"
  },{
    code: "clientCredit:findByClient",
    endpoint: "clientCredit/findByClient"
  },{
    code: "sale:findAllByClientAndStatus",
    endpoint: "sale/findAllByClientAndStatus"
  },{
    code: "sale:getTotalByClientAndStatus",
    endpoint: "sale/getTotalByClientAndStatus"
  },{
    code: "saleProduct:findAllBySale",
    endpoint: "saleProduct/findAllBySale"
  },{
    code: "saleReport:ticket",
    endpoint: "saleReport/ticket"
  },{
    code: "saleShift:findAllByBranchAndShiftStartDateBetween",
    endpoint: "saleShift/findAllByBranchAndShiftStartDateBetween"
  },{
    code: "sale:findAllBySaleShift",
    endpoint: "sale/findAllBySaleShift"
  },{
    code: "adjustment:layoutImportAdjustment",
    endpoint: "adjustment/layoutImportAdjustment"
  },{
    code: "adjustment:importAdjustment",
    endpoint: "adjustment/importAdjustment"
  },{
    code: "transfer:deleteTransferProduct",
    endpoint: "transfer/deleteTransferProduct"
  },{
    code: "saleShiftSale:findAllBySaleShift",
    endpoint: "saleShiftSale/findAllBySaleShift"
  },{
    code: "sale:findAllBySaleShif",
    endpoint: "sale/findAllBySaleShif"
  },{
    code: "sale:getTotalBySaleShiftAndPaymentMethod",
    endpoint: "sale/getTotalBySaleShiftAndPaymentMethod"
  },{
    code: "adjustment:findAllByBranch",
    endpoint: "adjustment/findAllByBranch"
  },{
    code: "product:importProduct",
    endpoint: "product/importProduct"
  },{
    code: "product:createToReportErrors",
    endpoint: "product/createToReportErrors"
  },{
    code: "nationalPurchase:listByBranch",
    endpoint: "nationalPurchase/listByBranch"
  },{
    code: "creditPayment:update",
    endpoint: "creditPayment/update"
  },{
    code: "creditPayment:findAllBySale",
    endpoint: "creditPayment/findAllBySale"
  },{
    code: "transfer:findAllByWarehouseOrigin",
    endpoint: "transfer/findAllByWarehouseOrigin"
  },{
    code: "reportXlsxWarehouseProduct:findAllProductByWarehouse",
    endpoint: "reportXlsxWarehouseProduct/findAllProductByWarehouse"
  },{
    code: "client:findAllByCompanyAndStatus",
    endpoint: "client/findAllByCompanyAndStatus"
  },{
    code: "client:findAllByBranchAndStatus",
    endpoint: "client/findAllByBranchAndStatus"
  },{
    code: "nationalPurchase:findAllByCompanyAndPeriodBetween",
    endpoint: "nationalPurchase/findAllByCompanyAndPeriodBetween"
  },{
    code: "provider:findAllByCompanyAndStatus",
    endpoint: "provider/findAllByCompanyAndStatus"
  },{
    code: "partialCut:listBySaleShift",
    endpoint: "partialCut/listBySaleShift"
  },{
    code: "partialCut:findAllBySaleShift",
    endpoint: "partialCut/findAllBySaleShift"
  },{
    code: "partialCut:ticket",
    endpoint: "partialCutReport/ticket"
  },{
    code: "accountingAccount:generateAccountNumber",
    endpoint: "accountingAccount/generateAccountNumber"
  },{
    code: "accountingAccount:findAllByAccountingAccountIsNullAndTypeAccount",
    endpoint: "accountingAccount/findAllByAccountingAccountIsNullAndTypeAccount"
  },{
    code: "typeAccount:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "typeAccount/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code:"accountingConfiguration:findByObjectTypeAndObjectTypeUuid",
    endpoint: "accountingConfiguration/findByObjectTypeAndObjectTypeUuid"
  },{
    code: "accountingAccount:delete",
    endpoint: "accountingAccount/delete"
  },{
    code: "accountingAccount:update",
    endpoint: "accountingAccount/update"
  },{
    code: "accountingAccount:downloadReport",
    endpoint: "accountingAccount/downloadReport"
  },{
    code: "accountingConfiguration:findByObjectTypeAndObjectTypeUuid",
    endpoint: "accountingConfiguration/findByObjectTypeAndObjectTypeUuid"
  },{
    code: "accountingConfiguration:update",
    endpoint: "accountingConfiguration/update"
  },{
    code: "currency:list",
    endpoint: "currency/list"
  },{
    code: "accountingAccount:findAllByAccountingAccountIsNullAndTypeAccount",
    endpoint: "accountingAccount/findAllByAccountingAccountIsNullAndTypeAccount"
  },{
    code: "typeAccount:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "typeAccount/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "registryAccountSettings:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "registryAccountSettings/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "registryAccountSettings:moveOrder",
    endpoint: "registryAccountSettings/moveOrder"
  },{
    code: "registryAccountSettings:update",
    endpoint: "registryAccountSettings/update"
  },{
    code: "registryAccountSettings:delete",
    endpoint: "registryAccountSettings/delete"
  },{
    code: "typePolicy:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "typePolicy/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "typePolicy:get",
    endpoint: "typePolicy/get"
  },{
    code: "typePolicy:update",
    endpoint: "typePolicy/update"
  },{
    code: "policy:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "policy/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "generatePolicy:update",
    endpoint: "generatePolicy/update"
  },{
    code: "policy:delete",
    endpoint: "policy/delete"
  },{
    code: "documentPolicy:findAllByPolicy",
    endpoint: "documentPolicy/findAllByPolicy"
  },{
    code: "documentPolicy:update",
    endpoint: "documentPolicy/update"
  },{
    code: "documentPolicy:delete",
    endpoint: "documentPolicy/delete"
  },{
    code: "policy:get",
    endpoint: "policy/get"
  },{
    code: "typePolicy:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "typePolicy/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "policy:generatePolicyNumber",
    endpoint: "policy/generatePolicyNumber"
  },{
    code: "accountingAccountPolicy:delete",
    endpoint: "accountingAccountPolicy/delete"
  },{
    code: "accountingAccount:findAllByDescriptionLike",
    endpoint: "accountingAccount/findAllByDescriptionLike"
  },{
    code: "policy:update",
    endpoint: "policy/update"
  },{
    code: "accountingAccountPolicy:update",
    endpoint: "accountingAccountPolicy/update"
  },{
    code: "accountingPeriod:findAllByObjectTypeAndObjectTypeUuid",
    endpoint:"accountingPeriod/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "documentAccountingAccountPolicy:findAllByAccountingAccountPolicy",
    endpoint: "documentAccountingAccountPolicy/findAllByAccountingAccountPolicy"
  },{
    code: "documentAccountingAccountPolicy:delete",
    endpoint: "documentAccountingAccountPolicy/delete"
  },{
    code: "dependentMethods:updateDocumentAccountingAccountPolicy",
    endpoint: "dependentMethods/updateDocumentAccountingAccountPolicy"
  },{
    code: "payment:findAllByCompanyBossesAndPeriodAndMonthAndYear",
    endpoint: "payment/findAllByCompanyBossesAndPeriodAndMonthAndYear"
  },{
    code: "settlement:findAllByCompanyBossesAndMonthAndYear",
    endpoint: "settlement/findAllByCompanyBossesAndMonthAndYear"
  },{
    code: "paymentPeriod:findAllByStatus",
    endpoint: "paymentPeriod/findAllByStatus"
  },{
    code: "typePolicy:findAllByObjectTypeAndObjectTypeUuid",
    endpoint: "typePolicy/findAllByObjectTypeAndObjectTypeUuid"
  },{
    code: "accountingPeriod:update",
    endpoint: "accountingPeriod/update"
  },{
    code: "accountingAccount:findAllByAccountingAccount",
    endpoint: "accountingAccount/findAllByAccountingAccount"
  },{
    code: "policy:downloadPolicy",
    endpoint: "policy/downloadPolicy"
  }, {
    code: "dashboard:findAllByBranchAndPeriod",
    endpoint: "dashboard/findAllByBranchAndPeriod"
  }, {
    code: "dashboard:findAllByWebAndPeriod",
    endpoint: "dashboard/findAllByWebAndPeriod"
  }, {
    code: "dashboard:findAllByPaymentMethodAndPeriod",
    endpoint: "dashboard/findAllByPaymentMethodAndPeriod"
  }, {
    code: "dashboard:findAllByPaymentMethodAndBranchAndPeriod",
    endpoint: "dashboard/findAllByPaymentMethodAndBranchAndPeriod"
  }, {
    code: "dashboard:findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween",
    endpoint: "dashboard/findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween"
  }, {
    code: "dashboard:findAllByProductFamilyAndDateBetween",
    endpoint: "dashboard/findAllByProductFamilyAndDateBetween"
  },{
    code: "saleReport:creditTicket",
    endpoint: "saleReport/creditTicket"
  },{
    code: "dependentMethods:kardexByWarehouse",
    endpoint: "dependentMethods/kardexByWarehouse"
  },{
    code: "sale:productAllByBranchAndBetweenDate",
    endpoint: "sale/productAllByBranchAndBetweenDate"
  },{
    code: "budget:findAllByBranch",
    endpoint: "budget/findAllByBranch"
  },{
    code: "budget:listByClient",
    endpoint: "budget/listByClient"
  },{
    code: "budget:update",
    endpoint: "budget/update"
  },{
    code: "saleReport:budgetTicket",
    endpoint: "saleReport/budgetTicket"
  },{
    code: "budget:get",
    endpoint: "budget/get"
  },{
    code: "sale:get",
    endpoint: "sale/get"
  },{
    code: "blindSaleTurn:findBySaleShift",
    endpoint: "blindSaleTurn/findBySaleShift"
  },{
    code: "creditPayment:findAllByClient",
    endpoint: "creditPayment/findAllByClient"
  },{
    code: "sale:updateStatus",
    endpoint: "sale/updateStatus"
  },{
    code: "creditPayment:findAllByClientAndSaleStatus",
    endpoint: "creditPayment/findAllByClientAndSaleStatus"
  },{
    code: "creditPaymentReport:ticket",
    endpoint: "creditPaymentReport/ticket"
  }
];

export default urls;
