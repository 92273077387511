import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TreeModule } from 'angular-tree-component';

import { StiContactComponent } from './directive/sti-contact/sti-contact.component';
import { StiAddressComponent } from './directive/sti-address/sti-address.directive';
import { StiFileComponent } from './directive/sti-file/sti-file.directive';
import { SelectedObjectComponent } from './login/selected-object/selected-object.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { ChartsModule, ThemeService } from 'ng2-charts';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { BranchComponent } from './admin/catalogs/branch/branch.component';
import { BranchUpdateComponent } from './admin/catalogs/branch/branch-update/branch-update.component';
import { ProductComponent } from './admin/catalogs/product/product.component';
import { ProductUpdateComponent } from './admin/catalogs/product/product-update/product-update.component';
import { ProductImportComponent } from './admin/catalogs/product/product-import/product-import.component';
import { ProductPriceComponent } from './admin/catalogs/product/product-price/product-price.component';
import { ProductPriceImportComponent } from './admin/catalogs/product/product-price-import/product-price-import.component';
import { ProductCategoriesComponent } from './admin/catalogs/product/product-categories/product-categories.component';
import { ProductMakerComponent } from './admin/catalogs/product/product-maker/product-maker.component';
import { ProductMeasurementComponent } from './admin/catalogs/product/product-measurement/product-measurement.component';
import { ProductMeasurementUpdateComponent } from './admin/catalogs/product/product-measurement/product-measurement-update/product-measurement-update.component';
import { ProductMakerUpdateComponent } from './admin/catalogs/product/product-maker/product-maker-update/product-maker-update.component';
import { ProductCategoriesUpdateComponent } from './admin/catalogs/product/product-categories/product-categories-update/product-categories-update.component';
import { SettingProductsMinPriceComponent } from './admin/catalogs/product/setting-products-min-price/setting-products-min-price.component';
import { WarehouseComponent } from './admin/catalogs/warehouse/warehouse.component';
import { WarehouseUpdateComponent } from './admin/catalogs/warehouse/warehouse-update/warehouse-update.component';
import { EmployeeComponent } from './admin/catalogs/employee/employee.component';
import { EmployeeUpdateComponent } from './admin/catalogs/employee/employee-update/employee-update.component';
import { CompanyComponent } from './admin/catalogs/company/company.component';
import { CompanyUpdateComponent } from './admin/catalogs/company/company-update/company-update.component';
import { ProductProviderComponent } from './admin/catalogs/product/product-provider/product-provider.component';
import { ProductProviderUpdateComponent } from './admin/catalogs/product/product-provider/product-provider-update/product-provider-update.component';
import { AdjustmentTypeComponent } from './admin/catalogs/inventory/adjustment-type/adjustment-type.component';
import { AdjustmentTypeUpdateComponent } from './admin/catalogs/inventory/adjustment-type/adjustment-type-update/adjustment-type-update.component';
import { InventoryAdjustmentComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment.component';
import { InventoryAdjustmentUpdateComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-update/inventory-adjustment-update.component';
import { InventoryAdjustmentValidateComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-validate/inventory-adjustment-validate.component';
import { DialogAdjusmentIncomplete } from './admin/inventory/inventory-adjustment/inventory-adjustment-validate/inventory-adjustment-validate.component';
import { InventoryDashboardComponent } from './admin/inventory/inventory-dashboard/inventory-dashboard.component';
import { ProductProviderRelationComponent } from './admin/catalogs/product/product-provider/product-provider-relation/product-provider-relation.component';
import { SaleClientComponent } from './admin/sale/sale-client/sale-client.component';
import { SaleClientUpdateComponent } from './admin/sale/sale-client/sale-client-update/sale-client-update.component';
import { InventoryTransferComponent } from './admin/inventory/inventory-transfer/inventory-transfer.component';
import { InventoryTransferUpdateComponent } from './admin/inventory/inventory-transfer/inventory-transfer-update/inventory-transfer-update.component';
import { InventoryTransferFinishComponent } from './admin/inventory/inventory-transfer/inventory-transfer-finish/inventory-transfer-finish.component';
import { InventoryTransferDetailsComponent } from './admin/inventory/inventory-transfer/inventory-transfer-details/inventory-transfer-details.component';
import { InventoryTransferRequestComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request.component';
import { InventoryTransferRequestUpdateComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request-update/inventory-transfer-request-update.component';
import { InventoryTransferRequestDetailsComponent } from './admin/inventory/inventory-transfer-request/inventory-transfer-request-details/inventory-transfer-request-details.component';
import { AdminRoleComponent } from './admin/systemSettings/admin-role/admin-role.component';
import { AdminRoleUpdateComponent } from './admin/systemSettings/admin-role/admin-role-update/admin-role-update.component';
import { AdminUsersComponent } from './admin/systemSettings/admin-users/admin-users.component';
import { AdminUsersUpdateComponent } from './admin/systemSettings/admin-users/admin-users-update/admin-users-update.component';
import { AdminUsersAccessComponent } from './admin/systemSettings/admin-users/admin-users-access/admin-users-access.component';
import { CounterDashboardComponent } from './counter/counter-dashboard/counter-dashboard.component';
import { CounterTransferComponent } from './counter/counter-transfer/counter-transfer.component';
import { StartShiftComponent } from './counter/start-shift/start-shift.component';
import { StartShiftUpdateComponent } from './counter/start-shift/start-shift-update/start-shift-update.component';
import { CounterTransferDetailsComponent } from './counter/counter-transfer/counter-transfer-details/counter-transfer-details.component';
import { CounterTransferReceiveComponent } from './counter/counter-transfer/counter-transfer-receive/counter-transfer-receive.component';
import { CounterTransferUpdateComponent } from './counter/counter-transfer/counter-transfer-update/counter-transfer-update.component';
import { CounterExistenceComponent } from './counter/counter-existence/counter-existence.component';
import { CounterShiftPaymentComponent } from './counter/start-shift/counter-shift-payment/counter-shift-payment.component';
import { CounterSaleshiftUpdateComponent } from './counter/start-shift/counter-saleshift-update/counter-saleshift-update.component';
import { TerminalComponent } from './admin/catalogs/sale/terminal/terminal.component';
import { SaleTerminalUpdateComponent } from './counter/start-shift/counter-terminal-update/sale-terminal-update.component';
import { TerminalUpdateComponent } from './admin/catalogs/sale/terminal/terminal-update/terminal-update.component';
import { PurchaseGenerateComponent } from './admin/purchase/purchase-generate/purchase-generate.component';
import { PurchaseGenerateUpdateComponent } from './admin/purchase/purchase-generate/purchase-generate-update/purchase-generate-update.component';
import { InventoryKardexComponent } from './admin/inventory/inventory-kardex/inventory-kardex.component';
import { SaleClientCreditComponent } from './admin/sale/sale-client-credit/sale-client-credit.component';
import { SaleClientCreditDetailsComponent } from './admin/sale/sale-client-credit/sale-client-credit-details/sale-client-credit-details.component';
import { SaleClientCreditUpdateComponent } from './admin/sale/sale-client-credit/sale-client-credit-update/sale-client-credit-update.component';
import { CounterSaleshiftCloseComponent } from './counter/start-shift/counter-saleshift-close/counter-saleshift-close.component';
import { CounterSaleEndByeComponent } from './counter/start-shift/counter-sale-end-bye/counter-sale-end-bye.component';
import { CatalogSaleCredittypeComponent } from './admin/catalogs/sale/catalog-sale-credittype/catalog-sale-credittype.component';
import { CatalogSaleCredittypeUpdateComponent } from './admin/catalogs/sale/catalog-sale-credittype/catalog-sale-credittype-update/catalog-sale-credittype-update.component';
import { CatalogSaleCreditDiscounttableComponent } from './admin/catalogs/sale/catalog-sale-credit-discounttable/catalog-sale-credit-discounttable.component';
import { CatalogSaleCreditDiscounttableUpdateComponent } from './admin/catalogs/sale/catalog-sale-credit-discounttable/catalog-sale-credit-discounttable-update/catalog-sale-credit-discounttable-update.component';
import { SaleRequestClientCreditComponent } from './admin/sale/sale-request-client-credit/sale-request-client-credit.component';
import { CounterCreditRequestComponent } from './counter/counter-credit-request/counter-credit-request.component';
import { CounterCreditRequestUpdateComponent } from './counter/counter-credit-request/counter-credit-request-update/counter-credit-request-update.component';
import { SaleSaleshiftDasboardComponent } from './admin/sale/sale-saleshift-dasboard/sale-saleshift-dasboard.component';
import { SaleSaleshiftDasboardDetailsComponent } from './admin/sale/sale-saleshift-dasboard/sale-saleshift-dasboard-details/sale-saleshift-dasboard-details.component';
import { CounterCreditClientComponent } from './counter/counter-credit-client/counter-credit-client.component';
import { CounterHistoricalCreditComponent } from './counter/counter-credit-client/counter-historical-credit/counter-historical-credit.component';
import { InventoryAdjustmentImportComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-import/inventory-adjustment-import.component';
import { PurchaseGenerateDetailsComponent } from './admin/purchase/purchase-generate/purchase-generate-details/purchase-generate-details.component';
import { CounterProductComponent } from './counter/counter-product/counter-product.component';
import { CounterProductUpdateComponent } from './counter/counter-product/counter-product-update/counter-product-update.component';
import { CounterAdjustmentComponent } from './counter/counter-adjustment/counter-adjustment.component';
import { CounterAdjustmentValidateComponent } from './counter/counter-adjustment/counter-adjustment-validate/counter-adjustment-validate.component';
import { CounterAdjustmentUpdateComponent } from './counter/counter-adjustment/counter-adjustment-update/counter-adjustment-update.component';
import { CounterPurchaseGenerateComponent } from './counter/counter-purchase-generate/counter-purchase-generate.component';
import { SaleClientCreditAbonoComponent } from './admin/sale/sale-client-credit/sale-client-credit-abono/sale-client-credit-abono.component';
import { SaleClientCreditAbonosComponent } from './admin/sale/sale-client-credit/sale-client-credit-abonos/sale-client-credit-abonos.component';
import { CounterTransferRequestComponent } from './counter/counter-transfer-request/counter-transfer-request.component';
import { PurchaseGeneratePeriodComponent } from './admin/purchase/purchase-generate/purchase-generate-period/purchase-generate-period.component';
import { CounterPartialcutUpdateComponent } from './counter/start-shift/counter-partialcut-update/counter-partialcut-update.component';
import { AdminAccountComponent } from './admin/systemSettings/admin-account/admin-account.component';
import { AdminAccountDeleteComponent } from './admin/systemSettings/admin-account/admin-account-delete/admin-account-delete.component';
import { AdminAccountTreeComponent } from './admin/systemSettings/admin-account/admin-account-tree/admin-account-tree.component';
import { AdminAccountingPeriodComponent } from './admin/systemSettings/admin-accounting-period/admin-accounting-period.component';
import { AdminSettingAccountingComponent } from './admin/systemSettings/admin-setting-accounting/admin-setting-accounting.component';
import { AccountingAccountSelectComponent } from './admin/systemSettings/admin-setting-accounting/accounting-account-select/accounting-account-select.component';
import { AdminSettingRegistrationAccountingComponent } from './admin/systemSettings/admin-setting-registration-accounting/admin-setting-registration-accounting.component';
import { SelectRegistrationAccountingComponent } from './admin/systemSettings/admin-setting-registration-accounting/select-registration-accounting/select-registration-accounting.component';
import { TypePolicyComponent } from './admin/accounting/type-policy/type-policy.component';
import { TypePolicyUpdateComponent } from './admin/accounting/type-policy/type-policy-update/type-policy-update.component';
import { PolicyCustomerComponent } from './admin/accounting/policy/policy.component';
import { PolicyDocumentComponent  } from './admin/accounting/policy/policy-document/policy-document.component';
import {  PolicyCustomerUpdateComponent } from './admin/accounting/policy/policy-customer-update/policy-customer-update.component';
import {  AddDocumentComponent } from './admin/accounting/policy/policy-customer-update/add-document/add-document.component';
import {  ConfirmDeletePolicyComponent } from './admin/accounting/policy/confirm-delete-policy/confirm-delete-policy.component';
import { SaleDashboardComponent } from './admin/sale/sale-dashboard/sale-dashboard.component';
import { InventoryKardexWarehouseComponent } from './admin/inventory/inventory-kardex-warehouse/inventory-kardex-warehouse.component';
import { InventoryAdjustmentDetailsComponent } from './admin/inventory/inventory-adjustment/inventory-adjustment-details/inventory-adjustment-details.component';
import { CounterTraspasoComponent } from './counter/counter-traspaso/counter-traspaso.component';
import { CounterTraspasoUpdateComponent } from './counter/counter-traspaso/counter-traspaso-update/counter-traspaso-update.component';
import { CounterTraspasoDetailsComponent } from './counter/counter-traspaso/counter-traspaso-details/counter-traspaso-details.component';
import { CounterTraspasoRecibidoComponent } from './counter/counter-traspaso-recibido/counter-traspaso-recibido.component';
import { CounterTraspasoRecibidoFinishComponent } from './counter/counter-traspaso-recibido/counter-traspaso-recibido-finish/counter-traspaso-recibido-finish.component';
import { CounterBudgetListComponent } from './counter/start-shift/counter-budget-list/counter-budget-list.component';
import { CounterBudgetUpdateComponent } from './counter/start-shift/counter-budget-update/counter-budget-update.component';
import { StiAdjustmentProductComponent } from './directive/sti-adjustment-product/sti-adjustment-product.component';

@NgModule({
  declarations: [
    AppComponent,
    StiContactComponent,
    StiAddressComponent,
    StiFileComponent,
    LoginComponent,
    SelectedObjectComponent,
    AdminComponent,
    MenuComponent,
    ToolBarComponent,
    BranchComponent,
    BranchUpdateComponent,
    ProductComponent,
    ProductUpdateComponent,
    ProductImportComponent,
    ProductPriceComponent,
    ProductPriceImportComponent,
    ProductCategoriesComponent,
    ProductMakerComponent,
    ProductMeasurementComponent,
    ProductMeasurementUpdateComponent,
    ProductMakerUpdateComponent,
    ProductCategoriesUpdateComponent,
    WarehouseComponent,
    WarehouseUpdateComponent,
    EmployeeComponent,
    EmployeeUpdateComponent,
    CompanyComponent,
    CompanyUpdateComponent,
    ProductProviderComponent,
    ProductProviderUpdateComponent,
    AdjustmentTypeComponent,
    AdjustmentTypeUpdateComponent,
    InventoryAdjustmentComponent,
    InventoryAdjustmentUpdateComponent,
    InventoryAdjustmentValidateComponent,
    DialogAdjusmentIncomplete,
    InventoryDashboardComponent,
    ProductProviderRelationComponent,
    SettingProductsMinPriceComponent,
    SaleClientComponent,
    SaleClientUpdateComponent,
    InventoryTransferComponent,
    InventoryTransferUpdateComponent,
    InventoryTransferFinishComponent,
    InventoryTransferDetailsComponent,
    AdminRoleComponent,
    AdminRoleUpdateComponent,
    AdminUsersComponent,
    AdminUsersUpdateComponent,
    AdminUsersAccessComponent,
    CounterDashboardComponent,
    CounterTransferComponent,
    StartShiftComponent,
    StartShiftUpdateComponent,
    CounterTransferUpdateComponent,
    InventoryTransferRequestComponent,
    InventoryTransferRequestUpdateComponent,
    InventoryTransferRequestDetailsComponent,
    CounterTransferDetailsComponent,
    CounterTransferReceiveComponent,
    CounterExistenceComponent,
    CounterSaleshiftUpdateComponent,
    CounterShiftPaymentComponent,
    TerminalComponent,
    TerminalUpdateComponent,
    SaleTerminalUpdateComponent,
    PurchaseGenerateComponent,
    PurchaseGenerateUpdateComponent,
    InventoryKardexComponent,
    SaleClientCreditComponent,
    SaleClientCreditDetailsComponent,
    SaleClientCreditUpdateComponent,
    CounterSaleshiftCloseComponent,
    CounterSaleEndByeComponent,
    CatalogSaleCredittypeComponent,
    CatalogSaleCredittypeUpdateComponent,
    CatalogSaleCreditDiscounttableComponent,
    CatalogSaleCreditDiscounttableUpdateComponent,
    SaleRequestClientCreditComponent,
    CounterCreditRequestComponent,
    CounterCreditRequestUpdateComponent,
    SaleSaleshiftDasboardComponent,
    SaleSaleshiftDasboardDetailsComponent,
    CounterCreditClientComponent,
    CounterHistoricalCreditComponent,
    InventoryAdjustmentImportComponent,
    PurchaseGenerateDetailsComponent,
    CounterProductComponent,
    CounterProductUpdateComponent,
    CounterAdjustmentComponent,
    CounterAdjustmentValidateComponent,
    CounterAdjustmentUpdateComponent,
    CounterPurchaseGenerateComponent,
    SaleClientCreditAbonoComponent,
    SaleClientCreditAbonosComponent,
    CounterTransferRequestComponent,
    PurchaseGeneratePeriodComponent,
    CounterPartialcutUpdateComponent,
    AdminAccountComponent,
    AdminAccountDeleteComponent,
    AdminAccountTreeComponent,
    AdminAccountingPeriodComponent,
    AdminSettingAccountingComponent,
    AccountingAccountSelectComponent,
    AdminSettingRegistrationAccountingComponent,
    SelectRegistrationAccountingComponent,
    TypePolicyComponent,
    TypePolicyUpdateComponent,
    PolicyCustomerComponent,
    PolicyDocumentComponent,
    PolicyCustomerUpdateComponent,
    AddDocumentComponent,
    ConfirmDeletePolicyComponent,
    SaleDashboardComponent,
    InventoryKardexWarehouseComponent,
    InventoryAdjustmentDetailsComponent,
    CounterTraspasoComponent,
    CounterTraspasoUpdateComponent,
    CounterTraspasoDetailsComponent,
    CounterTraspasoRecibidoComponent,
    CounterTraspasoRecibidoFinishComponent,
    CounterBudgetListComponent,
    CounterBudgetUpdateComponent,
    StiAdjustmentProductComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    TreeModule.forRoot(),
    HttpClientModule,
  ],
  entryComponents:[
    SelectedObjectComponent,
    BranchUpdateComponent,
    WarehouseUpdateComponent,
    EmployeeUpdateComponent,
    CompanyUpdateComponent,
    ProductMeasurementUpdateComponent,
    ProductMakerUpdateComponent,
    ProductCategoriesUpdateComponent,
    ProductImportComponent,
    ProductUpdateComponent,
    StiContactComponent,
    StiAddressComponent,
    StiFileComponent,
    ProductProviderUpdateComponent,
    AdjustmentTypeUpdateComponent,
    DialogAdjusmentIncomplete,
    SaleClientUpdateComponent,
    AdminRoleUpdateComponent,
    AdminUsersUpdateComponent,
    AdminUsersAccessComponent,
    CounterExistenceComponent,
    CounterSaleshiftUpdateComponent,
    SettingProductsMinPriceComponent,
    CounterShiftPaymentComponent,
    TerminalUpdateComponent,
    SaleTerminalUpdateComponent,
    CounterSaleshiftCloseComponent,
    CatalogSaleCredittypeUpdateComponent,
    CatalogSaleCreditDiscounttableUpdateComponent,
    SaleClientCreditUpdateComponent,
    CounterCreditRequestComponent,
    CounterCreditRequestUpdateComponent,
    CounterHistoricalCreditComponent,
    CounterProductUpdateComponent,
    SaleClientCreditAbonoComponent,
    SaleClientCreditAbonosComponent,
    SaleSaleshiftDasboardDetailsComponent,
    CounterPartialcutUpdateComponent,
    AdminAccountDeleteComponent,
    AdminAccountingPeriodComponent,
    AccountingAccountSelectComponent,
    SelectRegistrationAccountingComponent,
    PolicyCustomerComponent,
    PolicyDocumentComponent,
    PolicyCustomerUpdateComponent,
    AddDocumentComponent,
    ConfirmDeletePolicyComponent,
    CounterBudgetListComponent,
    CounterBudgetUpdateComponent,
    StiAdjustmentProductComponent,
  ],
  providers: [
    MenuService,
    ThemeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
