import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {Location} from '@angular/common';
import * as Rx from 'rxjs';

@Component({
  selector: 'app-inventory-transfer-request-update',
  templateUrl: './inventory-transfer-request-update.component.html',
  styleUrls: ['./inventory-transfer-request-update.component.css']
})
export class InventoryTransferRequestUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  // Aprobado -> Enviada
  //Validado -> completado
  transferModel = {
    main: {
      uuid: '',
      dateTransfer:'',
      status:'Por validar' //'Por validar', 'Validado', 'Cancelado'
    },
    complement:{
      comment:''
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      warehouseDestination:{
        main:{
          uuid:''
        }
      },
      warehouseOrigin:{
        main:{
          uuid:''
        }
      },
      employee:{
        main:{
          uuid:''
        },
        system:{
          physicalPerson:{
            main:{
              name:'',
              lastname:'',
              surname:''
            }
          }
        }
      },
      products:[]
    },
  }
  object = JSON.parse(JSON.stringify(this.transferModel));
  transferProductsListFilter = [];
  transferProductModel = {
    main:{
      uuid:'',
      quantity:1,
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      },
      transfer:{
        main:{
          uuid:''
        }
      }
    }

  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    dateTransfer:'',
    isLinear:true,
    searching:false
  }
  productResquestList = [];
  adjustment = {
    main: {
      uuid: '',
      dateAdjustment: '',
      status: 'Por validar',
      subtotal:0,
      total:0,
      discount:0,
    },
    complement: {
      comment: ''
    },
    system: {
      warehouse: {
        main: {
          uuid: ''
        }
      },
      adjustmentType: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
      products: [],
      employee:{
        main:{
          uuid:''
        }
      }
    }
  };
  adjustmentProductModel= {
    main: {
      uuid: '',
      quantity: 0
    },
    system: {
      product: {
        main: {
          uuid:'',
          code: '',
          name: ''
        },
        system: {
          transientDatas: []
        }
      },
      company:{
        main:{
          uuid:''
        }
      }
    }
  };
  adjustmentTypeList = [];
  adjustmentTypeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  productsFilter = [];
  productModel= {
    main: {
      uuid: '',
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog,private _location: Location) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.productsFilter = [];
          this.productFindAllByNameLike();
        } else {
          this.productsFilter = [];
          this.productModel.filter.value = '';
          val = 'false'
        }

        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){

      }else{
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('transfer:get',this.object).subscribe(async(data:any)=>{
          for(let i= 0; i < data.object.system.products.length; i++){
            let warehouseProduct:any = await this.getProductsAvailableByWharehouse(data.object.system.products[i].system.product,data.object.system.warehouseOrigin);
            data.object.system.products[i].metadata = {
              quantityWareHouse:warehouseProduct.main.uuid != null ? warehouseProduct.main.quantity : 0,
              quantitySend:warehouseProduct.main.uuid != null ? ( warehouseProduct.main.quantity > data.object.system.products[i].main.quantity ? data.object.system.products[i].main.quantity : warehouseProduct.main.quantity ) : 0
            };
          }
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateTransfer = moment(this.object.main.dateTransfer).format('DD-MM-YYYY HH:mm:ss');
          this.loadingService.hide();
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:transfer:get',error)
        })
      }
    });
  }

  /**funcionalidad para determinar cuanto producto hay en el amacen
  */
  getProductsAvailableByWharehouse(product,wharehouse){
    return new Promise(((resolve,reject)=>{
      this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
        (data:any) => {
          resolve(data.object);
       },
       error => {
         console.log("warehouseProduct:findByProductByWarehouse",error);
         this.loadingService.hide();
         reject(error);
       }
      );
    }))
  }

  /**funcionalidad enviar la solicitud a sucursal
  */
  send(){
    //verificamos si se crea un nuevo traspaso o este traspaso va completo
    let newTransfer = false;
    console.log(this.object.system.products);
    for(let i = 0; i < this.object.system.products.length; i++){
      if(this.object.system.products[i].metadata.quantitySend  >= this.object.system.products[i].main.quantity){
      }else{
        newTransfer = true;
      }
    }
    if(newTransfer){
      console.log("Se crea un nuevo traspaso y se actualiza el actual");
      // Se crea un nuevo traspaso en estatus "Aprobado" y se actualiza el actual a estatud 'En revisión' y se quitan los producto que se enviaron en el otro traspaso.
      let newTransfer = JSON.parse(JSON.stringify(this.transferModel));
      newTransfer.main.status = 'En revisión';
      newTransfer.complement.comment = this.object.complement.comment;
      newTransfer.system.company.main = this.object.system.company.main;
      newTransfer.system.employee.main = this.object.system.employee.main;
      newTransfer.system.warehouseOrigin.main = this.object.system.warehouseOrigin.main;
      newTransfer.system.warehouseDestination.main = this.object.system.warehouseDestination.main;
      newTransfer.system.products = [];
      // this.productResquestList = JSON.parse(JSON.stringify(this.object.system.products)); // Copia del original

      for(let i = 0; i < this.object.system.products.length; i++){
          if(this.object.system.products[i].main.quantity > this.object.system.products[i].metadata.quantitySend){
            let productAux = JSON.parse(JSON.stringify(this.transferProductModel));
            productAux.system.product.main = this.object.system.products[i].system.product.main;
            productAux.main.quantity = this.object.system.products[i].main.quantity - this.object.system.products[i].metadata.quantitySend;
            newTransfer.system.products.push(productAux);
          }
          this.object.system.products[i].main.quantity = this.object.system.products[i].metadata.quantitySend;
      }

      console.log("Faltantes",newTransfer.system.products );
      console.log("Enviados",this.object.system.products);
      this.loadingService.show(true,"Espere un momento...");
      this.updateTransfer(newTransfer,'En revisión').then((data)=>{
        this.updateTransfer(this.object,'Aprobado').then((data)=>{
          this.router.navigate(['/admin/inventory/transferRequest']);
          this.loadingService.hide();
        }).catch(e=>{
          this.loadingService.hide();
        })
      }).catch(e=>{
        this.loadingService.hide();
      });
    }else{
      console.log("Se actualiza tal como esta la  transferencia");
      this.loadingService.show(true,"Espere un momento...");
      this.updateTransfer(this.object,'Aprobado').then((data)=>{
        this.router.navigate(['/admin/inventory/transferRequest']);
        this.loadingService.hide();
      }).catch(e=>{
        console.log(e);
        this.loadingService.hide();
      })
    }
  }

  /**función para crear / actualiza una trasnferencia
  */
  updateTransfer(object,status){
    return new Promise((resolve,reject)=>{
      object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      // object.system.user.main.uuid = this.session.getUser().main.uuid;
      object.main.status = status;
      object.main.dateTransfer = moment().format('DD-MM-YYYY HH:mm:ss');
      this.session.getRequest("transfer:update", object).subscribe(
        (data:any) => {
          resolve(data.object);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteAProduct(object){
    console.log(object);
    for(let i=0; i < this.object.system.products.length; i++){
      if(this.object.system.products[i].system.product.main.uuid == object.system.product.main.uuid){
        if(object.main.uuid != ''){
          this.deteletProduct(object,i);
        }else{
          this.object.system.products.splice(i,1);
        }
      }
    }
  }

  /**función para eliminar un prodcuto del traspaso
  */
  deteletProduct(object,index){
    this.loadingService.wait();
    this.session.getRequest("transfer:deleteTransferProduct",object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.object.system.products.splice(index,1);
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  /**funcionalidad para crear un ajuste de inventario para uso temporal
  */
 async createAjustementTemp(){
    try{
      console.log("Pedido",this.object);
      this.loadingService.show(true,"Aplicando cambios, espere un momento...");
      this.adjustment.main.dateAdjustment = moment().format('DD-MM-YYYY HH:mm:ss');
      this.adjustment.main.discount = this.object.main.discount;
      this.adjustment.main.subtotal = this.object.main.subtotal;
      this.adjustment.main.total = this.object.main.total;
      this.adjustment.complement.comment = this.object.complement.comment;
      this.adjustment.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.adjustment.system.employee.main = this.object.system.employee.main;
      this.adjustment.system.warehouse.main = this.object.system.warehouseOrigin.main;
      let adjustmentType:any = await this.getAjustmentType();
      this.adjustment.system.adjustmentType.main = adjustmentType.main;
      //creamos los productos del ajuste
      for(let item of this.object.system.products){
        if(item.metadata.quantityWareHouse == 0 || item.main.quantity  < item.metadata.quantitySend){
          let adjustmentP = JSON.parse(JSON.stringify(this.adjustmentProductModel));
          adjustmentP.main.quantity = item.main.quantity;
          adjustmentP.system.product.main.uuid = item.system.product.main.uuid;
          adjustmentP.system.product.main.name = item.system.product.main.name;
          adjustmentP.system.product.main.code = item.system.product.main.code;
          adjustmentP.system.company.main = this.session.getSessionObject().main;
          this.adjustment.system.products.push(adjustmentP);
        }
      }
      this.session.getRequest("adjustment:update", this.adjustment).subscribe(
        (data:any) => {
          //realizamos el ajuste al inventario
          this.adjustment.main.uuid = data.object.main.uuid;
          this.adjustment.main.status = 'Validado';
          for (let i = 0; i < this.adjustment.system.products.length; i++) {
            this.adjustment.system.products[i].system.products = [];
            for (let j = 0; j < this.adjustment.system.products[i].main.quantity; j++) {
              this.adjustment.system.products[i].system.products.push({ main: { uuid: '' }, system: { transientDatas: [] } })
            };
          }
          this.session.getRequest("adjustment:validateAdjustment", this.adjustment).subscribe(
            (data:any) => {
              this.snackBar.open('El regsitro se guardo correctamente', '', {
                duration: 8000
              });
              this.loadingService.hide();
              //recargamos los datos de pedido
              this.reloadRequest();
           },
           error => {
             console.log("adjustment:validateAdjustment",error);
             this.loadingService.hide();
           }
          );
       },
       error => {
         console.log("adjustment:update",error);
         this.loadingService.hide();
       });
      console.log("Ajuste Temporar", this.adjustment);
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**Método para obetner el tipos de ajuset a realizar
  **/
  getAjustmentType(){
    return new Promise((resolve,reject)=>{
      this.adjustmentTypeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.adjustmentTypeList = [];
      // verificamos en que pagina verificamos
      this.session.getRequest("adjustmentType:findAllByCompany", this.adjustmentTypeMin).subscribe(
        (data: any) => {
          this.adjustmentTypeList = data.object.instanceList;
          let object = {
            main:{
              uuid:''
            }
          };
          for (let item of this.adjustmentTypeList){
            if(item.main.description == 'AJUSTE POR PEDIDO'){
              object = JSON.parse(JSON.stringify(item));
            }
          }
          resolve(object);
        },
        error => {
          console.log("Erro: adjustmentType:findAllByCompany", error);
          reject(error);
        }
      );
    });
  }

  /*funcion para hacer reload pedido*
  */
  reloadRequest(){
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest('transfer:get',this.object).subscribe(async(data:any)=>{
      for(let i= 0; i < data.object.system.products.length; i++){
        let warehouseProduct:any = await this.getProductsAvailableByWharehouse(data.object.system.products[i].system.product,data.object.system.warehouseOrigin);
        data.object.system.products[i].metadata = {
          quantityWareHouse:warehouseProduct.main.uuid != null ? warehouseProduct.main.quantity : 0,
          quantitySend:warehouseProduct.main.uuid != null ? ( warehouseProduct.main.quantity > data.object.system.products[i].main.quantity ? data.object.system.products[i].main.quantity : warehouseProduct.main.quantity ) : 0
        };
      }
      this.object = JSON.parse(JSON.stringify(data.object));
      this.metadata.dateTransfer = moment(this.object.main.dateTransfer).format('DD-MM-YYYY HH:mm:ss');
      this.loadingService.hide();
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error:transfer:get',error)
    })
  }
  backClicked() {
  this._location.back();
}
  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.productModel.filter.value);
  }

  productFindAllByNameLike() {
    this.productsFilter = [];
    this.metadata.searching = true;
    this.productModel.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    // this.productModel.filter.value = name;
    this.session.getRequest('product:findAllByCompany',this.productModel).subscribe(
      (data:any)=>{
        this.productsFilter = data.object.instanceList;
        this.metadata.searching = false;
      },
      error => {
        console.log("product:findAllByCompany",error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
      })
  }

  /*
  Funcion para agregar un producto al traspaso
  */
  addProductToSelectedList(object){
    this.getProductsAvailableByWharehouse(object,this.object.system.warehouseOrigin).then((data:any)=>{
      if(data.main.uuid != null && data.main.uuid != 'null' && data.main.quantity > 0){
        let transferProductAux = JSON.parse(JSON.stringify(this.transferProductModel));
        transferProductAux.main.quantity = 1;
        transferProductAux.system.product = data.system.product;
      transferProductAux.metadata = {
          quantityWareHouse: data.main.quantity,
          quantitySend:1
        };
        this.object.system.products.push(transferProductAux);
        this.productModel.filter.value = '';
      }else{
        this.snackBar.open('No hay existencias de este producto en el inventario.', 'Aceptar', {
          duration: 8000
        });
      }
    })
    this.productsFilter = [];
  }

}
