import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { CounterProductUpdateComponent } from './counter-product-update/counter-product-update.component';
import { SessionService } from './../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from './../../directive/loading/loading.module';

@Component({
  selector: 'app-counter-product',
  templateUrl: './counter-product.component.html',
  styleUrls: ['./counter-product.component.css']
})
export class CounterProductComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    company:{
      main:{
        uuid:''
      }
    },
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  pageEvent: PageEvent;
  productList = [];
  productMin = {
    system: {
      kindProduct: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };

  constructor(public dialog: MatDialog, public loadingService: LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.productList = [];
          this.productMin.filter.name = 'likeNameOrCode';
        } else {
          this.productList = [];
          this.productMin.filter.name = '';
          this.productMin.filter.value = '';
          val = 'false'
        }
        this.getProductList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.getProductList();
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  // funcion para obtener la lista de productos
  getProductList() {
    this.loadingService.wait();
    this.productList = [];
    let branch:any = this.session.getSessionObject();
    this.metadata.company = branch.system.company;
    this.productMin.system.company.main.uuid = this.metadata.company.main.uuid;
    this.session.getRequest("product:findAllByCompany", this.productMin).subscribe(
      (data: any) => {
        this.productList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("product:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }
  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.productMin.filter.value);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.productMin.offset = (event.pageIndex * event.pageSize);
    this.productMin.max = event.pageSize;
    this.getProductList();
  }
  /**
   * Modal para abrir product Update
   */
  productUpdateDialog(uuid) {
    this.dialog.open(CounterProductUpdateComponent, {
      width: '80%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getProductList();
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

}
