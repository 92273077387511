import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialog } from '@angular/material/dialog';
import { AdminUsersAccessComponent } from './admin-users-access/admin-users-access.component';
import { AdminUsersUpdateComponent } from './admin-users-update/admin-users-update.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    company:{
      main:{
        uuid:''
      }
    },
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    admins:false,
  }
  // MatPaginator Output
  pageEvent: PageEvent;

  userList = [];
  userMin = {
    type:"Branch",
    objectUuid:"",
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  companyList = [];


  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,public dialog: MatDialog) {
   }

   ngOnInit() {
     // this.drawerCtrl.opened = true;
     this.metadata.company.main.uuid = this.session.getSessionObject().main.uuid;
     this.findAllUsersByCompany();
   }

  findAllUsersByCompany(){
    this.userMin.objectUuid = this.metadata.company.main.uuid;
    this.session.getRequest('user:list', this.userMin).subscribe(
      (data:any)=>{
        this.userList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("user:list",error);
      })
  }
  /*
  Funcion a ejecutar cuando cambien el tab
  */
  findAllRequestByType(event){
    if(event.checked){
      this.userMin.type = 'Company';
    }else{
      this.userMin.type = 'Branch';
    }
    this.findAllUsersByCompany();
    // if(this.userMin.objectUuid != ''){
    //   this.userMin.max = 10;
    //   this.userMin.offset = 0;
    //   switch(event.index){
    //     case 0:{
    //       this.userMin.type = 'Company';
    //       break;
    //     }
    //     case 1:{
    //       this.userMin.type = 'Branch';
    //       break;
    //     }
    //   }
    //   this.findAllUsersByCompany();
    // }
  }
  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object){
    for(let i=0; i < this.userList.length; i++){
      this.userList[i].system.selected = false;
    }
    if(object.main.uuid == this.metadata.object.main.uuid){
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    }else{
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }
  /**funcion para crear un usuario
  */
  openUpdateAccess(object){
    this.dialog.open(AdminUsersUpdateComponent, {
      width: '70%',
      data: { object: object}
    }).afterClosed().subscribe(result => {
      if(result){
        this.findAllUsersByCompany();
      }
    });
  }

  openRoleAccess(object){
    this.dialog.open(AdminUsersAccessComponent , {
      width: '50%',
      data: { object: object}
    }).afterClosed().subscribe(result => {
      if(result){
        this.findAllUsersByCompany();
      }
    });
  }
  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.userMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.userMin.offset = (event.pageIndex * event.pageSize);
    this.userMin.max = event.pageSize;
    this.findAllUsersByCompany();
  }
  /*
   * funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
