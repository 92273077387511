import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-counter-budget-update',
  templateUrl: './counter-budget-update.component.html',
  styleUrls: ['./counter-budget-update.component.css']
})
export class CounterBudgetUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
