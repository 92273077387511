import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import {Location} from '@angular/common';

@Component({
  selector: 'app-inventory-transfer-request-details',
  templateUrl: './inventory-transfer-request-details.component.html',
  styleUrls: ['./inventory-transfer-request-details.component.css']
})
export class InventoryTransferRequestDetailsComponent implements OnInit {
  transferModel = {
    main: {
      uuid: '',
      dateTransfer:'',
      status:'Por validar' //'Por validar', 'Validado', 'Cancelado'
    },
    complement:{
      comment:''
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      warehouseDestination:{
        main:{
          uuid:''
        }
      },
      warehouseOrigin:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        },
        system:{
          physicalPerson:{
            main:{
              name:'',
              lastname:'',
              surname:''
            }
          }
        }
      },
      products:[]
    },
  }
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  object = JSON.parse(JSON.stringify(this.transferModel));
  transferProductsListFilter = [];
  metadata = {
    dateTransfer:'',
    isLinear:true,
    searching:false
  }
  constructor( public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, private _location: Location) {
  }


  ngOnInit() {
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){

        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('transfer:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateTransfer = moment(this.object.main.dateTransfer).format('DD-MM-YYYY HH:mm:ss');
          this.loadingService.hide();
          // console.log(this.object);
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:transfer:get',error)
        })
      }
    });
  }
  backClicked() {
  this._location.back();
}
  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
