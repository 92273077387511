import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../../service/session/session.module';
import { LoadingService } from '../../../../../directive/loading/loading.module';

@Component({
  selector: 'app-catalog-sale-credittype-update',
  templateUrl: './catalog-sale-credittype-update.component.html',
  styleUrls: ['./catalog-sale-credittype-update.component.css']
})
export class CatalogSaleCredittypeUpdateComponent implements OnInit {
  creditTypeModel = {
    main:{
      uuid:'',
      creditType:'', // Tipo de crédito: Indefinido, 30 días, etc.
      day:0, // dias de credito
      maxAmount:0.0 // monto maximo del credito.
    },
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };
  object = JSON.parse(JSON.stringify(this.creditTypeModel));

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<CatalogSaleCredittypeUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.session.getRequest('creditType:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.loadingService.hide();
      },
        (error) => {
          this.loadingService.hide();
          console.log('Error:creditType:get', error)
        })
    }
  }
  save(){
    this.loadingService.wait();
    this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("creditType:update",this.object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.close(true);
    },error=>{
      this.loadingService.hide();
      console.log("error:",error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'creditType:001';
    }
    this.dialogRef.close(object);
  }

}
