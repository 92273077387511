import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialogRef } from '@angular/material';
import { SessionService } from '../../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-product-import',
  templateUrl: './product-import.component.html',
  styleUrls: ['./product-import.component.css']
})
export class ProductImportComponent implements OnInit {
  metadata = {
    image:{
      name:'',
      type:'',
      file:''
    },
    message:'',
    errors: false,
    numError: 0,
    numWarning: 0,
    numProcess: 0
  }
  list = [];

  constructor(public dialogRef: MatDialogRef<ProductImportComponent>, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  uploadAFile(){
    console.log("Archivo...",this.metadata);
    this.loadingService.show(true, 'Realizando importación...');
    this.metadata.image.file = this.metadata.image.file.replace(/\+/g,"-");
    this.session.getRequest("product:importProduct", {file: this.metadata.image, main:{uuid: this.session.getSessionObject().main.uuid}}).subscribe((data:any)=>{
      this.snackBar.open(data.message, 'Información', {duration: 5000});
      this.metadata.message = data.message;
      if(data.object.numError >0 || data.object.numWarning >0){
        this.metadata.errors = true;
        this.metadata.numError = data.object.numError;
        this.metadata.numWarning = data.object.numWarning;
      }
      this.metadata.numProcess = data.object.numProcess;
      if(data.object.productListError.length > 0){
        this.list = data.object.productListError;
      }
      this.loadingService.hide();
    },error=>{
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
      console.log("product:importProduct",error);
    })
  }

  /*
  Funcion para descargar reporte de excel de datos de trabajadores.
  */
  getReportErrors(){
    this.loadingService.show(true,'Descargando, espere un momento...');
    this.session.getRequest('product:createToReportErrors', {lista: this.list}).subscribe(
      (data:any)=>{
        var elem = document.createElement('a');
        elem.href = data.object;
        elem.setAttribute('download', 'layaout-product-errors.xlsx');
        document.body.appendChild(elem);
        elem.click();
        this.loadingService.hide();
      },
      error => {
        console.log("product:createToReportErrors",error);
        this.loadingService.hide();
        this.snackBar.open('Error en la descarga', 'Error', {duration: 5000});
      })
  }
  /*
  funcion para cerrar el modal de incremento salarial.
  */
  closeDialog(object){
    this.dialogRef.close(object);
  }


}
