import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { ProductUpdateComponent } from './product-update/product-update.component';
import { SessionService } from '../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';
import { ProductImportComponent } from './product-import/product-import.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  productList = [];
  productMin = {
    system: {
      kindProduct: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  constructor(public dialog: MatDialog, public loadingService: LoadingService, protected session: SessionService, public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.productList = [];
          this.productMin.filter.name = 'likeNameOrCode';
        } else {
          this.productList = [];
          this.productMin.filter.name = '';
          this.productMin.filter.value = '';
          val = 'false'
        }
        this.getProductList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.getProductList();
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  // funcion para obtener la lista de productos
  getProductList() {
    this.loadingService.wait();
    this.productList = [];
    this.productMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("product:findAllByCompany", this.productMin).subscribe(
      (data: any) => {
        this.productList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("product:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }
  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.productMin.filter.value);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.productMin.offset = (event.pageIndex * event.pageSize);
    this.productMin.max = event.pageSize;
    this.getProductList();
  }
  /**
   * Modal para abrir product Update
   */
  productUpdateDialog(uuid) {
    this.dialog.open(ProductUpdateComponent, {
      width: '80%',
      data: uuid
    }).afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {
          // El modal se cerro con objeto
          this.getProductList();
        } else {
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  openImportProducts(){
    this.dialog.open(ProductImportComponent, {
      width: '80%',
    }).afterClosed().subscribe(result => {
      if(result){
        //Las notificaciones se enviaron en el modal, NO se hace nada.
        //Se importo exitosamente productos.
        this.getProductList();
      }else{
        //No hacemos Nada
      }
    });
  }


  disable(object){
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest('product:get',{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      let object = JSON.parse(JSON.stringify(data.object));
      object.system.status = false;
      this.session.getRequest("product:update",object).subscribe((data:any)=>{
        this.snackBar.open('El registro se desactivó correctamente', '', {
          duration: 8000
        });
        for(let  i = 0; i < this.productList.length; i++){
          if(this.productList[i].main.uuid == object.main.uuid){
            this.productList.splice(i,1);
          }
        }
        this.loadingService.hide();
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error:product:get',error)
    })

  }
}
