import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDrawer } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDialog } from '@angular/material/dialog';
import { CounterExistenceComponent } from '../counter-existence/counter-existence.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-counter-traspaso',
  templateUrl: './counter-traspaso.component.html',
  styleUrls: ['./counter-traspaso.component.css']
})
export class CounterTraspasoComponent implements OnInit {

  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  transferList = [];
  transferMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      },
      warehouseDestination:{
        main:{
          uuid:''
        }
      },
      warehouseOrigin:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    warehouse:{
      main:{
        uuid:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog:MatDialog) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.transferList = [];
        this.transferMin.filter.name = 'searchElement';
      }else{
        this.transferList = [];
        this.transferMin.filter.name = '';
        this.transferMin.filter.value = '';
        val = 'false'
      }
      this.gettransferList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.gettransferList();
  }

  gettransferList(){
    this.loadingService.wait();
    this.transferList = [];
    // verificamos en que pagina verificamos
    this.transferMin.system.warehouseOrigin.main = this.session.getWarehouse().main;
    let branch:any = this.session.getSessionObject();
    this.transferMin.system.company.main = branch.system.company.main;
    this.session.getRequest("transfer:findAllByWarehouseOrigin", this.transferMin).subscribe(
      (data: any) => {
        this.transferList = data.object.instanceList;
        // console.log("list", this.transferList)
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("transfer:findAllByWarehouseOrigin", error);
        this.loadingService.hide();
      }
    );
  }

  //funcion para controlar si se abre o cierra el menu
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
  /*
  *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.transferMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.transferMin.offset = (event.pageIndex * event.pageSize);
    this.transferMin.max = event.pageSize;
    this.gettransferList();
  }


  getDateFormat(date){
    return moment(date).format('DD-MM-YYYY HH:mm')
  }

  /**función para abrir el modal de existencias
  */
  openExistence(){
    const dialogRef = this.dialog.open(CounterExistenceComponent, {
      width: '80%',
    });
  }

}
