import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { EmployeeUpdateComponent } from './employee-update/employee-update.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  list = [];
  employeeMin = {
    system:{
      status:true,
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar, private loadingService: LoadingService) {
    this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.list = [];
        this.employeeMin.filter.name = 'findAllByCompanyAndLikeName';
      }else{
        this.list = [];
        this.employeeMin.filter.name = '';
        this.employeeMin.filter.value = '';
        val = 'false'
      }
      this.getEmployeeList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.getEmployeeList();
  }

  // funcion para obtener la lista de empleados
  getEmployeeList (){
    this.list = [];
    this.loadingService.wait();
    this.employeeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.employeeMin.system.status = true;
    if(this.employeeMin.filter.name != ''){
      this.session.getRequest("employee:list",this.employeeMin).subscribe(
        (data:any) => {
          this.list = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
       },
       error => {
         console.log("employee:list",error);
         this.loadingService.hide();
       }
      );
    }else{
      this.session.getRequest("employee:findAllByCompanyAndStatus",this.employeeMin).subscribe(
        (data:any) => {
          this.list = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
       },
       error => {
         console.log("employee:findAllByCompanyAndStatus",error);
         this.loadingService.hide();
       }
      );
    }

  }

  // Funcion para inactivar trabajador
  disableAEmployee(object){
    this.loadingService.show(true,"Espere un momento..");
    this.session.getRequest('employee:get',object).subscribe((data:any)=>{
      object = JSON.parse(JSON.stringify(data.object));
      object.system.physicalPerson.complement.dateBirth = moment(object.system.physicalPerson.complement.dateBirth).format("DD-MM-YYYY");
      object.system.status = false;
      this.session.getRequest("employee:update", object).subscribe(
        data => {
          this.loadingService.hide();
          this.snackBar.open('El trabajador se desactivó correctamente', '', {
            duration: 8000
          });
          for(let  i = 0; i < this.list.length; i++){
            if(this.list[i].main.uuid == object.main.uuid){
              this.list.splice(i,1);
            }
          }
       },
       error => {
         console.log("Error: employee:update",error);
         this.loadingService.hide();
       }
      );
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error:employee:get',error)
    })
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.employeeMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.employeeMin.offset = (event.pageIndex * event.pageSize);
    this.employeeMin.max = event.pageSize;
    this.getEmployeeList();
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Modal para dar de alta un empleados
   */
  UpdateDialog(uuid) {
    this.dialog.open(EmployeeUpdateComponent, {
      width: '60%',
      height:'40rem',
      data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getEmployeeList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

}
