import { Component, OnInit , ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { ProductMeasurementUpdateComponent } from './product-measurement-update/product-measurement-update.component';
import { SessionService } from '../../../../service/session/session.service';

@Component({
  selector: 'app-product-measurement',
  templateUrl: './product-measurement.component.html',
  styleUrls: ['./product-measurement.component.css']
})
export class ProductMeasurementComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  unitMeasureList = [];
  unitMeasureMin = {
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  constructor(public dialog: MatDialog, protected session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getUnitMeasureList();
  }

  // funcion para obtener la lista de sucursales
  getUnitMeasureList(){
    this.unitMeasureList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("unitMeasure:list",this.unitMeasureMin).subscribe(
      (data:any) => {
        this.unitMeasureList = data.object.instanceList;
     },
     error => {
       console.log("Error: unitMeasure:list",error);
     }
    );
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
  /**
   * Modal para visualizar el detalle de lo consumido por el paciente
   */
  unitMeasureUpdateDialog(uuid) {
    this.dialog.open(ProductMeasurementUpdateComponent, {
      width: '60%',
      data: uuid,
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getUnitMeasureList();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }
}
